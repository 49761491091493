import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { useTheme, styled } from '@mui/material/styles'
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';

// ----------------------------------------------------------------------

const ListItemStyle = styled(props => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body1,
  height: 48,
  flexGrow: 0,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  color: theme.palette.navbar.primary,
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.navbar.hoverBg,
  },
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
}

function NavItem({ item, active }) {
  const theme = useTheme()
  const isActiveRoot = active(item.path)
  const { title, path, info, children, styles = {} } = item
  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen(prev => !prev)
  }

  const activeRootStyle = {
    color: 'primary.main',
    bgcolor: theme.palette.background.activeBg,
    width: '90%',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    '&:hover': {
      bgcolor: theme.palette.background.activeBg,
    },
  }

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            ...styles,
          }}>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {children.map(item => {
              const { title, path } = item
              const isActiveSub = active(path)

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}>
                  <ListItemIconStyle>
                    <Box
                      component='span'
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: theme =>
                          theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              )
            })}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        ...styles,
      }}>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
}

const listStyle = {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}

export default function NavSection({ navConfig, ...rest }) {
  const { pathname } = useLocation()
  const match = path =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  return (
    <Box {...rest}>
      <List sx={listStyle} component='nav' disablePadding>
        {navConfig.map(item => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  )
}
