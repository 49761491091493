
import axios from '../config/axiosConfig';

import { createSortUrl } from './urlBuilder'

export const listCurrencies = async (search, page, limit, sort={}) => {
    const url = `/v1/currencies?${search}&page=${page}&size=${limit}&${createSortUrl(sort)}`;
    const response = await axios.get(url)

    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getCurrencyById = async (id) => {
    const response = await axios.get(`/v1/currencies/${id}`);
    return response.data;
}

export const addCurrency = async (currency) => {
    const response = await axios.post(`/v1/currencies`, currency);
    return response.data;
}

export const updateCurrency = async (currency) => {
    const response = await axios.put(`/v1/currencies/${currency.id}`, currency);
    return response.data;
}

export const deleteCurrency = async(id) => {
    const response = await axios.delete(`/v1/currencies/${id}`);
    return response.data;
}