import PropTypes from 'prop-types'

// Material
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const StyledPageHeading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    fontSize: '40px',
  },
}))

PageHeading.propTypes = {
  children: PropTypes.any.isRequired,
}

export default function PageHeading({ children, ...otherProps }) {
  return (
    <StyledPageHeading variant='h1' {...otherProps}>
      {children}
    </StyledPageHeading>
  )
}
