
import axios from '../config/axiosConfig';

import { getRoles } from './authenticationService'

export const listTransfers = async (search, page, limit) => {
    let url = `/v1/transfers?${search}&page=${page}&size=${limit}&sort=id,desc`
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getTransferById = async (id) => {
    let url = `/v1/transfers/${id}`
    const response = await axios.get(url);
    return response.data;
}

export const getTransferStatus = async (id) => {
    return ""
}

export const simulateTransfer = async (type, transfer) => {
    
    console.log(type, transfer)
    let url = `/v1/transfers/simulations`;
    const response = await axios.post(url, transfer);
    return response.data;
}

export const createTransfer = async (type, transfer) => {
    let url = "";
    if(getRoles().indexOf("ROLE_ADMIN") > -1) {
        url = `/v1/admin/transfers`;
    } else {
        url = `/v1/transfers`;
    }

    const response = await axios.post(url, transfer);
    return response.data;
}

export const simulateOnRampFunding = async(transferId) => {
    let url = "/v1/transfers/" + transferId + "/on-ramp/sandbox/funding";
    const response = await axios.post(url, {});
    return response.data;
}

export const closeTransfer = async (type, transfer) => {
    let url = `/v1/callback/bitso`
    const response = await axios.post(url, transfer);
    return response.data;
}

// this method should be used in order to get events for a transfer
export const getEventsForTransfer = async (id) => {
    let url = `/v1/admin/transfers/${id}/events`
    const response = await axios.get(url);
    return response.data;
}