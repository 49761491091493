import {
  useCallback,
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react'

// Material
import { styled } from '@mui/material/styles'

// Components
import { FileUploadContext } from '../providers/FileUploadProvider'

const StyledFileUploadWraper = styled('div')(({ theme }) => ({
  width: '200px',
  height: '200px',
  border: `1px dashed ${theme.palette.grey.secondary}`,
  borderRadius: '8px',
  padding: theme.spacing(3),
}))

function convertFilesObjectToArray(filesObject) {
  let i = 0
  let numFiles = filesObject.length
  const filesArr = []

  for (; i < numFiles; i++) {
    filesArr.push(filesObject[i])
  }

  return filesArr
}

// Main
export default function FileUpload({
  children,
  onFileAdd = () => {},
  draggingStateComponent: IsDraggingComponent,
  disabled = false,
}) {
  const wrapper = useRef(null)
  const { addFiles } = useContext(FileUploadContext)
  const [isDragging, setIsDraggingState] = useState(false)

  // Check if dropped element contains files
  // and if so, pass them to parent component
  const handleDrop = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      if (!disabled) {
        const { files } = e.dataTransfer
        const filesList = convertFilesObjectToArray(files)

        if (files && files.length) {
          addFiles(filesList)
          onFileAdd(filesList)
        }
      }
    },
    [addFiles, onFileAdd, disabled],
  )
  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragEnter = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      if (!disabled) {
        setIsDraggingState(true)
      }
    },
    [disabled, setIsDraggingState],
  )

  const handleDragLeave = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      if (!disabled) {
        setIsDraggingState(false)
      }
    },
    [disabled, setIsDraggingState],
  )

  useEffect(() => {
    const wrapperEl = wrapper.current

    if (wrapperEl) {
      wrapperEl.addEventListener('dragover', handleDragOver)
      wrapperEl.addEventListener('dragenter', handleDragEnter)
      wrapperEl.addEventListener('dragleave', handleDragLeave)
      wrapperEl.addEventListener('drop', handleDrop)
    }

    return () => {
      wrapperEl.removeEventListener('dragover', handleDragOver)
      wrapperEl.removeEventListener('dragenter', handleDragEnter)
      wrapperEl.removeEventListener('dragleave', handleDragLeave)
      wrapperEl.removeEventListener('drop', handleDrop)
    }
  }, [handleDrop, handleDragEnter, handleDragLeave])

  return (
    <StyledFileUploadWraper ref={wrapper} className='file_upload_wrapper'>
      {IsDraggingComponent && isDragging ? (
        <IsDraggingComponent />
      ) : (
        children
      )}
    </StyledFileUploadWraper>
  )
}
