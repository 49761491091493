import CurrencyFormat from 'react-currency-format'
import { TextField } from '@mui/material'

export default function NumberFormatCustom(props) {
  return (
    <CurrencyFormat
      {...props}
      customInput={TextField}
      fullWidth
      label={props.label}
      value={props.value}
      disabled={props.readOnly}
      onChange={e => {
        props.onChange(e.target.value)
      }}
      thousandSeparator
    />
  )
}
