import { useMemo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { getRoles } from '../../services/authenticationService'


import { SECTION_BASE_PATHS } from '../../utils/general'

// Services
import { getBalance } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import View from '../../components/crud/view'

import {
    getDollarAmountWithCurrency,
  } from '../../utils/general'

// COnstants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Utils - maybe move into utils/general.js
const formatDate = (d) => {
  const date = new Date(d)

  return format(date, 'd MMM, yyyy')
}
// Styling
BeneficiaryDetailPage.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
}
export default function BeneficiaryDetailPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)
  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getBalance(id)
      .then(balance => {
        setData(balance)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Balance is not enabled in Non-Custodial Flows')
        setLoading(false)
      })
  }, [setData, setLoading, id])

  const fields = useMemo(
    () => [
      {
        label: {
          displayValue: 'Total',
        },
        value: {
          displayValue: getDollarAmountWithCurrency({
            amount: data.total || 0,
            currencyCode: "USD"
          }),
        },
      },
    ],
    [data],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isEmpty(data)) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'balance',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  return (
    <Page className='main_content' title={'Beneficiary | View'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary |  ${
            data.lastName || ''
          } Basic Info`}
          tabsData={tabsData}
        />

        <section>
          <View fields={fields} />
        </section>
      </Loading>
    </Page>
  )
}
