import { useRef, useEffect } from 'react'

// Taken from https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export default function usePrevious(value, defaultValue = null) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef(defaultValue)

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
