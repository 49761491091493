import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getCustomShadows } from '../../theme/shadows'

const StyleTextFieldWithLabel = styled(TextField)(({ theme }) => ({
  color: theme.palette.blue.primary,
  '&:hover:before': {
    border: 'none',
  },
  '.MuiInput-root': {
    marginTop: theme.spacing(4),
  },
  '.MuiInput-root:hover:before': {
    border: 'none',
  },
  '.MuiInput-root:after': {
    border: 'none',
  },
  '.MuiInput-root:before': {
    border: 'none',
  },
  '.MuiInput-input:focus': {
    backgroundColor: theme.palette.blue.hover,
  },
  '.MuiInputLabel-root': {
    transform: 'none',
    color: 'currentColor',
  },
  '.MuiInput-input': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: getCustomShadows({ componentName: 'inputField' }),
    borderRadius: 8,
    padding: theme.spacing(1.5, 3),
  },
}))

TextFieldWithLabel.propTypes = {
  label: PropTypes.string,
  fieldKey: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onFormChangeHandler: PropTypes.func.isRequired,
  messages: PropTypes.object,
  formData: PropTypes.object,
  readOnly: PropTypes.bool,
}

TextFieldWithLabel.defaultProps = {
  type: 'text',
  size: 'medium',
  variant: 'standard',
}

export default function TextFieldWithLabel({
  onFormChangeHandler,
  fieldKey,
  readOnly,
  messages = {},
  ...rest
}) {
  const onChange = e => onFormChangeHandler(fieldKey, e.target.value)
  const helperText = messages[fieldKey]

  return (
    <StyleTextFieldWithLabel
      className='text_field_with_label'
      disabled={readOnly}
      onChange={onChange}
      error={!!helperText}
      helperText={helperText}
      {...rest}
    />
  )
}
