import axios from '../config/axiosConfig';

export const simulateLedger = async (ledgerTemplate, internalLedgerStatus) => {

    let url = `/v1/admin/ledger-transactions/simulate`;
    const request = {ledgerTemplate, internalLedgerStatus};
    console.log("simulateLedger", request)
    const response = await axios.post(url, request)
    return response.data
}

export const uploadFile = async (file) => {

    var formData = new FormData();
    formData.append("file", file);
    return axios.post('/v1/admin/ledger-transactions/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(f => f.data)
}

export const downloadFile = async (ledgerTemplate) => {

    let url = `/v1/admin/ledger-transactions/download`;
    const response = await axios.post(url, ledgerTemplate)
    return response.data
}