import * as React from 'react'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import palette from '../theme/palette'

const calendar_props = {
  backgroundColor: palette.primary.light,
  '.MuiCalendarPicker-root .MuiPickersDay-root': {
    backgroundColor: 'transparent',
  },
  '.MuiCalendarPicker-root .MuiPickersDay-root:hover': {
    backgroundColor: palette.blue.hover,
  },
  '.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected': {
    backgroundColor: palette.blue.secondary,
  },
}
export default function MaterialUIPickers({ data, inputFormat="MM/dd/yyyy", onChange, ...rest }) {
  const [value, setValue] = React.useState(data)

  const handleChange = newValue => {
    setValue(newValue)
    onChange(newValue)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        inputFormat={inputFormat}
        value={value}
        onChange={handleChange}
        className='datepicker'
        PaperProps={{ sx: calendar_props }}
        renderInput={params => <TextField {...params} />}
        {...rest}
      />
    </LocalizationProvider>
  )
}
