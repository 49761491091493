import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Save from "../../components/crud/upsert"
import { addAdmin } from '../../services/adminService'
import { errorAlert, successAlert } from '../../services/alertService'

// ----------------------------------------------------------------------

export default function AdminSave() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const saveData = (data) => {

    data.type="ADMIN";
    setLoading(true);
    return addAdmin(data)
        .then((adminCreated) => {
          navigate(`/dashboard/admins/${adminCreated.id}/edit`)
          successAlert("Admin created with success")
        })
        .catch((e) => {
          setLoading(false);
          errorAlert(e.message)
        })
  }

  const fields = {
    email: {
      type: "string",
      fieldType: "text",
      label: "Email",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 200,
        message: 'Email is to big'
      }, {
        type: "required",
        message: 'Email is required'
      }, {
        type: "email",
        message: 'Invalid email'
      }]
    },
    firstName: {
      type: "string",
      fieldType: "text",
      label: "First Name",
      constraints: [{
        type: "required",
        message: 'First Name is required'
      }]
    },
    lastName: {
      type: "string",
      fieldType: "text",
      label: "Last Name",
      constraints: [{
        type: "required",
        message: 'LastName is required'
      }]
    },
    active: {
      type: "string",
      fieldType: "switch",
      label: "Active",
    }
  };

  return (
    <Save
      label="Create Administrator"
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      initialValue={{email: "", firstName: "", lastName: "", active: false}}
      onSave={(data) => {
        saveData(data)
      }}
      goToListPage={() => navigate("/dashboard/admins/list")}
    />
  );
}
