import React from 'react';
import { Box, Stack } from '@mui/material';
import Variable from './variable'

export default ({data, onRowUpdate, rowsCalculation, ledgerStatus: {actions, operations, accounts, countries, currencies, providers, types}}) => {

    const update = (movement, cell, direction) => {

        const dataToBeUpdated = [...data];
        const newData = dataToBeUpdated.map(d => {
    
            if (d.id == cell.row.original.id) { 
                d[direction] = movement.join("");
            }
            return d;
        });
    
        onRowUpdate(newData);
    }
    
    const columnAmountToBeApplied = (row, direction) => {
    
        const item = rowsCalculation[row.id];
        if (!item || item.direction !== direction) {
            return 0
        }
        return item.amount.value;
    }
    
    const sumAll = (direction) => {
    
        const values = Object.values(rowsCalculation);
        return values.filter(it => it.direction == direction).map(it => it.amount.value).reduce((acc, c) => acc + c, 0)
    }

    return [

        {
            header: 'Ledger Action',
            accessorKey: 'action',
            size: 350,
            editVariant: 'select',
            editSelectOptions: actions
        },
        {
            header: 'Group',
            accessorKey: 'group',
            size: 120,
        },
        {
            header: 'Operation',
            accessorKey: 'operation',
            size: 120,
            editVariant: 'select',
            editSelectOptions: operations
        },
        {
            header: 'Account',
            accessorKey: 'account',
            size: 350,
            editVariant: 'select',
            editSelectOptions: accounts
        },
        {
            header: 'Debit',
            accessorKey: 'debit',
            editVariant: 'chip',
            size: 150,
            Footer: () => {
    
                return (<Stack>
                    Total Debits:
                    <Box color="warning.main">{sumAll("DEBIT")}</Box>
                </Stack>);
            },
            Cell: ({ cell }) => {
    
                const amount = columnAmountToBeApplied(cell.row.original, "DEBIT");
                return (
                    <Stack>
                        {amount}
                    </Stack>);
            },
            Edit: ({ cell, column, table }) => {
    
                return (
    
                    <Variable
                        providers={providers}
                        currencies={currencies}
                        fees={types}
                        value={cell.row.original.debit || []}
                        onChange={(debits) => update(debits, cell, "debit")}
                    />
                );
            }
        },
        {
            header: 'Credit',
            accessorKey: 'credit',
            editVariant: 'chip',
            Footer: () => {
    
                return (<Stack>
                    Total Credits:
                    <Box color="warning.main">{sumAll("CREDIT")}</Box>
                </Stack>);
            },
            size: 150,
            Cell: ({ cell }) => {
    
                const amount = columnAmountToBeApplied(cell.row.original, "CREDIT");
                return (
                    <Stack>
                        {amount}
                    </Stack>);
            },
            Edit: ({ cell, column, table }) => {
    
                return (<Variable
                    providers={providers}
                    currencies={currencies}
                    fees={types}
                    value={cell.row.original.credit || []}
                    onChange={(credits) => update(credits, cell, "credit")}
                />);
            }
        },
        {
            header: 'Currency',
            accessorKey: 'currency',
            //optionally, customize the cell render when this column is grouped. Make the text blue and pluralize the word
            GroupedCell: ({ cell, row }) => (
                <Box sx={{ color: 'primary.main' }}>
                    <strong>{cell.getValue()}s </strong> ({row.subRows?.length})
                </Box>
            ),
            size: 150,
            editVariant: 'select',
            editSelectOptions: currencies
        },
        {
            header: 'Country',
            accessorKey: 'country',
            //optionally, customize the cell render when this column is grouped. Make the text blue and pluralize the word
            GroupedCell: ({ cell, row }) => (
                <Box sx={{ color: 'primary.main' }}>
                    <strong>{cell.getValue()}s </strong> ({row.subRows?.length})
                </Box>
            ),
            size: 150,
            editVariant: 'select',
            editSelectOptions: countries
        },
        {
            header: 'Provider',
            accessorKey: 'provider',
            editVariant: 'select',
            editSelectOptions: providers,
            Cell: ({ cell }) => (
                <>
                    {cell.getValue()?.toLocaleString?.('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}
                </>
            ),
            size: 150,
    
        },
        {
            header: 'Type',
            accessorKey: 'type',
            size: 150,
            editVariant: 'select',
            editSelectOptions: types
        },
    ];
}



