export const createSortUrl = (sort) => {

    const url = Object.keys(sort).reduce((p, c) => {
        return (p.length > 0 ? (p + "&") : (p)) + "sort=" + sort[c].filter + "," + sort[c].type
    }, "");
    
    if(url.length === 0) {
        return ""
    }

    return "&" + url;
}

export const createFilters = (filters) => {
    const url = Object.keys(filters).filter(p => (filters[p] && filters[p].val) != null).reduce((p, c) => {
        return (p.length > 0 ? (p + "&") : (p)) + c + "=" + filters[c].val
    }, "");

    if(url.length === 0) {
        return ""
    }
    
    return "&" + url;
}