import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

// material
import { styled } from '@mui/material/styles'
import { Box, Container, Typography } from '@mui/material'
import palette from '../theme/palette'

// components
import Page from '../components/Page'
import Logo from '../components/Logo'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 768,
  textAlign: 'center',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}))
const LogoLink = styled(RouterLink)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(3),
  transform: 'translateX(-20px)',
}))
const LoginPageLink = styled(RouterLink)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '18px',
  color: theme.palette.blue.secondary,
}))
// ----------------------------------------------------------------------

export default function Register() {
  const navigate = useNavigate()

  return (
    <RootStyle title='Account Creation - Success'>
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 1 }}>
            <LogoLink to='/'>
              <Logo
                sx={{
                  width: 200,
                  g: { fill: palette.primary.main },
                }}
              />
            </LogoLink>
            <Typography variant='h2' gutterBottom>
              Your account was created successfully!
            </Typography>
            <Typography sx={{ color: 'text.secondary', marginBottom: 3 }}>
              You are going to receive a confirmation email
            </Typography>
            <LoginPageLink to={'/login'}>Go to login page</LoginPageLink>
          </Box>
        </ContentStyle>
      </Container>
    </RootStyle>
  )
}
