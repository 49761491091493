import * as React from 'react'

// Material
import { styled } from '@mui/material/styles'
import {
  Box,
  FormLabel,
  TextField,
  Autocomplete as MuiAutocomplete,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

// Components
import { ReactComponent as ClosedToggleIcon } from '../../images/down-arrow-toggle-icon.svg'
// ----------------------------------------------------------------------
const StyledAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '.MuiAutocomplete-inputRoot': {
    backgroundColor: theme.palette.primary.light,
  },
  '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
    right: theme.spacing(2),
  },
}))
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.blue.primary,
}))

export default function Autocomplete({
  onSearch,
  value,
  disabled,
  valueSelected,
  label,
  watchField,
  formikProps,
  onFormChangeHandler,
  fieldKey,
  ...rest
}) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  const fetchData = React.useCallback(
    (search = '') => {
      if (onSearch) {
        onSearch(search).then(data => {
          setOptions(data)
        })
      } else if (options) {
        setOptions(options)
      }
    },
    [setOptions, options, onSearch],
  )

  // @tedlin182 - Missing fetchData dependency but adding
  // it in causes infinite re-render. Need to look into this
  React.useEffect(() => {
    fetchData(value)
  }, [loading, watchField, value])

  return (
    <Box>
      {label ? <StyledFormLabel>{label}</StyledFormLabel> : null}

      <StyledAutocomplete
        defaultValue={value}
        open={open}
        autoComplete={false}
        disabled={disabled}
        popupIcon={
          <ClosedToggleIcon className='closed_toggle dropdown_toggle' />
        }
        onOpen={e => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.id === value || option.id === value.id
        }}
        getOptionLabel={(option, v) => {
          const opts = options.filter(field => {
            const id = option.id || option
            return field.id === id
          })
          const opt = opts.length > 0 ? opts[0] : { name: '' }
          return opt.name
        }}
        onChange={(v, value) => {
          onFormChangeHandler(fieldKey, value.id)
        }}
        options={options}
        loading={loading}
        renderInput={(params, key) => (
          <TextField
            key={key}
            {...params}
            {...formikProps}
            fullWidth
            onChange={e => {
              fetchData(e.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...rest}
      />
    </Box>
  )
}
