import React, { useState, useCallback } from 'react';
import { errorAlert } from '../../services/alertService'

import { Box,
  Button,
  IconButton,
  Typography,
  Stack,
  TextField,
  Tooltip, } from '@mui/material';
  
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import { getLedgerJournal, uploadTemplate, downloadTemplate } from './ledgerUtils'
import FileUpload from '../../components/FileUpload'
import CreateNewAccountModal from './createAccountModal'
import defineColumns from './ledgerTemplatesColumn'
import Status from './status'

//download
//adicionar campos para mudar ORIGINAL_AMOUNT, NET_AMOUNT and etc

const ORIGINAL_AMOUNT={
  value: 100,
  currencyCode: "BRL"
};
const AMOUNT_FOR_EXCHANGE = {
  value: 98,
  currencyCode: "BRL"
};
const GROSS_CONVERTED_AMOUNT = {
  value: 19.96,
  currencyCode: "USD"
}
const NET_AMOUNT={
  value: 19.96,
  currencyCode: "USDC"
};
const EXCHANGE_RATE = 5.01;

const FEES = [];

const LedgerTemplate = () => {

  const [loading, setLoading] = useState(false)

  const [rowsCalculation, setRowsCalculation] = useState({})
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({
    originalAmount: ORIGINAL_AMOUNT,
    netAmount: NET_AMOUNT,
    amountForExchange: AMOUNT_FOR_EXCHANGE,
    grossConvertedAmount: GROSS_CONVERTED_AMOUNT,
    exchangeRate: EXCHANGE_RATE,
    fees: FEES
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const handleCreateNewRow = (values) => {
    data.push(values);
    setData([...data]);
  };

  const handleDeleteRow = useCallback(
    (row) => {
     
      data.splice(row.index, 1);
      setData([...data]);
    },
    [data],
  );

  const actions = [
    { text: "PAYMENT_WIRE_DEPOSIT_RECEIVED", value: "PAYMENT_WIRE_DEPOSIT_RECEIVED" },
    { text: "PAYMENT_WIRE_EXCHANGE_STARTED", value: "PAYMENT_WIRE_EXCHANGE_STARTED" },
    { text: "PAYMENT_WIRE_EXCHANGE_COMPLETED", value: "PAYMENT_WIRE_EXCHANGE_COMPLETED" },
    { text: "PAYMENT_WIRE_WITHDRAWAL_STARTED", value: "PAYMENT_WIRE_WITHDRAWAL_STARTED" },
    { text: "PAYMENT_WIRE_WITHDRAWAL_COMPLETED", value: "PAYMENT_WIRE_WITHDRAWAL_COMPLETED" },
    { text: "ON_RAMP_STARTED", value: "ON_RAMP_STARTED" },
    { text: "ON_RAMP_COMPLETED", value: "ON_RAMP_COMPLETED" },
    { text: "OFF_RAMP_COMPLETED", value: "OFF_RAMP_COMPLETED" },

    { text: "ON_RAMP_DEPOSIT_RECEIVED", value: "ON_RAMP_DEPOSIT_RECEIVED" },
    { text: "TRANSFER_EXCHANGE_CONTRACT_CREATED", value: "TRANSFER_EXCHANGE_CONTRACT_CREATED" },
    
    { text: "INTEGRATOR_SETTLEMENT_IN_COMPLETED", value: "INTEGRATOR_SETTLEMENT_IN_COMPLETED" },
    { text: "INTEGRATOR_SETTLEMENT_OUT_COMPLETED", value: "INTEGRATOR_SETTLEMENT_OUT_COMPLETED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_STARTED", value: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_STARTED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_COMPLETED", value: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_COMPLETED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_STARTED", value: "ON_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_STARTED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_COMPLETED", value: "ON_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_COMPLETED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_FROM_INTERMEDIARY_BANK_TO_CRYPTO_EXCHANGE_STARTED", value: "ON_RAMP_INTERNAL_SETTLEMENT_FROM_INTERMEDIARY_BANK_TO_CRYPTO_EXCHANGE_STARTED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_FROM_INTERMEDIARY_BANK_TO_CRYPTO_EXCHANGE_COMPLETED", value: "ON_RAMP_INTERNAL_SETTLEMENT_FROM_INTERMEDIARY_BANK_TO_CRYPTO_EXCHANGE_COMPLETED" },
    { text: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_BANK_EXCHANGED_FUNDS_AVAILABLE", value: "ON_RAMP_INTERNAL_SETTLEMENT_LOCAL_BANK_EXCHANGED_FUNDS_AVAILABLE" },

    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_LOCAL_BANK_EXCHANGED_FUNDS_AVAILABLE", value: "OFF_RAMP_INTERNAL_SETTLEMENT_LOCAL_BANK_EXCHANGED_FUNDS_AVAILABLE" },
    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_CRYPTO_TO_INTERMEDIARY_BANK_STARTED", value: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_CRYPTO_TO_INTERMEDIARY_BANK_STARTED" },
    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_CRYPTO_TO_INTERMEDIARY_BANK_COMPLETED", value: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_CRYPTO_TO_INTERMEDIARY_BANK_COMPLETED" },
    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_COMPLETED", value: "OFF_RAMP_INTERNAL_SETTLEMENT_INTERMEDIARY_BANK_INTERNAL_EXCHANGE_COMPLETED" },
    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_INTERMEDIARY_BANK_TO_LOCAL_BANK_STARTED", value: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_INTERMEDIARY_BANK_TO_LOCAL_BANK_STARTED" },
    { text: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_INTERMEDIARY_BANK_TO_LOCAL_BANK_COMPLETED", value: "OFF_RAMP_INTERNAL_SETTLEMENT_TRANSFER_FROM_INTERMEDIARY_BANK_TO_LOCAL_BANK_COMPLETED" },
  ]

  const operations = [
    { text: "TRANSFER_STARTED", value: "TRANSFER_STARTED" },
    { text: "TRANSFER_COMPLETED", value: "TRANSFER_COMPLETED" },
    { text: "EXCHANGE_STARTED", value: "EXCHANGE_STARTED" },
    { text: "EXCHANGE_COMPLETED", value: "EXCHANGE_COMPLETED" },
    { text: "WITHDRAWAL_STARTED", value: "WITHDRAWAL_STARTED" },
    { text: "WITHDRAWAL_COMPLETED", value: "WITHDRAWAL_COMPLETED" },
    { text: "DEPOSIT_COMPLETED", value: "DEPOSIT_COMPLETED" },
    { text: "FUNDS_AVAILABLE", value: "FUNDS_AVAILABLE" },
  ]

  const accounts = [
    { text: "CUSTODIAL_BALANCES_PROCESSING", value: "CUSTODIAL_BALANCES_PROCESSING" },
    { text: "CUSTODIAL_BALANCES_AVAILABLE", value: "CUSTODIAL_BALANCES_AVAILABLE" },
    { text: "CUSTODIAL_LIABILITIES_PROCESSING", value: "CUSTODIAL_LIABILITIES_PROCESSING" },
    { text: "CUSTODIAL_LIABILITIES_AVAILABLE", value: "CUSTODIAL_LIABILITIES_AVAILABLE" },
    { text: "CALIZA_BALANCES_PROCESSING", value: "CALIZA_BALANCES_PROCESSING" },
    { text: "CALIZA_BALANCES_AVAILABLE", value: "CALIZA_BALANCES_AVAILABLE" },
    { text: "UNSETTLED_AMOUNTS_BENEFICIARIES", value: "UNSETTLED_AMOUNTS_BENEFICIARIES" },
    { text: "UNSETTLED_AMOUNTS_BANKS", value: "UNSETTLED_AMOUNTS_BANKS" },
    { text: "UNSETTLED_AMOUNTS_INTEGRATORS", value: "UNSETTLED_AMOUNTS_INTEGRATORS" },
    { text: "BENEFICIARY_ADVANCES", value: "BENEFICIARY_ADVANCES" },
    { text: "ACCRUED_REVENUE", value: "ACCRUED_REVENUE" },
    { text: "REVENUE", value: "REVENUE" },
    { text: "EXPENSE_TRANSACTION_BASED", value: "EXPENSE_TRANSACTION_BASED" },
    { text: "UNPAID_IOF_TAXES", value: "UNPAID_IOF_TAXES" }
  ]

  const currencies = [
    { text: "USDC", value: "USDC" },
    { text: "USD", value: "USD" },
    { text: "BRL", value: "BRL" },
  ]

  const providers = [
    { text: "BITSO", value: "BITSO" },
    { text: "FORTRESS", value: "FORTRESS" },
    { text: "KRAKEN", value: "KRAKEN" },
    { text: "GENIAL", value: "GENIAL" },

  ]

  const countries = [
    { text: "ALL", value: "ALL" },
    { text: "BR", value: "BR" },
    { text: "US", value: "US" },
    { text: "CO", value: "CO" },
  ]

  const types = [
    { text: "TRANSACTION_BASED_USD", value: "TRANSACTION_BASED_USD" },
    { text: "TRANSACTION_BASED_BRL", value: "TRANSACTION_BASED_BRL" },
    { text: "WIRE_FEE", value: "WIRE_FEE" },
    { text: "CALIZA_FEE", value: "CALIZA_FEE" },
    { text: "INTEGRATOR_FEE", value: "INTEGRATOR_FEE" },
    { text: "WITHDRAWAL_FEE", value: "WITHDRAWAL_FEE" },
    { text: "NETWORK_FEE", value: "NETWORK_FEE" },
    { text: "DEPOSIT_FEE", value: "DEPOSIT_FEE" },
    { text: "EXCHANGE_FEE", value: "EXCHANGE_FEE" },
    { text: "PIX_IN", value: "PIX_IN" },
  ]

  const stepAction = [
    { text: "INTEGRATOR_FEE", value: "INTEGRATOR_FEE" },
    { text: "CALIZA_FEE", value: "CALIZA_FEE" },
    { text: "CALIZA_FEE_USD", value: "CALIZA_FEE_USD" },
    { text: "EXCHANGE", value: "EXCHANGE" },
    { text: "PAYMENT_EXCHANGE", value: "PAYMENT_EXCHANGE" },
    { text: "TAXES", value: "TAXES" },
    { text: "WIRE_TX", value: "WIRE_TX" },
    { text: "CRYPTO_FX", value: "CRYPTO_FX" },
    { text: "POLYGON_TX", value: "POLYGON_TX" },
    { text: "DEPOSIT_TX", value: "DEPOSIT_TX" },
    { text: "WITHDRAWAL_TX", value: "WITHDRAWAL_TX" },
    { text: "TRANSACTIONAL_FEE", value: "TRANSACTIONAL_FEE" },

  ]
  

  const updateRowOnDrag = (row, targetRow) => {

    return data.map( d => {
      if(d.id == row.id) {
        d.group = targetRow.original.group;
        d.action = targetRow.original.action;
        d.operation = targetRow.original.operation
      }
      return d;
    });
  }

  const handleUpdateRow: MaterialReactTableProps['onEditingRowSave'] = async ({ exitEditingMode, row, values }) => {

    values.id = row.original.id

    const dataValues = data.map(value => {

      if(value.id == row.original.id) {

        return {
          ...value,
          debit: value.debit,
          credit: value.credit,
        };
      }
      return value;
    })

    console.log("handleUpdateRow", dataValues)

    setData(dataValues);
    simulateLedgerTemplateData(dataValues, status)
    exitEditingMode();
  };


  const simulateLedgerTemplateData = (rows, status) => {
    console.log(status)
    setLoading(true)
    getLedgerJournal(rows, status)
      .then(rowsCalculation => {
        setRowsCalculation(rowsCalculation)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  };

  const handleFileUpload = (rows) => {
    
    simulateLedgerTemplateData(rows.flat(1), status)
    setData(rows.flat(1))
  }

  const handleRowUpdate = (newData) => {

    console.log("handleRowUpdate", newData)
    setData(newData)
  }

  const customUploadFile = async (file) => {
    return await uploadTemplate(file)
  }

  const columns = defineColumns({data, rowsCalculation, onRowUpdate: handleRowUpdate, ledgerStatus: {actions, operations, accounts, countries, currencies, providers, types}});
 

  return (
    <Box sx={{ width: '1500px' }}>

      <Status 
      ledgerStatus={{
        actions,
        providers,
        currencies,
        types,
        stepAction
      }}
      onUpdate={(s) => {
        setStatus(s)
        simulateLedgerTemplateData(data, s)

      }}
      status = {status}/>
     
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnResizing
        enableSorting={false}
        enableGrouping
        enableStickyHeader
        enableStickyFooter
        onEditingRowSave={handleUpdateRow}
        editingMode="modal"
        enableEditing
        enablePagination={false}
        enableFilters={false}
        enableRowOrdering
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        muiTableBodyRowDragHandleProps={({ table }) => ({
          onDragEnd: () => {
            const { draggingRow, hoveredRow } = table.getState();

            const newData = updateRowOnDrag(draggingRow, hoveredRow);

            if (hoveredRow && draggingRow) {
              newData.splice(
                hoveredRow.index,
                0,
                newData.splice(draggingRow.index, 1)[0],
              );
              const sortedData = newData.sort((a, b) => a.id - b.id)
              setData([...sortedData]);
            }
          },
        })}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
          <Button
            color="secondary"
            onClick={() => {
              setCreateModalOpen(true)
            }}
            variant="contained"
          >
            Add new Ledger movement
          </Button>
          <Button
            color="primary"
            onClick={() => {
              downloadTemplate(data)
              .then(it => {
                navigator.clipboard.writeText(it);
              })
            }}
            variant="contained"
          >
            Copy Ledger Template to Clipboard
          </Button>
          </>
        )}
        initialState={{
          density: 'compact',
          expanded: true, //expand all groups by default
          grouping: ['action','operation', 'group'], //an array of columns to group by by default (can be multiple)
          pagination: { pageIndex: 0, pageSize: 20 },
          sorting: [{ id: 'action', desc: false }],
        }}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        muiTableContainerProps={{ sx: { maxHeight: 700 } }}
        positionToolbarAlertBanner="none"
      >
       
      </MaterialReactTable>
      <Typography variant='h4' gutterBottom>
        Upload Template
      </Typography>
      <FileUpload key={"file"} label={"File"} data={[]} hideFiles={true} handleChange={handleFileUpload} customUploadFile={customUploadFile}/>
      <CreateNewAccountModal
          columns={columns}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
          ledgerStatus={{
            actions,
            providers,
            currencies,
            types
          }}
          onCreation = {(row) => {
            const arr = [...data, row];
            setData(arr);
            simulateLedgerTemplateData(arr, status);
          }}
        />
    </Box>
  );
};

export default LedgerTemplate;