import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Edit from "../../components/crud/upsert"
import { getCountryById, updateCountry } from '../../services/countryService'
import { listCurrencies } from '../../services/currencyService'
import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'

// ----------------------------------------------------------------------

export default function CountryEdit() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [country, setCountry] = useState(null);

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true)
    getCountryById(id)
      .then((country) => {

        setCountry(country)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const updateData = (data) => {
    data.id = id;
    setLoading(true)
    return updateCountry(data)
        .then(() => {
          setLoading(false)
          setCountry(data)
          successAlert("Country updated with success")
          setLoading(false)
        })
        .catch((e) => {
          errorAlert(e.message)
        })
  }

  const fields = {
    id: {
      type: "string",
      fieldType: "text",
      label: "Id",
      readOnly: true
    },
    name: {
      type: "string",
      fieldType: "text",
      label: "Name",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'Name is required'
      }, {
        type: "required",
        message: 'Name is required'
      }],
    },
    code: {
      type: "string",
      fieldType: "text",
      label: "Code",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 2,
        message: 'Too big'
      }, {
        type: "required",
        message: 'Code is required'
      }]
    },
    phonePrefix: {
      type: "string",
      fieldType: "text",
      label: "Phone Prefix",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 2,
        message: 'Too'
      }, {
        type: "required",
        message: 'Phone prefix is required'
      }]
    },
    currencyId: {
      type: "string",
      fieldType: "select",
      label: "Currency",
      constraints: [{
        type: "required",
        message: 'Currency is required'
      }],
      onSearch: async (search) => {
        let query = search;
        if(!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}`;
        }
        return listCurrencies(query, 0, 5, "")
          .then(data => {
            return data.content
          })
      }
    },
    legalAge: {
      type: "number",
      fieldType: "text",
      label: "Legal Age",
      constraints: [{
        type: "required",
        message: 'Legal age is required'
      }]
    },
  };

  return (
    <>
    {
      country && <Edit
      label="Update Country"
      initialValue={country}
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      onSave={(data) => updateData(data)}
      goToListPage={() => navigate("/dashboard/countries/list")}
    />
    }
    </>
    
  );
}
