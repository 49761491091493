import { simulateLedger, uploadFile, downloadFile } from './../../services/ledgerTransaction'

const buildLedgerTemplate = async (rows) => {

    const groupBy = (field, list) => {
        
        const values = {};
        for(let i=0; i < list.length; i++) {
            var item = list[i];
            if(!values[[item[field]]]) {
                values[[item[field]]] = []
            }
            values[[item[field]]].push(item);
        }
        return values;
    }

    console.log(rows.flat(1))
    const groupedByActions = groupBy("action", rows)
    console.log(groupedByActions)

    const actions = Object.keys(groupedByActions).map(actionKey => {
        
        const rowGroupedByAction = groupedByActions[actionKey];
        const rowsGroupedByOperation = groupBy("operation", rowGroupedByAction);
        return Object.keys(rowsGroupedByOperation).map(key => {

            const rows = rowsGroupedByOperation[key];
            const ledgerMovementsGroup = groupBy("group", rows);
            const operationRow = rows[0];

            const groups = Object.values(ledgerMovementsGroup).map(row => {


                return {
                    debits: row.filter(it => it.debit != "").map(debit => {

                        return {
                            id: debit.id,
                            account: debit.account,
                            provider: debit.provider,
                            country: debit.country, 
                            currency: debit.currency, 
                            type: debit.type,
                            amountVariable: debit.debit
                        }
                    }),
                    credits: row.filter(it => it.credit != "").map(credit => {
                        return {
                            id: credit.id,
                            account: credit.account,
                            provider: credit.provider,
                            country: credit.country, 
                            currency: credit.currency, 
                            type: credit.type,
                            amountVariable: credit.credit
                        }
                    }),
                }
            })
           

            return {
                type: actionKey,
                ledgerActionOperation: operationRow != null ? operationRow.operation : "",
                ledgerMovementsGroup: groups
            }
        });
    });

    const ledgerTemplate = {
        type: "CUSTOM",
        configurationSection: {},
        actions: actions.flat(1)
    };

    return ledgerTemplate;
}

export const getLedgerJournal = async (rows, internalLedgerStatus) => {


    const ledgerTemplate = await buildLedgerTemplate(rows);
    const data = await simulateLedger(ledgerTemplate, internalLedgerStatus);

    const response = {};

    for(let i=0; i < data.length; i++) {

        const action = data[i];
        action.ledgerMovementsGroup.forEach(group => {
            group.credits.forEach(it => {
                response [[it.id]] = it;
            })
        })
        action.ledgerMovementsGroup.forEach(group => {
            group.debits.forEach(it => {
                response [[it.id]] = it;
            })
        })
    }
    console.log(response)

    return response;
}

export const uploadTemplate = async (file) => {

    const uploadedData = await uploadFile(file);
    return await convertToRows(uploadedData);
}

export const downloadTemplate = async (rows) => {

    const ledgerTemplate = await buildLedgerTemplate(rows);
    return await downloadFile(ledgerTemplate);
}

const convertToRows = async (ledgerJournal) => {

    console.log("ledgerJournal", ledgerJournal)

    const response = [];
    let groupId = 0;
    try {
        ledgerJournal.actions.forEach(action => {

            action.ledgerMovementsGroup.forEach(group => {
                group.credits.forEach((credit) => {
                    response.push({
                        id: uuidv4() ,
                        action: action.type,
                        operation: action.ledgerActionOperation,
                        account: credit.account,
                        debit: "",
                        credit: credit.amountVariable,
                        currency: credit.currency,
                        country: credit.country,
                        provider: credit.provider,
                        type: credit.type,
                        group: groupId
                    });
                })
                group.debits.forEach(debit => {
                    response.push({
                        id: uuidv4() ,
                        action: action.type,
                        operation: action.ledgerActionOperation,
                        account: debit.account,
                        debit: debit.amountVariable,
                        credit: "",
                        currency: debit.currency,
                        country: debit.country,
                        provider: debit.provider,
                        type: debit.type,
                        group: groupId
                    });
                })
                groupId++
            })
            
        })
    } catch(e) {
        console.log(e)
    }

    return response;
    
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    )
}