import {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Material
import { styled } from '@mui/material/styles'

// Other imports
import { getCustomShadows } from '../theme/shadows'

export const DropdownMenuContext = createContext()

export function DropdownMenuProvider({
  children,
  onClose = () => {},
  onOpen = () => {},
}) {
  const [menuIsOpen, setMenuIsOpenState] = useState(false)
  const toggleDropdownMenu = () => {
    setMenuIsOpenState(isOpen => !isOpen)
  }
  const openDropdownMenu = () => {
    setMenuIsOpenState(true)
    onOpen()
  }
  const closeDropdownMenu = () => {
    setMenuIsOpenState(false)
    onClose()
  }

  return (
    <DropdownMenuContext.Provider
      value={{
        menuIsOpen,
        toggleDropdownMenu,
        openDropdownMenu,
        closeDropdownMenu,
      }}>
      {children}
    </DropdownMenuContext.Provider>
  )
}

const StyledDropdownMenuWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
}))

const StyledMenu = styled('div')(({ theme }) => {
  return {
    listStyle: 'none',
    backgroundColor: theme.palette.primary.light,
    boxShadow: getCustomShadows({ componentName: 'dropdownMenu' }),
    borderRadius: 8,
    position: 'absolute',
    zIndex: 1000,
    top: '100%',
    left: 0,
    width: '100%',
    transform: `translateY(16px)`,
    minWidth: 275,
  }
})

const StyledMenuInner = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}))

function DropdownToggle({ children }) {
  const { menuIsOpen, toggleDropdownMenu } = useContext(
    DropdownMenuContext,
  )

  return (
    <div
      className={clsx('menu_toggle', { is_active: menuIsOpen })}
      onClick={toggleDropdownMenu}>
      {children}
    </div>
  )
}

Menu.propTypes = {
  children: PropTypes.any.isRequired,
  menuToggle: PropTypes.any.isRequired,
  sx: PropTypes.object,
  menuFooter: PropTypes.any,
}

export function Menu({
  children,
  menuToggle,
  sx,
  menuFooter = null,
  menuHeading = null,
  className,
  ...otherProps
}) {
  const menuWrapper = useRef(null)
  const { menuIsOpen, closeDropdownMenu } = useContext(DropdownMenuContext)
  const closeMenu = useCallback(
    evt => {
      if (
        !!menuWrapper.current &&
        !menuWrapper.current.contains(evt.target)
      ) {
        closeDropdownMenu()
      }
    },
    [closeDropdownMenu],
  )

  useEffect(() => {
    if (menuWrapper) {
      // { capture: true } fix from here:
      // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#fixing-potential-issues
      document.body.addEventListener('click', closeMenu, { capture: true })
    }

    return () => document.body.removeEventListener('click', closeMenu)
  }, [menuWrapper, closeMenu])

  return (
    <StyledDropdownMenuWrapper
      ref={menuWrapper}
      className={clsx(
        'dropdown_menu_wrapper',
        {
          is_visible: menuIsOpen,
        },
        className,
      )}
      sx={sx}>
      <DropdownToggle>{menuToggle}</DropdownToggle>

      {menuIsOpen ? (
        <StyledMenu
          {...otherProps}
          className={clsx('dropdown_menu', {
            is_visible: menuIsOpen,
          })}>
          {menuHeading || null}

          <StyledMenuInner className='dropdown_menu_inner'>
            {children}
          </StyledMenuInner>

          {menuFooter ? <footer>{menuFooter}</footer> : null}
        </StyledMenu>
      ) : null}
    </StyledDropdownMenuWrapper>
  )
}

export default function DropdownMenu({
  children,
  providerProps = {},
  ...otherProps
}) {
  return (
    <DropdownMenuProvider {...providerProps}>
      <Menu {...otherProps}>{children}</Menu>
    </DropdownMenuProvider>
  )
}
