import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
// components
import Page from '../components/Page';

import { getCurrentUser, redirectToLogin } from '../services/authenticationService'
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 800,
  margin: 'auto',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {

  const navigate = useNavigate();

   React.useEffect(() => {
    if(getCurrentUser()) {
      navigate("/dashboard/app")
      return <></>
    } else {
      redirectToLogin()
    }
  }, []);
  

  return (
    <RootStyle title="Welcome to Caliza">

      
    </RootStyle>
  );
}
