import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getFormFieldComponentByFieldType } from '../utils/form'

// Services
import { signup } from '../services/integratorService'
import { errorAlert, successAlert } from '../services/alertService'
import { listCountries } from '../services/countryService'

// material
import { styled } from '@mui/material/styles'

// Components
import Upsert from '../components/crud/upsert'
import Page from '../components/Page'

// ----------------------------------------------------------------------
// Constants
const fields = {
  name: {
    type: 'string',
    fieldType: 'text',
    label: 'User Name',
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Name is required',
      },
      {
        type: 'required',
        message: 'Name is required',
      },
    ],
  },
  email: {
    type: 'string',
    fieldType: 'text',
    label: 'User Email',
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'Email is to big',
      },
      {
        type: 'required',
        message: 'Email is required',
      },
      {
        type: 'email',
        message: 'Invalid email',
      },
    ],
  },
  company: {
    type: 'string',
    fieldType: 'text',
    label: 'Company name',
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 50,
        message: 'Company name is required',
      },
      {
        type: 'required',
        message: 'Company name is required',
      },
    ],
  },
  country: {
    type: 'string',
    fieldType: 'autocomplete',
    label: 'Country',
    constraints: [
      {
        type: 'required',
        message: 'is required',
      },
    ],
    value: null,
    onSearch: async search => {
      return listCountries(search, 0, 5, '').then(data => {
        return data.content
      })
    },
  },
}

// Styled Components
const Main = styled('main')(({ theme }) => ({
  flexShrink: 0,
  flexGrow: 1,
  padding: theme.spacing(3, 10),
  '.form_wrapper': {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 600,
  },
}))

const renderFormFields = ({
  fieldsKeys,
  fields,
  formData,
  ...restFormProps
}) => {
  return fieldsKeys.map((fieldKey, index) => {
    const { fieldType, ...restFieldData } = fields[fieldKey]
    const FormFieldComponent = getFormFieldComponentByFieldType({
      fieldType,
    })

    return (
      <FormFieldComponent
        key={`${restFieldData.label}-${index}`}
        fieldKey={fieldKey}
        value={formData[fieldKey]}
        {...restFieldData}
        {...restFormProps}
      />
    )
  })
}

const INITIAL_FORM_VALUE = {
  name: '',
  company: '',
  email: '',
  country: '',
}

export default function Signup() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const saveData = data => {
    setLoading(true)

    const request = {}
    request.name = data.name
    request.company = data.company
    request.email = data.email
    request.address = {
      country: data.country,
    }

    return signup(request)
      .then(integratorCreated => {
        navigate(`/created`)
        successAlert('User created with success')
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
  }

  return (
    <Page className='main_content' title={'Caliza | Signup'}>
      <Main>
        <Upsert
          hideGoBackButton
          label='Create Caliza Account'
          loading={loading}
          renderCustomFormFields={renderFormFields}
          isLoading={loaded => setLoading(loaded)}
          fields={fields}
          initialValue={INITIAL_FORM_VALUE}
          sx={{ maxWidth: 'none' }}
          saveButtonText='Create Account'
          backAction={() => navigate('/login')}
          onSave={data => {
            saveData(data)
          }}
        />
      </Main>
    </Page>
  )
}
