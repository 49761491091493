import { useContext, useMemo } from 'react'
import { isArray, isNull } from 'lodash'

// Utils
import { getCustomShadows } from '../../theme/shadows'

// Services
import { errorAlert } from '../../services/alertService'

// Material
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

// Components
import Button from '../Button'
import FileUpload from '../form/FileUpload'
import ProgressBar from '../ui/ProgressBar'
import {
  FileUploadProvider,
  FileUploadContext,
} from '../providers/FileUploadProvider'
import Modal from '../ui/Modal'
import { ModalContext } from '../providers/ModalProvider'
import { FormContext, FormProvider } from '../providers/FormProvider'

// Icons
import { ReactComponent as FolderCheckmarkIcon } from '../../images/folder-check.svg'

// Constants
import {
  BENEFICIARY_DOCUMENT_TYPE_MAP,
} from '../../utils/beneficiaries'
const FORM_FIELDS = {
  id: {
    key: 'id',
    type: 'string',
    fieldType: 'text',
    label: null,
    readOnly: true,
  },
  name: {
    key: 'name',
    type: 'string',
    fieldType: 'text',
    label: null,
    readOnly: true,
  },
  type: {
    key: 'type',
    type: 'string',
    fieldType: 'text',
    label: null,
    readOnly: true,
  },
  documentOwner: {
    key: 'documentOwner',
    type: 'string',
    fieldType: 'hidden',
    readOnly: true,
  },
  file: {
    key: 'files',
    type: 'string',
    fieldType: 'upload',
    label: 'File',
  },
}

// Styled Components
const StyledModal = styled(Modal)(({ theme }) => ({
  position: 'relative',
  minHeight: '600px',
  overflow: 'hidden',
  height: '100%',
  '.error_message': {
    marginBottom: theme.spacing(3),
  },
  '.file_upload_wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    height: 'auto',
  },
  '.folder_upload_icon': {
    marginBottom: theme.spacing(5),
    flex: '1 0 auto',
  },
  '.main_label': {
    marginBottom: theme.spacing(2.5),
  },
  '.separator_label': {
    marginBottom: theme.spacing(2.5),
  },
}))
const StyledModalFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const StyledPrimaryButton = styled(LoadingButton)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.primary
  const boxShadow = getCustomShadows({
    componentName: 'filterButtons',
  })

  return {
    ...defaultButton,
    height: 40,
    boxShadow,
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '&:active': pressed,
  }
})
const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
}))

// Main
const StyledAttachedDocumentsList = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '.document_wrapper:not(:last-of-type)': {
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderBottom: `1px dashed ${theme.palette.grey[400]}`,
  },
  '.document_owner_selection_wrapper': {
    marginBottom: theme.spacing(3),
  },
}))
const AttachedDocumentsMainWraper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
const StyleAttachedDocument = styled('div')(({ theme }) => ({
  width: '100%',
  '.folder_checkmark_icon': {
    marginRight: theme.spacing(1),
  },
  '.filename': {
    lineHeight: 1,
  },
}))
const AttachedFileCopyWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
}))
const FilenameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))
const StyledFileInputLabelButton = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.blue.secondary,
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '14px',
  padding: theme.spacing(1.5, 3),
  borderRadius: '8px',
  flexShrink: 0,
  position: 'relative',
  minWidth: '100px',
  minHeight: '40px',
  '.label': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.label:hover': {
    cursor: 'pointer',
  },
  '&:hover': {
    cursor: 'pointer',
  },
}))
const AttachedDocumentDescription = styled(Typography)(
  ({ theme }) => ({
    marginBottom: theme.spacing(2),
  }),
)

function AttachedDocument({ fileData, fileIndex }) {
  const { beneficiaryType, ...restOfFileData } = fileData
  const {
    formData: { files: formFiles },
    handleFormChange,
  } = useContext(FormContext)
  const id = `file-upload-input-${fileIndex}`
  const { addFiles } = useContext(FileUploadContext)
  const updateFileFormData = useMemo(
    () => attachedFile => {
      const formDataFiles = [...formFiles]
      const newFile = isArray(attachedFile)
        ? attachedFile[0]
        : attachedFile

      formDataFiles[fileIndex] = {
        ...restOfFileData,
        documentOwner: beneficiaryType,
        file: newFile,
        name: newFile.name,
      }

      handleFormChange({ key: 'files', value: formDataFiles })
    },
    [
      formFiles,
      fileIndex,
      handleFormChange,
      restOfFileData,
      beneficiaryType,
    ],
  )
  const attachDocumentManually = useMemo(
    () => async e => {
      e.preventDefault()
      const attachedFile = e.target.files[0]

      // Set to '0' since we only allow 1 file to be uploaded
      // await deleteFile(0)
      addFiles([attachedFile])
      updateFileFormData(attachedFile)
    },
    [addFiles, updateFileFormData],
  )
  const documentTypeLabel =
    BENEFICIARY_DOCUMENT_TYPE_MAP[fileData.type].label

  return (
    <div className='document_wrapper'>
      <FileUpload onFileAdd={updateFileFormData}>
        <StyleAttachedDocument className='document'>
          {fileData.file ? (
            <AttachedDocumentDescription component='p' variant='p'>
              <strong>{documentTypeLabel}</strong> for
              <strong>{fileData.documentOwner}</strong>
            </AttachedDocumentDescription>
          ) : null}
          <AttachedDocumentsMainWraper>
            <AttachedFileCopyWrapper>
              {fileData.file ? (
                <FilenameWrapper>
                  <FolderCheckmarkIcon className='folder_checkmark_icon icon' />
                  <span className='filename'>{fileData.name}</span>
                </FilenameWrapper>
              ) : (
                <>
                  <span className='filename'>
                    Drag or add a copy of your{' '}
                    <strong>{documentTypeLabel}</strong> in order to
                    verify <strong>{fileData.documentOwner}</strong>
                  </span>
                </>
              )}
            </AttachedFileCopyWrapper>

            <StyledFileInputLabelButton className='file_upload_button'>
              <input
                onChange={attachDocumentManually}
                type='file'
                id={id}
                hidden
              />
              <label htmlFor={id} className='label'>
                Upload file
              </label>
            </StyledFileInputLabelButton>
          </AttachedDocumentsMainWraper>
        </StyleAttachedDocument>
      </FileUpload>
    </div>
  )
}

function AttachedDocuments() {
  const {
    formData: { files: formFiles = [] },
  } = useContext(FormContext)

  return (
    <StyledAttachedDocumentsList className='attached_documents'>
      {formFiles.map((fileData, index) => {
        return (
          <FileUploadProvider key={`${fileData.type}_${index}`}>
            <AttachedDocument fileData={fileData} fileIndex={index} />
          </FileUploadProvider>
        )
      })}
    </StyledAttachedDocumentsList>
  )
}

const SavingOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 100,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255, 0.8)',
  '.progress_bar': {
    marginBottom: theme.spacing(3),
  },
}))
const SavingOverlayMain = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
  width: '60%',
}))
const SavingCopy = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
}))

function FormSavingView() {
  return (
    <SavingOverlay className='saving_overlay'>
      <SavingOverlayMain>
        <ProgressBar />

        <SavingCopy component='p' variant='p'>
          Uploading files...
        </SavingCopy>
      </SavingOverlayMain>
    </SavingOverlay>
  )
}

function UploadDocumentActions() {
  const { hideModal } = useContext(ModalContext)
  const {
    handleSubmit,
    formData: { files = [] },
  } = useContext(FormContext)
  const uploadDocument = e => handleSubmit(e)
  const isFormValid = files.every(fileData =>
    Object.values(fileData).every(val => !isNull(val)),
  )

  return (
    <StyledModalFooter className='footer'>
      <StyledSecondaryButton
        buttonType='secondary'
        onClick={hideModal}>
        Cancel
      </StyledSecondaryButton>
      <StyledPrimaryButton
        key='submit'
        type='submit'
        variant='contained'
        loading={false}
        disabled={!isFormValid}
        onClick={uploadDocument}>
        Upload
      </StyledPrimaryButton>
    </StyledModalFooter>
  )
}

const StyledHeader = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '.heading': {
    fontWeight: theme.typography.fontWeightMedium,
  },
}))
const MainModalContentWrapper = styled('div')(({ theme }) => ({
  overflow: 'auto',
  height: '100%',
  padding: theme.spacing(0, 1, 5, 1),
}))

function MainModalContent() {
  const { loading } = useContext(FormContext)

  if (loading) {
    return <FormSavingView />
  }

  return (
    <MainModalContentWrapper>
      <StyledHeader>
        <Typography className='heading' component='h3' variant='p'>
          Please upload all required documents for verification.
        </Typography>
      </StyledHeader>

      <AttachedDocuments />

      <UploadDocumentActions />
    </MainModalContentWrapper>
  )
}

export default function UploadBeneficiaryDocumentModal({
  data: { initialData = {}, saveAction = () => {} },
}) {
  const { hideModal } = useContext(ModalContext)

  return (
    <StyledModal
      className='document_upload_modal'
      ariaProps={{
        'aria-labelledby': 'Upload Documents Modal',
        'aria-describedby': 'Modal where you can upload documents',
      }}>
      <FormProvider
        initialFormData={initialData}
        formFields={FORM_FIELDS}
        submitAction={saveAction}
        onSuccess={hideModal}
        onError={errorAlert}>
        <MainModalContent />
      </FormProvider>
    </StyledModal>
  )
}
