import { useState, createContext } from 'react'
import download from 'js-file-download'

// Services
import {
  uploadFile,
  deleteFile,
  downloadFile,
} from '../../services/uploadService'
import { errorAlert } from '../../services/alertService'

// Main
export const FileUploadContext = createContext()

export function FileUploadProvider({ children, defaultFiles = [] }) {
  const [files, setFiles] = useState(defaultFiles)
  const addFiles = newFiles =>
    setFiles(currentFiles => [...currentFiles, ...newFiles])
  const uploadFiles = async onSuccess => {
    const promises = files.map(fileData => uploadFile(fileData))

    try {
      const uploadedFiles = await Promise.all(promises)

      onSuccess && onSuccess(uploadedFiles)
      setFiles([])
    } catch (e) {
      errorAlert('Error has occurred while uploading files')
    }
  }

  const downloadAttachedFile = async fileIndex => {
    let blob = await downloadFile(files[fileIndex])
    download(blob, files[fileIndex])
  }

  return (
    <FileUploadContext.Provider
      value={{
        files,
        deleteFile,
        downloadFile: downloadAttachedFile,
        uploadFiles,
        addFiles,
      }}>
      {children}
    </FileUploadContext.Provider>
  )
}
