import React, { useState, useCallback } from 'react';

import { 
    Box,
  Button,
  IconButton,
  Stack,
  Grid,
  TextField,
  Tooltip, } from '@mui/material';
  
import { MaterialReactTable } from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import CreateNewAccountModal from './createAccountModal'

const Status = (props) => {


    const actions = props.ledgerStatus.actions;
    const providers = props.ledgerStatus.providers;
    const currencies = props.ledgerStatus.currencies;
    const stepAction = props.ledgerStatus.stepAction;
    const types = props.ledgerStatus.types;
    const [status, setStatus] = useState({...props.status});
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const columns = [

        {
            header: 'provider',
            accessorKey: 'provider',
            editVariant: 'select',
            editSelectOptions: providers,
        },
        {
            header: 'type',
            accessorKey: 'type',
            editVariant: 'select',
            editSelectOptions: types
        },
        {
            header: 'Step Action',
            accessorKey: 'stepAction',
            editVariant: 'select',
            editSelectOptions: stepAction
        },
        {
            header: 'value',
            accessorKey: 'value',
        },
        {
            header: 'currency',
            accessorKey: 'currencyCode',
            editVariant: 'select',
            editSelectOptions: currencies
        }
    ]

    const mapToRows = (fees) => {
        return fees.map(it => {
            
            if(it.fee) {
                return {
                    provider: it.provider,
                    stepAction: it.stepAction,
                    type: it.type,
                    value: it.fee.value,
                    currencyCode: it.fee.currencyCode,
                }
            }
            return {
                provider: it.provider,
                stepAction: it.stepAction,
                type: it.type,
                value: it.value,
                currencyCode: it.currencyCode,
            }
            
        })
    }

    const handleUpdateRow: MaterialReactTableProps['onEditingRowSave'] = async ({ exitEditingMode, row, values }) => {
    
        const dataValues = status.fees.map(it => {

            if(row.original.provider == it.provider && row.original.stepAction == it.stepAction && row.original.currency == it.currency) {
                return {
                    provider: values.provider,
                    stepAction: values.stepAction,
                    type: values.type,
                    fee: {
                        value: values.value,
                        currencyCode: values.currencyCode
                    }
                }
            } else {
                console.log(it)

                return {
                    provider: it.provider,
                    stepAction: it.stepAction,
                    type: it.type,
                    fee: {
                        value: it.fee.value,
                        currencyCode: it.fee.currencyCode
                    }
                }
            }
          
        })
    
        console.log(dataValues)
        setStatus({
            ...status,
            fees: dataValues
        });
        props.onUpdate({
            ...status,
            fees: dataValues
        })

        exitEditingMode();
    };

    const handleDeleteRow = useCallback(
        (row) => {
         
          status.fees.splice(row.index, 1);
          setStatus({
            ...status,  
            fees: status.fees
          });
          props.onUpdate({
            ...status,  
            fees: status.fees
          })
        },
        [status],
    );

    const handleCreateNewRow = (values) => {
        console.log("handleCreateNewRow", values)

        const fee = {
            provider: values.provider,
            stepAction: values.stepAction,
            type: values.type,
            fee: {
                value: values.value,
                currencyCode: values.currencyCode
            }
        };

        status.fees.push(fee);
        setStatus([...status]);
        props.onUpdate(status)
      };

      const onFieldChange = (e) => {

        const variables = e.target.name.split("_");
        let newStatus = {...status}
        if(variables.length > 1) {
            newStatus = {
                ...status,
                [variables[0]]: {
                    ...status[variables[0]],
                    [variables[1]]: e.target.value
                }
            }
            setStatus(newStatus)
        } else {
            newStatus = {
                ...status,
                [variables[0]]: e.target.value
            }
            setStatus(newStatus)
        }
       
        props.onUpdate(newStatus)
      }

    return (
        <>
        <Grid container spacing={1}>
            <Box sx={{ width: '250px'}}>
                <Stack spacing={1}>
                <TextField
                    fullWidth
                    autoComplete='originalAmountValue'
                    type='text'
                    label='Original Amount value'
                    name="originalAmount_value"
                    onChange={(e) => onFieldChange(e)}
                    value={status.originalAmount.value}
                />
                <TextField
                    fullWidth
                    autoComplete='originalAmountCurrency'
                    name="originalAmount_currencyCode"
                    type='text'
                    label='Original Amount currency'
                    value={status.originalAmount.currencyCode}
                    onChange={(e) => onFieldChange(e)}
                />
                </Stack>
                <br/>
                <Stack spacing={1}>
                <TextField
                fullWidth
                autoComplete='amountForExchangeValue'
                name="amountForExchange_value"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Amount for exchange'
                value={status.amountForExchange.value}
                />
                <TextField
                fullWidth
                autoComplete='amountForExchange_currencyCode'
                name="amountForExchange_currencyCode"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Amount for exchange currency'
                value={status.amountForExchange.currencyCode}

                />
                </Stack>
                <br/>
                <Stack spacing={1}>
                <TextField
                fullWidth
                autoComplete='grossConvertedAmount_value'
                name="grossConvertedAmount_value"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Gross converted Amount value'
                value={status.grossConvertedAmount.value}
                />
                <TextField
                fullWidth
                autoComplete='grossConvertedAmountCurrencyCode'
                name="grossConvertedAmount_currencyCode"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Gross converted Amount currency'
                value={status.grossConvertedAmount.currencyCode}
                />
                </Stack>
                <br/>
                <Stack spacing={1}>
                <TextField
                fullWidth
                autoComplete='netAmountValue'
                name="netAmount_value"
                type='text'
                label='Net Amount value'
                value={status.netAmount.value}
                onChange={(e) => onFieldChange(e)}
                />
                <TextField
                fullWidth
                autoComplete='netAmountCurrencyCode'
                name="netAmount_currencyCode"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Net Amount value'
                value={status.netAmount.currencyCode}
                />
                </Stack>
                <br/>
                <Stack spacing={1}>
                <TextField
                fullWidth
                autoComplete='exchangeRate'
                name="exchangeRate"
                onChange={(e) => onFieldChange(e)}
                type='text'
                label='Exchange Rate'
                value={status.exchangeRate}

                />
                </Stack>
                <br/>
            </Box>
            <Box sx={{ width: '800px'}} style={{ display: "flex", gap: "10rem" }}>
                <MaterialReactTable
                    columns={columns}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableHiding={false}
                    data={mapToRows(status.fees)}
                    enableColumnResizing
                    enableSorting={false}
                    enableGrouping={false}
                    enableStickyHeader={false}
                    enableStickyFooter={false}
                    onEditingRowSave={handleUpdateRow}
                    editingMode="modal"
                    enableEditing
                    enablePagination={false}
                    enableFilters={false}
                    displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                        align: 'center',
                        },
                        size: 120,
                    },
                    }}
                    
                    renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit />
                        </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                            <Delete />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    )}
                    renderTopToolbarCustomActions={() => (
                    <>
                    <Button
                        color="secondary"
                        onClick={() => {
                        setCreateModalOpen(true)
                        }}
                        variant="contained"
                    >
                        Add new fee
                    </Button>
                    </>
                    )}
                    initialState={{
                    density: 'compact',
                    expanded: true, //expand all groups by default
                    grouping: ['action','operation', 'group'], //an array of columns to group by by default (can be multiple)
                    pagination: { pageIndex: 0, pageSize: 20 },
                    sorting: [{ id: 'action', desc: false }],
                    }}
                    muiToolbarAlertBannerChipProps={{ color: 'primary' }}
                    muiTableContainerProps={{ sx: { maxHeight: 700 } }}
                    positionToolbarAlertBanner="none"
                >
                
                </MaterialReactTable>
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                    ledgerStatus={{
                        actions,
                        providers,
                        currencies
                    }}
                    onCreation = {(row) => {
                        const fees = [...status.fees, row];
                        setStatus({
                            ...status,
                            fees
                        });
                    }}
                    />
            </Box>
        
        </Grid>
        <br/>
        <br/>
      </>
    )
}

export default Status;