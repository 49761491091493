
import axios from '../config/axiosConfig';

import { createSortUrl } from './urlBuilder'

export const listCountries = async (search, page, limit, sort ={}) => {
    const url = `/v1/countries?${search}&page=${page}&size=${limit}&${createSortUrl(sort)}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getCountryById = async (id) => {
    const response = await axios.get(`/v1/countries/${id}`);
    return response.data;
}

export const addCountry = async (country) => {
    const response = await axios.post(`/v1/countries`, country);
    return response.data;
}

export const updateCountry = async (country) => {
    const response = await axios.put(`/v1/countries/${country.id}`, country);
    return response.data;
}

export const deleteCountry = async(id) => {
    const response = await axios.delete(`/v1/countries/${id}`);
    return response.data;
}