
import axios from '../config/axiosConfig';

export const generateOfframpTransferDailyTransactions = async (integratorId, date) => {

    const url = `/v1/admin/reports/offramp?integratorId=${integratorId}&date=${getDate(date)}`;
    const response = await axios.get(url)
    return response.data
}

function getDate(today) {
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    
    return  yyyy + '/' + mm + '/' + dd;
}