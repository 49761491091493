import { useMemo, useCallback, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'

// Services
import { getPaymentById, getPaymentStatus } from '../../services/paymentService'
import { errorAlert } from '../../services/alertService'
import { showFormattedDate } from '../../services/dateService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import View from '../../components/crud/view'

// ----------------------------------------------------------------------
const PAYMENTS_BASE_PATH = SECTION_BASE_PATHS.payments

export default function PaymentView() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [data, setData] = useState({})
  const goToListPage = () => navigate(`${PAYMENTS_BASE_PATH}/list`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getPaymentById(id)
      .then(fetchedData => {
        return {
          id: fetchedData.id,
          simulationId: fetchedData.simulationId,
          integratorId: fetchedData.integratorId,
          beneficiaryId: fetchedData.beneficiaryId,
          type: fetchedData.type,
          status: fetchedData.status,
          amount: fetchedData.amount.value,
          amountAfterFee: fetchedData.amountAfterFee
            ? fetchedData.amountAfterFee.value
            : 0,
          paymentContactId: fetchedData.paymentContactId,
          description: fetchedData.description,
          createdDate: showFormattedDate(fetchedData.createdDate),
          errorMessage: ""
        }
      })
      .then(async fetchedData => {

        console.log("aaaa")
        if(fetchedData.status == "FAILED") {
          const status = await getPaymentStatus(fetchedData.id)
          fetchedData.errorMessage = status.message
          setData(fetchedData)
          setLoading(false)
        }
        
        setData(fetchedData);
        setLoading(false);
      })
      .catch(e => {
        errorAlert(e.message)
        setLoading(false)
      })
  }, [id])

  const fields = useMemo(
    () => [
      {
        label: {
          displayValue: 'Transfer ID',
        },
        value: {
          displayValue: data.id || '',
        },
      },
      {
        label: {
          displayValue: 'Beneficiary',
        },
        value: {
          displayValue: data.beneficiaryId || '',
        },
      },
      {
        label: {
          displayValue: 'Integrator',
        },
        value: {
          displayValue: data.integratorId,
        },
      },
      {
        label: {
          displayValue: 'Transfer Type',
        },
        value: {
          displayValue: data.type || '',
        },
      },
      {
        label: {
          displayValue: 'Total amount',
        },
        value: {
          displayValue: data.amount ? data.amount.toFixed(2) : 0,
        },
      },
      {
        label: {
          displayValue: 'Amount After Fee',
        },
        value: {
          displayValue: data.amountAfterFee
            ? data.amountAfterFee.toFixed(2)
            : 0,
        },
      },
      {
        label: {
          displayValue: 'Payment contact',
        },
        value: {
          displayValue: data.paymentContactId, // TODO: Need to fetch contact info
        },
      },
      {
        label: {
          displayValue: 'Payment Description',
        },
        value: {
          displayValue: data.description || '',
        },
      },
      {
        label: {
          displayValue: 'Created At',
        },
        value: {
          displayValue: data.createdDate
            ? format(new Date(data.createdDate), 'd MMM, yyyy')
            : '',
        },
      },
      {
        label: {
          displayValue: 'Error message',
        },
        value: {
          displayValue: data.errorMessage
            ? data.errorMessage
            : 'None',
        },
      },
    ],
    [data],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (!data) {
    return null
  }

  return (
    <Page className='main_content' title={'Payments | View'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Payments View`}
        />

        <View fields={fields} />
      </Loading>
    </Page>
  )
}
