import { useState, useEffect, useCallback } from 'react'
import Label from '../../components/Label'
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import { listAdmins, deleteAdmin } from '../../services/adminService'
import { SECTION_BASE_PATHS } from '../../utils/general'

// ----------------------------------------------------------------------
const ADMINS_BASE_PATH = SECTION_BASE_PATHS.administrators

export default function AdminList({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = ({ id }) =>
    navigate(`${ADMINS_BASE_PATH}/${id}/edit`)

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const getActiveLabel = label => {
    return (
      <Label variant='ghost' color={(label && 'success') || 'error'}>
        {label ? 'Active' : 'Not Active'}
      </Label>
    )
  }

  const fetchData = useCallback(() => {
    listAdmins(
      `filterEq=type:ADMIN&filterOr=email:${search},firstName:${search},lastName:${search}`,
      page,
      rowsPerPage,
      sort,
    )
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Adminstrators | Overview'}>
      <List
        loading={loading}
        label='Administrators'
        goToAddPage={() => navigate(`${ADMINS_BASE_PATH}/save`)}
        goToEditPage={id => navigate(`${ADMINS_BASE_PATH}/${id}/edit`)}
        onDelete={id => {
          setLoading(true)
          deleteAdmin(id)
            .then(() => {
              fetchData()
              successAlert('Admin deleted with success')
            })
            .catch(() =>
              errorAlert('Error has occurred while fetching admins'),
            )
            .finally(() => setLoading(false))
        }}
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'email', label: 'Email', alignRight: false },
          { id: 'firstName', label: 'First Name', alignRight: false },
          { id: 'lastName', label: 'Last Name', alignRight: false },
          {
            id: 'active',
            label: 'Active',
            alignRight: false,
            onRender: active => getActiveLabel(active),
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => {
          setSearch(search)
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
