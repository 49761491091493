
import axios from '../config/axiosConfig';

import { getRoles } from './authenticationService'

export const listPayments = async (search, page, limit, sort ={}) => {
    let url = `/v1/payments?${search}&page=${page}&size=${limit}&sort=id,desc`
    const response = await axios.get(url)
    return response.data
}

export const getPaymentById = async (id) => {
    let url = `/v1/payments/${id}`
    const response = await axios.get(url);
    return response.data;
}

export const getPaymentStatus = async (id) => {
    return "";
}

export const addPayment = async (payment) => {
    let url = getRoles().indexOf("ROLE_ADMIN") > -1 ? `/v1/admin/payments` : `/v1/payments`
    const response = await axios.post(url, payment);
    return response.data;
}

export const simulatePayment = async (payment) => {
    let url = `/v1/payments/simulations`
    const response = await axios.post(url, payment);
    return response.data;
}

export const createPaymentOut = async (payment) => {
    let url = `/v1/payments`
    const response = await axios.post(url, payment);
    return response.data;
}

export const createPaymentIn = async (payment) => {
    let url = `/v1/payments/sandbox/funding`
    const response = await axios.post(url, payment);
    return response.data;
}

// this method should be used in order to get events for a payment
export const getEventsForPayment = async (id) => {
    let url = `/v1/admin/payments/${id}/events`
    const response = await axios.get(url);
    return response.data;
}