import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepConnector from '@mui/material/StepConnector'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'

const CustomStep = styled(Step)(({ theme }) => {
  return {
    '.MuiSvgIcon-root': {
      color: 'transparent',
      border: `2px solid ${theme.palette.grey.secondary}`,
      borderRadius: '50%',
      width: '32px',
      height: '32px',
      fontFamily: theme.typography.headingFontFamily,
    },
    '.MuiSvgIcon-root.Mui-completed': {
      color: 'transparent',
      borderColor: theme.palette.blue.secondary,
    },
    '.MuiSvgIcon-root.Mui-active': {
      color: 'transparent',
      borderColor: theme.palette.blue.secondary,
    },
    '.MuiStepIcon-text': {
      fill: theme.palette.grey.secondary,
      fontSize: '14px',
      fontWeight: 700,
    },
    '.Mui-completed.MuiStepLabel-label': {
      color: theme.palette.blue.secondary,
    },
    '.Mui-completed path': {
      fill: theme.palette.blue.secondary,
    },
    '.Mui-active .MuiStepIcon-text': {
      fill: theme.palette.blue.secondary,
    },
    '.MuiStepLabel-label.Mui-active': {
      color: theme.palette.blue.secondary,
    },
  }
})
const CustomStepConnector = styled(StepConnector)(({ theme }) => {
  return {
    '.MuiStepConnector-line': {
      borderWidth: 2,
    },
  }
})

export default function StepperPage({
  steps,
  activeStep,
  changeStep,
  sx,
}) {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomStepConnector />}>
        {steps.map((step, key) => (
          <CustomStep
            key={step.label}
            style={{ cursor: 'pointer' }}
            index={key}
            onClick={() => {
              changeStep(key)
            }}>
            <StepLabel>{step.label}</StepLabel>
          </CustomStep>
        ))}
      </Stepper>
    </Box>
  )
}
