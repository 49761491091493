
import axios from '../config/axiosConfig';

export const listUsers = async (search, page, limit, sort ={}) => {
    
    const url = `/v1/users?${search}&page=${page}&size=${limit}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}