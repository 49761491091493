// material
import { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { errorAlert, successAlert } from '../../services/alertService'
import Select from "../../components/Select"


import {
  Widget
} from '../../components/_dashboard/app';
import { getBalanceForIntegrator, getBalanceForIntegratorAndBeneficiary } from '../../services/balanceService';
import { listIntegrators } from '../../services/integratorService';
import { listBeneficiaries } from '../../services/beneficiaryService';

import { getRoles } from '../../services/authenticationService'

// ----------------------------------------------------------------------

export default function DashboardApp(props) {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    beneficiaryId: "",
    integratorId: "",
    integratorOptions: [],
    beneficiaryOptions: [],
    balance: {}
  });

  useEffect(() => {
    getBalance()
  }, [data.integratorId, data.beneficiaryId])

  const handleChange = async (key, value) => {
    setData({
        ...data,
        [key]: value
    })
  }

  const searchIntegrators = async (search="") => {
    const response = await listIntegrators("filterEq="+(search || ""))
    console.log(response)
    return response.content
  } 

  const searchBeneficiaries = async (search="") => {

    const searchParams = "&filterOr=business.name:"+ (search || "") +",person.firstName:"+(search || "");
    const searchUrl = data.integratorId != search ? "filterEq=integratorId:"+ data.integratorId : "filterEq=integratorId:"+ data.integratorId+searchParams;
    console.log(searchUrl)
    const response = await listBeneficiaries(searchUrl)
    return response.content.map(v => {
      return {
        id: v.id,
        name: v.business ? v.business.name : (v.person.firstName + " " + v.person.lastName)
      }
    })
  } 

  const getBalance = () => {

    if(!data.integratorId && !data.beneficiaryId) {
      return;
    }

    setLoading(true)

    let response;
    if(data.beneficiaryId) {
      response = getBalanceForIntegratorAndBeneficiary(data.integratorId, data.beneficiaryId)
    } else {
      response = getBalanceForIntegrator(data.integratorId)
    }

    response
      .then((balance) => {
        setData({
          ...data,
          balance
        })
        setLoading(false)
      })
      .catch(() => {
        errorAlert("Balance is not enabled in Non-Custodial Flows")
        setLoading(false)
      })
  }

  const integratorInput = (
    <Select
        id={"integratorId"}
        name={"integratorId"}
        key={"integratorId"}
        fullWidth
        type="text"
        value={data.integratorId}
        watchField={[data.integratorId, data.beneficiaryId]}
        valueSelected={(v="") => {
          handleChange("integratorId", v)
        }
        }
        onSearch={s => searchIntegrators(s)}
        options={data.integratorOptions || []}
        label={"Integrator"}
    />
  )

  const beneficiaryInput = (
    <Select
        id={"beneficiaryId"}
        name={"beneficiaryId"}
        key={"beneficiaryId"}
        fullWidth
        type="text"
        value={data.beneficiaryId}
        watchField={[data.integratorId, data.beneficiaryId]}
        valueSelected={(v="") => {
          handleChange("beneficiaryId", v)
        }
        }
        onSearch={s => searchBeneficiaries(s)}
        options={data.beneficiaryOptions || []}
        label={"Beneficiary"}
    />
  )

  return (
    <Page title="Balance">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Balance</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={6}>
            {integratorInput}
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            {beneficiaryInput}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Widget title={"Total Value"} currencyCode={data.balance.currencyCode} value={data.balance.total}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}