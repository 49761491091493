import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Edit from "../../components/crud/upsert"
import { getPaymentContact, updatePaymentContact } from '../../services/paymentContactService'
import { listCountries } from '../../services/countryService'

import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import { getRoles } from '../../services/authenticationService'
import { mapPaymentRequestToForm } from '../../utils/paymentContact'


// ----------------------------------------------------------------------

export default function PaymentContactEdit() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({
    integratorId: ""
  });

  useEffect(() => {
    fetchData()
  }, []);

  const isAdmin = getRoles().indexOf("ROLE_ADMIN") > -1

  const fetchData = () => {
    setLoading(true)
    getPaymentContact(id)
      .then((data) => {
        console.log(mapPaymentRequestToForm(data))
        setData(mapPaymentRequestToForm(data))
        setFilter({
          ...filter,
          integratorId: data.integratorId
        })
        setLoading(false)
      })
      .catch((e) => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const updateData = (data) => {
    setLoading(true)
    
    let request = {...data}
    delete(request.id)
    return updatePaymentContact(id, request)
        .then(() => {
          
          setData(data)
          successAlert("Payment Contact updated with success")
        })
        .catch((e) => {
          errorAlert(e.message)
        })
        .finally(() => setLoading(false))
  }

  let fields = {
    beneficiaryId: {
      type: "string",
      fieldType: "select",
      label: "Beneficiary",
      readOnly: true,
      watchField: [filter.integratorId],
      constraints: [{
        type: "required",
        message: 'Beneficiary id is required'
      }],
      onSearch: async (search) => {

        const searchParams = "&filterOr=business.name:"+ (search || "") +",person.firstName:"+(search || "");
        const searchUrl = filter.integratorId != search ? "filterEq=integratorId:"+ filter.integratorId : "filterEq=integratorId:"+ filter.integratorId+searchParams;
        const response = await listBeneficiaries(searchUrl)
        return response.content.map(v => {
          return {
            id: v.id,
            name: v.business ? v.business.name : (v.person.firstName + " " + v.person.lastName)
          }
        })
      }
    },
    paymentType: {
      type: "string",
      fieldType: "select",
      label: "Payment Type",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Payment Type is required'
      }],
      options: [
        {id: "WIRE", name: "WIRE"},
      ]
    },
    
    bankAccountNumber: {
      type: "string",
      fieldType: "text",
      readOnly: true,
      label: "Bank Account Number",
      constraints: [{
        type: "required",
        message: 'Account Number is required'
      }]
    },

    contactName: {
      type: "string",
      fieldType: "text",
      readOnly: true,
      label: "Contact name",
      constraints: [{
        type: "required",
        message: 'Contact name is required'
      }]
    },
    contactRoutingKey: {
      type: "string",
      fieldType: "text",
      readOnly: true,
      label: "Routing number",
      constraints: [{
        type: "required",
        message: 'Routing number is required'
      }]
    },
    contactAccountType: {
      type: "string",
      fieldType: "select",
      label: "Account Type",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Account Type is required'
      }],
      options: [
        {id: "CHECKING", name: "CHECKING"},
      ]
    },
    contactType: {
      type: "string",
      fieldType: "select",
      label: "Contact Type",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Contact Type is required'
      }],
      options: [
        {id: "NATURAL_PERSON", name: "NATURAL_PERSON"},
        {id: "COMPANY", name: "COMPANY"},
      ]
    },
    contactAddressLine1: {
      type: "string",
      fieldType: "text",
      label: "Contact line 1",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Contact line 1 is required'
      }]
    },
    contactCity: {
      type: "string",
      fieldType: "text",
      label: "Contact city",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Contact city is required'
      }]
    },
    contactState: {
      type: "string",
      fieldType: "text",
      label: "Contact state",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Contact state is required'
      }]
    },
    contactCountry: {
      type: "string",
      fieldType: "text",
      label: "Contact Country",
      placeholder: "BR",
      constraints: [{
        type: "required",
        message: 'Contact country is required'
      }]
    },
    contactPostalCode: {
      type: "string",
      fieldType: "text",
      label: "Contact postalcode",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Contact postalcode is required'
      }]
    },
    bankName: {
      type: "string",
      fieldType: "text",
      label: "Bank name",
      placeholder: "",
      constraints: [{
        type: "required",
        message: 'Bankname is required'
      }]
    },
    bankAddressLine1: {
      type: "string",
      fieldType: "text",
      label: "Bank line 1",
      placeholder: "Addison St.",
      constraints: [{
        type: "required",
        message: 'Bank line 1 is required'
      }]
    },
    bankAddressLine2: {
      type: "string",
      fieldType: "text",
      label: "Bank line 2",
      placeholder: "Apt. 313",
      constraints: [{
        type: "required",
        message: 'Bank line 2 is required'
      }]
    },
    bankPostalCode: {
      type: "string",
      fieldType: "text",
      label: "Bank postalcode",
      placeholder: "NY",
      constraints: [{
        type: "required",
        message: 'Bank postalcode is required'
      }]
    },
    bankCity: {
      type: "string",
      fieldType: "text",
      label: "Bank city",
      placeholder: "NYC",
      constraints: [{
        type: "required",
        message: 'Bank city is required'
      }]
    },
    bankState: {
      type: "string",
      fieldType: "text",
      label: "Bank state",
      placeholder: "NY",
      constraints: [{
        type: "required",
        message: 'Bank state is required'
      }]
    },
    bankCountry: {
      type: "string",
      fieldType: "text",
      label: "Bank Country",
      placeholder: "BR",
      constraints: [{
        type: "required",
        message: 'Bank country is required'
      }]
    },
  };

  if(isAdmin) {
    fields = {
      integratorId: {
        type: "string",
        fieldType: "select",
        label: "Integrator",
        readOnly: true,
        constraints: [{
          type: "required",
          message: 'Integrator id is required'
        }],
        onSearch: async (search) => {
  
          let query = search;
          if(!isMongoId(search)) {
            query = `filterOr=name:${search},code:${search}`;
          }
          return listIntegrators(query, 0, 5, "")
            .then(data => {
              return data.content
            })
        },
        onSelected: (v) => {
          setFilter({
            integratorId: v
          })
        },
      },
      ...fields
    }
  }

  const steps = [
    {
      label: 'Basic Info',
      buttonName: 'Next',
      fields: ['beneficiaryId', 'paymentType'],
    },
    {
      label: 'Contact Info',
      buttonName: 'Next',
      fields: [
        'contactName',
        'contactType',
        'contactAddressLine1',
        'contactCity',
        'contactState',
        'contactPostalCode',
        'contactCountry'
      ],
    },
    {
      label: 'Bank Info',
      buttonName: 'Next',
      fields: [
        'bankName',
        'bankAddressLine1',
        'bankAddressLine2',
        'bankPostalCode',
        'bankCity',
        'bankState',
        'bankCountry'
      ],
    },
    {
      label: 'Contact Account Info',
      buttonName: 'Save',
      fields: [
        'bankAccountNumber',
        'contactRoutingKey',
        'contactAccountType',
      ],
    }
  ]

  return (
    <>
    {
      <Edit
      label="Payment Contact"
      initialValue={data}
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      steps={steps}
      hideSubmitButton={true}
      backAction={() => navigate("/dashboard/payment-contacts/list")}
    />
    }
    
    </>
    
  );
}
