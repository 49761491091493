import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Save from "../../components/crud/upsert"
import { addCountry } from '../../services/countryService'
import { listCurrencies } from '../../services/currencyService'

import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'

// ----------------------------------------------------------------------

export default function CountrySave() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const saveData = (data) => {
    setLoading(true);
    return addCountry(data)
        .then((countryCreated) => {
          navigate(`/dashboard/countries/${countryCreated.id}/edit`)
          successAlert("User created with success")
        })
        .catch((e) => {
          setLoading(false);
          errorAlert(e.message)
        })
  }

  const fields = {
    name: {
      type: "string",
      fieldType: "text",
      label: "Name",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'Name is required'
      }, {
        type: "required",
        message: 'Name is required'
      }],
    },
    code: {
      type: "string",
      fieldType: "text",
      label: "Code",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 2,
        message: 'Too big'
      }, {
        type: "required",
        message: 'Code is required'
      }]
    },
    phonePrefix: {
      type: "string",
      fieldType: "text",
      label: "Phone Prefix",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 2,
        message: 'Too'
      }, {
        type: "required",
        message: 'Phone prefix is required'
      }]
    },
    currencyId: {
      type: "string",
      fieldType: "select",
      label: "Currency",
      constraints: [{
        type: "required",
        message: 'Currency is required'
      }],
      onSearch: async (search) => {

        let query = search;
        if(!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}`;
        }
        return listCurrencies(query, 0, 5, "")
          .then(data => {
            return data.content
          })
      }
    },
    legalAge: {
      type: "number",
      fieldType: "text",
      label: "Legal Age",
      constraints: [{
        type: "required",
        message: 'Legal age is required'
      }]
    },
  };

  return (
    <Save
      label="Create Country"
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      initialValue={{name: "", code: "", phonePrefix: "", currencyId: "", legalAge: 0}}
      onSave={(data) => {
        saveData(data)
      }}
      goToListPage={() => navigate("/dashboard/countries/list")}
    />
  );
}
