import { useContext } from 'react'
import clsx from 'clsx'

// Utils
import { getCustomShadows } from '../../theme/shadows'
import { search } from '../../utils/form'

// Components
import NumberFormatCustom from '../NumberFormatCustom'
import FileUpload from '../FileUpload'
import PickDate from '../PickDate'
import { FormContext, FormProvider } from '../providers/FormProvider'
import Switch from '../switch/Switch'
import DynamicList from '../dynamic-list/DynamicList'
import Select from '../../components/Select'
import Loading from '../../components/Loading'

// Material
import { styled } from '@mui/material/styles'
import { TextField as MuiTextField } from '@mui/material'

// Local Utils
const createFormFieldOnChange = ({
  handleChange,
  parent,
  fieldKey,
  data,
  fieldSetIndex,
  callback,
}) => {
  return e => {
    // If the parent key already exists in the form data, then use that
    // Otherwise create an empty array
    // This is done as sometimes we use uncontrolled components and other
    // times we pass in the value directly.
    const value = e.target ? e.target.value : e
    let handlerParams = { key: fieldKey, value }

    if (parent && parent.fieldType === 'array') {
      const dataField = data[parent.key] || [{}]

      // Update contacts array
      dataField[fieldSetIndex] = {
        ...dataField[fieldSetIndex],
        [fieldKey]: value,
      }

      // Update form data
      handlerParams = { key: parent.key, value: dataField }
    }

    handleChange(handlerParams)

    callback && callback(value)
  }
}

function useGetFormFieldValue({
  fieldKey,
  parentFieldKey,
  fieldSetIndex,
}) {
  const { formData } = useContext(FormContext)
  const value =
    parentFieldKey && !isNaN(fieldSetIndex)
      ? formData[parentFieldKey][fieldSetIndex][fieldKey]
      : formData[fieldKey]

  return { value }
}

const FormFieldWrapper = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
    boxShadow: getCustomShadows({ componentName: 'formField' }),
    // Specific for the Autocomplete/Select component
    '& .MuiAutocomplete-root': {
      flexGrow: 1,
      maxWidth: 300,
    },
    '& .MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-root':
      {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: 'none',
      },
    '.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-notchedOutline':
      {
        border: `1px solid ${theme.palette.formFields.borderColor}`,
      },
  }
})
const Label = styled(props => <label {...props} />)(({ theme }) => {
  return {
    ...theme.typography.body1,
    color: theme.palette.blue.primary,
  }
})
const TextField = styled(props => <MuiTextField {...props} />)(
  ({ theme }) => {
    return {
      ...theme.typography.body1,
      flexGrow: 0.4,
      '.MuiInput-root': {
        border: `1px solid ${theme.palette.formFields.borderColor}`,
        borderRadius: 8,
      },
      '.Mui-focused.MuiInput-root': {
        borderColor: theme.palette.primary.main,
      },
      '.MuiInput-root:hover:not(.Mui-disabled):before': {
        border: 'none',
      },
      '.MuiInput-root:hover:after': {
        border: 'none',
      },
      '.MuiInput-root:after': {
        border: 'none',
      },
      '&:after': {
        border: 'none',
      },
      '& .MuiInput-input': {
        textAlign: 'right',
        height: 'auto',
        padding: theme.spacing(1, 2),
      },
      '& .MuiInput-root:before': {
        border: 'none',
      },
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
      '&:read-only': {
        color: theme.palette.grey[500],
      },
    }
  },
)
const ActiveToggle = styled(props => (
  <Switch {...props} disableRipple />
))(({ theme }) => {
  return {
    height: 48,
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.success.secondary,
    },
    '& .MuiSwitch-switchBase': {
      top: '50%',
      transform: 'translate(5px, -50%)',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translate(15px, -50%)',
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.active,
      opacity: 1,
    },
  }
})
const StyledSelect = styled(Select)(({ theme }) => {
  return {
    flexGrow: 1,
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      height: 'auto',
      padding: 0,
    },
  }
})
const StyledNumberField = styled(NumberFormatCustom)(({ theme }) => {
  return {
    width: 'auto',
    '.MuiInputBase-input': {
      height: 'auto',
      padding: theme.spacing(1),
    },
  }
})
const StyledFieldset = styled('fieldset')(({ theme }) => ({
  border: 'none',
}))

// Form Fields
const TextFormField = ({
  fieldKey,
  field,
  formData,
  value,
  handleFormChange,
  errorMessages,
  fieldSetIndex,
  errorMessageKey,
}) => {
  const onChange = createFormFieldOnChange({
    handleChange: handleFormChange,
    data: formData,
    fieldKey,
    parent: field.parent,
    fieldSetIndex,
  })

  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <TextField
        id={fieldKey}
        name={fieldKey}
        type='text'
        variant='standard'
        value={value}
        onChange={onChange}
        disabled={field.readOnly}
        error={errorMessages[errorMessageKey] != null}
        helperText={errorMessages[errorMessageKey]}
      />
    </FormFieldWrapper>
  )
}
const SelectFormField = ({
  fieldKey,
  field,
  formData,
  value,
  handleFormChange,
  errorMessages,
  fieldSetIndex,
  errorMessageKey,
}) => {
  const onChange = createFormFieldOnChange({
    handleChange: handleFormChange,
    data: formData,
    fieldKey,
    parent: field.parent,
    fieldSetIndex,
    callback: value => {
      field.onSelected && field.onSelected(value, formData)
    },
  })

  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <StyledSelect
        id={fieldKey}
        name={fieldKey}
        disabled={field.readOnly}
        type='text'
        watchField={field.watchField}
        value={value}
        valueSelected={onChange}
        onSearch={s => search(s, field)}
        options={field.options || []}
        error={errorMessages[errorMessageKey] != null}
        helperText={errorMessages[errorMessageKey]}
      />
    </FormFieldWrapper>
  )
}
const SwitchFormField = ({
  fieldKey,
  field,
  value,
  handleFormChange,
}) => {
  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <div style={{ flexGrow: 0 }}>
        <ActiveToggle
          label={field.label}
          readOnly={field.readOnly}
          data={value}
          onChange={data =>
            handleFormChange({ key: fieldKey, value: data })
          }
        />
      </div>
    </FormFieldWrapper>
  )
}
const UploadFormField = ({
  fieldKey,
  field,
  value,
  handleFormChange,
}) => {
  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <FileUpload
        label={field.label}
        data={value}
        handleChange={files =>
          handleFormChange({ key: fieldKey, value: files })
        }
      />
    </FormFieldWrapper>
  )
}
const PickDateFormField = ({
  fieldKey,
  field,
  formData,
  value,
  handleFormChange,
  fieldSetIndex,
}) => {
  const onChange = createFormFieldOnChange({
    handleChange: handleFormChange,
    data: formData,
    fieldKey,
    parent: field.parent,
    fieldSetIndex,
    callback: value => {
      field.onSelected && field.onSelected(value, formData)
    },
  })

  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <PickDate key={fieldKey} data={value} onChange={onChange} />
    </FormFieldWrapper>
  )
}
const ListFormField = ({
  fieldKey,
  field,
  formData,
  value,
  handleFormChange,
}) => {
  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <DynamicList
        id={fieldKey}
        name={fieldKey}
        label={field.label}
        data={value}
        onAdd={newItem => {
          handleFormChange({
            key: fieldKey,
            value: [...new Set([...formData[fieldKey], newItem])],
          })
        }}
        onRemove={idx => {
          formData[fieldKey].splice(idx, 1)
          handleFormChange({
            key: fieldKey,
            value: [...new Set([...formData[fieldKey]])],
          })
        }}
      />
    </FormFieldWrapper>
  )
}
const NumberFormField = ({
  fieldKey,
  field,
  value,
  handleFormChange,
  errorMessages,
  errorMessageKey,
}) => {
  return (
    <FormFieldWrapper className='form_field'>
      <Label>{field.label}</Label>
      <StyledNumberField
        value={value}
        onChange={value => handleFormChange({ key: fieldKey, value })}
        name={fieldKey}
        type='text'
        readOnly={field.readOnly}
        id={fieldKey}
        error={errorMessages[errorMessageKey] != null}
        helperText={errorMessages[errorMessageKey]}
      />
    </FormFieldWrapper>
  )
}

const FORM_FIELD_MAP = {
  text: TextFormField,
  select: SelectFormField,
  switch: SwitchFormField,
  upload: UploadFormField,
  pickdate: PickDateFormField,
  list: ListFormField,
  number: NumberFormField,
}

const MappedFormFieldComponent = ({
  field,
  fieldKey,
  fieldSetIndex,
}) => {
  const formProps = useContext(FormContext)
  const FormFieldComponent = FORM_FIELD_MAP[field.fieldType]
  const errorMessageKey =
    !isNaN(fieldSetIndex) && field.parent
      ? `${field.parent.key}[${fieldSetIndex}].${fieldKey}`
      : fieldKey
  const { value } = useGetFormFieldValue({
    fieldKey,
    parentFieldKey: field.parent ? field.parent.key : null,
    fieldSetIndex,
  })

  return (
    <FormFieldComponent
      fieldKey={fieldKey}
      field={field}
      fieldSetIndex={fieldSetIndex}
      value={value}
      errorMessageKey={errorMessageKey}
      {...formProps}
    />
  )
}

function renderFormFields({
  formFields,
  stepFormFields,
  fieldSetIndex,
}) {
  const fields = stepFormFields || formFields

  return Object.keys(fields).map((fieldKey, index) => {
    const field = fields[fieldKey]

    try {
      
      if (field.type === 'array') {
        return field.valueSchema.map((value, index) => {
          return (
            <GroupedFormFields
              key={`${field.label}_${index}`}
              stepFormFields={value}
              formFields={value}
              fieldSetIndex={index}
              label={field.label}
            />
          )
        })
      }

      return (
        <MappedFormFieldComponent
          key={`${fieldKey}-${index}`}
          fieldKey={fieldKey}
          field={field}
          fieldSetIndex={fieldSetIndex}
        />
      )
    } catch (e) {
      console.error(`Field error: ${field}, key: ${fieldKey}`)
      throw e
    }
  })
}

const Fieldset = styled('fieldset')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: 'none',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '&:last-of-type': {
    marginBottom: 0,
    paddingBottom: 0,
    borderBottom: 'none',
  },
}))

const GroupedFormFieldsHeading = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 24,
}))

function GroupedFormFields({
  formFields,
  stepFormFields,
  fieldSetIndex,
  label,
}) {
  return (
    <Fieldset className='grouped_fields_fieldset'>
      <GroupedFormFieldsHeading>{`${label} ${
        fieldSetIndex + 1
      }`}</GroupedFormFieldsHeading>

      {renderFormFields({
        formFields,
        stepFormFields,
        fieldSetIndex,
      })}
    </Fieldset>
  )
}

export function Form({
  formFields,
  className,
  children,
  stepFormFields,
  ...otherProps
}) {
  const { loading } = useContext(FormContext)

  return (
    <Loading loading={loading}>
      <form className={clsx('form', className)} {...otherProps}>
        <StyledFieldset className='fieldset'>
          {renderFormFields({
            formFields,
            stepFormFields,
          })}
        </StyledFieldset>

        {children}
      </form>
    </Loading>
  )
}

export default function ContextWrappedForm({
  formFields,
  stepFormFields,
  initialData,
  onSuccess,
  onError,
  submitAction,
  ...restOfProps
}) {
  return (
    <FormProvider
      onError={onError}
      onSuccess={onSuccess}
      initialFormData={initialData}
      formFields={formFields}
      submitAction={submitAction}>
      <Form
        formFields={formFields}
        stepFormFields={stepFormFields}
        {...restOfProps}
      />
    </FormProvider>
  )
}
