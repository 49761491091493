import * as React from 'react';
import { useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { notifySwiftCompleted, getBatchOperationById } from '../../services/batchOperationService'
import { errorAlert, successAlert } from '../../services/alertService'

// material
import Page from '../../components/Page'
import Save from '../../components/crud/upsert'

export default function BatchOperationOut() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        referenceId: '',
        amount: 0
    })
    const { id } = useParams()


    let fields = {
        id: {
            type: 'string',
            fieldType: 'text',
            label: 'Settlement id',
            readOnly: true,
            constraints: [
                {
                type: 'required',
                message: 'is required',
                },
            ],
        },
        referenceId: {
            type: 'string',
            fieldType: 'text',
            label: 'Reference id',
            constraints: [
                {
                type: 'required',
                message: 'is required',
                },
            ],
        },
        amount: {
            type: 'string',
            fieldType: 'number',
            label: 'Amount',
            readOnly: true,
            constraints: [
                {
                    type: 'min',
                    min: 1,
                    message: 'Too Short!',
                },
                {
                    type: 'required',
                    message: 'is required',
                },
            ],
        }
    }

    
    const fetchData = useCallback(() => {
        getBatchOperationById(id)
            .then(data => {

                setData({amount: data.amount.value, id: id})
                return data
            })
            .catch(e => errorAlert(e.message))
            .finally(() => setLoading(false))
        }, [])

    const executeSwiftStarted = data => {
        setLoading(true)
        const request = {
            referenceNumber: data.referenceId,
            batchOperationId: data.id
        }

        return notifySwiftCompleted(request)
            .then(_ => {
                navigate(`/dashboard/batch-operation/out/list`)
                successAlert('Swift completed notified')
                return true
            })
            .catch(e => {
                errorAlert(e.message)
                return false
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
      }, [fetchData])


    return (
        <Page className='main_content' title={'Batch Operation | Notify swift completed'}>
            <Save
                label='Notify swift completed'
                loading={loading}
                isLoading={loaded => setLoading(loaded)}
                fields={fields}
                initialValue={data}
                onSave={data => executeSwiftStarted(data)}
                saveButtonText='Notify'
                hideGoBackButton='true'
            />
        </Page>
    );
}

