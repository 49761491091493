import * as React from 'react';
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table } from '@mui/material';



import { getAccountsAffectedByTransaction } from '../../services/transactionService'
import Label from 'src/components/Label';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
});

// fix calculate node amount to be based in the beneficiary or integrator
const accountTree = function AccountTree(props) {



    const [data, setData] = useState(null)
    const { id } = useParams()

    const refresh = () => {
        getAccountsAffectedByTransaction(id)
            .then(data => {
                setData(data)
            })
    }


    useEffect(() => {
        refresh()
    }, []);

    const defineOwnerType = (owner) => {

        if (owner == null) {
            return "No owner";
        } else if (owner.beneficiaryId == null) {
            return "Integrator"
        } else if (owner.beneficiaryId != null) {
            return "Beneficiary"
        }
    }

    const transactionItems =
        (
            <Grid >
                <Label>Account movements</Label>
                <Paper >
                    <TableContainer style={{ maxWidth: 1500 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" align="right">Number</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Created At</TableCell>
                                <TableCell align="right">Revenue</TableCell>
                                <TableCell align="right">Account type</TableCell>
                                <TableCell align="right">Atomic Operation</TableCell>
                                <TableCell align="right">Ledgering Transaction</TableCell>
                                <TableCell align="right">Currency</TableCell>
                                <TableCell align="right">Debit</TableCell>
                                <TableCell align="right">Credit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody displayRowCheckbox={false}>
                            {data && data.transactionItems.map(row => {
                                return (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell align="right">{row.account.number}</TableCell>
                                        <TableCell align="right">{row.account.name}</TableCell>
                                        <TableCell align="right">{row.createdAt}</TableCell>
                                        <TableCell align="right">{row.account.revenueType}</TableCell>
                                        <TableCell align="right">{defineOwnerType(row.account.owner)}</TableCell>
                                        <TableCell align="right">{row.ledgeringTransaction.atomicOperation.operation}</TableCell>
                                        <TableCell align="right">{row.ledgeringTransaction.operation}</TableCell>
                                        <TableCell align="right">{row.currency}</TableCell>
                                        <TableCell align="right">{row.direction == "DEBIT" ? row.amount : 0}</TableCell>
                                        <TableCell align="right">{row.direction == "CREDIT" ? row.amount : 0}</TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </TableContainer>
                </Paper>
                <Label>Accounts current amount</Label>
                <Paper >
                    <TableContainer>
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" scope="row" align="right">Number</TableCell>
                                <TableCell align="right">Name</TableCell>
                                <TableCell align="right">Provider</TableCell>
                                <TableCell align="right">Currency</TableCell>
                                <TableCell align="right">Balance</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody displayRowCheckbox={false}>
                            {data && data.accountTotals.map(row => {
                                return (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell align="right">{row.number}</TableCell>
                                        <TableCell align="right">{row.name}</TableCell>
                                        <TableCell align="right">{row.provider}</TableCell>
                                        <TableCell align="right">{row.currency}</TableCell>
                                        <TableCell align="right">{row.balance}</TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </TableContainer>
                </Paper>
            </Grid>)

    return (
        <>
            {transactionItems}
        </>
    );
}

export default withStyles(styles)(accountTree);
