import { useCallback, useState, forwardRef, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEqual as _isEqual_ } from 'lodash'
import {
  isWithinInterval,
  addDays,
  isAfter,
  isEqual,
  differenceInCalendarDays,
  isBefore,
} from 'date-fns'

// Utils
import { getCustomShadows } from '../../theme/shadows'

// Material
import { TextField as MuiTextField } from '@mui/material'
import { styled as MuiStyled } from '@mui/material/styles'
import { ArrowBack, ArrowForward } from '@mui/icons-material'

// Constants
const WEEKDAY_NAME_DISPLAY_MAP = {
  sunday: 'su',
  monday: 'm',
  tuesday: 't',
  wednesday: 'w',
  thursday: 'th',
  friday: 'f',
  saturday: 's',
}

// Styled Components
const StyledDatepickerWrapper = MuiStyled('div')(({ theme }) => ({
  '.react-datepicker-popper': {
    backgroundColor: theme.palette.primary.light,
    width: '100%',
    maxWidth: '650px',
    boxShadow: getCustomShadows({
      componentName: 'datepickerCalendar',
    }),
    // TEST CODE
    position: 'relative',
    zIndex: 1000,
  },
  '.calendar': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: theme.spacing(2),
  },
  '.react-datepicker__month-container': {
    flex: '1 0 50%',
  },
  '.react-datepicker__day-names': {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 40px)',
    ...theme.typography.eyebrow,
    color: theme.palette.grey.secondary,
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
  },
  '.react-datepicker__month': {
    display: 'grid',
  },
  '.react-datepicker__week': {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 40px)',
    gridTemplateRows: theme.spacing(5),
  },
  '.react-datepicker__day .day': {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid transparent',
    borderRadius: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.primary.main,
    height: '100%',
  },
  '.react-datepicker__day:hover .day': {
    cursor: 'pointer',
    backgroundColor: theme.palette.blue.secondary,
    color: theme.palette.primary.light,
  },
  '.react-datepicker__day .is_date_range_bounds': {
    backgroundColor: theme.palette.blue.secondary,
    color: theme.palette.primary.light,
  },
  '&.date_range_selected .is_start_date': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&.date_range_selected .is_end_date': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  '&.date_range_selected .is_start_date.is_end_date': {
    borderRadius: 8,
  },
  '.selected_date_range_day .day': {
    backgroundColor: theme.palette.blue.tertiary,
    borderRadius: 0,
  },
}))

const TextField = MuiStyled(
  forwardRef((props, ref) => <MuiTextField ref={ref} {...props} />),
)(({ theme }) => {
  return {
    ...theme.typography.body1,
    flexGrow: 0.4,
    backgroundColor: theme.palette.primary.light,
    '.MuiInput-root': {
      border: `1px solid ${theme.palette.formFields.borderColor}`,
      borderRadius: 8,
    },
    '.Mui-focused.MuiInput-root': {
      borderColor: theme.palette.primary.main,
    },
    '.MuiInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '.MuiInput-root:hover:after': {
      border: 'none',
    },
    '.MuiInput-root:after': {
      border: 'none',
    },
    '&:after': {
      border: 'none',
    },
    '& .MuiInput-input': {
      textAlign: 'right',
      height: 'auto',
      padding: theme.spacing(1, 2),
    },
    '& .MuiInput-root:before': {
      border: 'none',
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
    '&:read-only': {
      color: theme.palette.grey[500],
    },
  }
})

const StyledDatepickerHeader = MuiStyled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  paddingBottom: theme.spacing(2),
  '.month_display': {
    flex: '1 0 auto',
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const StyledMonthToggleButton = MuiStyled('button')(({ theme }) => ({
  background: 'none',
  border: 'none',
  '.month_toggle_icon': {
    fill: theme.palette.blue.secondary,
  },
}))

const StyledWeekday = MuiStyled('span')(({ theme }) => ({
  ...theme.typography.eyebrow,
  color: theme.palette.grey.secondary,
  textAlign: 'center',
}))

function Weekday(name) {
  const display = WEEKDAY_NAME_DISPLAY_MAP[name.toLowerCase()]

  return (
    <StyledWeekday className='weekday_display'>{display}</StyledWeekday>
  )
}

function DateRangePickerHeader({
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  monthDate,
}) {
  const isFirstDisplayMonth = customHeaderCount === 0

  return (
    <StyledDatepickerHeader>
      <StyledMonthToggleButton
        aria-label='Previous Month'
        disabled={!isFirstDisplayMonth}
        className={
          'react-datepicker__navigation react-datepicker__navigation--previous'
        }
        style={!isFirstDisplayMonth ? { visibility: 'hidden' } : null}
        onClick={decreaseMonth}>
        <ArrowBack
          className={
            'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous month_toggle_icon'
          }
        />
      </StyledMonthToggleButton>
      <span className='react-datepicker__current-month month_display'>
        {monthDate.toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })}
      </span>
      <StyledMonthToggleButton
        aria-label='Next Month'
        disabled={isFirstDisplayMonth}
        className={
          'react-datepicker__navigation react-datepicker__navigation--next'
        }
        style={isFirstDisplayMonth ? { visibility: 'hidden' } : null}
        onClick={increaseMonth}>
        <ArrowForward
          className={
            'react-datepicker__navigation-icon react-datepicker__navigation-icon--next month_toggle_icon'
          }
        />
      </StyledMonthToggleButton>
    </StyledDatepickerHeader>
  )
}

// Single Datepicker
export function Datepicker({
  selectedDateRange: { startDate, endDate },
  ...otherProps
}) {
  return (
    <StyledDatepickerWrapper
      className={clsx('datepicker_wrapper', {
        date_range_selected: !!startDate && !!endDate,
      })}>
      <ReactDatePicker
        className='datepicker'
        calendarClassName='calendar'
        formatWeekDay={Weekday}
        renderDayContents={(day, date) => {
          const zeroedSelectedDate = date.setHours(0, 0, 0, 0)
          const isStartDate = startDate
            ? isEqual(zeroedSelectedDate, startDate.setHours(0, 0, 0, 0))
            : null
          const isEndDate = endDate
            ? isEqual(zeroedSelectedDate, endDate.setHours(0, 0, 0, 0))
            : endDate
          const className = clsx('day', {
            is_start_date: isStartDate,
            is_end_date: isEndDate,
            is_date_range_bounds: isStartDate || isEndDate,
          })

          return <span className={className}>{day}</span>
        }}
        inline
        {...otherProps}
      />
    </StyledDatepickerWrapper>
  )
}

Datepicker.propTypes = {
  monthsShown: PropTypes.number,
  customInput: PropTypes.any,
  renderCustomHeader: PropTypes.any,
  renderDayContents: PropTypes.any,
  isClearable: PropTypes.bool,
  selectedDateRange: PropTypes.shape({
    startDate: PropTypes.any,
    endDate: PropTypes.any,
  }),
}

Datepicker.defaultProps = {
  monthsShown: 1,
  customInput: <TextField />,
  isClearable: true,
}

function __getDateRange({ startDate, endDate }) {
  let inBetweenDays = []

  if (!!startDate && !!endDate) {
    let numDaysBetweenStartAndEndDate =
      differenceInCalendarDays(endDate, startDate) - 1

    while (numDaysBetweenStartAndEndDate > 0) {
      inBetweenDays = [
        ...inBetweenDays,
        addDays(startDate, numDaysBetweenStartAndEndDate),
      ]

      numDaysBetweenStartAndEndDate--
    }
  }

  return [{ selected_date_range_day: inBetweenDays }]
}

export const DATE_RANGE_DATES_KEYS = {
  startDate: 'startDate',
  endDate: 'endDate',
}
const DEFAULT_DATE_RANGE = {
  [DATE_RANGE_DATES_KEYS.startDate]: null,
  [DATE_RANGE_DATES_KEYS.endDate]: null,
}
// Date Range Picker
// https://reactdatepicker.com/#example-custom-header-with-two-months-displayed
export function DateRangePicker({
  onChange,
  selectedDateRange,
  ...otherProps
}) {
  const [dateRange, setDateRange] = useState(selectedDateRange)
  const { startDate: currentStartDate, endDate: currentEndDate } =
    dateRange
  const updateStartDate = useCallback(
    newStartDate => {
      const newDateRange = {
        startDate: newStartDate,
        endDate: currentEndDate,
      }
      setDateRange(() => newDateRange)
      onChange({
        dateRange: newDateRange,
        dateKey: DATE_RANGE_DATES_KEYS.startDate,
      })
    },
    [currentEndDate, onChange],
  )
  const updateEndDate = useCallback(
    newEndDate => {
      const newDateRange = {
        startDate: currentStartDate,
        endDate: newEndDate,
      }
      setDateRange(() => newDateRange)
      onChange({
        dateRange: newDateRange,
        dateKey: DATE_RANGE_DATES_KEYS.endDate,
      })
    },
    [currentStartDate, onChange],
  )
  const onDateSelect = useCallback(
    selectedDate => {
      /**
       *  Update Start Date:
       *    - If Start Date doesn't exist
       *    - If Start Date set and selected date is before Start Date,
       *    - If Start Date and End Date exist and selected date is in between them
       */
      if (
        !currentStartDate ||
        (!!currentStartDate && isBefore(selectedDate, currentStartDate)) ||
        (!!currentStartDate &&
          !!currentEndDate &&
          isWithinInterval(selectedDate, {
            start: currentStartDate,
            end: currentEndDate,
          }))
      ) {
        return updateStartDate(selectedDate)
      }

      /**
       *  Update End Date:
       *    - If End Date doesn't exist
       *    - If End Date doest exist but selected date is after End Date
       */
      if (
        !currentEndDate ||
        (!!currentEndDate && isAfter(selectedDate, currentEndDate))
      ) {
        return updateEndDate(selectedDate)
      }
    },
    [updateStartDate, updateEndDate, currentStartDate, currentEndDate],
  )
  useEffect(() => {
    if (!_isEqual_(selectedDateRange, dateRange)) {
      setDateRange(selectedDateRange)
    }
  }, [selectedDateRange, dateRange])

  // Highlight dates:
  // https://reactdatepicker.com/#example-highlight-dates-with-custom-class-names-and-ranges
  const highlightDates = __getDateRange(dateRange)
  return (
    <Datepicker
      {...otherProps}
      renderCustomHeader={DateRangePickerHeader}
      shouldCloseOnSelect={false}
      selected={null}
      onChange={onDateSelect}
      selectedDateRange={dateRange}
      highlightDates={highlightDates}
    />
  )
}

DateRangePicker.propTypes = {
  monthsShown: PropTypes.number,
  onChange: PropTypes.func,
  selectedDateRange: PropTypes.shape({
    startDate: PropTypes.object,
    endDate: PropTypes.object,
  }),
}

DateRangePicker.defaultProps = {
  monthsShown: 2,
  selectedDateRange: DEFAULT_DATE_RANGE,
}
