import axios from "axios";

import { getCurrentUser } from './../services/authenticationService'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CORE_API_URL,
    timeout: 30000
});

axiosInstance.interceptors.request.use(function (config) {
    const user = getCurrentUser();

    if(window.location.pathname.includes("/login")) {
        delete(config.headers.Authorization);
    } else if (user) {
        config.headers.Authorization =  'Bearer ' + user.token;
    } 

    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    if (401 === error.response.status && !window.location.pathname.includes("/login")) {
        window.location.href = "/login"
    } else {

        const errorResponse = (error.response.data.errors && error.response.data.errors[0]) || error.response.data || { message: "Error has occurred"}
        return Promise.reject(errorResponse);
    }
});

export default axiosInstance