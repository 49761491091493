import { getRoles } from '../services/authenticationService'
import { useLocalStorage } from '../hooks/general'
import { useState, useEffect } from 'react'

// material
import { IconButton, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import closeFill from '@iconify/icons-eva/close-fill'

// components
import Loading from '../components/Loading'

import Page from '../components/Page'
import PageHeader from '../components/PageHeader'
import Card from '../components/Card'
import { getGeneralInfo } from '../services/dashboardService'
import { fCurrency } from '../utils/formatNumber'
// import Table from '../components/Table'

// ----------------------------------------------------------------------
const StyledContainer = styled(Container)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}))

const LABELS = {
  totalBeneficiariesActive: {
    value: 0,
    label: 'KYC Completed Beneficiaries',
    note: 'Keep tabs on your Beneficiaries with completed KYC',
  },
  totalBeneficiariesPending: {
    value: 0,
    label: 'KYC Pending Beneficiaries',
    note: 'Keep tabs on your Beneficiaries with pending KYC',
  },
  totalIntegratorBalance: {
    value: 0,
    label: 'Total Beneficiary Balance',
    note: 'Keep tabs on your Beneficiaries balance',
    format: true,
  },
  totalTransfers: {
    value: 0,
    label: 'Sum of total Transfers',
    note: 'Keep tabs on your Total Transfers with success',
    format: true,
  },
  totalPayments: {
    label: 'Sum of total Payments',
    note: 'Keep tabs on your Total Payments with success',
    format: true,
  },
  totalIntegrators: {
    value: 0,
    label: 'Total Integrators',
    note: 'Keep tabs on your Total Integrators',
    adminView: true,
  },
  totalCurrencies: {
    value: 0,
    label: 'Total Currencies',
    note: 'Keep tabs on your Total Currencies',
    adminView: true,
  },
  totalCountries: {
    value: 0,
    label: 'Total Countries',
    note: 'Keep tabs on your Total Countries',
    adminView: true,
  },
}

const CalloutBox = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.headingFontFamily,
  borderRadius: 8,
  marginBottom: theme.spacing(4),
  position: 'relative',
}))

const StyledCalloutBoxCloseButton = styled(IconButton)(
  ({ theme }) => ({
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    zIndex: 10,
  }),
)

const StyledCardContainer = styled('section')(({ theme }) => ({
  display: 'grid',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(3),
  gridTemplateColumns: 'repeat(1, 1fr)',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}))

const StyledCardWrapper = styled('div')(({ theme }) => ({
  flexShrink: 1,
}))

const StyledCardValue = styled(Typography)(({ theme }) => ({
  fontSize: '48px',
  fontFamily: theme.typography.headingFontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: theme.spacing(4),
}))

const StyledCardLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.blue.secondary,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(2),
}))

const StyledCardNote = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey.secondary,
}))

function Banner() {
  const [bannerClosed, setBannerClosedState] = useLocalStorage(
    'dashboardBannerClosed',
    false,
  )

  if (bannerClosed) {
    return null
  }

  return (
    <CalloutBox>
      <StyledCalloutBoxCloseButton
        onClick={() => setBannerClosedState(true)}>
        <Icon icon={closeFill} color='white' width={20} height={20} />
      </StyledCalloutBoxCloseButton>
      Welcome to Caliza! Here's a glimpse of what's to come for the
      Dashboard.
    </CalloutBox>
  )
}

function MockDashboard() {
  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState(
    Object.keys(LABELS).map(key => LABELS[key]),
  )

  useEffect(() => {
    getGeneralInfo()
      .then(info => {
        const processedData = Object.keys(info)
          .filter(key => !(!isAdmin && LABELS[key].adminView))
          .map(key => {
            const labelObject = LABELS[key]

            return {
              value: labelObject.format
                ? fCurrency(info[key])
                : info[key],
              label: labelObject.label,
              note: labelObject.note,
            }
          })

        setData([...processedData])
      })
      .catch(err => { })
      .finally(() => setLoading(false))
  }, [isAdmin])

  return (
    <StyledCardContainer>
      <Loading loading={loading}>
        {data.map(({ value, label, note }, index) => (
          <StyledCardWrapper
            key={label}
            className='card_with_note_wrapper'>
            <Card>
              <StyledCardValue>{value}</StyledCardValue>

              <StyledCardLabel>{label}</StyledCardLabel>

              <StyledCardNote>{note}</StyledCardNote>
            </Card>
          </StyledCardWrapper>
        ))}
      </Loading>
    </StyledCardContainer>
  )
}

export default function DashboardApp() {
  return (
    <Page sx={{ flexGrow: 1 }} title='Caliza - Dashboard'>
      <PageHeader style={{ marginBottom: 32 }} heading='Dashboard' />
      <StyledContainer maxWidth='xl'>
        <Banner />
        <MockDashboard />
      </StyledContainer>
    </Page>
  )
}
