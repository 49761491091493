import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box, TextField, Button, MenuItem, List, ListItem, ListItemText } from '@mui/material';
import { getRiskScoreVersionById, addRiskRule, getRiskFactors, getRiskDimensions, getRiskRulesByVersionId } from '../../services/riskScoreService';
import { successAlert, errorAlert  } from '../../services/alertService';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

// Styled Components
const StyledPage = styled('div')(({ theme }) => ({
    maxWidth: 800,
    margin: '0 auto',
    padding: theme.spacing(3),
}));

const Header = styled('header')(({ theme }) => ({
    marginBottom: theme.spacing(6),
}));

const FormField = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const FormFooter = styled('footer')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
}));

// Main Component
export default function RiskScoreVersionDetails() {
    const { id } = useParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [riskScoreVersion, setRiskScoreVersion] = useState(null);
    const [riskFactors, setRiskFactors] = useState([]);
    const [riskDimensions, setRiskDimensions] = useState([]);
    const [riskRules, setRiskRules] = useState([]);
    const [newRiskRule, setNewRiskRule] = useState({
        riskFactorId: '',
        riskDimensionId: '',
        riskRuleConditional: '',
        conditionalRuleBaseValue: '',
        scoreIncrease: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getRiskScoreVersionById(id);
                setRiskScoreVersion(data);

                // Fetch the list of risk rules for this version
                const rules = await getRiskRulesByVersionId(id);
                console.log(rules)
                setRiskRules(rules);
            } catch (error) {
                console.error('Failed to fetch risk score version details', error);
            }
        }
        fetchData();
    }, [id]);

    useEffect(() => {
        async function fetchDropdownData() {
            try {
                const [factors, dimensions] = await Promise.all([
                    getRiskFactors(),
                    getRiskDimensions(),
                ]);
                setRiskFactors(factors);
                setRiskDimensions(dimensions);
            } catch (error) {
                console.error('Failed to fetch dropdown data', error);
            }
        }
        fetchDropdownData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleRuleChange = (e) => {
        const { name, value } = e.target;
        setNewRiskRule({
            ...newRiskRule,
            [name]: value,
        });
    };

    const handleAddRiskRule = async (e) => {
        e.preventDefault();
        // Validation: Ensure scoreIncrease is not null, 0, or empty
        if (!newRiskRule.scoreIncrease || parseFloat(newRiskRule.scoreIncrease) <= 0) {
            errorAlert('Score Increase must be greater than 0.');
            return;
        }

        try {
            const payload = {
                ...newRiskRule,
                scoreVersionId: id,
            };
            await addRiskRule(payload);
            successAlert('Risk rule added successfully');
            setNewRiskRule({
                riskFactorId: '',
                riskDimensionId: '',
                riskRuleConditional: '',
                conditionalRuleBaseValue: '',
                scoreIncrease: '',
            });

            // Reload the list of risk rules after adding a new one
            const updatedRules = await getRiskRulesByVersionId(id);
            setRiskRules(updatedRules);

        } catch (error) {
            console.error('Failed to add risk rule', error);
        }
    };

    const handleCancel = () => {
        navigate('/risk-versioning/list');
    };

    return (
        <StyledPage>
            <Header>
                <h1>Risk Score Version Details</h1>
            </Header>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Risk Score Details" />
                <Tab label="Add New Risk Rules" />
            </Tabs>
            {tabIndex === 0 && riskScoreVersion && (
                <Box p={3}>
                    <h2>Risk Score Details</h2>
                    <p><strong>Version:</strong> {riskScoreVersion.version}</p>
                    <p><strong>Active:</strong> {riskScoreVersion.active ? 'Yes' : 'No'}</p>
                    <p><strong>Low Risk Threshold:</strong> {riskScoreVersion.lowRiskThreshold}</p>
                    <p><strong>Medium Risk Threshold:</strong> {riskScoreVersion.mediumRiskThreshold}</p>
                    <p><strong>Created By:</strong> {riskScoreVersion.createdBy}</p>
                    <p><strong>Created Date:</strong> {riskScoreVersion.createdDate}</p>
                    <p><strong>Updated By:</strong> {riskScoreVersion.updatedBy}</p>
                    <p><strong>Updated Date:</strong> {riskScoreVersion.updatedDate}</p>

                    <p><strong>List of Risk Rules:</strong></p>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Risk Factor</TableCell>
                                    <TableCell>Risk Dimension</TableCell>
                                    <TableCell>Conditional</TableCell>
                                    <TableCell>Base Value</TableCell>
                                    <TableCell>Score Increase</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {riskRules.map((rule, index) => (
                                    <TableRow key={rule.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{rule.riskFactor.description}</TableCell>
                                        <TableCell>{rule.riskDimension.description}</TableCell>
                                        <TableCell>{rule.riskRuleConditional}</TableCell>
                                        <TableCell>{rule.conditionalRuleBaseValue}</TableCell>
                                        <TableCell>{rule.scoreIncrease}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
            {tabIndex === 1 && (
                <Box p={3}>
                    <h2>Add New Risk Rules</h2>
                    <form onSubmit={handleAddRiskRule}>
                        <FormField>
                            <TextField
                                select
                                label="Risk Factor ID"
                                name="riskFactorId"
                                value={newRiskRule.riskFactorId}
                                onChange={handleRuleChange}
                                required
                                fullWidth
                            >
                                {riskFactors.map(factor => (
                                    <MenuItem key={factor.id} value={factor.id}>
                                        {factor.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormField>
                        <FormField>
                            <TextField
                                select
                                label="Risk Dimension ID"
                                name="riskDimensionId"
                                value={newRiskRule.riskDimensionId}
                                onChange={handleRuleChange}
                                required
                                fullWidth
                            >
                                {riskDimensions.map(dimension => (
                                    <MenuItem key={dimension.id} value={dimension.id}>
                                        {dimension.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormField>
                        <FormField>
                            <TextField
                                select
                                label="Rule Conditional"
                                name="riskRuleConditional"
                                value={newRiskRule.riskRuleConditional}
                                onChange={handleRuleChange}
                                required
                                fullWidth
                            >
                                <MenuItem value="EQUALS">Equals</MenuItem>
                                <MenuItem value="NOT_EQUALS">Not Equals</MenuItem>
                                <MenuItem value="GREATER_THAN">Greater Than</MenuItem>
                                <MenuItem value="LESS_THAN">Less Than</MenuItem>
                            </TextField>
                        </FormField>
                        <FormField>
                            <TextField
                                label="Conditional Rule Base Value"
                                name="conditionalRuleBaseValue"
                                type="text"
                                value={newRiskRule.conditionalRuleBaseValue}
                                onChange={handleRuleChange}
                                required
                                fullWidth
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                label="Score Increase"
                                name="scoreIncrease"
                                type="number"
                                value={newRiskRule.scoreIncrease}
                                onChange={handleRuleChange}
                                required
                                fullWidth
                            />
                        </FormField>
                        <FormFooter>
                            <Button variant="outlined" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" type="submit">
                                Add Risk Rule
                            </Button>
                        </FormFooter>
                    </form>
                </Box>
            )}
        </StyledPage>
    );
}
