import * as React from 'react';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { errorAlert, successAlert } from '../../services/alertService'


import { getPaymentContactAccount } from '../../services/paymentContactService'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 6,
};

export default function BasicModal({
    buttonName,
    id
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState({
    type: '',
    accountName: '',
    email: '',
    accountType: '',
    accountAddress1: '',
    accountAddress2: '',
    accountCity: '',
    accountState: '',
    accountCountry: '',
    accountZipcode: '',
    bankName: '',
    bankAccountNumber: '',
    bankRoutingNumber: '',
    bankType: ''
});


  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    getPaymentContactAccount(id)
      .then((data) => {

        console.log(data)
        const formattedData = {
            accountName: data.receiverName,
            accountAddress1: data.receiverAddress.street1,
            accountAddress2: data.receiverAddress.street2,
            accountCity: data.receiverAddress.city,
            accountState: data.receiverAddress.state,
            accountCountry: data.receiverAddress.country,
            accountZipcode: data.receiverAddress.postalCode,
            bankName: data.receiverBankName,
            bankAccountNumber: data.accountNumber,
            bankRoutingNumber: data.routingNumber,
            swiftCode: data.swiftCode
        }

        setData(formattedData)
      })
      .catch((e) => {
        errorAlert(e.message)
      })
  }

  return (
    <div>
      <Button onClick={handleOpen}>{buttonName}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid sx={style} container spacing={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Deposit Details
          </Typography>
          <Grid item>
            <TextField
                id="accountName"
                style = {{width: 350}}
                label="Account Name"
                disabled={true}
                value={data.accountName}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="accountAddress1"
                style = {{width: 350}}
                label="Account Address 1"
                disabled={true}
                value={data.accountAddress1}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="accountAddress2"
                style = {{width: 350}}
                label="Account Address 2"
                disabled={true}
                value={data.accountAddress2}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="bankName"
                style = {{width: 350}}
                label="Bank Name"
                disabled={true}
                value={data.bankName}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="bankAccountNumber"
                style = {{width: 350}}
                label="Account Number"
                disabled={true}
                value={data.bankAccountNumber}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="bankRoutingNumber"
                style = {{width: 350}}
                label="Routing Number"
                disabled={true}
                value={data.bankRoutingNumber}
            />
           </Grid>
           <Grid item xs={12}>
           <TextField
                id="SwiftCode"
                style = {{width: 350}}
                label="Reference ID"
                disabled={true}
                value={data.swiftCode}
            />
           </Grid>
        </Grid>
      </Modal>
    </div>
  );
}