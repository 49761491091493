import { useState, useEffect } from 'react';

// material
import { generateOfframpTransferDailyTransactions } from '../../services/reportService'
import { FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { errorAlert, successAlert } from '../../services/alertService'
import { listIntegrators } from '../../services/integratorService'
import Select from '../../components/Select'
import { styled } from '@mui/material/styles'
import { isMongoId } from '../../services/stringValidation'
import PickDate from '../../components/PickDate'

// ----------------------------------------------------------------------

export default function PaymentContactSave() {

    const [loading, setLoading] = useState(false)
    const [integratorId, setIntegratorId] = useState([]);
    const [integrators, setIntegrators] = useState([]);
    const [date, setDate] = useState(new Date());

    const StyledSelect = styled(Select)(({ theme }) => {
        return {
          'flexGrow': 1,
          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            height: 'auto',
            padding: 0,
          },
        }
    })

    const searchIntegrator = async (search) => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listIntegrators(query, 0, 100, '').then(data => {
          return data.content
        })
    }

    const onClick = async () => {
        setLoading(true)
        try {
            await generateOfframpTransferDailyTransactions(integratorId, date);
            successAlert("A report will be generated. As soon as it is ready, an email will be sent for the configured admins ")
        } catch(e) {
            errorAlert(e.message)
        } finally{
            setLoading(false)
        }
    }
  
    return (
        <FormControl>
        
            <StyledSelect
                id={1}
                name={"integrator"}
                key={"integrator"}
                disabled={false}
                type='text'
                value={integratorId}
                watchField={[]}
                valueSelected={(id) => {
                    setIntegratorId(id)
                }}
                onSearch={(s) => searchIntegrator(s)}
                options={integrators}
            />

            <PickDate 
                key={"1"} 
                data={date} 
                onChange={(value) => setDate(value)} 
            />

            <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                onClick={onClick}
                loading={loading}>
                Generate daily offramp transactions
            </LoadingButton>
        </FormControl>
  );
}
