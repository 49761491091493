import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listBatchOperations
} from '../../services/batchOperationService'
import { showFormattedDate } from '../../services/dateService'

import Page from '../../components/Page'
import {
  SECTION_BASE_PATHS,
  numberWithCommas,
  getCurrency
} from '../../utils/general'
// ----------------------------------------------------------------------
const BATCH_OPERATION_BASE_PATH = SECTION_BASE_PATHS.batch_operation

export default function BatchOperationsList({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = ({ id }) =>
    navigate(`${BATCH_OPERATION_BASE_PATH}/in/${id}/start`)

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchData = useCallback(() => {
    listBatchOperations('BRL_TO_USDC')
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Batch Operation In | Overview'}>
      <List
        hideSearchFilter={true}
        loading={loading}
        label='Batch operation in'
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'origin', label: 'Origin', alignRight: false },
          { id: 'destination', label: 'Destination', alignRight: false },
          { id: 'currencyExchangeFlow', label: 'Exchange Flow', alignRight: false },
          {
            id: 'createdDate',
            label: 'Date',
            alignRight: false,
            onRender: createdDate => {
              return showFormattedDate(createdDate)
            },
          },
          {
            id: 'amount',
            label: 'Amount',
            alignRight: false,
            onRender: (_, row) => {
              const num = parseFloat(row.amount.value)
              return `${getCurrency(
                row.amount.currencyCode,
              )}${numberWithCommas(num.toFixed(2))}`
            },
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
