import { useParams, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Edit from '../../components/crud/upsert'
import { getMe } from '../../services/authenticationService'
import { getAdminById, updateAdmin } from '../../services/adminService'
import { errorAlert, successAlert } from '../../services/alertService'
import { listCountries } from '../../services/countryService'
import { getIntegratorByToken } from '../../services/integratorService'

import IntegratorProfile from './integratorProfile'
import AdminProfile from './adminProfile'

// ----------------------------------------------------------------------

export default function Profile() {
  const [loading, setLoading] = useState(true)

  const [user, setUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })

  const [integrator, setIntegrator] = useState({})

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    setLoading(true)
    getMe().then(user => {
      setUser(user)
      console.log(user)
      if (user.type == 'INTEGRATOR') {
        getIntegratorByToken()
          .then(integrator => {
            const data = {
              id: integrator.id,
              name: integrator.name,
              document: integrator.document,
              phone1: integrator.telephones && integrator.telephones[0],
              phone2: integrator.telephones && integrator.telephones[1],
              phone3: integrator.telephones && integrator.telephones[2],
              street1: integrator.address.streetOne,
              street2: integrator.address.streetTwo,
              city: integrator.address.city,
              zipcode: integrator.address.zipcode,
              state: integrator.address.state,
              country: integrator.address.country,
              clientId: integrator.credentials.clientId,
              clientSecret: integrator.credentials.clientSecret,
              callbackUrl: integrator.callbackUrl,
              contactEmails: integrator.contactEmails,
              webhookSecret: integrator.webhookSecret,
            }
            setIntegrator(data)
            setLoading(false)
          })
          .catch(e => {
            errorAlert(e.message)
            setLoading(false)
          })
      } else {
        setLoading(false)
      }
    })
  }

  return (
    !loading && (
      <>
        {user.type === 'ADMIN' ? (
          <AdminProfile data={user} />
        ) : (
          <IntegratorProfile initalIntegrator={integrator} />
        )}
      </>
    )
  )
}
