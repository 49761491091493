import React, { useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Icon } from '@iconify/react'
import { format } from 'date-fns'

// Utils
import { getCustomShadows } from '../../../theme/shadows'
import palette from '../../../theme/palette'

// Material ----------------------------------------------------------------------
import { styled } from '@mui/material/styles'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Checkbox,
  Radio,
  FormControlLabel,
  Select,
  TextField,
  IconButton,
} from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

// Components ----------------------------------------------------------------------
import Button from '../../Button'
import DropdownMenu, { DropdownMenuContext } from '../../DropdownMenu'
import { DateRangePicker, DATE_RANGE_DATES_KEYS } from '../Datepicker'
import { FilterContext } from './FilterProvider'
import { ReactComponent as FilterIcon } from '../../../images/filter-icon.svg'
import { ReactComponent as SortIcon } from '../../../images/sort-icon.svg'
import { ReactComponent as CalendarIcon } from '../../../images/calendar-icon.svg'

// Constants ----------------------------------------------------------------------
const DROPDOWN_MENU_PADDING = 1.5
const { startDate: startDateKey, endDate: endDateKey } =
  DATE_RANGE_DATES_KEYS
const DROPDOWN_STYLE = {
  '.dropdown_menu_inner': {
    padding: DROPDOWN_MENU_PADDING,
  },
  '.filter_icon': {
    width: 24,
  },
  '.form_control_section': {
    width: '100%',
  },
  '.form_control_section:not(:last-of-type)': {
    marginBottom: 2,
  },
}
const FILTER_ICON_MAP = {
  filter: FilterIcon,
  sort: SortIcon,
  date: CalendarIcon,
}
export const FILTER_QUERY_PARAM_KEY = 'q'

// Styled Components ----------------------------------------------------------------------
const FilterMenuHeader = styled('header')(({ theme }) => ({
  padding: theme.spacing(
    DROPDOWN_MENU_PADDING,
    0,
    DROPDOWN_MENU_PADDING,
    0,
  ),
  marginLeft: theme.spacing(DROPDOWN_MENU_PADDING),
  marginRight: theme.spacing(DROPDOWN_MENU_PADDING),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  '.filter_icon path': {
    fill: theme.palette.blue.secondary,
  },
}))

const FilterHeading = styled('p')(({ theme }) => ({
  fontSize: 15,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.blue.secondary,
  margin: 0,
  lineHeight: 1,
}))

const FilterButton = styled(Button)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.filterAction

  return {
    ...defaultButton,
    fontWeight: theme.typography.fontWeightRegular,
    height: 40,
    boxShadow: getCustomShadows({ componentName: 'filterButtons' }),
    marginRight: 16,
    '&:hover': hover,
    '& .filter_icon path': icon,
    '&:active': pressed,
    '&.has_filters_applied': {
      color: theme.palette.blue.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.has_filters_applied .filter_icon path': {
      fill: theme.palette.blue.secondary,
    },
  }
})

const FilterApplyButton = styled(Button)(() => {
  return {
    width: '100%',
  }
})

const OptionGroup = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.form_control_label': {
    flexGrow: 1,
    color: theme.palette.blue.primary,
  },
}))

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '.form_control_label': {
    flexGrow: 1,
    color: theme.palette.blue.primary,
  },
}))

const StyledFormLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiCheckbox-root input': {
    borderColor: 'black',
  },
  '&:hover .MuiSvgIcon-root': {
    fill: theme.palette.blue.secondary,
  },
  '.Mui-checked .MuiSvgIcon-root': {
    fill: theme.palette.blue.secondary,
  },
}))

const StyledRadio = styled(Radio)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    fill: theme.palette.common.black,
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    fill: theme.palette.common.black,
  },
}))

const StyledFilterSectionWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const StyledFilterSectionHeading = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.grey.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  '&.Mui-focused': {
    color: 'currentColor',
  },
}))

const StyledClearFilterActionItem = styled('a')(({ theme }) => ({
  fontSize: 15,
  color: theme.palette.grey.secondary,
  fontWeight: theme.typography.fontWeightRegular,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.blue.secondary,
  },
}))

const FORM_FIELD_MAP = {
  radio: StyledRadio,
  checkbox: StyledCheckbox,
  selectMenu: Select,
}

const FORM_CONTROL_GROUP_MAP = {
  radio: StyledRadioGroup,
  checkbox: OptionGroup,
  selectMenu: OptionGroup,
}

function FilterSection({
  label,
  filterKey,
  filterSetKey,
  options = [],
  filterType,
  acceptsMultipleValues,
  acceptsMultipleFilters,
}) {
  const {
    currentFilterState: {
      [filterSetKey]: {
        appliedFilters: { [filterKey]: filterValue },
      },
    },
    addFilter,
    removeFilter,
  } = useContext(FilterContext)
  const isChecked = value =>
    acceptsMultipleValues
      ? filterValue.includes(value)
      : filterValue === value
  const InputField = FORM_FIELD_MAP[filterType]
  const FormControlGroup = FORM_CONTROL_GROUP_MAP[filterType]
  const onInputSelect = ({ target }) => {
    const filterAction = target.checked ? addFilter : removeFilter

    filterAction({
      filterSetKey,
      key: filterKey,
      value: target.value,
      acceptsMultipleValues,
      acceptsMultipleFilters,
    })
  }

  return (
    <FormControl className='form_control_section'>
      <StyledFilterSectionHeading>{label}</StyledFilterSectionHeading>
      <FormControlGroup>
        {options.map(({ displayValue, className, ...optionData }) => (
          <StyledFormLabel
            className={clsx('form_control_label', className)}
            control={<InputField />}
            label={displayValue}
            checked={isChecked(optionData.value)}
            onChange={onInputSelect}
            {...optionData}
          />
        ))}
      </FormControlGroup>
    </FormControl>
  )
}

export function FilterButtonToggle({
  title,
  iconType,
  filterSetKey,
  customAppliedFilterCopy,
  ...otherProps
}) {
  const {
    appliedFilterState: {
      [filterSetKey]: { appliedFilterCount },
    },
  } = useContext(FilterContext)
  const hasFiltersApplied = appliedFilterCount > 0
  const IconComponent = FILTER_ICON_MAP[iconType]

  return (
    <FilterButton
      variant='contained'
      className={clsx('filter_toggle', {
        has_filters_applied: hasFiltersApplied,
      })}
      startIcon={<IconComponent className='filter_icon icon' />}
      {...otherProps}>
      {title}:&nbsp;
      {hasFiltersApplied
        ? customAppliedFilterCopy || appliedFilterCount
        : null}
    </FilterButton>
  )
}

function ApplyButton() {
  const { closeDropdownMenu } = useContext(DropdownMenuContext)
  const { applyFilters } = useContext(FilterContext)
  const onApplyFilter = () => {
    applyFilters()
    closeDropdownMenu()
  }

  return (
    <FilterApplyButton className='apply_button' onClick={onApplyFilter}>
      Apply
    </FilterApplyButton>
  )
}

const ApplyFilterButton = React.memo(ApplyButton)

// @tedlin182 - Need to pass in clear function
function FilterHeader({ title, hideClearFilter, iconType, filterSetKey }) {
  const { clearCurrentFilters } = useContext(FilterContext)
  const IconComponent = FILTER_ICON_MAP[iconType]
  const onClearFilter = () => {
    clearCurrentFilters({ filterSetKey })
  }

  return (
    <FilterMenuHeader>
      <FilterHeading>
        <IconComponent className='filter_icon icon' /> {title}
      </FilterHeading>

      {hideClearFilter ? null : (
        <StyledClearFilterActionItem onClick={onClearFilter}>
          clear
        </StyledClearFilterActionItem>
      )}
    </FilterMenuHeader>
  )
}

function FilterWrapper({
  children,
  iconType,
  filterSetKey,
  hideClearFilter,
  title,
  toggleButtonLabel,
  menuHeading,
  customAppliedFilterCopy,
  sx = {},
  wrapperProps = {},
}) {
  return (
    <DropdownMenu
      className='filter_wrapper'
      sx={{ ...DROPDOWN_STYLE, ...sx }}
      menuHeading={menuHeading({
        iconType,
        filterSetKey,
        hideClearFilter,
        title,
        toggleButtonLabel,
      })}
      menuToggle={
        <FilterButtonToggle
          iconType={iconType}
          title={toggleButtonLabel}
          filterSetKey={filterSetKey}
          customAppliedFilterCopy={customAppliedFilterCopy}
        />
      }
      providerProps={wrapperProps}>
      {children}
    </DropdownMenu>
  )
}

FilterWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  iconType: PropTypes.oneOf(Object.keys(FILTER_ICON_MAP)),
  menuHeading: PropTypes.func,
  title: PropTypes.any.isRequired,
  filterSetKey: PropTypes.string.isRequired,
  hideClearFilter: PropTypes.bool,
  toggleButtonLabel: PropTypes.string.isRequired,
  customAppliedFilterCopy: PropTypes.any,
}

FilterWrapper.defaultProps = {
  menuHeading: props => <FilterHeader {...props} />,
}

export function Filter({
  filters,
  filterSetKey,
  acceptsMultipleFilters,
  ...otherProps
}) {
  return (
    <FilterWrapper filterSetKey={filterSetKey} {...otherProps}>
      <StyledFilterSectionWrapper>
        {filters.map(filterData => (
          <FilterSection
            key={filterData.label}
            filterSetKey={filterSetKey}
            filterKey={filterData.key}
            acceptsMultipleFilters={acceptsMultipleFilters}
            {...filterData}
          />
        ))}
      </StyledFilterSectionWrapper>

      <ApplyFilterButton filterSetKey={filterSetKey} />
    </FilterWrapper>
  )
}

Filter.propTypes = {
  title: PropTypes.any.isRequired,
  filterSetKey: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      acceptsMultipleValues: PropTypes.bool,
      filterType: PropTypes.oneOf([
        'radio',
        'checkbox',
        'selectMenu',
        'autocomplete',
        'daterange',
      ]).isRequired,
      defaultSelected: PropTypes.arrayOf(PropTypes.any),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.any,
          displayValue: PropTypes.any.isRequired,
        }),
      ),
    }),
  ).isRequired,
  hideClearFilter: PropTypes.bool,
  toggleButtonLabel: PropTypes.string.isRequired,
}

const StyledDateRangeFilterHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.grey[500_16]}`,
  paddingTop: theme.spacing(0.5),
  margin: theme.spacing(0, 1.5),
  '.calendar_icon path': {
    fill: theme.palette.blue.secondary,
  },
  '.forward_arrow_icon': {
    fill: theme.palette.blue.secondary,
    flex: 0.75,
    marginRight: theme.spacing(1.5),
  },
}))

const StyledDateRangeHeaderLabel = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.blue.secondary,
  fontWeight: theme.typography.fontWeightBold,
  '.calendar_icon': {
    marginRight: theme.spacing(1),
  },
}))

const StyledDateFieldsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledDateField = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}))

const StyledDateInputField = styled(TextField)(({ theme }) => ({
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiOutlinedInput-input': {
    padding: 0,
    width: '90px',
    color: theme.palette.grey.secondary,
  },
}))

const StyledDateRangeFilterFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2.5, 0, 1.5),
  margin: theme.spacing(0, 1.5),
  borderTop: `1px solid ${theme.palette.grey[500_16]}`,
  '.apply_button': {
    flexGrow: 0,
    width: 'auto',
  },
}))

const StyledClearDateRangeFilter = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
}))

function DateRangePickerHeader({ filterSetKey }) {
  const {
    currentFilterState: {
      [filterSetKey]: {
        appliedFilters: { startDate, endDate },
      },
    },
  } = useContext(FilterContext)

  return (
    <StyledDateRangeFilterHeader>
      <StyledDateRangeHeaderLabel className='label'>
        <CalendarIcon className='calendar_icon icon' />
        Date:
      </StyledDateRangeHeaderLabel>

      <StyledDateFieldsWrapper className='date_fields'>
        <StyledDateField>
          <CalendarIcon className='calendar_icon icon' />
          <StyledDateInputField
            placeholder='Start Date'
            value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
            readOnly
          />
        </StyledDateField>

        <ArrowForward className='forward_arrow_icon' />

        <StyledDateField>
          <CalendarIcon className='calendar_icon icon' />
          <StyledDateInputField
            placeholder='End Date'
            value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
            readOnly
          />
        </StyledDateField>
      </StyledDateFieldsWrapper>
    </StyledDateRangeFilterHeader>
  )
}

const StyledAppliedDateRangeWrapper = styled('p')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}))

const StyledAppliedDateRangeLabel = styled('span')(({ theme }) => ({
  color: theme.palette.grey.secondary,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  '&:last-of-type': {
    marginRight: 0,
  },
}))

const StyledAppliedDateRangeDate = styled('span')(({ theme }) => ({
  color: theme.palette.blue.secondary,
  fontWeight: theme.typography.fontWeightRegular,
}))

function AppliedDateRangeButtonCopy({ filterSetKey }) {
  const {
    appliedFilterState: {
      [filterSetKey]: {
        appliedFilters: { startDate, endDate },
      },
    },
  } = useContext(FilterContext)
  return (
    <StyledAppliedDateRangeWrapper>
      <StyledAppliedDateRangeLabel>
        From:&nbsp;
        <StyledAppliedDateRangeDate>
          {startDate ? format(startDate, 'd MMM, yyyy') : ''}
        </StyledAppliedDateRangeDate>
      </StyledAppliedDateRangeLabel>
      <StyledAppliedDateRangeLabel>
        To:&nbsp;
        <StyledAppliedDateRangeDate>
          {startDate ? format(endDate, 'd MMM, yyyy') : ''}
        </StyledAppliedDateRangeDate>
      </StyledAppliedDateRangeLabel>
    </StyledAppliedDateRangeWrapper>
  )
}
export function DateRangeFilter({ filterSetKey, ...otherProps }) {
  const {
    currentFilterState: {
      [filterSetKey]: { appliedFilters },
    },
    addFilter,
    clearCurrentFilters,
  } = useContext(FilterContext)
  const onDateSelect = ({ dateRange, dateKey }) => {
    addFilter({
      filterSetKey,
      key: dateKey,
      value: dateRange[dateKey],
      acceptsMultipleValues: false,
      acceptsMultipleFilters: true,
    })
  }
  const onClearFilter = () => clearCurrentFilters({ filterSetKey })
  const sx = useMemo(
    () => ({
      '.dropdown_menu': { minWidth: '660px' },
      '.react-datepicker__triangle': {
        display: 'none',
      },
      '.calendar': {
        columnGap: '40px',
      },
    }),
    [],
  )

  // Here we can set the default selected dates in the datepicker
  // if any present in the URL.
  // This also helps reset the datepicker UI when we clear/reset the date range picker.
  const selectedDateRange = {
    [startDateKey]: appliedFilters[startDateKey] || null,
    [endDateKey]: appliedFilters[endDateKey] || null,
  }

  return (
    <FilterWrapper
      filterSetKey={filterSetKey}
      menuHeading={DateRangePickerHeader}
      sx={sx}
      customAppliedFilterCopy={
        <AppliedDateRangeButtonCopy filterSetKey={filterSetKey} />
      }
      {...otherProps}>
      <DateRangePicker
        {...otherProps}
        onChange={onDateSelect}
        customInput={null}
        selectedDateRange={selectedDateRange}
      />
      <StyledDateRangeFilterFooter>
        <StyledClearDateRangeFilter onClick={onClearFilter}>
          <IconButton>
            <Icon
              color={palette.grey.secondary}
              icon='clarity:window-close-line'
              width={20}
              height={20}
            />
          </IconButton>
          Clear
        </StyledClearDateRangeFilter>

        <ApplyFilterButton filterSetKey={filterSetKey} />
      </StyledDateRangeFilterFooter>
    </FilterWrapper>
  )
}

DateRangeFilter.propTypes = {
  title: PropTypes.any.isRequired,
  filterSetKey: PropTypes.string.isRequired,
  toggleButtonLabel: PropTypes.string.isRequired,
}

const DefaultFilter = { Filter, DateRangeFilter }

export default DefaultFilter
