import axios from "../config/axiosConfig";

export const getRiskScoreSnapshotById = async (id) => {
    let url = `/v1/risk-score-snapshots/${id}`
    const response = await axios.get(url);
    return response.data;
}

export const listRiskScoreVersions = async (page, size, sort) => {
    let sortAndOrder = ''

    if (sort && Object.keys(sort).length > 0) {
        const sortField = sort.filter;
        sortAndOrder = `&sortDirection=${sort.type}&sortBy=${sortField}`;
        console.log(sortAndOrder)
    }

    let url = `/v1/risk-score-versions?page=${page}&size=${size}`;

    if(!sortAndOrder.includes('undefined')) {
        url += sortAndOrder
    }

    const response = await axios.get(url);
    return response.data;
};

export const createRiskScoreVersion = async (riskScoreVersionData) => {
    console.log(riskScoreVersionData)
    let url = `/v1/risk-score-versions`;
    const response = await axios.post(url, riskScoreVersionData);
    return response.data;
}

export const getRiskFactors = async () => {
    const response = await axios.get('/v1/risk-factors');
    return response.data;
}

export const getRiskDimensions = async () => {
    const response = await axios.get('/v1/risk-dimensions');
    return response.data;
}

export const getRiskScoreVersionById = async (id) => {
    const response = await axios.get(`/v1/risk-score-versions/${id}`);
    return response.data;
};

export const addRiskRule = async (riskRuleData) => {
    const response = await axios.post(`/v1/risk-rules`, riskRuleData);
    return response.data;
};

export const getRiskRulesByVersionId = async (id) => {
    const response = await axios.get(`/v1/risk-score-versions/${id}/risk-rules`);
    return response.data;
};
