import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Edit from '../../components/crud/upsert'
import Page from '../../components/Page'
import { getAdminById, updateAdmin } from '../../services/adminService'
import { errorAlert, successAlert } from '../../services/alertService'

// ----------------------------------------------------------------------

export default function AdminEdit() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [admin, setAdmin] = useState(null)

  const fetchData = useCallback(() => {
    setLoading(true)
    getAdminById(id)
      .then(admin => {
        setAdmin(admin)
        setLoading(false)
      })
      .catch(e => {
        errorAlert(e.message)
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateData = data => {
    delete data.type
    delete data.credentials
    setLoading(true)
    return updateAdmin(data)
      .then(() => {
        setAdmin(data)
        successAlert('Administrator updated with success')
        setLoading(false)
      })
      .catch(e => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const fields = {
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Id',
      readOnly: true,
    },
    email: {
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      readOnly: true,
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    firstName: {
      type: 'string',
      fieldType: 'text',
      label: 'First Name',
      constraints: [
        {
          type: 'required',
          message: 'First Name is required',
        },
      ],
    },
    lastName: {
      type: 'string',
      fieldType: 'text',
      label: 'Last Name',
      constraints: [
        {
          type: 'required',
          message: 'LastName is required',
        },
      ],
    },
    active: {
      type: 'string',
      fieldType: 'switch',
      label: 'Active',
    },
  }

  if (!admin) {
    return null
  }

  return (
    <Page className='main_content' title={'Beneficiaries | Overview'}>
      <Edit
        label='Update Administrator'
        initialValue={admin}
        loading={loading}
        isLoading={loaded => setLoading(loaded)}
        fields={fields}
        onSave={data => updateData(data)}
        goToListPage={() => navigate('/dashboard/admins/list')}
      />
    </Page>
  )
}
