import {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert } from '../../services/alertService'
import { listTeam } from '../../services/teamService'
import { getMe, getRoles } from '../../services/authenticationService'

// Components
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'
import EditTeamMemberModal from '../../components/modals/EditTeamMemberModal'
import { ModalContext } from '../../components/providers/ModalProvider'

// Constants
import { TEAM_MEMBER_TYPES } from '../../utils/constants'
const { INTEGRATOR } = TEAM_MEMBER_TYPES
const TEAM_BASE_PATH = SECTION_BASE_PATHS.team

// Main
export default function TeamList() {
  const navigate = useNavigate()
  const { showModal } = useContext(ModalContext)
  const [appliedFiltersFromQueryParams, setSearchParams] = useQueryParam(
    FILTER_QUERY_PARAM_KEY,
  )
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [userRole, setUserRole] = useState(null)
  const updateSearchFilter = searchVal => {
    setSearch(searchVal)
    setSearchParams(
      {
        'person.firstName': searchVal,
        'person.lastName': searchVal,
        'person.email': searchVal,
      },
      { replace: true },
    )
  }
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const isAdmin = userRole == 'INTEGRATOR'

  const fetchFilteredData = useCallback(
    appliedFilters => {
      // const filterStr = !isEmpty(appliedFilters)
      //   ? `filterEq=${convertFilterObjectToString({
      //       filters: appliedFilters,
      //     })}&`
      //   : ''
      // const filter = `${filterStr}filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`

      listTeam('', page, rowsPerPage, sort, appliedFilters)
        .then(({ totalPages, totalElements, content }) => {
          setDataWrapper({
            totalPages,
            totalElements,
            content,
          })
          setLoading(false)
        })
        .catch(e => errorAlert(e.message))
        .finally(() => setLoading(false))
    },
    [page, rowsPerPage, sort, search],
  )

  // This is for the initial page load and search
  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams)
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
  ])

  // Get current logged in user's role and see
  // if they have access to change ownership
  useEffect(() => {
    if (!userRole) {
      async function getCurrentUser() {
        const { type } = await getMe()

        setUserRole(type)
      }

      getCurrentUser()
    }
  }, [userRole])

  // Only Managers can edit team members
  const onTableRowClick =
    userRole === INTEGRATOR.key
      ? rowData =>
          showModal({ data: rowData, modalComponent: EditTeamMemberModal })
      : null

  return (
    <Page className='main_content' title={'Team | Overview'}>
      <List
        loading={loading}
        label='Team'
        addButtonLabel='Add Team Member'
        hideAddButton={!isAdmin}
        goToAddPage={() => navigate(`${TEAM_BASE_PATH}/save`)}
        onDelete={id => {
          setLoading(true)
        }}
        headers={[
          {
            id: 'name',
            label: 'Name',
            alignRight: false,
            onRender: (_, { firstName, lastName }) =>
              `${firstName} ${lastName}`,
          },
          {
            id: 'email',
            label: 'Email',
            alignRight: false,
            onRender: (value, row) => value,
          },
          {
            id: 'title',
            label: 'Title',
            alignRight: false,
            onRender: (_, { type }) =>
              TEAM_MEMBER_TYPES[type].displayValue,
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={updateSearchFilter}
        filters={
          {
            // filterSets: filterSets,
          }
        }
        onTableRowClick={onTableRowClick}
      />
    </Page>
  )
}
