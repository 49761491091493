import { useState, useEffect, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'

// Services
import {
  getIntegratorById,
  updateIntegrator,
} from '../../services/integratorService'
import { errorAlert, successAlert } from '../../services/alertService'
import { listCountries } from '../../services/countryService'
import {
  isMongoId,
  removeNumberCommaAndConvertToNumber,
  toFloat,
} from '../../services/stringValidation'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import Upsert from '../../components/crud/upsert'
// ----------------------------------------------------------------------
// Constants
const INTEGRATORS_BASE_PATH = SECTION_BASE_PATHS.integrators

export default function IntegratorEdit() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [data, setData] = useState(null)
  const goToListPage = () => navigate(`${INTEGRATORS_BASE_PATH}/list`)

  const fetchData = useCallback(() => {
    getIntegratorById(id)
      .then(integrator => {
        const contract = integrator.contract || {}
        const data = {
          name: integrator.name,
          document: integrator.document,
          email: integrator.email,
          phone1: integrator.telephones[0],
          phone2: integrator.telephones[1],
          phone3: integrator.telephones[2],
          street1: integrator.address.streetOne,
          street2: integrator.address.streetTwo,
          city: integrator.address.city,
          zipcode: integrator.address.zipcode,
          state: integrator.address.state,
          country: integrator.address.country,

          startDate: contract.startDate,
          endDate: contract.endDate,
          price: toFloat(contract.price),
          periodicity: contract.periodicity,
          transactionFee: toFloat(contract.transactionFee),
          transactionFeeMode: contract.transactionFeeMode,
          active: integrator.active,

          files: integrator.files || [],
        }

        setData(data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const updateData = data => {
    const request = {}
    request.id = id
    request.name = data.name
    request.document = data.document
    request.telephones = [data.phone1, data.phone2, data.phone3]
    request.email = data.email
    request.active = data.active
    request.files = data.files
    request.address = {
      streetOne: data.street1,
      streetTwo: data.street2,
      city: data.city,
      zipcode: data.zipcode,
      state: data.state,
      country: data.country,
    }
    request.contract = {
      startDate: data.startDate,
      endDate: data.endDate,
      price: removeNumberCommaAndConvertToNumber(data.price),
      periodicity: data.periodicity,
      transactionFee: removeNumberCommaAndConvertToNumber(
        data.transactionFee,
      ),
      transactionFeeMode: data.transactionFeeMode,
    }
    return updateIntegrator(request)
      .then(() => {
        setData(data)
        successAlert('Integrator updated with success')
      })
      .catch(e => {
        errorAlert(e.message)
      })
  }

  const fields = {
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Id',
      readOnly: true,
    },
    name: {
      type: 'string',
      fieldType: 'text',
      label: 'Name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Name is required',
        },
        {
          type: 'required',
          message: 'Name is required',
        },
      ],
    },
    document: {
      type: 'string',
      fieldType: 'text',
      label: 'Document',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Document is required',
        },
        {
          type: 'required',
          message: 'Document is required',
        },
      ],
    },
    email: {
      readOnly: true,
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    phone1: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Phone is to big',
        },
        {
          type: 'required',
          message: 'Phone is required',
        },
      ],
    },
    phone2: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 2',
    },
    phone3: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 3',
    },
    street1: {
      type: 'string',
      fieldType: 'text',
      label: 'Street1',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street is required',
        },
      ],
    },
    street2: {
      type: 'string',
      fieldType: 'text',
      label: 'Street2',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street2 is required',
        },
      ],
    },
    city: {
      type: 'string',
      fieldType: 'text',
      label: 'City',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street2 is required',
        },
      ],
    },
    zipcode: {
      type: 'string',
      fieldType: 'text',
      label: 'Zipcode',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    state: {
      type: 'string',
      fieldType: 'text',
      label: 'State',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    country: {
      type: 'string',
      fieldType: 'select',
      label: 'Country',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      value: null,
      onSearch: async search => {
        let query = search
        if (!!search && !isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listCountries(query, 0, 5, '').then(data => {
          return data.content
        })
      },
    },

    startDate: {
      type: 'date',
      fieldType: 'pickdate',
      label: 'Start Date',
    },
    endDate: {
      type: 'string',
      fieldType: 'pickdate',
      label: 'End Date',
    },
    price: {
      type: 'string',
      fieldType: 'number',
      label: 'Amount to pay',
    },
    periodicity: {
      type: 'string',
      fieldType: 'select',
      label: 'Periodicity',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'DAILY', name: 'DAILY' },
        { id: 'WEEKLY', name: 'WEEKLY' },
        { id: 'MONTHLY', name: 'MONTHLY' },
        { id: 'QUARTERLY', name: 'QUARTERLY' },
        { id: 'YEARLY', name: 'YEARLY' },
      ],
    },
    transactionFee: {
      type: 'string',
      fieldType: 'number',
      label: 'Transaction fee',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    transactionFeeMode: {
      type: 'string',
      fieldType: 'select',
      label: 'Transaction Fee Mode',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'PERCENT', name: 'PERCENT' },
        { id: 'AMOUNT', name: 'AMOUNT' },
      ],
    },
    active: {
      type: 'string',
      fieldType: 'switch',
      label: 'Active',
    },

    files: {
      type: 'string',
      fieldType: 'upload',
      label: 'Upload integrator documents',
    },
  }

  const steps = [
    {
      label: 'Basic Info',
      fields: [
        'name',
        'document',
        'email',
        'phone1',
        'phone2',
        'phone3',
        'street1',
        'street2',
        'city',
        'zipcode',
        'state',
        'country',
      ],
    },
    {
      label: 'Contract info',
      fields: [
        'startDate',
        'endDate',
        'price',
        'periodicity',
        'transactionFee',
        'transactionFeeMode',
        'active',
      ],
    },
    {
      label: 'Documents',
      fields: ['files'],
    },
  ]

  if (!data) {
    return null
  }

  return (
    <Page className='main_content' title={'Integrator | Edit'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Integrator | Update`}
        />
        <Upsert
          loading={loading}
          isLoading={loaded => setLoading(loaded)}
          fields={fields}
          initialValue={data}
          onSave={data => {
            updateData(data)
          }}
          steps={steps}
          hideGoBackButton
          goToListPage={goToListPage}
        />
      </Loading>
    </Page>
  )
}
