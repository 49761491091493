import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Edit from "../../components/crud/upsert"
import { getPendingDeposit, depositRefunded } from '../../services/depositService'
import { errorAlert, successAlert } from '../../services/alertService'
import { confirmAlert } from 'react-confirm-alert'; // Import

// ----------------------------------------------------------------------

export default function PendingDepositRefunded() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [deposit, setDeposit] = useState(null);

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true)
    getPendingDeposit(id)
      .then((deposit) => {

        console.log(deposit)
        setDeposit(deposit)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const updateData = (data) => {

    const yesAction = () => {
      data.id = id;
      data.refunded = true
      setLoading(true)
      return depositRefunded(data.id)
          .then(() => {
            setLoading(false)
            setDeposit(data)
            successAlert("Deposit has been marked as refunded with success")
            setLoading(false)
          })
          .catch((e) => {
            errorAlert(e.message)
          })
    }
    confirmAlert({
      title: 'Marking deposit as refunded',
      message: 'Are you sure, this action cannot be undone?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => yesAction()
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });

    
  }

  const fields = {
    id: {
      type: "string",
      fieldType: "text",
      label: "Id",
      readOnly: true
    },
    integratorId: {
      type: "string",
      fieldType: "text",
      label: "Integrator ID",
      readOnly: true
    },
    beneficiaryId: {
      type: "string",
      fieldType: "text",
      label: "Beneficiary ID",
      readOnly: true
    },
    country: {
      type: "string",
      fieldType: "text",
      label: "Country",
      readOnly: true
    },
    provider: {
      type: "string",
      fieldType: "text",
      label: "Provider",
      readOnly: true
    },
    identifierType: {
      type: "string",
      fieldType: "text",
      label: "Identifier Type",
      readOnly: true
    },
    identifier: {
      type: "string",
      fieldType: "text",
      label: "Identifier",
      readOnly: true
    }
  };

  return (
    <>
    {
      deposit && <Edit
      label="Mark deposit as refunded"
      initialValue={deposit}
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      onSave={(data) => updateData(data)}
      goToListPage={() => navigate("/dashboard/deposit-refunding/list")}
      saveButtonText='Mark deposit as refunded'
    />
    }
    </>
    
  );
}
