import { useParams, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Edit from '../../components/crud/upsert'
import { getMe } from '../../services/authenticationService'
import { errorAlert, successAlert } from '../../services/alertService'
import { listCountries } from '../../services/countryService'
import {
  getIntegratorByToken,
  updateMe,
} from '../../services/integratorService'

// ----------------------------------------------------------------------

export default function Profile({ initalIntegrator }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [integrator, setIntegrator] = useState(initalIntegrator)

  const fields = {
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Id',
      readOnly: true,
    },
    name: {
      type: 'string',
      fieldType: 'text',
      label: 'Name'
    },
    document: {
      type: 'string',
      fieldType: 'text',
      label: 'Document',
    },
    phone1: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone',
    },
    phone2: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 2',
    },
    phone3: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 3',
    },
    street1: {
      type: 'string',
      fieldType: 'text',
      label: 'Street1',
    },
    street2: {
      type: 'string',
      fieldType: 'text',
      label: 'Street2',
    },
    city: {
      type: 'string',
      fieldType: 'text',
      label: 'City',
    },
    zipcode: {
      type: 'string',
      fieldType: 'text',
      label: 'Zipcode',
    },
    state: {
      type: 'string',
      fieldType: 'text',
      label: 'State',
    },
    country: {
      type: 'string',
      fieldType: 'select',
      label: 'Country',
      value: null,
      onSearch: async search => {
        return listCountries(search, 0, 5, '').then(data => {
          return data.content
        })
      },
    },

    clientId: {
      type: 'string',
      fieldType: 'text',
      label: 'Client Id',
      readOnly: true,
    },
    clientSecret: {
      type: 'string',
      fieldType: 'text',
      label: 'Client Secret',
      readOnly: true,
    },
    callbackUrl: {
      type: 'string',
      fieldType: 'text',
      label: 'Callback url',
      readOnly: false,
    },
    webhookSecret: {
      type: 'string',
      fieldType: 'text',
      label: 'Webhook Secret',
      readOnly: true,
    },
    contactEmails: {
      type: 'string',
      fieldType: 'text',
      label: 'Contact email',
      readOnly: false,
    },
  }

  const steps = [
    {
      id: 0,
      label: 'Basic Info',
      fields: [
        'name',
        'document',
        'phone1',
        'phone2',
        'phone3',
        'street1',
        'street2',
        'city',
        'zipcode',
        'state',
        'country',
      ],
    },
    {
      id: 1,
      label: 'Client Info',
      fields: ['clientId', 'clientSecret', 'callbackUrl','webhookSecret', 'contactEmails'],
    },
  ]

  const updateData = data => {
    setLoading(true)

    data.contactEmails = data.contactEmails ? data.contactEmails : [];

    const request = {}
    request.id = integrator.id
    request.name = data.name
    request.document = data.document
    request.telephones = [data.phone1, data.phone2, data.phone3]
    request.files = data.files
    request.callbackUrl = data.callbackUrl
    request.webhookSecret = data.webhookSecret
    request.contactEmails = Array.isArray(data.contactEmails) ? data.contactEmails : [data.contactEmails] 
    request.address = {
      streetOne: data.street1,
      streetTwo: data.street2,
      city: data.city,
      zipcode: data.zipcode,
      state: data.state,
      country: data.country,
    }
    return updateMe(request)
      .then(() => {
        setIntegrator(data)
        successAlert('Integrator updated with success')
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
  }

  console.log(integrator)
  return (
    <>
      <Edit
        title='User Profile'
        label='Profile'
        hideGoBackButton={true}
        initialValue={integrator}
        loading={loading}
        isLoading={loaded => {}}
        fields={fields}
        steps={steps}
        onSave={data => updateData(data)}
        backAction={() => navigate('/dashboard/app')}
      />
    </>
  )
}
