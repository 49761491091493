import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listPendingSwiftTransfer
} from '../../services/operationService'
import Page from '../../components/Page'
import { SECTION_BASE_PATHS, getCurrency, numberWithCommas } from '../../utils/general'
import { showFormattedDate } from '../../services/dateService'

// ----------------------------------------------------------------------
const PENDING_SWIFT_TRANSFERS_BASE_PATH = SECTION_BASE_PATHS.pending_swift_transfers

export default function PendingSwiftTransfers({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = ({ id }) =>
    navigate(`${PENDING_SWIFT_TRANSFERS_BASE_PATH}/${id}/edit`)

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchData = useCallback(() => {
    listPendingSwiftTransfer(page, rowsPerPage, sort)
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderAmount = (amount) => {

    if(amount == null) {
      return 0;
    }
    const num = parseFloat(amount.value)
    return `${getCurrency(
      amount.currencyCode,
    )}${numberWithCommas(num.toFixed(2))}`
  }

  return (
    <Page className='main_content' title={'Pending Swift Transfers | Overview'}>
      <List
        loading={loading}
        label='Pending Swift Transfers'
        goToEditPage={id => navigate(`/dashboard/pending/${id}/edit`)}
        hideSearchFilter
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'provider', label: 'Provider', alignRight: false },
          { id: 'status', label: 'Status', alignRight: false },
          { id: 'externalId', label: 'External ID', alignRight: false },
          { id: 'operationAction', label: 'Operation Action', alignRight: false },
          { id: 'startAmount', label: 'Start Amount', alignRight: false, onRender: renderAmount },
          { id: 'endAmount', label: 'End Amount', alignRight: false, onRender: renderAmount },
          {
            id: 'createdDate',
            label: 'Date',
            alignRight: false,
            onRender: createdDate => {
              return showFormattedDate(createdDate)
            },
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
