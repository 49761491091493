
import axios from '../config/axiosConfig';

export const listWallets = async (search, page, limit, sort ={}) => {
    let url = `/v1/admin/wallets?${search}&page=${page}&size=${limit}`
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}