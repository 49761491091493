
import axios from '../config/axiosConfig';
import { getRoles } from './authenticationService'

export const listPaymentContacts = async (search, page, limit, sort ={}) => {
    let url = `?${search}&page=${page}&size=${limit}`;
    url = isAdmin() ? `/v1/admin/payment-contacts${url}` : `/v1/payment-contacts${url}`;

    const response = await axios.get(url)
    return response.data
}

export const getPaymentContact = async (id) => {
    const url = isAdmin() ? `/v1/admin/payment-contacts/${id}` : `/v1/payment-contacts/${id}`;
    const response = await axios.get(url);
    return response.data;
}

export const getPaymentContactAccount = async (id) => {
    const url = `/v1/payments/beneficiaries/${id}/instructions`;
    const response = await axios.get(url);
    return response.data;
}

export const addPaymentContact = async (paymentContact) => {
    const url = isAdmin() ? `/v1/admin/payment-contacts` : `/v1/payment-contacts`;
    const response = await axios.post(url, paymentContact);
    return response.data;
}

export const updatePaymentContact = async (id, paymentContact) => {
    const url = isAdmin() ? `/v1/admin/payment-contacts/${id}` : `/v1/payment-contacts/${id}`;
    const response = await axios.put(url, paymentContact);
    return response.data;
}

export const deletePaymentContact = async(id) => {
    const url = isAdmin() ? `/v1/admin/payment-contacts/${id}` : `/v1/payment-contacts/${id}`;
    const response = await axios.delete(url);
    return response.data;
}

const isAdmin = () => {
    return getRoles().indexOf("ROLE_ADMIN") > -1
}