import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Edit from '../../components/crud/upsert'
import { findStatementById } from '../../services/statementService'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import { SECTION_BASE_PATHS } from '../../utils/general'
import { showFormattedDate } from '../../services/dateService'


// ----------------------------------------------------------------------
const TRANSFERS_BASE_PATH = SECTION_BASE_PATHS.transfers

export default function TransferView() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { id, statementId } = useParams()
  const [data, setData] = useState({})
  const goToListPage = () => navigate(`${TRANSFERS_BASE_PATH}/list`)

  const fetchData = useCallback(() => {
    setLoading(true)
    findStatementById(statementId, id)
      .then(data => {

        return {
          ...data,
          amount: data.amount.value,
          currencyId: data.amount.currencyId
        }
        
      })
      .then(async data => {

        setData(data)
        setLoading(false)
      })
      .catch(e => {
        errorAlert(e.message)
        setLoading(false)
      })
  }, [statementId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fields = {
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Statement Id',
      readOnly: true,
    },
    beneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = 'filterEq=' + search

        if (!isMongoId(search)) {
          query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`
        }
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    integratorId: {
      type: 'string',
      fieldType: 'select',
      label: 'Integrator',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = 'filterEq=' + search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name: v.name,
            }
          })
        })
      },
    },
    type: {
      type: 'string',
      fieldType: 'text',
      label: 'Type',
      readOnly: true,
      constraints: [],
    },
    correlationId: {
      type: 'string',
      fieldType: 'text',
      label: 'Operation id',
      readOnly: true,
      constraints: []
    },
    amount: {
      type: 'string',
      fieldType: 'number',
      label: 'Total amount',
      readOnly: true,
      constraints: [],
    },
    status: {
      type: 'string',
      fieldType: 'text',
      label: 'Status',
      readOnly: true,
      constraints: []
    },
    statementType: {
      type: 'string',
      fieldType: 'text',
      label: 'Statement Type',
      readOnly: true,
      constraints: []
    },
    createdDate: {
      type: 'string',
      fieldType: 'text',
      label: 'Created at',
      readOnly: true,
      constraints: []
    },
  }

  return (
    <>
      {!loading && (
        <Edit
          label='View Transfers'
          initialValue={data}
          loading={loading}
          hideSubmitButton={true}
          isLoading={loaded => setLoading(loaded)}
          fields={fields}
          hideSubmitButton={true}
          goToListPage={goToListPage}
          backAction={goToListPage}
        />
      )}
    </>
  )
}
