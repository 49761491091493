import { createContext, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isArray, isEmpty } from 'lodash'
import { format, parseISO } from 'date-fns'

// Utils
import {
  STANDARD_DATE_DISPLAY_FORMAT,
  DATE_FILTER_KEYS,
} from '../../../utils/filters'

// Hooks
import { useQueryParam } from '../../../hooks/useQueryParam'

// Components
import { Filter, FILTER_QUERY_PARAM_KEY } from './Filter'

// Local Helpers/Utils
/**
 *  __constructDefaultSelectedFilters
 * *************************************************
 *  - This first checks if there are any filters defined in the url query params
 *    and if so, leverage those values to set the default selected filter state.
 *  - If no filter query params exist, then check to see if there are default selected
 *    values passed in via props.
 *  - If none of the above are true, then just set an empty array for each filter value.
 */
function __constructDefaultSelectedFilters({
  filterSets,
  filtersFromUrl = {},
}) {
  return filterSets.reduce((acc, { filterSetKey, filters }) => {
    let filterSetSections = {}
    let count = 0

    filters.forEach(({ key, defaultSelected, acceptsMultipleValues }) => {
      // Check first if there are filter query params in the URL
      // If not, then see if there is a default value for this filter
      // If none, set to either an empty array or null based on whether
      // this filter accepts multiple values
      const filterVal =
        filtersFromUrl[key] ||
        defaultSelected ||
        (acceptsMultipleValues ? [] : null)

      // If a default selected filter is present, then increase the
      // applied filter count by the size of the array as this indicates
      // the number of filters applied
      // We do a truthy check because using isEmpty with Date objects will
      // return true as it does not have any no own enumerable string keyed properties.
      if (!isEmpty(filterVal) || !!filterVal) {
        count += acceptsMultipleValues ? filterVal.length : 1
      }

      filterSetSections[key] = filterVal
    })

    acc[filterSetKey] = {
      appliedFilterCount: count,
      appliedFilters: filterSetSections,
    }

    return acc
  }, {})
}

// This is used for setting the query params when filters are applied
function __flattenAppliedFilters(appliedFilters) {
  return Object.keys(appliedFilters).reduce((filters, key) => {
    const { appliedFilters: filterSetFilters } = appliedFilters[key]

    return { ...filters, ...filterSetFilters }
  }, {})
}

// If filter query params are present, we need to make sure they're formatted
// in the correct way to store in the filter state.
// For dates, we store them as Date objects so we need to use parseISO to
// re-convert them back to Date objects
function __formatFilterQueryStringToFilterState(
  appliedFiltersFromQueryParams,
) {
  return Object.keys(appliedFiltersFromQueryParams).reduce(
    (filters, key) => {
      filters[key] = !!DATE_FILTER_KEYS[key]
        ? parseISO(appliedFiltersFromQueryParams[key])
        : appliedFiltersFromQueryParams[key]

      return filters
    },
    {},
  )
}

// We need to format the filter state object in order to append to the URL
// as a query param string.
// In particular, the date object needs to be a string in order to properly be
// added to the query param.
function __formatFilterStateToQueryString(appliedFiltersFromQueryParams) {
  return Object.keys(appliedFiltersFromQueryParams).reduce(
    (filters, key) => {
      filters[key] = !!DATE_FILTER_KEYS[key]
        ? format(
            appliedFiltersFromQueryParams[key],
            STANDARD_DATE_DISPLAY_FORMAT,
          )
        : appliedFiltersFromQueryParams[key]

      return filters
    },
    {},
  )
}

// Main
export const FilterContext = createContext()

export function FilterProvider({ children, filterSets }) {
  const [appliedFiltersFromQueryParams, setSearchParams] = useQueryParam(
    FILTER_QUERY_PARAM_KEY,
  )
  const defaultSelectedFilters = useMemo(
    () =>
      __constructDefaultSelectedFilters({
        filterSets,
        filtersFromUrl: __formatFilterQueryStringToFilterState(
          appliedFiltersFromQueryParams,
        ),
      }),
    [filterSets, appliedFiltersFromQueryParams],
  )
  const [appliedFilterState, setAppledFilterState] = useState(
    defaultSelectedFilters,
  )
  const [currentFilterState, setCurrentFilterState] =
    useState(appliedFilterState)

  /**
   *  Filter Set Options:
   *    1. Allows for multiple filters to be set where each filter can have multiple values
   *    (checkboxes)
   *    2. Allows for multiple filters to be set where each filter can have only ONE value
   *    (radios)
   *    3. Can have multiple filters within the set but only one filter can be set and that
   *    filter can only have one value (radio)
   *    4. Can have multiple filters within the set but only one filter can be set and that
   *    filter can have multiple vales (checkbox)
   */
  const addFilter = ({
    filterSetKey,
    key: filterKey,
    value: valueToAdd,
    acceptsMultipleValues,
    acceptsMultipleFilters,
  }) => {
    setCurrentFilterState(currentFilterState => {
      const {
        [filterSetKey]: {
          appliedFilters,
          appliedFilterCount,
          ...restFilterSet
        },
        ...otherFilterSets
      } = currentFilterState
      const updatedAppliedFilters = appliedFilters
      // If filter accepts multiple values, the filter value is
      // an array so add new selected value to
      const newFilterValue = acceptsMultipleValues
        ? [...appliedFilters[filterKey], valueToAdd]
        : valueToAdd
      let updatedAppliedFilterCount = appliedFilterCount

      // If the filter set allows multiple filters to be applied,
      // then we simply add or update that filter value and
      // increment the filter count by 1
      if (acceptsMultipleFilters) {
        updatedAppliedFilters[filterKey] = newFilterValue

        updatedAppliedFilterCount++

        return {
          ...otherFilterSets,
          [filterSetKey]: {
            ...restFilterSet,
            appliedFilters: updatedAppliedFilters,
            // If the filter accepts multiple but only single values
            appliedFilterCount: updatedAppliedFilterCount,
          },
        }
      }

      // If only one filter can be applied at once, then
      // we simply create a new set of applied filters with only
      // one filter and the selected value
      return {
        ...otherFilterSets,
        [filterSetKey]: {
          ...restFilterSet,
          appliedFilters: { [filterKey]: newFilterValue },
          appliedFilterCount: acceptsMultipleValues
            ? newFilterValue.length
            : 1,
        },
      }
    })
  }
  const removeFilter = ({ filterSetKey, key, value }) =>
    setCurrentFilterState(
      ({
        [filterSetKey]: {
          appliedFilterCount,
          appliedFilters: { [key]: filterValuesToUpdate, ...otherFilters },
        },
        ...otherFilterSets
      }) => {
        const valueIndex = filterValuesToUpdate.indexOf(value)

        filterValuesToUpdate.splice(valueIndex, 1)

        return {
          ...otherFilterSets,
          [filterSetKey]: {
            appliedFilterCount: appliedFilterCount - 1,
            appliedFilters: {
              ...otherFilters,
              [key]: filterValuesToUpdate,
            },
          },
        }
      },
    )
  const clearCurrentFilters = ({ filterSetKey }) =>
    setCurrentFilterState(
      ({ [filterSetKey]: { appliedFilters }, ...otherFilterSets }) => ({
        ...otherFilterSets,
        [filterSetKey]: {
          appliedFilterCount: 0,
          appliedFilters: Object.keys(appliedFilters).reduce(
            (defaultValue, key) => {
              defaultValue[key] = isArray(appliedFilters[key]) ? [] : null
              return defaultValue
            },
            {},
          ),
        },
      }),
    )
  const applyFilters = () => {
    const filters = __formatFilterStateToQueryString(
      __flattenAppliedFilters(currentFilterState),
    )

    setAppledFilterState(currentFilterState)
    setCurrentFilterState(currentFilterState)
    setSearchParams(filters)
  }

  return (
    <FilterContext.Provider
      value={{
        appliedFilterState,
        currentFilterState,
        addFilter,
        removeFilter,
        clearCurrentFilters,
        applyFilters,
      }}>
      {children}
    </FilterContext.Provider>
  )
}

FilterProvider.propTypes = {
  children: PropTypes.any.isRequired,
  filterSets: PropTypes.arrayOf(PropTypes.shape(Filter.propTypes)),
}

const FilterWrapper = { FilterContext, FilterProvider }

export default FilterWrapper
