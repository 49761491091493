
import axios from '../config/axiosConfig';

import { createSortUrl } from './urlBuilder'

export const listAdmins = async (search, page, limit, sort) => {
    const url = `/v1/users?${search}&page=${page}&size=${limit}&${createSortUrl(sort)}`;
    const response = await axios.get(url)

    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getAdminById = async (id) => {
    const response = await axios.get(`/v1/users/${id}`);
    return response.data;
}

export const addAdmin = async (admin) => {
    const response = await axios.post(`/v1/users`, admin);
    return response.data;
}

export const updateAdmin = async (admin) => {
    const response = await axios.put(`/v1/users/${admin.id}`, admin);
    return response.data;
}

export const deleteAdmin = async(id) => {
    const response = await axios.delete(`/v1/users/${id}`);
    return response.data;
}