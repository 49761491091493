import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
    listPendingWireIn,
} from '../../services/pendingWireInService'
import Page from '../../components/Page'
import {
    numberWithCommas,
    getCurrency,
  } from '../../utils/general'
// ----------------------------------------------------------------------

import { styled } from '@mui/material/styles'
import PickDate from '../../components/PickDate'

import { getCustomShadows } from '../../theme/shadows'

function getFirstDayOfMonth() {
    var date = new Date();
    date.setHours(0, 0, 0); 
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  
  function getLastDayOfMonth() {
    var date = new Date();
    date.setHours(23, 59, 59); 
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
  }

export default function CountryList({ theme }) {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [from, setFrom] = useState(getFirstDayOfMonth())
  const [to, setTo] = useState(getLastDayOfMonth())

  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const Label = styled((props) => <label {...props} />)(({ theme }) => {
    return {
      ...theme.typography.body1,
      color: theme.palette.blue.primary,
    }
  })

  const FormFieldWrapper = styled('div')(({ theme }) => {
    return {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      'backgroundColor': theme.palette.primary.light,
      'borderRadius': 8,
      'padding': theme.spacing(1, 1.5),
      'boxShadow': getCustomShadows({ componentName: 'formField' }),
      // Specific for the Autocomplete/Select component
      '& .MuiAutocomplete-root': {
        flexGrow: 1,
        maxWidth: 300,
      },
      '& .MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-root': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: 'none',
      },
      '.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.formFields.borderColor}`,
      },
    }
  })

  const PickDateFormField = ({ fieldKey, label, data, handleChange }) => (
    <FormFieldWrapper className='form_field'>
      <Label>{label}</Label>
      <PickDate key={fieldKey} data={data} onChange={(value) => handleChange(fieldKey, value)} />
    </FormFieldWrapper>
  )

  const fetchData = useCallback(() => {
    listPendingWireIn(from, to, page, rowsPerPage)
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Failed Wire IN | Overview'}>
        <PickDateFormField
            data ={from}
            fieldKey = {"from"} 
            label= {"from"} 
            handleChange = {(field, d) => {
              setFrom(d)
            }}
          />

          <PickDateFormField
            data ={to}
            fieldKey = {"to"} 
            label= {"to"} 
            handleChange = {(field, d) => {
              setTo(d)
            }}
          />
      <List
        loading={loading}
        label='Failed Wire IN'
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'externalId', label: 'External ID', alignRight: false },
          { id: 'amount', label: 'Amount', alignRight: false, onRender: (_, row) => {
            const num = row.amount.value
            return `${getCurrency(
              row.amount.currencyCode,
            )}${numberWithCommas(num)}`
          }, },
          { id: 'createdAt', label: 'Created At', alignRight: false },
          { id: 'message', label: 'Error message', alignRight: false },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        hideAddButton={true}
        hideSearchFilter={true}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => {
          setSearch(search)
        }}
      />
    </Page>
  )
}
