
import { useState } from 'react';

// material
import Edit from "../../components/crud/upsert"
import { errorAlert, successAlert } from '../../services/alertService'
import { updateAdmin } from '../../services/adminService'

// ----------------------------------------------------------------------

export default function Profile({data}) {

  const [loading, setLoading] = useState(false);

  const fields = {
    id: {
      type: "string",
      fieldType: "text",
      label: "Id",
      readOnly: true
    },
    firstName: {
      type: "string",
      fieldType: "text",
      label: "Firstname",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'Firstname is required'
      }, {
        type: "required",
        message: 'Firstname is required'
      }],
    },
    lastName: {
      type: "string",
      fieldType: "text",
      label: "Last Name",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'Last Name is required'
      }, {
        type: "required",
        message: 'Last Name is required'
      }],
    },
    email: {
      type: "string",
      fieldType: "text",
      label: "Email",
      readOnly: true,
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 200,
        message: 'Email is to big'
      }, {
        type: "required",
        message: 'Email is required'
      }, {
        type: "email",
        message: 'Invalid email'
      }],
    }
  };

  const steps = [
    {
      id: 0,
      label: "Basic Info",
      fields: ["firstName", "lastName", "email"]
    },
  ]

  const updateData = (data) => {

    setLoading(true)
    return updateAdmin({
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email
    })
        .then(() => {
          setLoading(false)
          successAlert("Admin updated with success")
        })
        .catch((e) => {
          setLoading(false)
          errorAlert(e.message)
        })
  }

  return (
        <>
          <Edit
            title="User Profile"
            label="Profile"
            hideGoBackButton={true}
            initialValue={data}
            loading={loading}
            isLoading={(loaded) => {}}
            fields={fields}
            steps={steps}
            onSave={(data) => updateData(data)}
          />
        </>
  );
}
