import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listAccounts
} from '../../services/accountService'
import Page from '../../components/Page'
import { SECTION_BASE_PATHS } from '../../utils/general'
// ----------------------------------------------------------------------

export default function AccountsList({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = (v) => {
    console.log(v)
    const url = `/dashboard/accounts/${v.id}/transactions/list`;
    navigate(url)
  }

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchData = useCallback(() => {
    listAccounts(null, null, page, rowsPerPage)
      .then(({ totalPages, totalElements, content }) => {
        console.log(content)
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Accounts | Overview'}>
      <List
        loading={loading}
        label='Accounts'
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'name', label: 'Name', alignRight: false },
          { id: 'number', label: 'Number', alignRight: false },
          { id: 'normal', label: 'Normal', alignRight: false },
          { id: 'revenueType', label: 'Revenue Type', alignRight: false },
          { id: 'Account type', label: 'Account type', alignRight: false, onRender: (owner)=> {

              if(!owner) {
                return "No owner"
              }
              if(owner.integratorId && owner.beneficiaryId) {
                return "Beneficiary owner"
              } else {
                return "Integrator owner"
              }
          } },
          { id: 'owner', label: 'Owner Integrator Id', alignRight: false, onRender: (owner)=> {

            if(!owner) {
              return ""
            }
            return owner.integratorId
        } },
        { id: 'owner', label: 'Owner Beneficiary Id', alignRight: false, onRender: (owner)=> {

          if(!owner) {
            return ""
          }
          return owner.beneficiaryId
      } },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => {
          setSearch(search)
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
