import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Page from '../components/Page'
import { useSearchParams } from 'react-router-dom'
import { getToken } from '../services/authenticationService'
import { useLocalStorage } from '../hooks/general'

window.onunload = function () {
  debugger
}

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}))

export default function Login() {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const [bannerClosed, setBannerClosedState] = useLocalStorage(
    'dashboardBannerClosed',
    false,
  )

  useEffect(() => {
    if (bannerClosed) {
      setBannerClosedState(false)
    }
  }, [bannerClosed, setBannerClosedState])

  getToken(code).then(data => {
    document.location.href = '/dashboard/app'
  })

  return <RootStyle title='Authentication'></RootStyle>
}
