// import { Icon } from '@iconify/react'
import { useRef, useContext } from 'react'
import PersonIcon from '@mui/icons-material/Person'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { Link as RouterLink } from 'react-router-dom'

// material
import { alpha, styled } from '@mui/material/styles'
import { MenuItem, Avatar, IconButton } from '@mui/material'

// components
import Button from '../../components/Button'
import DropdownMenu, {
  DropdownMenuContext,
} from '../../components/DropdownMenu'
import { logout, getRoles } from '../../services/authenticationService'

// ----------------------------------------------------------------------
const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: PersonIcon,
    linkTo: 'profiles',
  },
]

const ADMIN_MENU_OPTIONS = [
  ...MENU_OPTIONS,
  {
    label: 'Wallet',
    icon: AccountBalanceWalletIcon,
    linkTo: 'wallets',
  },
]

const account = []

// ----------------------------------------------------------------------
const DROPDOWN_STYLE = {
  '.dropdown_menu': {
    left: 'auto',
    right: 0,
    padding: 0,
  },
}
const StyledLogoutButton = styled(Button)(({ theme }) => ({
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
}))

function MenuToggle() {
  const { menuIsOpen } = useContext(DropdownMenuContext)
  const anchorRef = useRef(null)

  return (
    <IconButton
      ref={anchorRef}
      sx={{
        padding: 0,
        width: 44,
        height: 44,
        ...(menuIsOpen && {
          '&:before': {
            zIndex: 1,
            content: "''",
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
            bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
          },
        }),
      }}>
      <Avatar src={account.photoURL} alt='photoURL' />
    </IconButton>
  )
}

function MenuItems() {
  const { closeDropdownMenu } = useContext(DropdownMenuContext)
  const menuOptions =
    getRoles().indexOf('ROLE_ADMIN') > -1
      ? ADMIN_MENU_OPTIONS
      : MENU_OPTIONS

  return menuOptions.map(({ label, linkTo, icon: MenuIcon }) => {
    return (
      <MenuItem
        key={label}
        to={linkTo}
        component={RouterLink}
        onClick={closeDropdownMenu}
        sx={{ typography: 'body2', py: 1, px: 2.5 }}>
        <MenuIcon sx={{ marginRight: 2 }} />

        {label}
      </MenuItem>
    )
  })
}

export default function AccountPopover() {
  // const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const buttonList = []

  // if (isAdmin) {
  //   buttonList.push(
  //     <Button fullWidth key='balance' color="inherit" variant="outlined" onClick={() => {
  //         return navigate("/dashboard/balance")
  //       }}>
  //       Balance
  //     </Button>
  //   )
  // } else {
  //   buttonList.push(
  //     <Button
  //       fullWidth
  //       key='balance'
  //       color='inherit'
  //       variant='outlined'
  //       onClick={() => {
  //         return navigate('/dashboard/balance')
  //       }}>
  //       Balance
  //     </Button>,
  //   )
  // }

  buttonList.push(
    <StyledLogoutButton
      fullWidth
      key='logout'
      color='inherit'
      variant='outlined'
      onClick={() => {
        return logout().then(() => (document.location.href = '/main'))
      }}>
      Logout
    </StyledLogoutButton>,
  )

  return (
    <DropdownMenu
      sx={DROPDOWN_STYLE}
      menuToggle={<MenuToggle />}
      menuFooter={buttonList}>
      <MenuItems />
    </DropdownMenu>
  )
}
