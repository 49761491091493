
import axios from '../config/axiosConfig';

import { createSortUrl } from './urlBuilder'

export const listPendingSwiftTransfer = async (page, limit, sort ={}) => {
    const url = `/v1/batch-operation/operations?type=SWIFT_TRANSFER&status=PENDING&page=${page}&size=${limit}&${createSortUrl(sort)}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const listPendingExchangedFundsAvailable = async (page, limit, sort ={}) => {
    const url = `/v1/batch-operation/operations?type=EXCHANGE_FUNDS_AVAILABLE&status=PENDING&page=${page}&size=${limit}&${createSortUrl(sort)}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getOperationById = async (id) => {
    const response = await axios.get(`/v1/batch-operation/operations/${id}`);
    return response.data;
}

export const markSwiftAsCompleted = async (operation) => {
    const response = await axios.post(`/v1/batch-operation/swift-completed`, operation);
    return response.data;
}

export const markExchangedFundsAvailable = async (operation) => {
    const response = await axios.post(`/v1/batch-operation/exchanged-funds-available`, operation);
    return response.data;
}