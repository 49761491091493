import PropTypes from 'prop-types'

// Material
import { styled } from '@mui/material/styles'

// Components
import { FilterProvider } from './FilterProvider'
import { Filter, DateRangeFilter } from './Filter'

// Constants
const FILTER_TYPE_COMPONENT_MAP = {
  sort: Filter,
  daterange: DateRangeFilter,
  filter: Filter,
}

const StyledFilterGroupWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {},
}))

export default function FilterGroup({ filterSets = [] }) {
  return (
    <FilterProvider filterSets={filterSets}>
      <StyledFilterGroupWrapper className='filter_group'>
        {filterSets.map(filterSetData => {
          const FilterComponent =
            FILTER_TYPE_COMPONENT_MAP[filterSetData.filterSetKey]

          return (
            <FilterComponent
              key={filterSetData.filterSetKey}
              {...filterSetData}
            />
          )
        })}
      </StyledFilterGroupWrapper>
    </FilterProvider>
  )
}

FilterGroup.propTypes = {
  filterSets: PropTypes.arrayOf(PropTypes.shape(Filter.propTypes)),
}
