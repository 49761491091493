// material
import { Stack, Container, TextField as MuiTextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import Stepper from '../Stepper'
import FileUpload from '../FileUpload'
import PickDate from '../PickDate'
import { useState, useEffect } from 'react'
import NumberFormatCustom from '../NumberFormatCustom'
import TextareaAutosize from '@mui/material/TextareaAutosize';


// components
import Page from '../../components/Page'
import Loading from '../../components/Loading'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import Select from '../../components/Select'
import PageHeading from '../../components/ui/PageHeading'
import DynamicList from '../dynamic-list/DynamicList'
import Switch from '../switch/Switch'
import { errorAlert } from '../../services/alertService'
import { getCustomShadows } from '../../theme/shadows'

// ----------------------------------------------------------------------
const Header = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))
const FormWrapper = styled(Container)(({ theme, sx }) => ({
  maxWidth: 600,
  margin: 0,
  ...sx,
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}))
const FooterButtonGroup = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px !important',
}))
const FormFieldWrapper = styled('div')(({ theme }) => {
  return {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'space-between',
    'backgroundColor': theme.palette.primary.light,
    'borderRadius': 8,
    'padding': theme.spacing(1, 1.5),
    'boxShadow': getCustomShadows({ componentName: 'formField' }),
    // Specific for the Autocomplete/Select component
    '& .MuiAutocomplete-root': {
      flexGrow: 1,
      maxWidth: 300,
    },
    '& .MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-root': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: 'none',
    },
    '.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.formFields.borderColor}`,
    },
  }
})
const Label = styled((props) => <label {...props} />)(({ theme }) => {
  return {
    ...theme.typography.body1,
    color: theme.palette.blue.primary,
  }
})
const TextField = styled((props) => <MuiTextField {...props} />)(({ theme }) => {
  return {
    ...theme.typography.body1,
    'flexGrow': 0.4,
    '.MuiInput-root': {
      border: `1px solid ${theme.palette.formFields.borderColor}`,
      borderRadius: 8,
    },
    '.Mui-focused.MuiInput-root': {
      borderColor: theme.palette.primary.main,
    },
    '.MuiInput-root:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '.MuiInput-root:hover:after': {
      border: 'none',
    },
    '.MuiInput-root:after': {
      border: 'none',
    },
    '&:after': {
      border: 'none',
    },
    '& .MuiInput-input': {
      textAlign: 'right',
      height: 'auto',
      padding: theme.spacing(1, 2),
    },
    '& .MuiInput-root:before': {
      border: 'none',
    },
    '&:focus': {
      outline: 'none',
      border: 'none',
    },
    '&:read-only': {
      color: theme.palette.grey[500],
    },
  }
})
const ActiveToggle = styled((props) => <Switch {...props} disableRipple />)(({ theme }) => {
  return {
    'height': 48,
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.success.secondary,
    },
    '& .MuiSwitch-switchBase': {
      top: '50%',
      transform: 'translate(5px, -50%)',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translate(15px, -50%)',
    },
    '& .MuiSwitch-track': {
      borderRadius: 12,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.success.active,
      opacity: 1,
    },
  }
})
const StyledSelect = styled(Select)(({ theme }) => {
  return {
    'flexGrow': 1,
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      height: 'auto',
      padding: 0,
    },
  }
})
const StyledPrimaryButton = styled(LoadingButton)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.primary
  const boxShadow = getCustomShadows({ componentName: 'filterButtons' })

  return {
    ...defaultButton,
    'height': 40,
    boxShadow,
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '&:active': pressed,
  }
})
const StyledNumberField = styled(NumberFormatCustom)(({ theme }) => {
  return {
    'width': 'auto',
    '.MuiInputBase-input': {
      height: 'auto',
      padding: theme.spacing(1),
    },
  }
})

// Form Fields
const TextFormField = ({ index, fieldKey, field, data, handleChange, messages, onRender }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <TextField
      id={fieldKey}
      name={fieldKey}
      key={fieldKey}
      type='text'
      placeholder={field.placeholder || ''}
      variant='standard'
      value={onRender ? (onRender(data[fieldKey])) : data[fieldKey]}
      onChange={(e) => handleChange(fieldKey, e.target.value)}
      disabled={field.readOnly}
      error={messages[fieldKey] != null}
      helperText={messages[fieldKey]}
    />
  </FormFieldWrapper>
)
const SelectFormField = ({ index, fieldKey, field, data, handleChange, messages, search }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <StyledSelect
      id={fieldKey}
      name={fieldKey}
      key={fieldKey}
      disabled={field.readOnly}
      type='text'
      watchField={field.watchField}
      value={data[fieldKey]}
      valueSelected={(v) => {
        handleChange(fieldKey, v)
        field.onSelected && field.onSelected(v, data)
      }}
      onSearch={(s) => search(s, field)}
      options={field.options || []}
      error={messages[fieldKey] != null}
      helperText={messages[fieldKey]}
    />
  </FormFieldWrapper>
)
const SwitchFormField = ({ index, fieldKey, field, data, handleChange }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <div style={{ flexGrow: 0 }}>
      <ActiveToggle
        key={index}
        label={field.label}
        readOnly={field.readOnly}
        data={data[fieldKey]}
        onChange={(data) => handleChange(fieldKey, data)}
      />
    </div>
  </FormFieldWrapper>
)
const UploadFormField = ({ index, fieldKey, field, data, handleChange }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <FileUpload key={index} label={field.label} data={data[fieldKey]} handleChange={(files) => handleChange(fieldKey, files)} />
  </FormFieldWrapper>
)
const PickDateFormField = ({ index, fieldKey, field, data, handleChange }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <PickDate key={fieldKey} data={data[fieldKey]} onChange={(value) => handleChange(fieldKey, value)} />
  </FormFieldWrapper>
)
const ListFormField = ({ index, key, field, data, handleChange }) => (
  <FormFieldWrapper key={`${key}-${index}`} className='form_field'>
    <Label>{field.label}</Label>
    <DynamicList
      id={key}
      name={key}
      key={key}
      label={field.label}
      data={data[key]}
      onAdd={(newItem) => {
        handleChange(key, [...new Set([...data[key], newItem])])
      }}
      onRemove={(idx) => {
        data[key].splice(idx, 1)
        handleChange(key, [...new Set([...data[key]])])
      }}
    />
  </FormFieldWrapper>
)
const NumberFormField = ({ index, fieldKey, field, data, handleChange, messages }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <StyledNumberField
      value={data[fieldKey]}
      onChange={(val) => handleChange(fieldKey, val)}
      name={fieldKey}
      type='text'
      readOnly={field.readOnly}
      id={fieldKey}
      error={messages[fieldKey] != null}
      helperText={messages[fieldKey]}
    />
  </FormFieldWrapper>
)

const TextArea = ({ index, fieldKey, field, data, handleChange, messages }) => (
  <FormFieldWrapper className='form_field'>
    <Label>{field.label}</Label>
    <TextareaAutosize
      value={data[fieldKey]}
      onChange={(val) => handleChange(fieldKey, val.target.value)}
      name={fieldKey}
      readOnly={field.readOnly}
      id={fieldKey}
      error={messages[fieldKey] != null}
      helperText={messages[fieldKey]}
      maxRows={100}
      minRows={5}
      style={{ width: 500 }}
    />
  </FormFieldWrapper>
)

// Constants
const FORM_FIELD_MAP = {
  text: TextFormField,
  select: SelectFormField,
  switch: SwitchFormField,
  upload: UploadFormField,
  pickdate: PickDateFormField,
  list: ListFormField,
  number: NumberFormField,
  text_area: TextArea
}

const MappedFormFieldComponent = ({
  field,
  fieldKey,
  index,
  data,
  handleChange,
  messages,
  search,
  placeholder
}) => {
  const FormFieldComponent = FORM_FIELD_MAP[field.fieldType]

  return (
    <FormFieldComponent
      fieldKey={fieldKey}
      index={index}
      field={field}
      data={data}
      placeholder={placeholder}
      handleChange={handleChange}
      messages={messages}
      search={search}
      onRender={field && field.onRender}
    />
  )
}

const operations = {
  required: (instance, contraint) => instance.required(contraint.message),
  min: (instance, contraint) =>
    instance.min(contraint.min, contraint.message),
  max: (instance, contraint) =>
    instance.max(contraint.max, contraint.message),
  email: (instance, contraint) => instance.email(contraint.message),
}

function SubmitButton({ loading, formSubmitHandler, submitButtonText }) {
  return (
    <StyledPrimaryButton
      key='submit'
      type='submit'
      variant='contained'
      loading={loading}
      onClick={formSubmitHandler}>
      {submitButtonText || 'Save Changes'}
    </StyledPrimaryButton>
  )
}

function StepButton({ onClick, children, ...otherProps }) {
  return (
    <StyledPrimaryButton
      color='primary'
      type='button'
      variant='contained'
      onClick={onClick}
      {...otherProps}>
      {children}
    </StyledPrimaryButton>
  )
}

function UpsertButtonGroup({
  hideSubmitButton,
  steps,
  customRender,
  currentStepIndex,
  changeStepHandler,
  submitStepHandler,
  formSubmitHandler,
  loading,
  submitButtonText,
}) {
  const showNextStepButton = currentStepIndex + 1 < steps.length
  const showPrevStepButton = currentStepIndex > 0
  const currentStep = steps[currentStepIndex]
  const nextStepHandler = () =>
    changeStepHandler(currentStepIndex + 1, true)
  const prevStepHandler = () =>
    changeStepHandler(currentStepIndex - 1, true)
  const onNextStepButtonClick = currentStep.submit
    ? submitStepHandler
    : nextStepHandler
  const buttons = []

  if (showPrevStepButton) {
    buttons.push(
      <StepButton
        key='previous_step'
        sx={{ marginRight: 'auto' }}
        onClick={prevStepHandler}>
        {'Prev Step'}
      </StepButton>,
    )
  }

  if (showNextStepButton) {
    buttons.push(
      <StepButton key='next_step' onClick={onNextStepButtonClick}>
        {currentStep.buttonName || 'Next Step'}
      </StepButton>,
    )
  }

  if (!hideSubmitButton && !showNextStepButton) {
    buttons.push(
      <SubmitButton
        key='submit_button'
        loading={loading}
        submitButtonText={currentStep.buttonName || submitButtonText}
        formSubmitHandler={formSubmitHandler}
      />,
    )
  }

  return buttons
}

export default function GenericUpsert({
  label,
  sequentialStep = false,
  hideSubmitButton,
  hideGoBackButton,
  initialValue,
  loading,
  onSave,
  fields,
  steps,
  customRender,
  backAction,
  sx,
  saveButtonText = 'Save Changes',
  renderCustomFormFields,
}) {
  const [data, setData] = useState(initialValue)
  const [step, setStep] = useState(0)
  const [messages, setMessages] = useState({})

  useEffect(() => {
    setData(initialValue)
  }, [initialValue])

  const handleSubmit = async e => {
    e.preventDefault()

    const request = {
      ...data,
    }

    const validation = await checkValue(Object.keys(fields), request)

    console.log(validation)
    if (!validation.ok) {
      if (steps) {
        errorAlert('There is a problem in the form')
      }

      const messagesUpdate = { ...messages }
      validation.errors.forEach(err => {
        messagesUpdate[err.field] = err.message
      })
      setMessages(messagesUpdate)
    } else {
      setMessages({})
      onSave(request)
    }
  }

  const createSchema = fieldsName => {
    const schemaFields = {}

    fieldsName
      .filter(f => f)
      .forEach(key => {
        const field = fields[key]
        if (field.constraints) {
          schemaFields[key] = Yup[field.type]()

          field.constraints.forEach(constraint => {
            schemaFields[key] = operations[constraint.type](
              schemaFields[key],
              constraint,
            )
          })
        }
      })

    return Yup.object().shape({
      ...schemaFields,
    })
  }

  const checkValue = async (keys, values) => {
    try {
      const schema = createSchema(keys)
      await schema.validateSync(values, { abortEarly: false })
      return { ok: true }
    } catch (e) {
      if (!e.inner) {
        return { ok: true }
      }
      const errors = e.inner.map(i => ({
        field: i.path,
        message: i.message,
      }))
      return { ok: false, errors }
    }
  }

  const handleChange = async (key, value) => {
    setData({
      ...data,
      [key]: value,
    })

    checkIndividualValue(key, value)
  }

  const checkIndividualValue = async (key, value) => {
    const validation = await checkValue([key], { [key]: value })
    if (!validation.ok) {
      const error = validation.errors[0]
      setMessages({
        ...messages,
        [key]: error.message,
      })
    } else {
      setMessages({
        ...messages,
        [key]: null,
      })
    }
  }

  const search = async (search, field) => {
    if (field.onSearch) {
      const response = await field.onSearch(search)
      return response
    } else {
      return field.options
    }
  }

  const changeStep = (toStep, force = false) => {
    if (sequentialStep && !force && toStep > step) {
      return
    }

    if (toStep + 1 > steps.length) {
      return
    }

    setStep(toStep)
  }

  const renderFields = keys => {
    const formFields = keys.map((key, i) => {
      const field = fields[key]

      try {
        return (
          <MappedFormFieldComponent
            key={`${key}-${i}`}
            fieldKey={key}
            index={i}
            field={field}
            data={data}
            handleChange={handleChange}
            messages={messages}
            search={search}
          />
        )
      } catch (e) {
        console.log('field error: ' + field + ', key: ' + key)
        throw e
      }
    })
    return formFields
  }

  const submitStep = async step => {
    const request = {}

    for (let i = 0; i < step.fields.length; i++) {
      request[step.fields[i]] = data[step.fields[i]]
    }

    const validation = await checkValue(step.fields, request)
    if (validation.ok) {
      try {
        await step.submit(request)
      } catch (e) {
        return false
      }

      return true
    } else {
      const messagesUpdate = { ...messages }
      validation.errors.forEach(err => {
        messagesUpdate[err.field] = err.message
      })
      setMessages({
        ...messages,
        ...messagesUpdate,
      })
      return false
    }
  }

  const fieldsKeys = steps ? steps[step].fields : Object.keys(fields)
  const formFields = renderCustomFormFields
    ? renderCustomFormFields({
        formData: data,
        fields,
        fieldsKeys,
        messages,
        onFormChangeHandler: handleChange,
      })
    : renderFields(fieldsKeys)
  const onSubmit = e => handleSubmit(e)

  return (
    <Page title={label ? `${label} | Edit` : 'Edit'}>
      <Loading loading={loading}>
        {label || (!hideGoBackButton && backAction) ? (
          <Header>
            {label ? (
              <PageHeading gutterBottom>{label}</PageHeading>
            ) : null}

            {!hideGoBackButton && <BackButton onClick={backAction} />}
          </Header>
        ) : null}

        <FormWrapper sx={sx} className='form_wrapper container'>
          {steps && (
            <Stepper
              sx={{ marginBottom: '32px' }}
              sequentialStep={sequentialStep}
              steps={steps}
              activeStep={step}
              changeStep={step => changeStep(step)}
            />
          )}

          <form autoComplete='off'>
            <Stack spacing={2}>
              {formFields}

              {(customRender != undefined) ? customRender : ''}
              <FooterButtonGroup>
                
                {!!steps ? (
                  <UpsertButtonGroup
                    steps={steps}
                    currentStepIndex={step}
                    hideSubmitButton={hideSubmitButton}
                    submitButtonText={saveButtonText}
                    loading={loading}
                    changeStepHandler={changeStep}
                    submitStepHandler={() => {
                      submitStep(steps[step]).then(isValid => {
                        if (isValid) {
                          changeStep(step + 1, true)
                        }
                      })
                    }}
                    formSubmitHandler={onSubmit}
                  />
                ) : (
                  <SubmitButton
                    loading={loading}
                    submitButtonText={saveButtonText}
                    formSubmitHandler={onSubmit}
                  />
                )}
              </FooterButtonGroup>
            </Stack>
          </form>
        </FormWrapper>
      </Loading>
    </Page>
  )
}
