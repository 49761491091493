import * as Yup from 'yup'
import { styled } from '@mui/material/styles'
import {
  Autocomplete,
  TextFieldWithLabel,
  SelectMenu,
  Switch,
} from '../components/form'

const StyledSwitch = styled(props => (
  <Switch {...props} disableRipple />
))(({ theme }) => ({
  height: 48,
  transform: `translateX(${theme.spacing(1.5)})`,
  marginBottom: theme.spacing(-1.5),
  marginTop: theme.spacing(-1.5),
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.success.secondary,
  },
  '& .MuiSwitch-switchBase': {
    top: '50%',
    transform: 'translate(5px, -50%)',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translate(15px, -50%)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.success.active,
    opacity: 1,
  },
  '&.is_disabled .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
    {
      opacity: 0.5,
    },
}))
const FORM_COMPONENT_MAP = {
  text: TextFieldWithLabel,
  select: SelectMenu,
  autocomplete: Autocomplete,
  switch: StyledSwitch,
}

export const getFormFieldComponentByFieldType = ({ fieldType }) =>
  FORM_COMPONENT_MAP[fieldType] || null

export const FORM_FIELD_TYPES = {
  email: {
    type: 'string',
    fieldType: 'text',
    label: 'Email',
    constraints: [
      {
        type: 'min',
        min: 2,
        message: 'Too Short!',
      },
      {
        type: 'max',
        max: 200,
        message: 'Email is to big',
      },
      {
        type: 'required',
        message: 'Email is required',
      },
      {
        type: 'email',
        message: 'Invalid email',
      },
    ],
  },
  firstName: {
    type: 'string',
    fieldType: 'text',
    label: 'First Name',
    constraints: [
      {
        type: 'required',
        message: 'First Name is required',
      },
    ],
  },
  lastName: {
    type: 'string',
    fieldType: 'text',
    label: 'Last Name',
    constraints: [
      {
        type: 'required',
        message: 'LastName is required',
      },
    ],
  },
  active: {
    type: 'string',
    fieldType: 'switch',
    label: 'Active',
  },
}

// Form Field Validation
export const OPERATIONS = {
  required: (instance, contraint) =>
    instance.required(contraint.message),
  min: (instance, contraint) =>
    instance.min(contraint.min, contraint.message),
  max: (instance, contraint) =>
    instance.max(contraint.max, contraint.message),
  email: (instance, contraint) => instance.email(contraint.message),
}

export const createSchema = ({ fields, fieldsName }) => {
  const schemaFields = fieldsName
    .filter(f => f)
    .reduce((schema, fieldKey) => {
      const field = fields[fieldKey]

      if (field.constraints) {
        // This assumes valueSchema is an object
        if (field.type === 'array' && field.valueSchema) {
          const ArrayValueSchema = createSchema({
            fields: field.valueSchema,
            fieldsName: Object.keys(field.valueSchema),
          })

          schema[fieldKey] = Yup[field.type]().of(ArrayValueSchema)
        } else {
          schema[fieldKey] = Yup[field.type]()
        }

        field.constraints.forEach(constraint => {
          schema[fieldKey] = OPERATIONS[constraint.type](
            schema[fieldKey],
            constraint,
          )
        })
      }
      return schema
    }, {})

  return Yup.object().shape({
    ...schemaFields,
  })
}

export const checkValue = async (
  fieldValidations,
  formDataValues,
) => {
  try {
    // TODO: Create scheme if form data value is not an array or object
    const schema = createSchema({
      fields: fieldValidations,
      fieldsName: Object.keys(fieldValidations),
    })
    await schema.validateSync(formDataValues, {
      abortEarly: false,
    })
    return { ok: true }
  } catch (e) {
    if (!e.inner) {
      return { ok: true }
    }
    const errors = e.inner.map(i => ({
      field: i.path,
      message: i.message,
    }))
    return { ok: false, errors }
  }
}

// TODO: This could be a hook
export const checkIndividualValue = async ({
  key,
  value,
  callback,
}) => {
  const validation = await checkValue([key], { [key]: value })

  callback({
    key,
    value,
    hasErrors: !validation.ok,
    errors: validation.errors || null,
  })
}

export const search = async (search, field) => {
  if (field.onSearch) {
    const response = await field.onSearch(search)
    return response
  } else {
    return field.options
  }
}
