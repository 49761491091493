import ContentLoader, { Code, List } from 'react-content-loader'


export default function LoadingComponent({loading, children}) {

    if(loading) {
        return <List />
    } 

    return children

}
