import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Page from '../../components/Page'
import Save from '../../components/crud/upsert'
import {
  simulatePayment,
  createPaymentOut,
  createPaymentIn
} from '../../services/paymentService'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import { listPaymentContacts } from '../../services/paymentContactService'
import { getRoles } from '../../services/authenticationService'

import { errorAlert, successAlert } from '../../services/alertService'
import {
  isMongoId,
  removeNumberCommaAndConvertToNumber,
} from '../../services/stringValidation'
import { SECTION_BASE_PATHS } from '../../utils/general'

// ----------------------------------------------------------------------
const PAYMENTS_BASE_PATH = SECTION_BASE_PATHS.payments

export default function CreateFakePayment() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    integratorId: '',
    paymentType: 'PAYMENT_IN',
    simulationIntegratorId: '',
    simulationBeneficiaryId: '',
    simulationType: '',
    simulationAmount: '',
    beneficiaryId: '',
    paymentContactId: '',
    amount: '',
    fee: '',
    description: '',
    paymentId: '',
  })
  const goToListPage = () => navigate(`${PAYMENTS_BASE_PATH}/list`)

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  const simulatePaymentOut = async data => {
    setLoading(true)

    const request = {
      beneficiaryId: data.simulationBeneficiaryId,
      integratorId: data.simulationIntegratorId,
      type: data.simulationType,
      amount: removeNumberCommaAndConvertToNumber(data.simulationAmount),
    }

    try {
      const response = await simulatePayment(request)
      console.log(response)

      setData({
        ...data,
        simulationId: response.id,
        beneficiaryId: response.beneficiaryId,
        integratorId: response.integratorId,
        type: response.type,
        amount: response.amount.value,
        feeAmount: response.feeDetails ? response.feeDetails.value : 0,
      })

      setLoading(false)
      return true
    } catch (e) {
      setLoading(false)
      errorAlert(e.message)
      throw e
    }
  }

  const executePaymentOut = data => {
    setLoading(true)

    const request = {
      simulationId: data.simulationId,
      paymentContactId: data.paymentContactId,
      description: data.description,
    }
    return createPaymentOut(request)
      .then(response => {
        console.log("created", response)
        setData({
          ...data,
          paymentId: response.id,
          paymentContactId: data.simulationPaymentContactId,
        })

        successAlert('Payment created with success')
        navigate(`/dashboard/payments/list`)

        return true
      })
      .catch(e => {
        errorAlert(e.message)
        return false
      })
      .finally(() => setLoading(false))
  }

  const executePaymentIn = data => {
    setLoading(true)

    const request = {
      beneficiaryId: data.beneficiaryId,
      amount: removeNumberCommaAndConvertToNumber(data.amount)
    }

    console.log(request)
    return createPaymentIn(request)
      .then(response => {
        console.log("created", response)
        setData({
          ...data,
          paymentId: response.id
        })

        successAlert('Payment created with success')
        navigate(`/dashboard/payments/list`)

        return true
      })
      .catch(e => {
        errorAlert(e.message)
        return false
      })
      .finally(() => setLoading(false))
  }

  let fields = {
    paymentType: {
      type: 'string',
      fieldType: 'select',
      label: 'Payment Type',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [{ id: 'PAYMENT_IN', name: 'PAYMENT IN' }, { id: 'PAYMENT_OUT', name: 'PAYMENT OUT' }],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          paymentType: type,
        })
      },
    }
  }

  if (data && data.paymentType == "PAYMENT_OUT") {

    fields = {
      ...fields,
      simulationBeneficiaryId: {
        type: 'string',
        fieldType: 'select',
        label: 'Beneficiary',
        constraints: [
          {
            type: 'required',
            message: 'Beneficiary is required',
          },
        ],
        onSelected: simulationBeneficiaryId => {
          setData({
            ...data,
            simulationBeneficiaryId,
          })
        },
        onSearch: async search => {
          let query = search
          if (!isMongoId(search)) {
            query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`
          }
          console.log(query)
          return listBeneficiaries(query, 0, 5, '').then(data => {
            return data.content.map(v => {
              return {
                id: v.id,
                name:
                  (v.person &&
                    v.person.firstName + ' ' + v.person.lastName) ||
                  (v.business && v.business.name),
              }
            })
          })
        },
      },

      simulationType: {
        type: 'string',
        fieldType: 'select',
        label: 'Type',
        constraints: [
          {
            type: 'required',
            message: 'is required',
          },
        ],
        options: [{ id: 'WIRE', name: 'WIRE' }],
        onSelected: (type, allValues) => {
          setData({
            ...allValues,
            simulationType: type,
          })
        },
      },
      simulationAmount: {
        type: 'string',
        fieldType: 'number',
        label: 'Amount',
        constraints: [
          {
            type: 'min',
            min: 2,
            message: 'Too Short!',
          },
          {
            type: 'max',
            max: 50,
            message: 'is required',
          },
          {
            type: 'required',
            message: 'is required',
          },
        ],
      },

      simulationId: {
        type: 'string',
        fieldType: 'text',
        label: 'Simulation Id',
        readOnly: true,
        constraints: [],
      },
      paymentContactId: {
        type: 'string',
        fieldType: 'select',
        label: 'Payment contact id',
        watchField: [data.integratorId, data.simulationBeneficiaryId],
        constraints: [
          {
            type: 'required',
            message: 'Payment Contact is required',
          },
        ],
        onSearch: async search => {
          let query = search
          if (!isMongoId(search)) {
            query = `filterOr=firstName:${search},lastName:${search}&filterEq=beneficiaryId:${data.simulationBeneficiaryId}`
          }
          console.log(query)
          return listPaymentContacts(query, 0, 5, '').then(data => {
            return data.content.map(v => {
              return {
                id: v.id,
                name: v.userInfo.name,
              }
            })
          })
        },
      },
      amount: {
        type: 'string',
        fieldType: 'text',
        label: 'Amount',
        readOnly: true,
        constraints: [
          {
            type: 'required',
            message: 'Amount is required',
          },
        ],
      },
      feeAmount: {
        type: 'string',
        fieldType: 'text',
        label: 'Fee',
        readOnly: true,
        constraints: [
          {
            type: 'required',
            message: 'Fee Amount is required',
          },
        ],
      },
      description: {
        type: 'string',
        fieldType: 'text',
        label: 'Description',
        constraints: [
          {
            type: 'required',
            message: 'Description is required',
          },
        ],
      },

      paymentId: {
        type: 'string',
        fieldType: 'text',
        label: 'Payment id',
        constraints: [],
        readOnly: true,
      },
      transferId: {
        type: 'string',
        fieldType: 'text',
        label: 'Payment Identification',
        constraints: [],
        readOnly: true,
      }
    }

    let firstStepFields = isAdmin
      ? ["paymentType", 'simulationIntegratorId', 'simulationBeneficiaryId', 'simulationType', 'simulationAmount']
      : ["paymentType", 'simulationBeneficiaryId', 'simulationType', 'simulationAmount']

    const steps = [
      {
        label: 'Payment Simulation',
        buttonName: 'Simulate Payment',
        fields: firstStepFields,
        submit: data => simulatePaymentOut(data),
      },
      {
        label: 'Confirm Payment',
        buttonName: 'Confirm Payment',
        fields: [
          'simulationId',
          'amount',
          'feeAmount',
          'paymentContactId',
          'description',
        ],
      }
    ]

    if (isAdmin) {
      fields = {
        simulationIntegratorId: {
          type: 'string',
          fieldType: 'select',
          label: 'Integrator',
          constraints: [
            {
              type: 'required',
              message: 'Integrator is required',
            },
          ],
          watchField: data.simulationIntegratorId,
          onSelected: simulationIntegratorId => {
            setData({
              ...data,
              simulationIntegratorId,
            })
          },
          onSearch: async search => {
            let query = search
            if (!isMongoId(search)) {
              query = `filterOr=name:${search}`
            }
            return listIntegrators(query, 0, 5, '').then(data => {
              return data.content.map(v => {
                return {
                  id: v.id,
                  name: v.name,
                }
              })
            })
          },
        },
        integratorId: {
          type: 'string',
          fieldType: 'select',
          label: 'Integrator',
          readOnly: true,
          constraints: [],
          onSearch: async search => {
            let query = search
            if (!isMongoId(search)) {
              query = `filterOr=name:${search}`
            }
            return listIntegrators(query, 0, 5, '').then(data => {
              return data.content.map(v => {
                return {
                  id: v.id,
                  name: v.name,
                }
              })
            })
          },
        },
        ...fields,
      }
    }

    return (
      <Page className='main_content' title={'Payments | Create'}>
        <Save
          label='Create Fake Payment'
          loading={loading}
          isLoading={loaded => setLoading(loaded)}
          fields={fields}
          initialValue={data}
          sequentialStep={true}
          steps={steps}
          hideGoBackButton={true}
          onSave={data => executePaymentOut(data)}
          goToListPage={goToListPage}
          backAction={isAdmin ? null : goToListPage}
        />
      </Page>
    )
  }

  fields = {
    ...fields,
    beneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      constraints: [
        {
          type: 'required',
          message: 'Beneficiary is required',
        },
      ],
      onSelected: beneficiaryId => {
        setData({
          ...data,
          beneficiaryId,
        })
      },
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`
        }
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    amount: {
      type: 'string',
      fieldType: 'number',
      label: 'Amount',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'is required',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    type: {
      type: 'string',
      fieldType: 'select',
      label: 'Type',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [{ id: 'WIRE', name: 'WIRE' }],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          type: type,
        })
      },
    }
  }

  return (
    <Page className='main_content' title={'Payments | Create'}>
      <Save
        label='Create Fake Payment'
        loading={loading}
        isLoading={loaded => setLoading(loaded)}
        fields={fields}
        initialValue={data}
        onSave={data => executePaymentIn(data)}
        backAction={isAdmin ? null : goToListPage}
      />
    </Page>
  )

}
