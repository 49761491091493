import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Box, Typography, Button, Input,  } from '@mui/material';
import { MenuItem, ListItemIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';

import palette from '../../theme/palette'


export default function DynamicList({label, data, onAdd, onRemove}) {

  const [document, setDocument] = useState('');

  const listItems = (
    data.map((d, i) => {
      return (
        <ListItem disablePadding key={i}>
          <ListItemButton>
            <ListItemText primary={d} />
          </ListItemButton>
          <MenuItem component={RouterLink} to="#" onClick={(e) => {
            e.preventDefault()
            onRemove(i)
          }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Remove" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        </ListItem>
      )
    })
    
  );
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      
      
      <Divider orientation="horizontal" flexItem />
      <Typography variant="h8" gutterBottom>
        {label}
      </Typography>
        <List>
          {listItems}
        </List>
      <Divider />
      <Input
         autoFocus
         fullWidth
         placeholder="Document name..."
         variant="contained"
         value={document}
         onChange={(e) => setDocument(e.target.value)}
      />
      <Button 
        color="primary"
        size="small"
        type="button"
        variant="contained"
        style={{backgroundColor: palette.primary.base}}
        onClick={(e) => {
            e.preventDefault();
            if(document) {
              onAdd(document)
              setDocument("")
            }
        }}>
            Add document
        </Button>
    </Box>
  );
}