import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Save from "../../components/crud/upsert"
import { addCurrency } from '../../services/currencyService'
import { errorAlert, successAlert } from '../../services/alertService'

// ----------------------------------------------------------------------

export default function CurrencySave() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const saveData = (data) => {
    setLoading(true);
    return addCurrency(data)
        .then((currencyCreated) => {
          setLoading(false)
          navigate(`/dashboard/currencies/${currencyCreated.id}/edit`)
          successAlert("User created with success")
        })
        .catch((e) => {
          errorAlert(e.message)
          setLoading(false)
        })
  }

  const fields = {
    name: {
      type: "string",
      fieldType: "text",
      label: "Name",
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'Too Big'
      }, {
        type: "required",
        message: 'Name is required'
      }],
    },
    code: {
      type: "string",
      fieldType: "text",
      label: "Code",
      constraints: [{
        type: "min",
        min: 3,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 8,
        message: 'Too big'
      }, {
        type: "required",
        message: 'Code is required'
      }]
    },
    type: {
      type: "string",
      fieldType: "select",
      label: "Currency Type",
      constraints: [{
        type: "required",
        message: 'Type is required'
      }],
      options: [
        {id: "FIAT", name: "Fiat"},
        {id: "CRYPTO", name: "Crypto"}
      ]
    }
  };

  return (
    <Save
      label="Create Currency"
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      initialValue={{name: "", code: "", type: ""}}
      onSave={(data) => {
        saveData(data)
      }}
      goToListPage={() => navigate("/dashboard/currencies/list")}
    />
  );
}
