import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'; // Import clsx
import {
  Table,
  Card,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Slider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import palette from './../theme/palette';
import typography from '../theme/typography';

// components
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from './SearchNotFound';
import {
  UserListHead,
  UserListToolbar,
} from '../components/_dashboard/user';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

TableMaterial.propTypes = {
  onEdit: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onView: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onTableRowClick: PropTypes.func,
  headers: PropTypes.array,
  filters: PropTypes.shape({
    filterSets: PropTypes.arrayOf(PropTypes.object),
  }),
  riskScoreRange: PropTypes.array,
  handleSliderChange: PropTypes.func,
  data: PropTypes.shape({
    content: PropTypes.array,
  }).isRequired,
  label: PropTypes.string,
  addButtonLabel: PropTypes.string,
};

TableMaterial.defaultProps = {
  onEdit: () => {},
  onTableRowClick: () => {},
};

const NoResultsFoundTableRow = () => (
    <TableRow sx={{ backgroundColor: palette.grey[200] }}>
      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
        <SearchNotFound />
      </TableCell>
    </TableRow>
);

const AnimatedTableRow = styled(TableRow)(({ theme }) => ({
  '.MuiTableCell-root': {
    border: 'none',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.primary.light,
  },
  '.inner_wrapper': {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'all 500ms ease-in-out',
  },
  '.inner_wrapper.is_open': {
    transition: 'all 500ms ease-in-out',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxHeight: 500,
  },
}));

function ExpandableTableRow({
                              tableCells,
                              data,
                              renderExpandableTableRow,
                            }) {
  const [isOpen, setIsOpenState] = useState(false);
  const toggleAccordion = () => setIsOpenState((isOpen) => !isOpen);

  return (
      <>
        <TableRow
            sx={{
              backgroundColor: palette.primary.light,
              borderBottom: `1px solid ${palette.borderColor}`,
              '&:hover': {
                backgroundColor: palette.blue.hover,
                cursor: 'pointer',
                transition: 'background-color 100ms ease-in',
              },
            }}
            onClick={toggleAccordion}
        >
          {tableCells.map((v) => v)}
        </TableRow>
        { isOpen !== null && isOpen !== undefined ? (
        <AnimatedTableRow
            className={clsx('animated_table_row', {
              is_open: isOpen,
            })}
        >
          <TableCell colSpan={tableCells.length} align="left">
            <div
                className={clsx('inner_wrapper', {
                  is_open: isOpen,
                })}
            >
              {renderExpandableTableRow({ data })}
            </div>
          </TableCell>
        </AnimatedTableRow>
          ) :
              null
          }
      </>
  );
}

async function createTableRows({
                                 content,
                                 headers,
                                 onTableRowClick,
                                 useAccordionTableRow,
                                 renderExpandableTableRow,
                               }) {
  return await Promise.all(
      content.map(async (row, index) => {
        const tableCells = await Promise.all(
            headers.map(async (header, keyCol) => {
              let value = row[header.id];
              if (header.onRender) {
                value = await header.onRender(value, row);
              }

              return (
                  <TableCell
                      key={'i:' + index + ',c:' + keyCol}
                      className={`${header.id}_col`}
                      align="left"
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography
                          color={palette.blue.primary}
                          variant="subtitle2"
                          sx={{ fontWeight: typography.fontWeightRegular }}
                          noWrap
                      >
                        {value}
                      </Typography>
                    </Stack>
                  </TableCell>
              );
            })
        );

        if (useAccordionTableRow && renderExpandableTableRow) {
          return (
              <ExpandableTableRow
                  key={row.id}
                  data={row}
                  tableCells={tableCells}
                  renderExpandableTableRow={renderExpandableTableRow}
              />
          );
        }

        const onRowClick = () => onTableRowClick(row);

        return (
            <TableRow
                key={row.id}
                onClick={onRowClick}
                sx={{
                  backgroundColor: palette.primary.light,
                  '&:hover': {
                    backgroundColor: palette.blue.hover,
                    cursor: 'pointer',
                    transition: 'background-color 100ms ease-in',
                  },
                }}
            >
              {tableCells.map((v) => v)}
            </TableRow>
        );
      })
  );
}

export default function TableMaterial({
                                        data,
                                        headers,
                                        onTableRowClick,
                                        goToAddPage,
                                        label,
                                        addButtonLabel,
                                        hideAddButton,
                                        hideSearchFilter,
                                        useAccordionTableRow,
                                        sx,
                                        renderExpandableTableRow,
                                        riskScoreRange,
                                        handleSliderChange,
                                        ...restProps
                                      }) {
  const [search, setSearch] = useState('');
  const [content, setContent] = useState([]);

  const clearSearchField = () => {
    restProps.onSearch('');
    setSearch('');
  };

  useEffect(() => {
    async function getTableRows() {
      const rows = await createTableRows({
        content: data.content,
        headers,
        onTableRowClick,
        useAccordionTableRow,
        renderExpandableTableRow,
      });

      setContent(rows);
    }

    getTableRows();
  }, [
    data.content,
    headers,
    onTableRowClick,
    useAccordionTableRow,
    renderExpandableTableRow,
  ]);

  return (
      <section>
        {!restProps.hideHeader && (
            <UserListToolbar
                filters={restProps.filters}
                riskScoreRange={riskScoreRange}
                handleSliderChange={handleSliderChange}
                numSelected={0}
                filterName={search}
                hideSearchFilter={hideSearchFilter}
                onFilterSelected={(filter) => restProps.onFilterSelected(filter)}
                clearSearchHandler={clearSearchField}
                goToAddPage={goToAddPage}
                hideAddButton={hideAddButton}
                label={label}
                addButtonLabel={addButtonLabel}
                onFilterName={(s) => {
                  setSearch(s.target.value);
                  restProps.onSearch(s.target.value);
                }}
            />
        )}
        <Card>
          <Scrollbar>
            <TableContainer
                sx={{ minWidth: 800, borderRadius: 0 }}
                component={Paper}
            >
              <Table aria-label="sticky table" className="table" sx={sx}>
                <colgroup>
                  {headers.map((header, index) => {
                    return (
                        <col
                            key={index}
                            span="1"
                            className={`${header.id}_col`}
                        />
                    );
                  })}
                </colgroup>
                <UserListHead
                    sx={{
                      ...typography.eyebrow,
                      color: palette.grey.secondary,
                      background: palette.primary.light,
                    }}
                    headLabel={headers}
                    onRequestSort={(e, filterName) =>
                        restProps.onRequestSort(filterName)
                    }
                />
                <TableBody>
                  {isEmpty(content) ? <NoResultsFoundTableRow /> : content}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {!restProps.disablePagination && (
              <TablePagination
                  sx={{
                    background: palette.primary.light,
                    color: palette.grey.secondary,
                  }}
                  rowsPerPageOptions={[1, 10, 15, 25]}
                  component="div"
                  count={data.totalElements}
                  rowsPerPage={restProps.rowsPerPage}
                  page={restProps.currentPage}
                  onPageChange={(e, page) => {
                    e.preventDefault();
                    restProps.onPageChange(page);
                  }}
                  onRowsPerPageChange={(e, size) => {
                    e.preventDefault();
                    restProps.onRowsPerPageChange(e.target.value);
                  }}
              />
          )}
        </Card>
      </section>
  );
}
