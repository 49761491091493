import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Page from '../../components/Page'
import Upsert from '../../components/crud/upsert'
import { addIntegrator } from '../../services/integratorService'
import { errorAlert, successAlert } from '../../services/alertService'
import { listCountries } from '../../services/countryService'
import {
  isMongoId,
  removeNumberCommaAndConvertToNumber,
  toFloat,
} from '../../services/stringValidation'

// ----------------------------------------------------------------------

export default function IntegratorSave() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: '',
    document: '',
    email: '',
    phone1: '',
    phone2: '',
    phone3: '',
    street1: '',
    street2: '',
    city: '',
    zipcode: '',
    state: '',
    country: '',
    startDate: null,
    endDate: null,
    price: '',
    periodicity: '',
    transactionFee: '',
    transactionFeeMode: '',
    files: [],
  })

  const saveData = data => {
    setLoading(true)

    const request = {}
    request.name = data.name
    request.document = data.document
    request.telephones = [data.phone1, data.phone2, data.phone3]
    request.email = data.email
    request.files = data.files
    request.address = {
      streetOne: data.street1,
      streetTwo: data.street2,
      city: data.city,
      zipcode: data.zipcode,
      state: data.state,
      country: data.country,
    }
    request.contract = {
      startDate: data.startDate,
      endDate: data.endDate,
      price: removeNumberCommaAndConvertToNumber(data.price),
      periodicity: data.periodicity,
      transactionFee: removeNumberCommaAndConvertToNumber(
        data.transactionFee,
      ),
      transactionFeeMode: data.transactionFeeMode,
    }

    return addIntegrator(request)
      .then(integratorCreated => {
        navigate(
          `/dashboard/integrators/${integratorCreated.id}/edit?createdMessage=true`,
        )
        successAlert('User created with success')
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
  }

  const fields = {
    name: {
      type: 'string',
      fieldType: 'text',
      label: 'Name',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Name is required',
        },
        {
          type: 'required',
          message: 'Name is required',
        },
      ],
    },
    document: {
      type: 'string',
      fieldType: 'text',
      label: 'Document',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'Document is required',
        },
        {
          type: 'required',
          message: 'Document is required',
        },
      ],
    },
    email: {
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    phone1: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Phone is to big',
        },
        {
          type: 'required',
          message: 'Phone is required',
        },
      ],
    },
    phone2: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 2',
    },
    phone3: {
      type: 'string',
      fieldType: 'text',
      label: 'Phone 3',
    },
    street1: {
      type: 'string',
      fieldType: 'text',
      label: 'Street1',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street is required',
        },
      ],
    },
    street2: {
      type: 'string',
      fieldType: 'text',
      label: 'Street2',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street2 is required',
        },
      ],
    },
    city: {
      type: 'string',
      fieldType: 'text',
      label: 'City',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'Street2 is required',
        },
      ],
    },
    zipcode: {
      type: 'string',
      fieldType: 'text',
      label: 'Zipcode',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    state: {
      type: 'string',
      fieldType: 'text',
      label: 'State',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'is to big',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    country: {
      type: 'string',
      fieldType: 'select',
      label: 'Country',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      value: null,
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listCountries(query, 0, 5, '').then(data => {
          return data.content
        })
      },
    },

    startDate: {
      type: 'date',
      fieldType: 'pickdate',
      label: 'Start Date',
    },
    endDate: {
      type: 'string',
      fieldType: 'pickdate',
      label: 'End Date',
    },
    price: {
      type: 'text',
      fieldType: 'number',
      label: 'Amount to pay',
    },
    periodicity: {
      type: 'string',
      fieldType: 'select',
      label: 'Periodicity',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'DAILY', name: 'DAILY' },
        { id: 'WEEKLY', name: 'WEEKLY' },
        { id: 'MONTHLY', name: 'MONTHLY' },
        { id: 'QUARTERLY', name: 'QUARTERLY' },
        { id: 'YEARLY', name: 'YEARLY' },
      ],
    },
    transactionFee: {
      type: 'text',
      fieldType: 'number',
      label: 'Transaction fee',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },
    transactionFeeMode: {
      type: 'string',
      fieldType: 'select',
      label: 'Transaction Fee Mode',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'PERCENT', name: 'PERCENT' },
        { id: 'AMOUNT', name: 'AMOUNT' },
      ],
    },

    files: {
      type: 'string',
      fieldType: 'upload',
      label: 'Upload integrator documents',
    },
  }

  const steps = [
    {
      label: 'Basic Info',
      fields: [
        'name',
        'document',
        'email',
        'phone1',
        'phone2',
        'phone3',
        'street1',
        'street2',
        'city',
        'zipcode',
        'state',
        'country',
      ],
    },
    {
      label: 'Contract info',
      fields: [
        'startDate',
        'endDate',
        'price',
        'periodicity',
        'transactionFee',
        'transactionFeeMode',
      ],
    },
    {
      label: 'Documents',
      fields: ['files'],
    },
  ]

  return (
    <Page className='main_content' title={'Integrators | Create'}>
      <Upsert
        label='Create Integrator'
        loading={loading}
        isLoading={loaded => setLoading(loaded)}
        fields={fields}
        initialValue={data}
        onSave={data => {
          saveData(data)
        }}
        steps={steps}
        goToListPage={() => navigate('/dashboard/integrators/list')}
      />
    </Page>
  )
}
