import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'

// Material UI
import { styled } from '@mui/material/styles'

// Components
import Page from '../../components/Page'
import Form from '../../components/form/Form'
import FormSubmitButton from '../../components/form/FormSubmitButton'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import PageHeading from '../../components/ui/PageHeading'

// Utils
import { getRoles } from '../../services/authenticationService'
import {
  updateBeneficiary,
  getBeneficiaryById,
} from '../../services/beneficiaryService'
import { listIntegrators } from '../../services/integratorService'
import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
  mapIndividualRequest,
  mapBusinessRequest,
  BENEFICIARY_USER_TYPES,
  BENEFICIARY_DATA,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// ----------------------------------------------------------------------
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Styled Components
const StyledPage = styled(Page)(({ theme, sx }) => ({
  maxWidth: 600,
  margin: 0,
  ...sx,
  '.fieldset': {
    marginBottom: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}))
const Header = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))
const StyledForm = styled(Form)(({ theme }) => ({
  '.grouped_fields_fieldset': {
    marginTop: theme.spacing(3),
  },
  '.form_field': {
    marginBottom: theme.spacing(1.5),
  },
}))
const FormFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

// Local Utils
function getFormFields({
  isAdmin,
  isBusiness,
  beneficiaryData,
  fieldsSchemas,
}) {
  const fields = { ...fieldsSchemas }

  // If we have beneficiary data (meaning we have values to populate for
  // the form fields like on the Edit page) AND the beneficiary type is BUSINESS
  // then check the number of contacts and update formFields schema > contacts
  // to be an array with the appropriate number of contact schemas
  if (beneficiaryData && isBusiness) {
    const { valueSchema: contactSchema } = fields.contacts
    const contactsSchemaList = []

    beneficiaryData.contacts.forEach(contact => {
      contactsSchemaList.push(contactSchema)
    })

    fields.contacts.valueSchema = contactsSchemaList
  }

  if (isAdmin) {
    fields.integratorId = {
      type: 'string',
      fieldType: 'select',
      readOnly: false,
      label: 'Integrator',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      value: null,
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content
        })
      },
    }
  }

  fields.type.readOnly = true

  return fields
}
function getBeneficiaryName({ firstName, name, lastName }) {
  return `${firstName || name || ''} ${lastName || ''}`
}

const fetchData = async id =>
  getBeneficiaryById(id)
    .then(beneficiaryData => beneficiaryData)
    .catch(err => {
      console.log(err)
      errorAlert('Error has occurred while fetching integrator info')
    })

// Main
export default function BeneficiarySave() {
  const beneficiaryFieldsSchemas = cloneDeep(BENEFICIARY_DATA)
  const navigate = useNavigate()
  const { id } = useParams()
  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const [data, setData] = useState(null)
  const onSuccess = () => {
    navigate(
      `${BENEFICIARIES_BASE_PATH}/${id}/edit?createdMessage=true`,
    )
    successAlert('Beneficiary updated successfully!')
  }
  const onError = error => errorAlert(error.message)
  const saveData = data => {
    const request =
      data.type === BENEFICIARY_USER_TYPES.INDIVIDUAL.value
        ? mapIndividualRequest(data)
        : mapBusinessRequest(data)

    return updateBeneficiary(id, request)
  }
  const beneficiaryName = data ? getBeneficiaryName(data) : ''

  // Need to check if the previous data state was null and
  // if the current data state before triggering the fetchData.
  // This will ensure we only fire fetchData once on page load.
  useEffect(() => {
    async function getData() {
      const data = await fetchData(id)
      const formattedData =
        data.type === BENEFICIARY_USER_TYPES.INDIVIDUAL.value
          ? mapFromIndividualRequest(data)
          : mapFromBusinessRequest(data)

      setData(formattedData)
    }

    getData()
  }, [id])

  if (!data) {
    return null
  }

  const fields = getFormFields({
    isAdmin,
    beneficiaryData: data,
    isBusiness: data.type === BENEFICIARY_USER_TYPES.BUSINESS.value,
    fieldsSchemas: beneficiaryFieldsSchemas[data.type],
  })

  return (
    <StyledPage className='main_content' title={'Beneficiary | Edit'}>
      <Header>
        <PageHeading gutterBottom>
          Beneficiary | {beneficiaryName} - Basic Info
        </PageHeading>

        <BackButton onClick={goToViewPage} />
      </Header>

      <StyledForm
        className={clsx({
          is_individual:
            data.type === BENEFICIARY_USER_TYPES.INDIVIDUAL.value,
          is_business:
            data.type === BENEFICIARY_USER_TYPES.BUSINESS.value,
        })}
        initialData={data}
        formFields={fields}
        submitAction={saveData}
        onSuccess={onSuccess}
        onError={onError}>
        <FormFooter>
          <Button buttonType='secondary' onClick={goToViewPage}>
            Cancel
          </Button>

          <FormSubmitButton submitButtonText='Save Changes' />
        </FormFooter>
      </StyledForm>
    </StyledPage>
  )
}
