// Transform form data for saving
export const mapPaymentContactRequest = data => {
  return {
    integratorId: data.integratorId,
    beneficiaryId: data.beneficiaryId,
    paymentType: data.paymentType,
    userInfo: {
      name: data.contactName,
      street1: data.contactAddressLine1,
      postalCode: data.contactPostalCode,
      city: data.contactCity,
      state: data.contactState,
      country: data.contactCountry,
      routingKey: data.contactRoutingKey,
      accountNumber: data.bankAccountNumber,
      accountType: data.contactAccountType,
      type: data.contactType,
    },
    bankInfo: {
      name: data.bankName,
      street1: data.bankAddressLine1,
      street2: data.bankAddressLine2,
      postalCode: data.bankPostalCode,
      city: data.bankCity,
      state: data.bankState,
      country: data.bankCountry,
    }
  }
}

export const mapPaymentRequestToForm = data => {

  return {
    integratorId: data.integratorId,
    beneficiaryId: data.beneficiaryId,
    paymentType: data.paymentType,
    contactName: data.userInfo.name,
    contactType: data.userInfo.type,
    contactCity: data.userInfo.city,
    contactAddressLine1: data.userInfo.street1,
    contactState: data.userInfo.state,
    contactPostalCode: data.userInfo.postalCode,
    bankAccountNumber: data.userInfo.accountNumber,
    contactRoutingKey: data.userInfo.routingKey,
    contactAccountType: data.userInfo.accountType,
    contactCountry: data.userInfo.country,
    bankName: data.bankInfo.name,
    bankAddressLine1: data.bankInfo.street1,
    bankAddressLine2: data.bankInfo.street2,
    bankPostalCode: data.bankInfo.postalCode,
    bankCity: data.bankInfo.city,
    bankState: data.bankInfo.state,
    bankCountry: data.bankInfo.country,
  }
}

const helpers = {
  mapPaymentContactRequest,
  mapPaymentRequestToForm
}

export default helpers

