import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getCustomShadows } from '../theme/shadows'

Button.propTypes = {
  // Taken from theme/palette.js
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'filterAction']),
}

Button.defaultProps = {
  buttonType: 'primary',
}

const ButtonBase = styled(({ buttonType, ...rest }) => (
  <MuiButton {...rest} />
))(({ theme, buttonType }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons[buttonType]
  const boxShadow = getCustomShadows({ componentName: 'filterButtons' })

  return {
    ...defaultButton,
    marginRight: 16,
    height: 40,
    boxShadow,
    padding: theme.spacing(1.5, 2),
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '& .icon path': icon,
    '&:active': pressed,
  }
})

export default function Button({ children, buttonType, ...rest }) {
  return (
    <ButtonBase
      className={clsx('action_button', `${buttonType}_button`)}
      buttonType={buttonType}
      {...rest}>
      {children}
    </ButtonBase>
  )
}
