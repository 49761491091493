import * as React from 'react'
import clsx from 'clsx'

// Material
import Switch from '@mui/material/Switch'
import { Typography } from '@mui/material'

// Main
export default function BasicSwitches({
  readOnly,
  data,
  label,
  onChange,
  className,
  ...rest
}) {
  const labelProps = { inputProps: { 'aria-label': label } }
  return (
    <>
      {label ? (
        <Typography className='switch_label' variant='h8' gutterBottom>
          {label}
        </Typography>
      ) : null}
      <Switch
        {...labelProps}
        className={clsx('switch', { is_disabled: readOnly }, className)}
        disabled={readOnly}
        defaultChecked={!!data}
        onChange={(v, c) => onChange(c)}
        {...rest}
      />
    </>
  )
}
