import {
  useMemo,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert } from '../../services/alertService'
import { getMe } from '../../services/authenticationService'
import { listPendingDeposits } from '../../services/depositService'

// Components
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'
import { ModalContext } from '../../components/providers/ModalProvider'

// Constants
const INTEGRATORS_BASE_PATH = SECTION_BASE_PATHS.integrators

// Main
export default function DepositRefundingList() {
  const navigate = useNavigate()
  const { showModal } = useContext(ModalContext)
  const [appliedFiltersFromQueryParams, setSearchParams] = useQueryParam(
    FILTER_QUERY_PARAM_KEY,
  )
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [userRole, setUserRole] = useState(null)
  const updateSearchFilter = searchVal => {
    setSearch(searchVal)
    setSearchParams(
      {
        'query': searchVal
      },
      { replace: true },
    )
  }

  const goToEditPage = ({ id }) => {
    console.log(`/dashboard/deposit-refunding/${id}/edit`)
    navigate(`/dashboard/deposit-refunding/${id}/edit`)
  }
  
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchFilteredData = useCallback(
    appliedFilters => {

      listPendingDeposits(search, page, 10)
        .then(({ totalPages, totalElements, content }) => {
          setDataWrapper({
            totalPages,
            totalElements,
            content,
          })
          setLoading(false)
        })
        .catch(e => errorAlert(e.message))
        .finally(() => setLoading(false))
    },
    [page, rowsPerPage, sort, search],
  )

  // This is for the initial page load and search
  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams)
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
  ])

  // Get current logged in user's role and see
  // if they have access to change ownership
  useEffect(() => {
    if (!userRole) {
      async function getCurrentUser() {
        const { type } = await getMe()

        setUserRole(type)
      }

      getCurrentUser()
    }
  }, [userRole])

  console.log(dataWrapper)
  return (
    <Page className='main_content' title={'Deposit Refunding | Overview'}>
      <List
        loading={loading}
        label='Deposit Refunding'
        hideAddButton={true}
        onDelete={id => {
          setLoading(true)
        }}
        headers={[
          {
            id: 'id',
            label: 'ID',
            alignRight: false
          },
          {
            id: 'beneficiaryId',
            label: 'Beneficiary',
            alignRight: false
          },
          {
            id: 'integratorId',
            label: 'Integrator',
            alignRight: false
          },
          {
            id: 'correlationId',
            label: 'Correlation ID',
            alignRight: false
          },
          {
            id: 'provider',
            label: 'Provider',
            alignRight: false
          },
          {
            id: 'country',
            label: 'Country',
            alignRight: false
          },
          {
            id: 'identifierType',
            label: 'Type',
            alignRight: false
          },
          {
            id: 'identifier',
            label: 'Identifier',
            alignRight: false
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={updateSearchFilter}
        filters={
          {
            // filterSets: filterSets,
          }
        }
        onTableRowClick={(r) => goToEditPage (r)}
      />
    </Page>
  )
}
