import { useMemo, useEffect, useState, useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// Material
import { styled } from '@mui/material/styles'

// Services
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { listKyx, updateStatus } from '../../services/kyxService'
import { getRoles } from '../../services/authenticationService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import View from '../../components/crud/view'
import Button from 'src/components/Button'

// Constants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Main
const StyledFormField = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}))
const StyledFieldLabel = styled('span')(({ theme }) => ({
  color: theme.palette.blue.primary,
}))
const StyledReadOnlyFieldValue = styled('span')(({ theme }) => ({
  color: theme.palette.grey.secondary,
  textAlign: 'right',
}))
const StyledDivider = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  marginBottom: theme.spacing(4),
}))
const StyledModalFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export default function BeneficiaryDetailPage() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [kyx, setKyx] = useState({})

  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)

  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)

    getBeneficiaryById(id)
      .then(beneficiary => {
        const data =
          beneficiary.type === 'INDIVIDUAL'
            ? mapFromIndividualRequest(beneficiary)
            : mapFromBusinessRequest(beneficiary)
        setData(data)
      })

    listKyx(id)
      .then(resultKyx => {
        setKyx(resultKyx)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert('Error while fetching kyx')
        setLoading(false)
      })
  }, [setLoading, setKyx, id])

  const fields = useMemo(
    () => [
      {
        label: {
          displayValue: 'KYX ID',
        },
        value: {
          displayValue: kyx.id || '',
        },
      },
      {
        label: {
          displayValue: 'Status',
        },
        value: {
          displayValue: kyx.status || '',
        },
      },
      {
        label: {
          displayValue: 'Reason',
        },
        value: {
          displayValue: kyx.reason,
        },
      },
    ],
    [kyx],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isEmpty(data)) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'kyx',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  function shouldShowButton(step) {
    return step.type === 'KYB' && isAdmin && step.active
  }

  function onClickUpdateStatus(kyxId, workflowId, status, reason) {
    updateStatus(kyxId, workflowId, status, reason)
    window.location.reload()
  }

  return (
    <Page className='main_content' title={'Beneficiary | View'} style={{width: "70%"}}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary | ${data.firstName || data.name} ${
            data.lastName || ''
          } - Basic Info`}
          tabsData={tabsData}
        />
        <View fields={fields} />
        <StyledDivider />
        <h3>Steps</h3>
        {(kyx?.steps?.length || 0) > 0 ? kyx.steps.map(step => {
          return (
            <div key={`${step.workflowId}-${step.type}-${step.status}`}>
              <StyledFormField>
                <StyledFieldLabel>Workflow ID</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{`${step.workflowId}`}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Type</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step.type}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Status</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step.status}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Reason</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step.reason}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Upload link</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{step?.jumioWeb?.href || ''}</StyledReadOnlyFieldValue>
              </StyledFormField>
              {shouldShowButton(step) ? (
                <StyledModalFooter>
                  <Button onClick={() => onClickUpdateStatus(kyx.id, step.workflowId, 'PASSED', 'OK')}>Approve</Button>
                  <Button onClick={() => onClickUpdateStatus(kyx.id, step.workflowId, 'REJECTED', 'LOW_QUALITY_DOCUMENT')}>Reject</Button>
                  <StyledDivider />
              </StyledModalFooter>
              ) : (
                <StyledDivider />
              )}
            </div>
          )}) : (<h2>No content found</h2>)}
        <StyledDivider />
        <h3>Errors</h3>
        {(kyx?.errors?.length || 0) > 0 ? kyx.errors.map(error => {
          return (
            <div key={`${error.workflowId}-${error.type}-${error.status}`}>
              <StyledFormField>
                <StyledFieldLabel>Workflow ID</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{`${error.workflowId}`}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Type</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{error.type}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledFormField>
                <StyledFieldLabel>Reason</StyledFieldLabel>
                <StyledReadOnlyFieldValue>{error.reason}</StyledReadOnlyFieldValue>
              </StyledFormField>
              <StyledDivider />
            </div>
        )}) : (<h2>No content found</h2>)}
      </Loading>
    </Page>
  )
}

