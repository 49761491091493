import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import Page from '../../components/Page'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listCurrencies,
  deleteCurrency,
} from '../../services/currencyService'
import { SECTION_BASE_PATHS } from '../../utils/general'
// ----------------------------------------------------------------------
const CURRENCIES_BASE_PATH = SECTION_BASE_PATHS.currencies

export default function CurrencyList({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = ({ id }) =>
    navigate(`${CURRENCIES_BASE_PATH}/${id}/edit`)

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchData = useCallback(() => {
    listCurrencies(`filterEq=name:${search}`, page, rowsPerPage, sort)
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Currencies | Overview'}>
      <List
        loading={loading}
        label='Currencies'
        goToAddPage={() => navigate(`${CURRENCIES_BASE_PATH}/save`)}
        goToEditPage={id => navigate(`${CURRENCIES_BASE_PATH}/${id}/edit`)}
        onDelete={id => {
          setLoading(true)
          deleteCurrency(id)
            .then(() => {
              fetchData()
              successAlert('Currency deleted with success')
            })
            .catch(() =>
              errorAlert('Error has occurred while fetching currencies'),
            )
            .finally(() => setLoading(false))
        }}
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'name', label: 'Name', alignRight: false },
          { id: 'code', label: 'Code', alignRight: false },
          { id: 'type', label: 'Type', alignRight: false },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => {
          setSearch(search)
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
