import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { convertFilterObjectToString } from '../../utils/filters'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listIntegrators,
  deleteIntegrator,
} from '../../services/integratorService'

// Components
import ActiveLabel from '../../components/ActiveLabel'
import List from '../../components/crud/list'
import Page from '../../components/Page'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'

// Constants
const INTEGRATORS_BASE_PATH = SECTION_BASE_PATHS.integrators

// Main
export default function IntegratorList({ theme }) {
  const navigate = useNavigate()
  const [appliedFiltersFromQueryParams, setSearchParams] = useQueryParam(
    FILTER_QUERY_PARAM_KEY,
  )
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const updateSearchFilter = searchVal => {
    setSearch(searchVal)
    setSearchParams(
      {
        'person.firstName': searchVal,
        'person.lastName': searchVal,
        'business.name': searchVal,
      },
      { replace: true },
    )
  }
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToEditPage = ({ id }) =>
    navigate(`${INTEGRATORS_BASE_PATH}/${id}/edit`)
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchFilteredData = useCallback(
    appliedFilters => {
      const filterStr = !isEmpty(appliedFilters)
        ? `filterEq=${convertFilterObjectToString({
            filters: appliedFilters,
          })}&`
        : ''
      const filter = `filterEq=${filterStr}&filterOr=email:${search},name:${search}`

      listIntegrators(filter, page, rowsPerPage, sort)
        .then(({ totalPages, totalElements, content }) => {
          setDataWrapper({
            totalPages,
            totalElements,
            content,
          })
          setLoading(false)
        })
        .catch(e => errorAlert(e.message))
        .finally(() => setLoading(false))
    },
    [page, rowsPerPage, sort, search],
  )

  // This is for the initial page load and search
  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams)
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
  ])

  const tableFilter = useMemo(() => {
    let filterSets = [
      {
        title: 'Filters',
        filterSetKey: 'filter',
        iconType: 'filter',
        toggleButtonLabel: 'Filter',
        filters: [
          {
            label: 'Active',
            key: 'active',
            filterType: 'checkbox',
            options: [
              { key: 'active', value: 'active', displayValue: 'Active' },
              {
                key: 'inactive',
                value: 'inactive',
                displayValue: 'Inactive',
              },
            ],
          },
        ],
      },
    ]

    return filterSets
  }, [])

  return (
    <Page className='main_content' title={'Integrators | Overview'}>
      <List
        loading={loading}
        label='Integrators'
        goToAddPage={() => navigate(`${INTEGRATORS_BASE_PATH}/save`)}
        goToEditPage={id =>
          navigate(`${INTEGRATORS_BASE_PATH}/${id}/edit`)
        }
        onDelete={id => {
          setLoading(true)
          deleteIntegrator(id)
            .then(() => {
              fetchFilteredData()
              successAlert('Integrator deleted with success')
            })
            .catch(() =>
              errorAlert('Error has occurred while fetching integrators'),
            )
            .finally(() => setLoading(false))
        }}
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'name', label: 'Name', alignRight: false },
          { id: 'email', label: 'Email', alignRight: false },
          {
            id: 'active',
            label: 'Active',
            alignRight: false,
            onRender: active => <ActiveLabel isActive={active} />,
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={updateSearchFilter}
        filters={{
          filterSets: tableFilter,
        }}
        onTableRowClick={goToEditPage}
      />
    </Page>
  )
}
