import { useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Utils
import { getCustomShadows } from '../../theme/shadows'

// Material
import { styled } from '@mui/material/styles'

// Components
import Portal from './Portal'
import { ModalContext } from '../providers/ModalProvider'
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg'

// Main
const StyledModalWrapper = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0, 0.3)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: -1,
  opacity: 0,
  transition: 'all 200ms ease-out',
  '&.is_visible': {
    transition: 'all 200ms ease-in',
    zIndex: 9999,
    opacity: 1,
  },
}))
const StyledModalInner = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    transform: 'none',
    top: 0,
    left: 0,
  },
}))
const StyledModal = styled('div')(({ theme }) => {
  return {
    borderRadius: theme.spacing(1),
    boxShadow: getCustomShadows({ componentName: 'modal' }),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    width: '80%',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      height: '100vh',
      overflow: 'auto',
    },
    '.modal_header': {
      marginBottom: theme.spacing(2),
    },
    '.close_icon': {
      width: '24px',
      marginLeft: 'auto',
      display: 'block',
    },
    '.close_icon:hover': {
      cursor: 'pointer',
    },
    '.close_icon:hover path': {
      transition: 'fill 200ms ease-out',
      fill: theme.palette.blue.secondary,
    },
  }
})

Modal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  headerContent: PropTypes.any,
  onClose: PropTypes.func,
}

export default function Modal({
  children,
  className,
  headerContent,
  onClose = () => {},
  ariaProps = {},
}) {
  const backdrop = useRef(null)
  const inner = useRef(null)
  const modalMain = useRef(null)
  const { isVisible, hideModal } = useContext(ModalContext)
  const onBackdropClick = evt => {
    if (evt.target === backdrop.current || evt.target === inner.current) {
      onCloseModal()
    }
  }
  const onCloseModal = () => {
    hideModal()
    onClose()
  }

  useEffect(() => {
    document.body.classList.toggle('no_scroll', isVisible)
  }, [isVisible])

  return (
    <Portal el={document.getElementById('modal-root')}>
      <StyledModalWrapper
        role='dialog'
        aria-modal='true'
        aria-hidden={!isVisible}
        {...ariaProps}
        ref={backdrop}
        onClick={onBackdropClick}
        className={clsx('modal_wrapper', 'backdrop', {
          is_visible: isVisible,
        })}>
        <StyledModalInner ref={inner} className='modal_inner'>
          <StyledModal
            ref={modalMain}
            className={clsx(
              'modal',
              { is_visible: isVisible },
              className,
            )}>
            <header className='modal_header'>
              {headerContent}

              <CloseIcon
                onClick={onCloseModal}
                className='close_icon icon'
              />
            </header>

            {children || null}
          </StyledModal>
        </StyledModalInner>
      </StyledModalWrapper>
    </Portal>
  )
}
