export const TEAM_MEMBER_TYPES = Object.freeze({
  INTEGRATOR_USER: {
    key: 'INTEGRATOR_USER',
    displayValue: 'User',
    userType: 'user',
  },
  INTEGRATOR: {
    key: 'INTEGRATOR',
    displayValue: 'Manager',
    userType: 'manager',
  },
})
