import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// material
import { styled } from '@mui/material/styles'

// Components
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'

// Services
import { getMe } from '../../services/authenticationService'

// ----------------------------------------------------------------------
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: '#F9FAFD',
})

const MainWrapperStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
}))
const MainStyle = styled('div')(({ theme }) => ({
  overflow: 'auto',
  minHeight: '100%',
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 3, 10, 3),
  '.main_content': {
    position: 'relative',
    zIndex: 10,
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    getMe()
  }, [])

  return (
    <>
      <RootStyle>
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <MainWrapperStyle className='main'>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <MainStyle>
            <Outlet className='main_content' />
          </MainStyle>
        </MainWrapperStyle>
      </RootStyle>
    </>
  )
}
