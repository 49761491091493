import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TextField, Switch, FormControlLabel, Button } from '@mui/material';
import { createRiskScoreVersion } from '../../services/riskScoreService';
import { successAlert, errorAlert } from '../../services/alertService'; // Import errorAlert

// Styled Components
const StyledPage = styled('div')(({ theme }) => ({
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
}));

const Header = styled('header')(({ theme }) => ({
    marginBottom: theme.spacing(6),
}));

const FormField = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const FormFooter = styled('footer')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
}));

// Main Component
export default function RiskScoreVersionSave() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        version: '',
        active: false,
        lowRiskThreshold: '',
        mediumRiskThreshold: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (parseFloat(formData.lowRiskThreshold) > parseFloat(formData.mediumRiskThreshold)) {
            errorAlert('Low Risk Threshold cannot be greater than Medium Risk Threshold.');
            return;
        }

        try {
            const createdRiskVersion = await createRiskScoreVersion(formData);
            successAlert('Risk Score Version created successfully');
            navigate(`/dashboard/risk-versioning/list`);
        } catch (error) {
            errorAlert('Failed to create risk score version');
            console.error('Failed to create risk score version', error);
        }
    };

    const handleCancel = () => {
        navigate('/risk-versioning/list');
    };

    return (
        <StyledPage>
            <Header>
                <h1>Create Risk Score Version</h1>
            </Header>
            <form onSubmit={handleSubmit}>
                <FormField>
                    <TextField
                        label="Version Number"
                        name="version"
                        type="number"
                        value={formData.version}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </FormField>

                <FormField>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.active}
                                onChange={handleChange}
                                name="active"
                                color="primary"
                            />
                        }
                        label="Active"
                    />
                </FormField>

                <FormField>
                    <TextField
                        label="Low Risk Threshold"
                        name="lowRiskThreshold"
                        type="number"
                        value={formData.lowRiskThreshold}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </FormField>

                <FormField>
                    <TextField
                        label="Medium Risk Threshold"
                        name="mediumRiskThreshold"
                        type="number"
                        value={formData.mediumRiskThreshold}
                        onChange={handleChange}
                        required
                        fullWidth
                    />
                </FormField>

                <FormFooter>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Create Risk Score Version
                    </Button>
                </FormFooter>
            </form>
        </StyledPage>
    );
}
