import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
} from '../../utils/beneficiaries'

// Services
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { getRoles } from '../../services/authenticationService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import PaymentsList from '../../pages/payments/list'
import {SECTION_BASE_PATHS} from "../../utils/general";

const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

BeneficiaryDetailPage.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
}
export default function BeneficiaryDetailPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  // Button click handlers
  const goToListPage = () => navigate('/dashboard/beneficiaries/list')
  const goToViewPage = () =>
    navigate(`/dashboard/beneficiaries/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`/dashboard/beneficiaries/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`/dashboard/beneficiaries/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`/dashboard/beneficiaries/${id}/documents`)
  const goToBalance = () =>
    navigate(`/dashboard/beneficiaries/${id}/balance`)
  const goToStatements = () =>
    navigate(`/dashboard/beneficiaries/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`/dashboard/beneficiaries/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`/dashboard/beneficiaries/${id}/kyx`)

  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getBeneficiaryById(id)
      .then(beneficiary => {
        const data =
          beneficiary.type === 'INDIVIDUAL'
            ? mapFromIndividualRequest(beneficiary)
            : mapFromBusinessRequest(beneficiary)
        setData(data)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  }, [setData, setLoading, id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (!data) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      { label: 'Payments', value: 'payments', onClick: goToPaymentsPage },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'payments',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  return (
    <Page className='main_content' title={'Beneficiary | Payments'}>
      <Loading loading={loading}>
        <PageHeader
          backButtonAction={goToListPage}
          heading={`Beneficiary | ${data.firstName || data.name} ${
            data.lastName || ''
          } - Basic Info`}
          tabsData={tabsData}
        />

        <PaymentsList
          hideAddButton
          hideHeading
          filters={[`beneficiaryId:${id}`]}
        />
      </Loading>
    </Page>
  )
}
