import { Card as MuiCard } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getCustomShadows } from '../theme/shadows'

const StyledCard = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(3),
  boxShadow: getCustomShadows({ componentName: 'card' }),
}))

export default function Card({ children, ...restProps }) {
  return <StyledCard {...restProps}>{children}</StyledCard>
}
