import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import { getRoles } from '../../services/authenticationService'
import {
  listPaymentContacts,
  deletePaymentContact,
} from '../../services/paymentContactService'
import ActiveLabel from '../../components/ActiveLabel'
import Page from '../../components/Page'
import { getBeneficiaryById } from '../../services/beneficiaryService'

import {
  getIntegratorById,
  getIntegratorByToken,
} from '../../services/integratorService'

import { SECTION_BASE_PATHS } from '../../utils/general'
// ----------------------------------------------------------------------

const PAYMENT_CONTACT_BASE_PATH = SECTION_BASE_PATHS.paymentContacts

export default function PaymentContactList({ theme }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const [filters] = useState({
    active: null,
    integratorId: null,
  })
  const goToViewPage = ({ id }) => {
    const url = `${PAYMENT_CONTACT_BASE_PATH}/${id}/edit`;
    console.log(url)
    navigate(url);
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const getActiveFilter = useCallback(() => {
    if (filters.active == null || filters.active === 'All') {
      return null
    }

    return filters.active === 'Active'
  }, [filters.active])

  const getIntegratorFilter = useCallback(() => {
    if (!filters.integratorId) {
      return null
    }

    return filters.integratorId
  }, [filters.integratorId])

  const fetchData = useCallback(() => {
    const filterActive =
      getActiveFilter() != null ? `active:${getActiveFilter()},` : ''
    const filterIntegrator = getIntegratorFilter()
      ? `integratorId:${getIntegratorFilter()}`
      : ''
    const filterEq =
      `${filterActive}${filterIntegrator}`.length > 0
        ? `filterEq=${filterActive}${filterIntegrator}&`
        : ''
    console.log(filterEq)
    listPaymentContacts(
      `${filterEq}filterOr=contactName:${search}`,
      page,
      rowsPerPage,
      sort,
      filters,
    )
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [
    getActiveFilter,
    getIntegratorFilter,
    search,
    page,
    rowsPerPage,
    sort,
    filters,
  ])

  useEffect(() => {
    fetchData()
  }, [fetchData, search, page, rowsPerPage, sort, filters])

  // TODO: Doesn't show in develop branch so unsure if this works
  // if (isAdmin) {
  //   tableFilter.push({
  //     title: 'Integrator',
  //     name: 'Integrator',
  //     key: 'integratorId',
  //     selected: filters.integratorId,
  //     iconType: 'filter',
  //     onSelected: selected => {
  //       if (!selected) {
  //         setFilters({
  //           ...filters,
  //           integratorId: null,
  //         })
  //         return
  //       }
  //       setFilters({
  //         ...filters,
  //         integratorId: selected,
  //       })
  //     },
  //     onSearch: async s => {
  //       const r = await listIntegrators('filterEq=' + s, 0, 10)
  //       return r.content
  //     },
  //   })
  // }

  return (
    <Page className='main_content' title={'Payment Contacts | Overview'}>
      <List
        loading={loading}
        label='Payment Contacts'
        goToAddPage={() => navigate(`${PAYMENT_CONTACT_BASE_PATH}/save`)}
        goToEditPage={id => () =>
          navigate(`${PAYMENT_CONTACT_BASE_PATH}/${id}/edit`)}
        onDelete={id => {
          setLoading(true)
          deletePaymentContact(id)
            .then(() => {
              fetchData()
              successAlert('Payment Contact deleted with success')
            })
            .catch(() =>
              errorAlert(
                'Error has occurred while fetching payment contacts',
              ),
            )
            .finally(() => setLoading(false))
        }}
        headers={[
          { id: 'id', label: 'Database ID', alignRight: false },
          {
            id: 'integratorId',
            label: 'Integrator',
            alignRight: false,
            onRender: async id => {
              try{
                const integrator = isAdmin
                  ? await getIntegratorById(id)
                  : await getIntegratorByToken()
                return integrator.name
              } catch(e) {
                return "";
              }
            },
          },
          {
            id: 'beneficiaryId',
            label: 'Beneficiary',
            alignRight: false,
            onRender: async id => {
              try {
                const beneficiary = await getBeneficiaryById(id)
                return (
                  (beneficiary.person &&
                    beneficiary.person.firstName +
                      ' ' +
                      beneficiary.person.lastName) ||
                  beneficiary.business.name
                )
              } catch(e) {
                return ""
              }
            },
          },
          { 
            id: 'userInfo', 
            label: 'Contact Name', 
            alignRight: false ,
            onRender: contact => {
              return (contact && contact.name)
            },
          },
          {
            id: 'isDeleted',
            label: 'Payment Contact Status',
            alignRight: false,
            onRender: active => <ActiveLabel isActive={!active} />,
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => setSearch(search)}
        // filters={tableFilter}
        onTableRowClick={goToViewPage}
      />
    </Page>
  )
}
