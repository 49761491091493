import PropTypes from 'prop-types'
// material
import { Paper, Typography } from '@mui/material'

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
}

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper sx={{ backgroundColor: 'transparent' }}>
      <Typography
        gutterBottom
        align='center'
        variant='subtitle1'
        sx={{ fontSize: 18 }}
        mx={{ align: 'center' }}>
        No results found
      </Typography>
    </Paper>
  )
}
