import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { convertFilterObjectToString } from '../../utils/filters'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert } from '../../services/alertService'
import {
  listAmlLocks,
} from '../../services/amlLockService'
import {
  getBeneficiaryById,
} from '../../services/beneficiaryService'
import {
  getIntegratorById,
} from '../../services/integratorService'

// Components
import StatusPill from '../../components/ui/StatusPill'
import ActiveLabel from '../../components/ActiveLabel'
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'
import { Icon } from '@iconify/react'

// Constants
const AML_LOCK_BASE_PATH = SECTION_BASE_PATHS.aml_lock

let AML_LOCK_FILTER_SET = [
  {
    title: 'Filters',
    filterSetKey: 'filter',
    iconType: 'filter',
    toggleButtonLabel: 'Filter',
    acceptsMultipleFilters: true,
    filters: [
      {
        label: 'Active',
        key: 'active',
        filterType: 'checkbox',
        acceptsMultipleValues: true,
        options: [
          { key: 'active', value: 'active', displayValue: 'Active' },
          {
            key: 'inactive',
            value: 'inactive',
            displayValue: 'Inactive',
          },
        ],
      },
    ],
  },
]

// Main
export default function AmlLockList() {
  const navigate = useNavigate()
  const [appliedFiltersFromQueryParams, setSearchParams] =
    useQueryParam(FILTER_QUERY_PARAM_KEY)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const updateSearchFilter = searchVal => {
    setSearch(searchVal)
    setSearchParams(
      {
        'integratorId': searchVal,
        'beneficiaryId': searchVal,
      },
      { replace: true },
    )
  }
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const goToViewPage = ({ id }) =>
    navigate(`${AML_LOCK_BASE_PATH}/${id}/edit`)
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchFilteredData = useCallback(
    appliedFilters => {
      const filterStr = !isEmpty(appliedFilters)
        ? `filterEq=${convertFilterObjectToString({
            filters: appliedFilters,
          })}&`
        : ''
      const filter = `${filterStr}filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`

      listAmlLocks(
        filter,
        page,
        rowsPerPage,
        sort,
        appliedFilters,
      )
        .then(({ totalPages, totalElements, content }) => {
          setDataWrapper({
            totalPages,
            totalElements,
            content,
          })
          setLoading(false)
        })
        .catch(e => errorAlert(e.message))
        .finally(() => setLoading(false))
    },
    [page, rowsPerPage, sort, search],
  )

  // This is for the initial page load and search
  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams)
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
  ])

  const filterSets = useMemo(() => AML_LOCK_FILTER_SET, [])

  return (
    <Page className='main_content' title={'AML Lock | Overview'}>
      <List
        loading={loading}
        label='AML Lock'
        goToAddPage={() =>
          navigate(`${AML_LOCK_BASE_PATH}/save`)
        }
        goToEditPage={(id) => {
          console.log(`${AML_LOCK_BASE_PATH}/${id}/edit`)
          navigate(`${AML_LOCK_BASE_PATH}/${id}/edit`)
        }}
        headers={[
          { id: 'id', label: 'Database ID', alignRight: false },
          {
            id: 'integratorId',
            label: 'Integrator',
            alignRight: false,
            onRender: getIntegratorName,
          },
          {
            id: 'beneficiaryId',
            label: 'Beneficiary ID',
            alignRight: false
          },
          {
            id: 'beneficiaryName',
            label: 'Beneficiary Name',
            alignRight: false,
            onRender: getBeneficiaryName,
          },
          {
            id: 'active',
            label: 'Lock Status',
            alignRight: false,
            onRender: active => <ActiveLabel isActive={active} />,
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={updateSearchFilter}
        filters={{
          filterSets: filterSets,
        }}
        onTableRowClick={goToViewPage}
      />
    </Page>
  )
}

async function getBeneficiaryName(a, obj) {
  const beneficiary = await getBeneficiaryById(obj.beneficiaryId)
  const name = beneficiary.person
    ? `${beneficiary.person.firstName} ${beneficiary.person.lastName}`
    : beneficiary.business.name
  return name
}

async function getIntegratorName(integratorId) {
  const { name } = await getIntegratorById(integratorId)

  return name
}