import { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Slider, Box, Typography } from '@mui/material';

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general';
import { convertFilterObjectToString } from '../../utils/filters';
import { useQueryParam } from '../../hooks/useQueryParam';

// Services
import { errorAlert, successAlert } from '../../services/alertService';
import {
  listBeneficiaries,
  deleteBeneficiary,
  beneficiariesLookup,
} from '../../services/beneficiaryService';

// Components
import Page from '../../components/Page';
import List from '../../components/crud/list';
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter';

// Constants
const USER_TYPE = {
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
};
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries;

const getRiskColor = (riskValue) => {
  switch (riskValue) {
    case 'LOW':
      return 'green';
    case 'MEDIUM':
      return '#c9ac05';
    case 'HIGH':
      return 'red';
    default:
      return 'black';
  }
};

const BENEFICIARIES_FILTER_SET = [
  {
    title: 'Filters',
    filterSetKey: 'filter',
    iconType: 'filter',
    toggleButtonLabel: 'Filter',
    acceptsMultipleFilters: true,
    filters: [
      {
        label: 'Rating',
        key: 'riskRatings',
        filterType: 'checkbox',
        acceptsMultipleValues: true,
        options: [
          {
            key: 'low',
            value: 'LOW',
            displayValue: 'LOW',
          },
          {
            key: 'medium',
            value: 'MEDIUM',
            displayValue: 'MEDIUM',
          },
          {
            key: 'high',
            value: 'HIGH',
            displayValue: 'HIGH',
          },
        ],
      },
    ],
  },
  {
    title: 'Date:',
    filterSetKey: 'daterange',
    iconType: 'date',
    toggleButtonLabel: 'Date',
    acceptsMultipleFilters: true,
    filters: [
      {
        label: 'Start Date',
        key: 'startDate',
        filterType: 'daterange',
        acceptsMultipleValues: false,
        options: [
          {
            key: 'startDate',
            value: null,
            displayValue: 'Start Date',
          },
          { key: 'endDate', value: null, displayValue: 'End Date' },
        ],
      },
      {
        label: 'End Date',
        key: 'endDate',
        filterType: 'daterange',
        acceptsMultipleValues: false,
        options: [
          { key: 'endDate', value: null, displayValue: 'End Date' },
        ],
      },
    ],
  },
];

// Main
// Main Component: BeneficiaryList
export default function BeneficiaryList() {
  const navigate = useNavigate();
  const [appliedFiltersFromQueryParams, setSearchParams] = useQueryParam(FILTER_QUERY_PARAM_KEY);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [riskScoreRange, setRiskScoreRange] = useState([0, 700]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({});
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  });

  const filterSets = useMemo(() => BENEFICIARIES_FILTER_SET, []);

  const goToViewPage = ({ id }) => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`);

  const fetchFilteredData = useCallback(
      (appliedFilters, riskRange) => {
        setLoading(true);
        beneficiariesLookup(page, rowsPerPage, sort, appliedFilters, riskRange)
            .then(({ totalPages, totalElements, content }) => {
              console.log('Page:', page, 'Rows Per Page:', rowsPerPage, 'DataWrapper:', dataWrapper);
              setDataWrapper({
                totalPages,
                totalElements,
                content,
              });
            })
            .catch((e) => {
              errorAlert(e.message);
            })
            .finally(() => setLoading(false));
      },
      [page, rowsPerPage, sort, appliedFiltersFromQueryParams, riskScoreRange]
  );

  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams, riskScoreRange);
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
    riskScoreRange,
  ]);

  const handleSliderChange = (event, newValue) => {
    setRiskScoreRange(newValue);
    const updatedFilters = {
      ...appliedFiltersFromQueryParams,
      riskScore: { $gte: newValue[0], $lte: newValue[1] },
    };
    setSearchParams(updatedFilters, { replace: true });
    fetchFilteredData(updatedFilters, newValue);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage); // Update the page state to the new page
  };

  return (
      <Page className="main_content" title={'Beneficiaries | Overview'}>
        <List
            loading={loading}
            label="Onboarded Beneficiaries"
            goToAddPage={null}
            goToEditPage={(id) => () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/edit`)}
            onDelete={(id) => {
              setLoading(true);
              deleteBeneficiary(id)
                  .then(() => {
                    fetchFilteredData(appliedFiltersFromQueryParams, riskScoreRange);
                    successAlert('Beneficiary deleted successfully');
                  })
                  .catch(() => errorAlert('An error occurred while fetching beneficiaries'))
                  .finally(() => setLoading(false));
            }}
            headers={[
              {
                id: 'type',
                label: 'Name',
                alignRight: false,
                onRender: (value, row) => row.type === 'INDIVIDUAL'
                    ? `${row.person.firstName} ${row.person.lastName}`
                    : row.business.name,
              },
              {
                id: 'risk_score',
                label: 'Risk Score',
                alignRight: false,
                onRender: (value, row) => {
                  const riskScore = row.riskScore?.riskScore;
                  const color = getRiskColor(row.riskScore?.riskRating);
                  return <span style={{ color }}>{riskScore}</span>;
                },
              },
              {
                id: 'risk_score.riskRating',
                label: 'Risk Rating',
                alignRight: false,
                onRender: (value, row) => {
                  const riskRating = row.riskScore?.riskRating || 'N/A';
                  const color = getRiskColor(riskRating);
                  return <span style={{ color }}>{riskRating}</span>;
                },
              },
              {
                id: 'approved_at',
                label: 'Approved At',
                alignRight: false,
                onRender: (value, row) => row.approvedDate,
              },
              {
                id: 'integrator_id',
                label: 'Integrator',
                alignRight: false,
                onRender: (value, row) => row.integratorId,
              },
            ]}
            dataWrapper={dataWrapper}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(perPage) => {
              setRowsPerPage(perPage);
              setPage(0);
            }}
            onPageChange={(newPage) => handlePageChange(newPage)}
            onRequestSort={(selectedSort) => {
              setSort({
                [selectedSort.filter]: selectedSort,
              });
            }}
            onSearch={(searchVal) => {
              setSearch(searchVal);
            }}
            filters={{
              filterSets: filterSets,
            }}
            riskScoreRange={riskScoreRange}
            handleSliderChange={handleSliderChange}
            onTableRowClick={goToViewPage}
        />
      </Page>
  );
}