import * as React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

SelectInput.propTypes = {
  onSearch: PropTypes.func,
  options: PropTypes.array,
  watchField: PropTypes.arrayOf(PropTypes.string),
  formikProps: PropTypes.object,
  valueSelected: PropTypes.func,
  value: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
}
export default function SelectInput({
  onSearch,
  options: fieldOptions,
  watchField,
  formikProps,
  valueSelected,
  ...otherProps
}) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  const fetchData = (search = '') => {
    if (onSearch) {
      onSearch(search).then(data => {
        setOptions(data)
      })
    } else if (fieldOptions) {
      setOptions(fieldOptions)
    }
  }
  React.useEffect(() => {
    fetchData(otherProps.value)
  }, [loading, watchField])

  return (
    <Autocomplete
      defaultValue={otherProps.value}
      open={open}
      autoComplete={false}
      disabled={otherProps.disabled}
      onOpen={e => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id === value || option.id === value.id
      }}
      getOptionLabel={(option, v) => {
        const opts = options.filter(field => {
          const id = option.id || option
          return field.id === id
        })
        const opt = opts.length > 0 ? opts[0] : { name: '' }
        return opt.name
      }}
      onChange={(v, value) => {
        valueSelected(value && value.id)
      }}
      options={options}
      loading={loading}
      renderInput={(params, key) => (
        <TextField
          key={key}
          {...params}
          {...otherProps}
          {...formikProps}
          fullWidth
          onChange={e => {
            fetchData(e.target.value)
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
