import * as React from 'react';
import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Select from '../../components/Select'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { isMongoId } from '../../services/stringValidation'

import { getAccountTView } from '../../services/accountService'


// fix calculate node amount to be based in the beneficiary or integrator
export default function AccountTree() {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [nodesAmount, setNodesAmount] = useState({})
    const [integratorId, setIntegratorId] = useState("");
    const [integrators, setIntegrators] = useState([]);
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [beneficiaries, setBeneficiaries] = useState([]);

    const refresh = () => {
        console.log(integratorId, beneficiaryId)
        getAccountTView(integratorId, beneficiaryId)
            .then(data => {
                console.log("data", data)
                setData(data)
            })
    }

    useEffect(() => {
        refresh();
    }, [integratorId, beneficiaryId]);

    const StyledSelect = styled(Select)(({ theme }) => {
        return {
          'flexGrow': 1,
          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            height: 'auto',
            padding: 0,
          },
        }
    })

    const searchIntegrator = async (search) => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listIntegrators(query, 0, 100, '').then(data => {
          return data.content
        })
    }

    const searchBeneficiary = async (search) => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search},integratorId:${integratorId}`
        }
        return listBeneficiaries(query, 0, 100, '').then(data => {
          return data.content.map(c => {
              return {
                  id: c.id,
                  name: c.type == "BUSINESS" ? c.business.name : (c.person.firstName + " " + c.person.lastName)
              }
          })
        })
    }

    const tView = 
    (<TableContainer component={Paper}>
            <TableHead>
                <TableRow>
                    <TableCell>Transaction item id</TableCell>
                    <TableCell align="right">Number</TableCell>
                    <TableCell align="right">Created At</TableCell>
                    <TableCell align="right">Name</TableCell>
                    <TableCell align="right">Debit</TableCell>
                    <TableCell align="right">Credit</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data && data.items.map(row => {

                    return (
                    <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.number}</TableCell>
                        <TableCell align="right">{row.createdAt}</TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.debit ? row.debit : 0}</TableCell>
                        <TableCell align="right">{row.credit ? row.credit : 0}</TableCell>
                    </TableRow>)
                })}
            </TableBody>
    </TableContainer>)

    return (
        <>
            <label for="my-input">Integrator ID</label>
            <StyledSelect
                id={1}
                name={"integrator"}
                key={"integrator"}
                disabled={false}
                type='text'
                value={integratorId}
                watchField={[]}
                valueSelected={(id) => {
                    setIntegratorId(id)
                }}
                onSearch={(s) => searchIntegrator(s)}
                options={integrators}
            />
            <label for="my-input">Beneficiary ID</label>
            <StyledSelect
                id={1}
                name={"beneficiary"}
                key={"beneficiary"}
                disabled={false}
                type='text'
                value={beneficiaryId}
                watchField={[integratorId]}
                valueSelected={(id) => {
                    setBeneficiaryId(id)
                }}
                onSearch={(s) => searchBeneficiary(s)}
                options={beneficiaries}
            /><br/>

            {tView}
        </>
    );
}

