import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Save from "../../components/crud/upsert"
import { listBeneficiaries } from '../../services/beneficiaryService'

import { addAmlLock } from '../../services/amlLockService'
import { listIntegrators } from '../../services/integratorService'

import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'

// ----------------------------------------------------------------------

export default function AmlLockSave() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [filter, setFiter] = useState({
    integratorId: ""
  });
  const [data, setData] = useState({integratorId: "", beneficiaryId: "", reason: ""})

  const saveData = (data) => {
    setLoading(true);
    return addAmlLock(data)
        .then((amllockCreated) => {
          navigate(`/dashboard/amllock/list`)
          successAlert("AML lock created with success")
        })
        .catch((e) => {
          setLoading(false);
          errorAlert(e.message)
        })
  }

  const fields = {
    integratorId: {
      type: "string",
      fieldType: "select",
      label: "Integrator",
      constraints: [{
        type: "required",
        message: 'Integrator id is required'
      }],
      onSearch: async (search) => {

        let query = search;
        if(!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}`;
        }
        return listIntegrators(query, 0, 5, "")
          .then(data => {
            return data.content
          })
      },
      onSelected: (v) => {
        setFiter({
          integratorId: v
        })
      },
    },
    beneficiaryId: {
      type: "string",
      fieldType: "select",
      label: "Beneficiary",
      watchField: [filter.integratorId],
      constraints: [{
        type: "required",
        message: 'Beneficiary id is required'
      }],
      onSearch: async (search) => {

        const searchParams = "&filterOr=business.name:"+ (search || "") +",person.firstName:"+(search || "");
        const searchUrl = filter.integratorId != search ? "filterEq=integratorId:"+ filter.integratorId : "filterEq=integratorId:"+ filter.integratorId+searchParams;
        const response = await listBeneficiaries(searchUrl)
        return response.content.map(v => {
          return {
            id: v.id,
            name: v.business ? v.business.name : (v.person.firstName + " " + v.person.lastName)
          }
        })
      }
    },
    reason: {
      type: "string",
      fieldType: "text_area",
      label: "Reason",
      placeholder: "Money laundry",
      constraints: [{
        type: "required",
        message: 'Reason is required'
      }]
    },
  };

  return (
    <Save
      label="Create Aml Lock"
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      initialValue={data}
      onSave={(data) => {
        saveData(data)
      }}
      backAction={() => navigate("/dashboard/amllock/list")}
    />
  );
}
