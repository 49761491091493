import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Page from '../../components/Page'
import Save from '../../components/crud/upsert'
import {
  simulateTransfer,
  createTransfer,
  simulateOnRampFunding
} from '../../services/transferService'
import { listCurrencies } from '../../services/currencyService'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import { getRoles } from '../../services/authenticationService'

import { errorAlert, successAlert } from '../../services/alertService'
import {
  isMongoId,
  removeNumberCommaAndConvertToNumber,
} from '../../services/stringValidation'
import { SECTION_BASE_PATHS } from '../../utils/general'
import {getClientIp} from "../../services/clientIpService";

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

// ----------------------------------------------------------------------
const TRANSFERS_BASE_PATH = SECTION_BASE_PATHS.transfers

export default function CreateFakeTransfer() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [, updateState] = useState()
  const [data, setData,] = useState({
    simulationIntegratorId: '',
    simulationBeneficiaryId: '',
    simulatationCurrencyCode: '',
    simulationType: '',
    simulationAmount: '',

    integratorId: '',
    beneficiaryId: '',
    type: '',
    simulationId: '',
    currencyCode: '',
    amount: '',
    feeCurrency: '',
    feeAmount: '',
    exchangeRate: '',

    event: '',
    transferId: '',
  })

  const goToListPage = () => navigate(`${TRANSFERS_BASE_PATH}/list`)

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  const simulate = async data => {
    setLoading(true)
    const request = {
      beneficiaryId: data.simulationBeneficiaryId,
      integratorId: data.simulationIntegratorId,
      amount: {
        value: removeNumberCommaAndConvertToNumber(data.simulationAmount),
        currencyCode: data.simulationCurrencyCode,
      },
      type: data.simulationType
    }

    try {
      const response = await simulateTransfer(data.simulationType, request)

      console.log(response, response)
      setData({
        ...data,
        correlationId: response.id,
        beneficiaryId: response.beneficiaryId,
        integratorId: response.integratorId,
        type: response.type,
        amount: response.to.value,
        feeCurrency: response.transactionDetails.feeDetails.totalFees.currencyCode,
        feeAmount: response.transactionDetails.feeDetails.totalFees.value,
        currencyCode: response.to.currencyCode,
        exchangeRate: response.transactionDetails.conversionDetails.exchangeRate
      }) 

      setLoading(false)
      return true
    } catch (e) {
      console.log(e)
      setLoading(false)
      errorAlert(e.message)
      throw e
    }
  }

  const create = async (data) => {
    setLoading(true)

    const request  = {
      simulationId: data.correlationId,
      beneficiaryIp: await getClientIp()
    };
    console.log(request)
    return createTransfer(data.type, request)
      .then(response => {
        console.log(response)
        if(response.type == "ON_RAMP") {
          simulateOnRampFunding(response.id);
        }

        successAlert('Transfer created with success')
        navigate(`/dashboard/transfers/list`)
        return true
      })
      .catch(e => {
        console.log(e)
        errorAlert(e.message)
        return false
      })
      .finally(() => {
        setLoading(false)
        
        
      })
  }

  let fields = {
    simulationBeneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      constraints: [
        {
          type: 'required',
          message: 'Beneficiary is required',
        },
      ],
      onSearch: async search => {
        let query = search
        if (!isMongoId(search) && search) {
          query = `filterOr=person.firstName:${search},business.name:${search}`
          if (isAdmin) {
            query = `${query}&filterEq=integratorId:${data.simulationIntegratorId}`
          }
        }

        console.log(query)
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    simulationIntegratorId: {
      type: 'string',
      fieldType: 'select',
      label: 'Integrator',
      constraints: [
        {
          type: 'required',
          message: 'Integrator is required',
        },
      ],
      watchField: data.simulationIntegratorId,
      onSelected: simulationIntegratorId => {
        setData({
          ...data,
          simulationIntegratorId,
        })
      },
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name: v.name,
            }
          })
        })
      },
    },
    simulationType: {
      type: 'string',
      fieldType: 'select',
      label: 'Transfer Type',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [
        { id: 'ON_RAMP', name: 'On Ramp' },
        { id: 'OFF_RAMP', name: 'Off Ramp' },
      ],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          simulationType: type,
        })
      },
    },
    simulationCurrencyCode: {
      type: 'string',
      fieldType: 'select',
      label: 'Currency',
      constraints: [
        {
          type: 'required',
          message: 'Currency is required',
        },
      ],
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}&filterEq=type:FIAT`
        }
        return listCurrencies(query, 0, 5, '').then(data => {
          return data.content.map(c => {
            return {
              name: c.name,
              id: c.code
            }
          })
        })
      },
    },
    simulationAmount: {
      type: 'string',
      fieldType: 'number',
      label: 'Amount',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 50,
          message: 'is required',
        },
        {
          type: 'required',
          message: 'is required',
        },
      ],
    },

    beneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}&filterEq=integratorId:${data.simulationIntegratorId}`
        }
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    integratorId: {
      type: 'string',
      fieldType: 'select',
      label: 'Integrator',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name: v.name,
            }
          })
        })
      },
    },
    type: {
      type: 'string',
      fieldType: 'text',
      label: 'Transfer Type',
      readOnly: true,
      constraints: [],
    },
    currencyCode: {
      type: 'text',
      fieldType: 'text',
      label: 'Currency',
      readOnly: true,
      constraints: [],
    },
    correlationId: {
      type: 'text',
      fieldType: 'text',
      label: 'Correlation Identification',
      readOnly: true,
      constraints: [],
    },
    amount: {
      type: 'string',
      fieldType: 'number',
      label: 'Amount',
      readOnly: true,
      constraints: [],
    },
    feeCurrency: {
      type: 'text',
      fieldType: 'text',
      label: 'Fee Currency',
      readOnly: true,
      constraints: [],
    },
    feeAmount: {
      type: 'string',
      fieldType: 'number',
      label: 'Fee amount',
      readOnly: true,
      constraints: [],
    },
    exchangeRate: {
      type: 'string',
      fieldType: 'number',
      label: 'Exchange rate',
      readOnly: true,
      constraints: [],
    },

    event: {
      type: 'string',
      fieldType: 'text',
      label: 'Event',
      constraints: [
        {
          type: 'required',
          message: 'Event type is required',
        },
      ],
      readOnly: true,
    },
    transferId: {
      type: 'string',
      fieldType: 'text',
      label: 'Transfer Identification',
      constraints: [],
      readOnly: true,
    },
  }

  let firstStepFields = isAdmin
    ? [
        'simulationIntegratorId',
        'simulationBeneficiaryId',
        'simulationType',
        'simulationCurrencyCode',
        'simulationAmount',
      ]
    : [
        'simulationBeneficiaryId',
        'simulationType',
        'simulationCurrencyCode',
        'simulationAmount',
      ]

  if (data.simulationType === 'OFF_RAMP') {
    firstStepFields.splice(
      firstStepFields.indexOf('simulationCurrencyCode'),
      1,
    )
  }

  const steps = [
    {
      label: 'Transfer Simulation',
      buttonName: 'Simulate Transfer',
      fields: firstStepFields,
      submit: data => simulate(data),
    },
    {
      label: 'Confirm Transfer',
      buttonName: 'Confirm Transfer',
      fields: isAdmin
        ? [
            'integratorId',
            'beneficiaryId',
            'type',
            'correlationId',
            'currencyCode',
            'amount',
            'feeCurrency',
            'feeAmount',
            'exchangeRate'
          ]
        : [
            'beneficiaryId',
            'type',
            'correlationId',
            'currencyCode',
            'amount',
            'feeCurrency',
            'feeAmount',
            'exchangeRate'
          ],
    }
  ]

  if (isAdmin) {
    fields = {
      integratorId: {
        type: 'string',
        fieldType: 'select',
        label: 'Integrator',
        constraints: [
          {
            type: 'required',
            message: 'Integrator is required',
          },
        ],
        onSearch: async search => {
          let query = search
          if (!isMongoId(search)) {
            query = `filterEq=name:${search}`
          }
          return listIntegrators(query, 0, 5, '').then(data => {
            return data.content
          })
        },
      },
      ...fields,
    }
  }

  return (
    <Page className='main_content' title={'Transfers | Create'}>
      <Save
        label='Create Fake Transfer'
        loading={loading}
        isLoading={loaded => setLoading(loaded)}
        fields={fields}
        initialValue={data}
        sequentialStep={true}
        steps={steps}
        backAction={isAdmin ? null : goToListPage}
        onSave={data => create(data)}
      />
    </Page>
  )
}
