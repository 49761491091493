import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'

// Material
import { styled } from '@mui/material/styles'
import { Toolbar, IconButton, Box, Stack } from '@mui/material'

// Components
import { MHidden } from '../../components/@material-extend'
import Logo from '../../components/Logo'
import AccountPopover from './AccountPopover'
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled('nav')(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: theme.palette.primary.main,
  zIndex: 10,
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    backgroundColor: 'transparent',
    backdropFilter: 'none',
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

const StyledLogoMobile = styled(Logo)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width='lgUp'>
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} color='white' />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }}>
          <StyledLogoMobile />
        </Box>

        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}
