import { styled } from '@mui/material/styles'
import Page from '../components/Page'
import { redirectToLogin } from '../services/authenticationService'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}))

export default function Login() {
  redirectToLogin()
  return <RootStyle title='Authentication'>{null}</RootStyle>
}
