import axios from '../config/axiosConfig'
import { getRoles } from './authenticationService'

import { createSortUrl, createFilters } from './urlBuilder'

export const listAmlLocks = async (
  search,
  page,
  limit,
  sort = {},
  filters = {},
) => {
  let url = `?${search}&page=${page}&size=${limit}${createSortUrl(
    sort,
  )}${createFilters(filters)}`
  url = `/v1/admin/aml-lock${url}`

  const response = await axios.get(url)

  response.data.content = response.data.content.map(content => {
    return content
  })
  return response.data
}

export const getAmlLockById = async id => {
  let url =`/v1/admin/aml-lock/${id}`
  const response = await axios.get(url)
  return response.data
}

export const addAmlLock = async amlLock => {
  let url = `/v1/admin/aml-lock`
  const response = await axios.post(url, amlLock)
  return response.data
}

export const deleteAmlLock = async id => {
  let url = `/v1/admin/aml-lock/${id}` 
  const response = await axios.delete(url)
  return response.data
}

export const checkAmlLock = async (integratorId, beneficiaryId) => {

  try {
    let url = `/v1/admin/aml-lock/exists?integratorId=${integratorId}&beneficiaryId=${beneficiaryId}` 
    const response = await axios.get(url)
    return response.data
  } catch(e) {
    return null;
  }
  
}