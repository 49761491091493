import { styled } from '@mui/material/styles'

export const StatusPill = styled('div')(({ theme }) => ({
  borderRadius: '24px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.75, 1.25),
  color: theme.palette.primary.light,
  backgroundColor: 'black',
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightMedium,
  '.icon': {
    marginRight: theme.spacing(1),
    width: '20px',
    height: '20px',
  },
  '&.document_under_review': {
    backgroundColor: theme.palette.warning.active,
  },
  '&.document_under_review .icon': {
    width: '18px',
    height: '18px',
  },
  '&.document_verified': {
    backgroundColor: theme.palette.success.active,
  },
  '&.document_rejected': {
    backgroundColor: theme.palette.error.inactive,
  },
  '&.document_missing': {
    backgroundColor: theme.palette.error.inactive,
  },
}))

export default StatusPill
