import axios from '../config/axiosConfig';

export const downloadFile = async (id) => {
    return axios({
        url: '/v1/uploads/' + id,
        method: 'GET',
        responseType: 'blob'
    }).then(f => f.data)
}

export const uploadFile = async (file) => {

    var formData = new FormData();
    formData.append("file", file);
    return axios.post('/v1/uploads', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(f => f.data)
}

export const deleteFile = async (fileId) => {
    return (await axios.delete('/v1/uploads/' + fileId)).data
}