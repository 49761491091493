import React, { createContext, useState } from 'react'
import { isNull } from 'lodash'

// Material
import ThemeConfig from '../../theme'

// Constants
const DEFAULT_MODAL_STATE = {
  data: null,
  modal: null,
}

// Main
export const ModalContext = createContext()

export function ModalProvider({ children }) {
  const [modalState, setModalState] = useState(DEFAULT_MODAL_STATE)
  const showModal = ({ data, modalComponent }) => {
    setModalState({
      data,
      modal: modalComponent,
    })
  }
  const hideModal = () => setModalState(DEFAULT_MODAL_STATE)
  const { data, modal: CurrentModal } = modalState
  const isVisible = !isNull(CurrentModal)

  return (
    <ModalContext.Provider
      value={{
        isVisible,
        showModal,
        hideModal,
        ...modalState,
      }}>
      {children}
      <ThemeConfig>
        {CurrentModal ? <CurrentModal data={data} /> : null}
      </ThemeConfig>
    </ModalContext.Provider>
  )
}
