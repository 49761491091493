import getSymbolFromCurrency from 'currency-symbol-map'
import { ReactComponent as BrazilIcon } from '../images/brazil.svg'
import { ReactComponent as UnitedStatesIcon } from '../images/united-states.svg'

// Constants
export const SECTION_BASE_PATHS = {
  integrators: '/dashboard/integrators',
  beneficiaries: '/dashboard/beneficiaries',
  administrators: '/dashboard/admins',
  transfers: '/dashboard/transfers',
  payments: '/dashboard/payments',
  countries: '/dashboard/countries',
  paymentContacts: '/dashboard/payment-contacts',
  currencies: '/dashboard/currencies',
  team: '/dashboard/team',
  aml_lock: '/dashboard/amllock',
  batch_operation: '/dashboard/batch-operation',
  pending_swift_transfers: '/dashboard/pending-swift-transfers',
  exchanged_funds_available: '/dashboard/exchanged-funds-available',
  riskScoreVersions: '/dashboard/risk-versioning'
}

export const COUNTRY_CURRENCY_ICON_MAP = {
  USDC: (
    <UnitedStatesIcon className='usa_icon currency_country_icon icon' />
  ),
  BRL: <BrazilIcon className='brazil_icon currency_country_icon icon' />,
}

// Util Methods
export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getCurrency = currency => {
  if (currency === 'USDC') {
    return '$'
  }
  return getSymbolFromCurrency(currency)
}

export function formatPriceAmount(amount) {
  return !isNaN(amount) ? numberWithCommas(amount.toFixed(2)) : amount
}

export function getDollarAmountWithCurrency({ currencyCode, amount }) {
  return `${getCurrency(currencyCode)}${formatPriceAmount(parseFloat(amount))}`
}
