import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import palette from '../../../theme/palette';

// material
import { styled } from '@mui/material/styles';
import {
    Toolbar,
    OutlinedInput,
    IconButton,
    InputAdornment,
    Slider, Box, Typography,
} from '@mui/material';
import Button from '../../Button';
import { FilterGroup } from '../../ui/Filter/index';

// ----------------------------------------------------------------------
const RootStyle = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(3),
  color: palette.grey.secondary,
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: 0,
    minHeight: 'unset',
    flexFlow: 'wrap',
  },
  '.search_filter': {
    order: 2,
    width: 'auto',
    paddingRight: 0,

    [theme.breakpoints.up('sm')]: {
      order: 1,
    },
  },
  '.filter_group': {
    order: 3,
    width: '100%',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      order: 2,
      width: 'auto',
      marginTop: 0,
    },
  },
  '.create_button': {
    order: 1,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      order: 2,
      marginBottom: 0,
      marginLeft: 'auto',
    },

    [theme.breakpoints.up('md')]: {
      order: 3,
    },
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 276,
  height: 40,
  backgroundColor: theme.palette.primary.light,
  marginRight: theme.spacing(2),
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.07)', // Note: Didn't add to shadows list as not sure if this will be reused or standardized.
  ...theme.typography.body2,
  '.MuiInputBase-input': {
    height: '100%',
    padding: theme.spacing(0, 3),
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[300]} !important`,
  },
}));

// ----------------------------------------------------------------------
UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  label: PropTypes.string,
  addButtonLabel: PropTypes.string,
  onFilterName: PropTypes.func,
  clearSearchHandler: PropTypes.func,
  hideSearchFilter: PropTypes.bool,
  filters: PropTypes.shape({
    filterSets: PropTypes.arrayOf(PropTypes.object),
  }),
  riskScoreRange: PropTypes.array,
  handleSliderChange: PropTypes.func,
};

export default function UserListToolbar({
                                          filters,
                                          riskScoreRange,
                                          handleSliderChange,
                                          numSelected,
                                          filterName,
                                          onFilterName,
                                          sx,
                                          clearSearchHandler,
                                          goToAddPage,
                                          label,
                                          addButtonLabel,
                                          hideAddButton,
                                          hideSearchFilter,
                                        }) {
  return (
      <RootStyle
          sx={{
            ...(numSelected > 0 && {
              color: 'primary.main',
            }),
          }}
      >
        {!hideSearchFilter ? (
            <SearchStyle
                sx={sx}
                onChange={onFilterName}
                placeholder="Search"
                value={filterName}
                className="search_filter"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearchHandler}>
                      <Icon
                          color={palette.grey.secondary}
                          icon="clarity:window-close-line"
                          width={20}
                          height={20}
                      />
                    </IconButton>
                  </InputAdornment>
                }
            />
        ) : null}

        {filters ? <FilterGroup {...filters} /> : null}

        {goToAddPage && !hideAddButton ? (
            <Button
                variant="contained"
                sx={{ marginRight: 0 }}
                className="create_button"
                onClick={(e) => {
                  e.preventDefault();
                  goToAddPage();
                }}
            >
              {addButtonLabel || `Create ${label}`}
            </Button>
        ) : (
            <Box
                variant="contained"
                sx={{ marginRight: 0, width: 180, paddingBottom: 0 }}
                className="create_button"
            >
                <Typography id="range-slider" gutterBottom sx={{ marginLeft: 0 }}>
                    Risk Score Range
                </Typography>
                <Slider
                    value={riskScoreRange}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    min={0}
                    max={700}
                    aria-labelledby="range-slider"
                />
            </Box>
        )}
      </RootStyle>
  );
}
