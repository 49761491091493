import axios from '../config/axiosConfig'

const BASE_URL = `/v1/users/integrators`
const USERS_BASE_URL = `/v1/users`

export const listTeam = async (search, page, limit, sort) => {
  const response = await axios.get(BASE_URL)

  response.data.content = response.data.content.map(content => {
    return content
  })
  return response.data
}

export const addTeamMember = async ({
  email,
  firstName,
  lastName,
  type,
  integratorId,
}) => {
  const response = await axios.post(`${BASE_URL}/${integratorId}`, {
    email,
    firstName,
    lastName,
    type,
  })

  return response.data
}

export const updateTeamMember = async teamMemberData => {
  const response = await axios.put(
    `${USERS_BASE_URL}/${teamMemberData.id}`,
    teamMemberData,
  )
  return response.data
}

export const userCheck = async ({ password, email }) => {
  const response = await axios.post(`${USERS_BASE_URL}/check`, {
    password,
    email,
  })

  return response ? response.data : response
}
