import * as React from 'react';
import { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Label from '@mui/icons-material/Label';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Select from '../../components/Select'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'

import { isMongoId } from '../../services/stringValidation'

import { getAccountsTree, calculateNodeBalance } from '../../services/accountService'


// fix calculate node amount to be based in the beneficiary or integrator
export default function AccountTree() {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [nodesAmount, setNodesAmount] = useState({})
    const [integratorId, setIntegratorId] = useState("");
    const [integrators, setIntegrators] = useState([]);
    const [beneficiaryId, setBeneficiaryId] = useState("");
    const [beneficiaries, setBeneficiaries] = useState([]);

    const refresh = (id) => {
        getAccountsTree(id, integratorId, beneficiaryId)
            .then(data => {
                setData(data)
            })
    }

    useEffect(() => {
        refresh();
    }, []);

    const buildTree = (node) => {
        if(!node)
            return <></>
    
        const formatName = (label) => {
            return label + "-" + number;
        }
    
        const id = node.value ? node.value.id : null;
        const number = node.value ? node.value.number : "";
        const label = formatName(node.value ? node.value.name : "Root", number);
    
        const calculateNodeAmount = (nodeId) => {

            console.log(integratorId, beneficiaryId)
            calculateNodeBalance(nodeId, integratorId, beneficiaryId)
                .then((data) => {
                    const  balances  = data.balances;
                    setNodesAmount({
                        ...nodesAmount,
                        [nodeId]: balances
                    })
                })
        }

        const formatAmount = (nodeId) => {

            if(!nodesAmount[nodeId]) {
                return;
            }

            return (
                    <Stack direction="row" spacing={1}>
                        {
                            nodesAmount[nodeId].map(amount => {
                                const label = `${amount.provider} - ${amount.currency}:${amount.balance}`;
                                return <Chip label={label} color="primary" />
                            })
                        }
                    </Stack>
            )
        }
        
        return (
            <>
                <TreeItem nodeId={id} label={label} labelIcon={Label} onClick={() => refresh(id)}>
                    <Button onClick={() => {calculateNodeAmount(id)}}>
                        {"Calculate"}
                    </Button>
                    {formatAmount(id)}
                    {node && node.leaves && node.leaves.map(leave => {
                        return buildTree(leave, refresh)
                    })}
                    
                </TreeItem>
               
            </>
        );
    }

    const tree = buildTree(data, refresh)

    const handleExpandClick = () => {
        refresh();
    }

    const StyledSelect = styled(Select)(({ theme }) => {
        return {
          'flexGrow': 1,
          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
            height: 'auto',
            padding: 0,
          },
        }
    })

    const searchIntegrator = async (search) => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listIntegrators(query, 0, 100, '').then(data => {
          return data.content
        })
    }

    const searchBeneficiary = async (search) => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search},integratorId:${integratorId}`
        }
        return listBeneficiaries(query, 0, 100, '').then(data => {
          return data.content.map(c => {
              console.log(c)
              return {
                  id: c.id,
                  name: c.type == "BUSINESS" ? c.business.name : (c.person.firstName + " " + c.person.lastName)
              }
          })
        })
    }

    return (
        <>
            <label for="my-input">Integrator ID</label>
            <StyledSelect
                id={1}
                name={"integrator"}
                key={"integrator"}
                disabled={false}
                type='text'
                value={integratorId}
                watchField={[]}
                valueSelected={(id) => {
                    setIntegratorId(id)
                }}
                onSearch={(s) => searchIntegrator(s)}
                options={integrators}
            />
            <label for="my-input">Beneficiary ID</label>
            <StyledSelect
                id={1}
                name={"beneficiary"}
                key={"beneficiary"}
                disabled={false}
                type='text'
                value={beneficiaryId}
                watchField={[integratorId]}
                valueSelected={(id) => {
                    setBeneficiaryId(id)
                }}
                onSearch={(s) => searchBeneficiary(s)}
                options={beneficiaries}
            /><br/>

            <label for="my-input">Account Tree</label>
            <Box sx={{ mb: 1 }}>
                <Button onClick={handleExpandClick}>
                {"Reset tree"}
                </Button>
            </Box>
            
            <TreeView
            aria-label="gmail"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 100 }} />}
            sx={{ flexGrow: 1, overflowY: 'auto'}}
            multiSelect
            >
                {tree}
            </TreeView>
        </>
    );
}

