import {
  // useCallback,
  useContext,
  useState,
  useEffect,
} from 'react'

// Services
import { errorAlert } from '../../services/alertService'
import { updateTeamMember } from '../../services/teamService'
import { getIntegratorById } from '../../services/integratorService'

// Utils
import { getCustomShadows } from '../../theme/shadows'

// Material
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// Components
import Button from '../../components/Button'
import Switch from '../switch/Switch'
import Modal from '../ui/Modal'
import Loading from '../../components/Loading'
import SelectMenu from '../../components/form/SelectMenu'
// import TextFieldWithLabel from '../../components/form/TextFieldWithLabel'
import { FormContext, FormProvider } from '../providers/FormProvider'
import { ModalContext } from '../providers/ModalProvider'
import { Icon } from '@iconify/react'

// Constants
import { TEAM_MEMBER_TYPES } from '../../utils/constants'
const { INTEGRATOR, INTEGRATOR_USER } = TEAM_MEMBER_TYPES
const EDIT_TEAM_MEMBER_SCREEN_KEYS = Object.freeze({
  readOnly: 'readOnly',
  editMode: 'editMode',
  confirmChange: 'confirmChange',
  success: 'success',
})
const ROLE_OPTIONS_LIST = [
  {
    value: INTEGRATOR_USER.key,
    displayValue: INTEGRATOR_USER.displayValue,
  },
  {
    value: INTEGRATOR.key,
    displayValue: INTEGRATOR.displayValue,
  },
]
const FORM_FIELDS = {
  email: {
    key: 'email',
    type: 'string',
    fieldType: 'text',
    label: 'Email',
    readOnly: true,
  },
  name: {
    key: 'name',
    type: 'string',
    fieldType: 'text',
    label: 'Name',
    readOnly: true,
  },
  active: {
    key: 'active',
    type: 'string',
    fieldType: 'switch',
    label: 'Active',
  },
  type: {
    key: 'type',
    type: 'string',
    fieldType: 'select',
    label: 'Role',
    constraints: [
      {
        type: 'required',
        message: 'Role is required',
      },
    ],
    options: [
      { id: INTEGRATOR_USER.key, name: INTEGRATOR_USER.displayValue },
      { id: INTEGRATOR.key, name: INTEGRATOR.displayValue },
    ],
  },
  password: {
    key: 'password',
    type: 'string',
    fieldType: 'text',
    label: 'Password',
    constraints: [
      {
        type: 'required',
        message: 'Password is required',
      },
    ],
  },
}

// Local Utils
function createFullName({ firstName, lastName }) {
  return `${firstName} ${lastName}`
}

// Main
const StyledModal = styled(Modal)(({ theme }) => ({
  position: 'relative',
  '.modal_header': {
    position: 'absolute',
    top: theme.spacing(3.5),
    right: theme.spacing(3.5),
    width: '24px',
    marginBottom: 0,
  },
  '.close_icon': {
    width: '18px',
    height: '18px',
  },
  '.heading': {
    fontSize: '24px',
    marginBottom: theme.spacing(3),
  },
  '.switch_label': {
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: theme.typography.fontWeightSemiMedium,
    letterSpacing: '0.3px',
  },
}))
const StyledForm = styled('form')(({ theme }) => ({
  '.role_form_field': {
    flexWrap: 'wrap',
  },
  '.role_form_field .field_label': {
    marginBottom: theme.spacing(1),
  },
  '.MuiAutocomplete-root ': {
    flex: '1 0 auto',
    width: '100%',
  },
  '.switch_label': {
    color: theme.palette.blue.primary,
  },
}))
const StyledFormField = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4),
}))
const StyledFieldLabel = styled('span')(({ theme }) => ({
  color: theme.palette.blue.primary,
}))
const StyledReadOnlyFieldValue = styled('span')(({ theme }) => ({
  color: theme.palette.grey.secondary,
}))
const StyledDivider = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  marginBottom: theme.spacing(4),
}))

// Note: Duplicated from crud/upsert.js with exception of
//       disabled styling
const ActiveToggle = styled(props => (
  <Switch {...props} disableRipple />
))(({ theme }) => ({
  height: 48,
  width: '64px',
  marginBottom: theme.spacing(-1.5),
  marginTop: theme.spacing(-1.5),
  marginRight: theme.spacing(-1.5),
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.success.secondary,
  },
  '& .MuiSwitch-switchBase': {
    top: '50%',
    transform: 'translate(5px, -50%)',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translate(20px, -50%)',
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.success.active,
    opacity: 1,
  },
  '&.is_disabled .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
    {
      opacity: 0.5,
    },
}))
const StyledModalFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.manage_account_button': {
    marginLeft: 'auto',
    marginRight: 0,
  },
}))
const StyledPrimaryButton = styled(LoadingButton)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.primary
  const boxShadow = getCustomShadows({
    componentName: 'filterButtons',
  })

  return {
    ...defaultButton,
    height: 40,
    boxShadow,
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '&:active': pressed,
  }
})
const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
}))
const StyledWarningMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.error.secondary,
  borderRadius: '8px',
  margin: theme.spacing(3, 0),
}))
const StyledWarningIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.error.main,
  width: '24px',
  height: '24px',
  flex: '1 0 auto',
  marginRight: theme.spacing(1.5),
}))
const StyledSuccessWrapper = styled('div')(({ theme }) => ({
  '.header': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '.checkmark_circle_icon': {
    color: theme.palette.success.active,
    width: '80px',
    height: '80px',
    marginBottom: theme.spacing(3),
  },
  '.heading': {
    marginBottom: theme.spacing(4),
  },
}))
const StyledConfirmChangeWrapper = styled('div')(({ theme }) => ({
  '.heading': {
    marginBottom: theme.spacing(3),
  },
  '.body': {
    marginBottom: theme.spacing(3),
  },
  '.text_field_with_label': {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  '.MuiInput-input': {
    fontSize: 18,
  },
}))
// const StyledConfirmChangesButton = styled(StyledPrimaryButton)(
//   ({ theme }) => ({
//     backgroundColor: theme.palette.error.inactive,
//   }),
// )

function TeamMemberDetailsReadOnly({ data, screenChangeHandler }) {
  const nextScreenHandler = () =>
    screenChangeHandler(EDIT_TEAM_MEMBER_SCREEN_KEYS.editMode)

  return (
    <div className='team_member_read_only_view'>
      <h3 className='heading'>Account Details</h3>

      <StyledFormField>
        <StyledFieldLabel>Name</StyledFieldLabel>
        <StyledReadOnlyFieldValue>{`${data.firstName} ${data.lastName}`}</StyledReadOnlyFieldValue>
      </StyledFormField>
      <StyledFormField>
        <StyledFieldLabel>Email</StyledFieldLabel>
        <StyledReadOnlyFieldValue>
          {data.email}
        </StyledReadOnlyFieldValue>
      </StyledFormField>

      <StyledDivider />

      <StyledFormField>
        <StyledFieldLabel>Account Status:</StyledFieldLabel>
        <StyledReadOnlyFieldValue>
          <ActiveToggle
            readOnly
            label={
              data.active ? FORM_FIELDS.active.label : 'Inactive'
            }
            data={data.active}
          />
        </StyledReadOnlyFieldValue>
      </StyledFormField>
      <StyledFormField>
        <StyledFieldLabel>Role</StyledFieldLabel>
        <StyledReadOnlyFieldValue>
          {TEAM_MEMBER_TYPES[data.type].displayValue}
        </StyledReadOnlyFieldValue>
      </StyledFormField>

      <StyledModalFooter>
        <Button
          className='manage_account_button'
          onClick={nextScreenHandler}>
          Manage Account
        </Button>
      </StyledModalFooter>
    </div>
  )
}

function TeamMemberDetailsEditMode({ data, screenChangeHandler }) {
  const { hideModal } = useContext(ModalContext)
  const {
    formData,
    handleFormChange,
    resetForm,
    handleSubmit,
    loading,
  } = useContext(FormContext)
  const onSaveClick = evt => handleSubmit(evt)
  const onCancelClick = () => {
    resetForm()
    hideModal()
  }

  return (
    <div className='team_member_edit_view'>
      <Loading loading={loading}>
        <StyledForm autoComplete='off'>
          <h3 className='heading'>Edit Account</h3>

          <StyledFormField>
            <StyledFieldLabel>Name</StyledFieldLabel>
            <StyledReadOnlyFieldValue>
              {createFullName(data)}
            </StyledReadOnlyFieldValue>
          </StyledFormField>
          <StyledFormField>
            <StyledFieldLabel>Email</StyledFieldLabel>
            <StyledReadOnlyFieldValue>
              {data.email}
            </StyledReadOnlyFieldValue>
          </StyledFormField>

          <StyledDivider />

          <StyledFormField>
            <StyledFieldLabel>Account Status:</StyledFieldLabel>
            <StyledReadOnlyFieldValue>
              <ActiveToggle
                data={data.active}
                label={
                  formData.active
                    ? FORM_FIELDS.active.label
                    : 'Inactive'
                }
                onChange={data =>
                  handleFormChange({ key: 'active', value: data })
                }
              />
            </StyledReadOnlyFieldValue>
          </StyledFormField>
          <StyledFormField className='role_form_field'>
            <StyledFieldLabel className='field_label'>
              Role
            </StyledFieldLabel>
            <SelectMenu
              id='type'
              name='type'
              type='text'
              className='role_select_menu'
              defaultValue={data.type}
              onChange={value =>
                handleFormChange({ key: 'type', value })
              }
              menuItems={ROLE_OPTIONS_LIST}
            />
          </StyledFormField>

          <StyledModalFooter>
            <StyledSecondaryButton
              buttonType='secondary'
              onClick={onCancelClick}>
              Cancel
            </StyledSecondaryButton>
            <StyledPrimaryButton
              key='submit'
              type='submit'
              variant='contained'
              loading={loading}
              onClick={onSaveClick}>
              Save Changes
            </StyledPrimaryButton>
          </StyledModalFooter>
        </StyledForm>
      </Loading>
    </div>
  )
}

// function ChangeOwnershipWarning({ data }) {
//   return (
//     <StyledWarningMessage className='ownership_change_warning_message'>
//       <StyledWarningIcon icon='clarity:warning-line' />

//       <div className='main'>
//         <Typography variant='p' component='p'>
//           This action is not reversible!
//         </Typography>

//         <Typography variant='p' component='p'>
//           You will lose all Administrative Authority. Please confirm you
//           want to transfer ownership to&nbsp;
//           <strong>{createFullName(data)}</strong>
//         </Typography>
//       </div>
//     </StyledWarningMessage>
//   )
// }

// function ConfirmOwnershipChangeForm() {
//   const {
//     handleFormChange,
//     resetForm,
//     handleSubmit,
//     loading,
//     errorMessages,
//   } = useContext(FormContext)
//   const { hideModal } = useContext(ModalContext)
//   const onCancelClick = () => {
//     resetForm()
//     hideModal()
//   }
//   const onChange = (key, value) =>
//     handleFormChange({ key: 'password', value })

//   return (
//     <StyledForm autoComplete='off'>
//       <TextFieldWithLabel
//         label='Password'
//         fieldKey='password'
//         type='password'
//         name='password'
//         id='password'
//         messages={errorMessages}
//         onFormChangeHandler={onChange}
//       />
//       <StyledModalFooter>
//         <StyledSecondaryButton
//           buttonType='secondary'
//           onClick={onCancelClick}>
//           Cancel
//         </StyledSecondaryButton>
//         <StyledConfirmChangesButton
//           variant='contained'
//           loading={loading}
//           onClick={handleSubmit}>
//           Confirm Changes
//         </StyledConfirmChangesButton>
//       </StyledModalFooter>
//     </StyledForm>
//   )
// }

// function ConfirmOwnershipChangeScreen({ data, screenChangeHandler }) {
//   const { formData, loading, errorMessages } = useContext(FormContext)
//   const prevLoadingState = usePrevious(loading)
//   const { password, ...teamMemberData } = formData
//   const onSuccess = useCallback(async () => {
//     const updatedTeamMember = await updateTeamMember(teamMemberData)
//     if (updatedTeamMember) {
//       screenChangeHandler(EDIT_TEAM_MEMBER_SCREEN_KEYS.success)
//     }
//   }, [teamMemberData, screenChangeHandler])

//   useEffect(() => {
//     if (isEmpty(errorMessages) && prevLoadingState && !loading) {
//       onSuccess()
//     }
//   }, [
//     onSuccess,
//     screenChangeHandler,
//     errorMessages,
//     prevLoadingState,
//     loading,
//   ])
//   return (
//     <StyledConfirmChangeWrapper className='ownership_change_confirmation_screen'>
//       <h3 className='heading'>Confirm ownership change</h3>

//       <Typography variant='p' component='p' className='body'>
//         Please confirm your decision to make {createFullName(data)} the
//         owner by providing your password. Once you confirm, your account
//         will lose administrative privileges.
//       </Typography>

//       <ConfirmOwnershipChangeForm />
//     </StyledConfirmChangeWrapper>
//   )
// }

function UpdateTeamMemberSuccessScreen({ data }) {
  const [companyName, setCompanyName] = useState(null)

  useEffect(() => {
    if (!companyName) {
      async function getCompanyName() {
        const { name } = await getIntegratorById(data.integratorId)

        setCompanyName(name)
      }

      getCompanyName()
    }
  }, [companyName, data.integratorId])

  return (
    <StyledSuccessWrapper className='success_screen'>
      <header className='header'>
        <Icon
          icon='fluent:checkmark-circle-32-filled'
          className='checkmark_circle_icon icon'
        />
        <h3 className='heading'>Ownership transfer complete</h3>
      </header>

      <Typography className='copy' component='p' variant='p'>
        Ownership of <strong>{companyName}</strong> has been
        transferred to&nbsp;
        <strong>{createFullName(data)}</strong>. An email has been
        sent informing them of the transfer of ownership.
      </Typography>
    </StyledSuccessWrapper>
  )
}

const EDIT_TEAM_MEMBER_SCREEN_STEPS = {
  [EDIT_TEAM_MEMBER_SCREEN_KEYS.readOnly]: TeamMemberDetailsReadOnly,
  [EDIT_TEAM_MEMBER_SCREEN_KEYS.editMode]: TeamMemberDetailsEditMode,
  // [EDIT_TEAM_MEMBER_SCREEN_KEYS.confirmChange]: ConfirmOwnershipChangeScreen,
  [EDIT_TEAM_MEMBER_SCREEN_KEYS.success]:
    UpdateTeamMemberSuccessScreen,
}

export default function EditTeamMemberModal({ data }) {
  const [currentModalScreen, setCurrentModalScreen] = useState(
    EDIT_TEAM_MEMBER_SCREEN_KEYS.readOnly,
  )
  const ModalScreen =
    EDIT_TEAM_MEMBER_SCREEN_STEPS[currentModalScreen]
  const onSuccess = () => {
    setCurrentModalScreen(EDIT_TEAM_MEMBER_SCREEN_KEYS.success)
  }

  return (
    <StyledModal className='team_member_details_modal'>
      <FormProvider
        initialFormData={data}
        formFields={FORM_FIELDS}
        submitAction={updateTeamMember}
        onSuccess={onSuccess}
        onError={errorAlert}>
        <ModalScreen
          data={data}
          screenChangeHandler={setCurrentModalScreen}
        />
      </FormProvider>
    </StyledModal>
  )
}
