import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  secondary: '#979BA8',
}

const BLUE = {
  primary: '#0B2233',
  secondary: '#1882DB',
  tertiary: '#CEEBFD',
  hover: '#F0F8FD',
}

const PRIMARY = {
  lighter: '#C8FACD',
  light: 'white',
  main: '#094063',
  dark: '#007B55',
  darker: '#092563',
  contrastText: '#fff',
  table: '#EDF8FF',
  base: '#54B6B6',
}
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
}
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
}
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  active: '#257D72',
  secondary: '#DEECEA',
  contrastText: GREY[800],
}
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  active: '#D99C14',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
}
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  inactive: '#DA5656',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
  secondary: '#F9E6E6',
}

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
}

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
}

const BUTTONS = {
  primaryBg: BLUE.secondary,
  primaryBgHover: '#157AD7',
  primaryBgActive: PRIMARY.main,
  primaryText: PRIMARY.light,
  primaryIconColor: PRIMARY.light,
  secondaryBg: PRIMARY.light,
  secondaryBgHover: BLUE.hover,
  secondaryBgActive: BLUE.tertiary,
  secondaryText: BLUE.secondary,
  secondaryIconColor: BLUE.secondary,
}

const palette = {
  common: { black: '#000', white: '#fff' },
  violet: '#826AF9',
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  blue: BLUE,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  buttons: {
    primary: {
      default: {
        backgroundColor: BUTTONS.primaryBg,
        color: BUTTONS.primaryText,
        icon: {
          fill: BUTTONS.primaryIconColor,
        },
      },
      hover: {
        backgroundColor: BUTTONS.primaryBgHover,
      },
      pressed: {
        backgroundColor: BUTTONS.primaryBgActive,
      },
      disabled: {
        opacity: 0.4,
      },
    },
    secondary: {
      default: {
        backgroundColor: BUTTONS.secondaryBg,
        color: BUTTONS.secondaryText,
        icon: {
          fill: BUTTONS.secondaryIconColor,
        },
      },
      hover: {
        backgroundColor: BUTTONS.secondaryBgHover,
      },
      pressed: {
        backgroundColor: BUTTONS.secondaryBgActive,
      },
      disabled: {
        opacity: 0.4,
      },
    },
    filterAction: {
      default: {
        backgroundColor: BUTTONS.secondaryBg,
        color: PRIMARY.main,
        icon: {
          fill: PRIMARY.main,
        },
      },
      hover: {
        backgroundColor: BUTTONS.secondaryBgHover,
      },
      pressed: {
        backgroundColor: BUTTONS.secondaryBgActive,
      },
      active: {
        color: BLUE.secondary,
      },
      disabled: {
        opacity: 0.4,
      },
    },
  },
  borderColor: GREY[500_32],
  formFields: {
    borderColor: GREY[500_32],
  },
  background: {
    paper: '#E5E5E5',
    activeBg: BLUE.tertiary,
    default: '#E5E5E5',
    neutral: GREY[200],
  },
  navbar: {
    paper: '#F2F9FF',
    primary: PRIMARY.light,
    default: PRIMARY.main,
    hoverBg: BLUE.primary,
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
