import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { format } from 'date-fns'

// Services
import { errorAlert } from '../../services/alertService'
import {
  listTransfers,
  getEventsForTransfer,
  getTransferStatus,
} from '../../services/transferService'
import { getIntegratorById } from '../../services/integratorService'
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { getRoles } from '../../services/authenticationService'
import { showFormattedDate } from '../../services/dateService'

// Utils
import { getTransferTypeDisplayValue } from '../../utils/transfers'
import {
  SECTION_BASE_PATHS,
  COUNTRY_CURRENCY_ICON_MAP,
  numberWithCommas,
  getCurrency,
  getDollarAmountWithCurrency,
} from '../../utils/general'
import { getCustomShadows } from '../../theme/shadows'
import palette from '../../theme/palette'

// Material
import { Typography, Box, Modal, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Cancel, CheckCircle, ArrowForward } from '@mui/icons-material'

// Components
import Button from '../../components/Button'
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { Icon } from '@iconify/react'
import closeFill from '@iconify/icons-eva/close-fill'
import { isEmpty } from 'lodash'

// ----------------------------------------------------------------------
// Constants
const constructFiltersString = filters => filters.join(',')
const TRANSFERS_BASE_PATH = SECTION_BASE_PATHS.transfers
const OVERALL_STATUS_MAP = {
  PENDING: {
    displayValue: 'pending',
    color: palette.grey.secondary,
    className: 'status_pending',
  },
  IN_PROGRESS: {
    displayValue: 'in progress',
    color: palette.grey.secondary,
    className: 'status_in_progress',
  },
  SUCCEEDED: {
    displayValue: 'completed',
    color: palette.success.active,
    className: 'status_completed',
  },
  FAILED: {
    displayValue: 'failed',
    color: palette.error.inactive,
    className: 'status_failed',
  },
}

const TRANSFER_TYPES = {
  ON_RAMP: {
    key: 'ON_RAMP',
    displayValue: 'On Ramp',
    currencyOrigin: 'from',
  },
  OFF_RAMP: {
    key: 'OFF_RAMP',
    displayValue: 'Off Ramp',
    currencyOrigin: 'to',
  },
}

// Local Utils
async function getBeneficiaryName(beneficiaryId) {
  const beneficiary = await getBeneficiaryById(beneficiaryId)
  const name = beneficiary.person
    ? `${beneficiary.person.firstName} ${beneficiary.person.lastName}`
    : beneficiary.business.name
  return name
}

async function getIntegratorName(integratorId) {
  const { name } = await getIntegratorById(integratorId)

  return name
}

async function getErrorMessage(id, status) {
  if(status == "FAILED") {
    const { message } = await getTransferStatus(id)
    return message
  }
  
  return "";
}

// Styled Components
const StyledTransferStatus = styled('div')(({ theme }) => ({
  ...theme.typography.eyebrow,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey.secondary,
  marginBottom: theme.spacing(2),
  '.icon': {
    marginRight: theme.spacing(2),
    width: 24,
  },
  '&.success_status .icon': {
    color: theme.palette.success.active,
  },
  '&.success_status .status_main': {
    color: theme.palette.success.active,
    backgroundColor: theme.palette.success.secondary,
  },
  '&.failed_status .icon': {
    color: theme.palette.error.inactive,
  },
  '&.failed_status .status_main': {
    color: theme.palette.error.inactive,
    backgroundColor: theme.palette.error.secondary,
  },
  '.success_icon': {
    color: theme.palette.success.active,
  },
  '.pending_icon path': {
    fill: theme.palette.primary.main,
  },
}))

const StyledTransferContent = styled('div')(({ theme }) => ({
  borderRadius: 8,
  padding: theme.spacing(3),
  flexGrow: 1,
  backgroundColor: '#F9FAFD',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  '.step': {
    flexBasis: '20%',
  },
  '.date': {
    flexBasis: '25%',
    textAlign: 'right',
  },
}))

function TransferStatus({ status, type, createdDate, ...rest }) {
  //const { label, icon: StatusIcon } = EVENT_STATUS_MAP[status]
  const date = new Date(createdDate)
  //const lowercasedStatus = status.toLowerCase()
  return (
    <StyledTransferStatus
      className={clsx(`success_status`, 'status')}>
      
      <StyledTransferContent className='status_main'>
        <Typography className='step' variant='body'>
        {type}
        </Typography>
        
        <Typography className='date' variant='body'>
          {format(date, 'MMM dd, yyyy HH:mma')}
        </Typography>
      </StyledTransferContent>
    </StyledTransferStatus>
  )
}

const StyledTransferDetailsModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 640,
  backgroundColor: theme.palette.primary.light,
  boxShadow: getCustomShadows({ componentName: 'modal' }),
  padding: theme.spacing(4),
  borderRadius: 8,
  '.divider': {
    marginBottom: theme.spacing(2),
  },
  '@media screen and (min-width: 600px)': {
    padding: theme.spacing(6),
    width: '100%',
  },
}))

const StyledSecondaryTransferDetailsWrapper = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  }),
)

const StyledTransferDetail = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.grey.secondary,
  marginRight: theme.spacing(2),
  fontSize: '14px',
  '&:last-child': {
    marginRight: 0,
  },
  '@media screen and (min-width: 600px)': {
    fontSize: '16px',
  },
}))

const StyledTransferIDLabel = styled(StyledTransferDetail)(
  ({ theme }) => ({
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    '@media screen and (min-width: 600px)': {
      fontSize: '24px',
    },
  }),
)

const StyledTransferDetailValue = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}))

const StyledTransferIDValue = styled(StyledTransferDetailValue)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
  }),
)

const StyledTransferStatusValue = styled(StyledTransferDetailValue)(
  ({ theme }) => ({
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  }),
)

const StyledModalCloseIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 10,
  '@media screen and (min-width: 600px)': {
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const StyledDivider = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
}))

const StyledMainTransferDetailsSection = styled(
  StyledSecondaryTransferDetailsWrapper,
)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1.5fr 1fr',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(2),
}))

const StyledToFromAmount = styled(StyledTransferDetail)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  '.currency_country_icon': {
    marginRight: theme.spacing(1),
  },
}))

const StyledAdditionalInfoButtonLink = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  backgroundColor: 'transparent',
  color: theme.palette.blue.secondary,
  fontWeight: theme.typography.fontWeightMedium,
  boxShadow: 'none',
  display: 'inline-block',
  padding: 0,
  minWidth: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
}))

const TransferDetailsModalContext = createContext()

function TransferDetailsModalProvider({ children }) {
  const [modalData, setModalData] = useState({})
  const [modalIsOpen, setIsOpenState] = useState(false)
  const openModal = data => {
    setModalData(data)
    setIsOpenState(true)
  }
  const closeModal = () => {
    setModalData({})
    setIsOpenState(false)
  }

  return (
    <TransferDetailsModalContext.Provider
      value={{
        modalIsOpen,
        openModal,
        closeModal,
      }}>
      {children}
      {modalIsOpen && !isEmpty(modalData) ? (
        <TransferDetailsModal data={modalData} />
      ) : null}
    </TransferDetailsModalContext.Provider>
  )
}

function TransferDetailsModal({
  data: {
    status,
    integratorId,
    beneficiaryId,
    id,
    lastModifiedDate,
    createdDate,
    transactionDetails: { feeDetails: { totalFees: {currencyCode: feeCurrencyCode, value: feeValue}  }, conversionDetails: {exchangeRate: exchangeRate, effectiveTransactionValue: effectiveTransactionValue} },
    to: { currencyCode: destinationCurrencyCode, value: destinationValue },
    from: { currencyCode: originCurrencyCode, value: originValue },
    type
  },
}) {
  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const { modalIsOpen, closeModal } = useContext(
    TransferDetailsModalContext,
  )
  const [fetchedExtraDetails, setFetchState] = useState(false)
  const [extraDetails, setExtraDetails] = useState({
    integratorName: null,
    beneficiaryName: null,
    errorMessage: null,
  })
  const {
    color,
    displayValue,
    className: statusClassName,
  } = OVERALL_STATUS_MAP[status]
  const currency =
    type === TRANSFER_TYPES.ON_RAMP.key
      ? originCurrencyCode
      : destinationCurrencyCode

  // Fetch beneficiary, integrator, and currency values for display
  useEffect(() => {
    if (!fetchedExtraDetails) {
      async function fetchExtraDetails() {
        const [beneficiaryName, integratorName = null, errorMessage = null] = await Promise.all(
          [
            getBeneficiaryName(beneficiaryId),
            getIntegratorName(integratorId),
            getErrorMessage(id, status)
          ],
        )
        const mappedDetails = {
          integratorName,
          beneficiaryName,
          errorMessage
        }
        setExtraDetails(mappedDetails)
        setFetchState(true)
      }

      fetchExtraDetails()
    }
  }, [isAdmin, fetchedExtraDetails, beneficiaryId, integratorId])

  return (
    <Modal open={modalIsOpen} onClose={closeModal}>
      <StyledTransferDetailsModalContent>
        <StyledModalCloseIconButton onClick={closeModal}>
          <Icon icon={closeFill} width={32} height={32} />
        </StyledModalCloseIconButton>

        <StyledSecondaryTransferDetailsWrapper>
          <StyledTransferIDLabel variant='p'>
            Transfer ID:&nbsp;
            <StyledTransferIDValue>{id}</StyledTransferIDValue>
          </StyledTransferIDLabel>
        </StyledSecondaryTransferDetailsWrapper>

        <StyledSecondaryTransferDetailsWrapper>
          <StyledTransferDetail variant='p'>
            Status:
            <StyledTransferStatusValue className={statusClassName}>
              <Icon
                icon='ci:dot-05-xl'
                width={24}
                className='icon'
                color={color}
              />
              {displayValue}
            </StyledTransferStatusValue>
          </StyledTransferDetail>

          <StyledTransferDetail variant='p'>
            Last Update:&nbsp;
            <StyledTransferDetailValue>
              {format(
                new Date(lastModifiedDate),
                'do MMM, YYY HH:mm a',
              )}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
        </StyledSecondaryTransferDetailsWrapper>

        <StyledSecondaryTransferDetailsWrapper>
          <StyledToFromAmount variant='p'>
            {COUNTRY_CURRENCY_ICON_MAP[originCurrencyCode]}
            {getDollarAmountWithCurrency({
              currencyCode: originCurrencyCode,
              amount: originValue,
            })}
          </StyledToFromAmount>
          <ArrowForward
            sx={{ marginRight: '16px' }}
            className='forward_arrow_icon'
          />
          <StyledToFromAmount variant='p'>
            {COUNTRY_CURRENCY_ICON_MAP[destinationCurrencyCode]}
            {getDollarAmountWithCurrency({
              currencyCode: destinationCurrencyCode,
              amount: destinationValue,
            })}
          </StyledToFromAmount>
        </StyledSecondaryTransferDetailsWrapper>

        <StyledDivider className='divider' />

        <StyledMainTransferDetailsSection>
          <StyledTransferDetail variant='p'>
            Integrator:&nbsp;
            <StyledTransferDetailValue>
              {extraDetails.integratorName}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Currency:&nbsp;
            <StyledTransferDetailValue>
              {currency}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Beneficiary:&nbsp;
            <StyledTransferDetailValue>
              {extraDetails.beneficiaryName}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Total Fees:&nbsp;
            <StyledTransferDetailValue>
              {getDollarAmountWithCurrency({
                currencyCode: feeCurrencyCode,
                amount: feeValue,
              })}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Exchange Rate:&nbsp;
            <StyledTransferDetailValue>
              {exchangeRate}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Effective transaction value (VET):&nbsp;
            <StyledTransferDetailValue>
              {effectiveTransactionValue}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Created On:&nbsp;
            <StyledTransferDetailValue>
              {format(new Date(createdDate), 'do MMM, YYY HH:mm a')}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
           
          </StyledTransferDetail>
          <StyledTransferDetail variant='p'>
            Error message:&nbsp;
            <StyledTransferDetailValue>
              {extraDetails.errorMessage}
            </StyledTransferDetailValue>
          </StyledTransferDetail>
        </StyledMainTransferDetailsSection>
      </StyledTransferDetailsModalContent>
    </Modal>
  )
}

function TransferDetailsExpandableRow({ data }) {
  const { openModal } = useContext(TransferDetailsModalContext)
  const onLinkClick = () => openModal(data)

  console.log(data.eventStatus)
  return (
    <div className='transfer_status_states'>
      {data.eventStatus.map((statusData, index) => (
        <TransferStatus key={index} {...statusData} />
      ))}

      <footer style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <StyledAdditionalInfoButtonLink onClick={onLinkClick}>
          Additional Info
        </StyledAdditionalInfoButtonLink>
      </footer>
    </div>
  )
}

export function TransfersList({
  theme,
  hideHeading = false,
  filters = []
}) {
  // @tedlin182 - When logging in, this causes an error so possible resolutions:
  //  1. Lazy load pages so this doesn't register
  //  2. Do a better job handling this with a check
  //  3. This can only reside within a component it seems. Possible
  //     this could be due to the fact that all imports/pages/
  //     components are compliled are runtime so lazy load pages first
  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const hideAddButton = process.env.REACT_APP_IS_PRODUCTION === 'MAIN'
  const navigate = useNavigate()
  const { openModal } = useContext(TransferDetailsModalContext)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })
  const goToViewPage = ({ id }) =>
    navigate(`${TRANSFERS_BASE_PATH}/${id}/view`)

  const otherFilters = !!filters.length
    ? `,${constructFiltersString(filters)}`
    : ''

  // This should just fetch and return data
  // Any state updates should occur within
  // a useEffect block.
  const fetchData = useCallback(async () => {
    const {
      content,
      totalPages,
      totalElements,
      message: errorMessage,
    } = await listTransfers(
      `filterEq=name:${search}${otherFilters}`,
      page,
      rowsPerPage,
      sort,
    )

    if (errorMessage) {
      return errorAlert(errorMessage)
    }

    let transfers = content

    // If admin, also fetch each transfer's statuses
    if (isAdmin) {
      transfers = await Promise.all(
        content.map(async ({ id, ...transferData }) => {
          const eventStatus = await getEventsForTransfer(id)

          return {
            ...transferData,
            id,
            eventStatus,
          }
        }),
      )
    }

    return { totalPages, totalElements, content: transfers }
  }, [isAdmin, page, sort, rowsPerPage, search, otherFilters])

  useEffect(() => {
    async function setDataState() {
      const transfersData = await fetchData()

      setDataWrapper(transfersData)
      setLoading(false)
    }

    setDataState()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Transfers | Overview'}>
      <List
        loading={loading}
        label={hideHeading ? null : 'Transfers'}
        goToView={goToViewPage}
        hideSearchFilter
        hideAddButton={hideAddButton}
        useAccordionTableRow={isAdmin}
        onTableRowClick={isAdmin ? null : openModal}
        renderExpandableTableRow={TransferDetailsExpandableRow}
        headers={[
          { id: 'id', label: 'Transfer ID', alignRight: false },
          {
            id: 'beneficiaryId',
            label: 'Beneficiary',
            alignRight: false,
            onRender: getBeneficiaryName,
          },
          {
            id: 'type',
            label: 'Type',
            alignRight: false,
            onRender: (value, row) => getTransferTypeDisplayValue(value),
          },
          {
            id: 'createdDate',
            label: 'Date',
            alignRight: false,
            onRender: createdDate => {
              return showFormattedDate(createdDate)
            },
          },
          {
            id: 'to',
            label: 'To Amount',
            alignRight: false,
            onRender: (_, row) => {
              const num = parseFloat(row.to.value)
              return `${getCurrency(
                row.to.currencyCode,
              )}${numberWithCommas(num.toFixed(2))}`
            },
          },
          {
            id: 'from',
            label: 'From Amount',
            alignRight: false,
            onRender: (_, row) =>
              `${getCurrency(row.from.currencyCode)}${numberWithCommas(
                parseFloat(row.from.value).toFixed(2),
              )}`,
          },
          {
            id: 'status',
            label: 'Status',
            alignRight: false,
            onRender: statusVal => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {statusVal.toLowerCase()}
                </span>
              )
            },
          },
        ]}
        goToAddPage={() => navigate('/dashboard/transfers/fake')}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={search => {
          setSearch(search)
        }}
      />
    </Page>
  )
}

export default function TransfersListPage(props) {
  return (
    <TransferDetailsModalProvider>
      <TransfersList {...props} />
    </TransferDetailsModalProvider>
  )
}
