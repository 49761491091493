import PropTypes from 'prop-types'
import clsx from 'clsx'

// Material
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const FieldWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
}))

const FieldDisplay = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
  }
})

const FieldLabel = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.blue.primary,
  }
})

const FieldValue = styled(Typography)(({ theme }) => {
  return {
    color: '#646464',
    textAlign: 'right',
  }
})

View.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.shape({
        displayValue: PropTypes.any,
        className: PropTypes.any,
      }),
      value: PropTypes.shape({
        displayValue: PropTypes.any,
        className: PropTypes.any,
      }),
    }),
  ).isRequired,
  className: PropTypes.any,
}

export default function View({ fields, className }) {
  return (
    <FieldWrapper className={clsx('field_wrapper', className)}>
      {fields.map(
        (
          {
            label: { displayValue: labelDisplay, ...labelProps },
            value: { displayValue: valueDisplay, ...valueProps },
          },
          index,
        ) => (
          <FieldDisplay key={`field_display_${index}`}>
            <FieldLabel {...labelProps}>{labelDisplay}</FieldLabel>
            <FieldValue {...valueProps}>{valueDisplay}</FieldValue>
          </FieldDisplay>
        ),
      )}
    </FieldWrapper>
  )
}
