import { isArray } from 'lodash'

// Constants
export const FILTER_TYPES = Object.freeze({
  checkbox: 'checkbox',
  radio: 'radio',
  date: 'date',
  daterange: 'daterange',
  autocomplete: 'autocomplete',
  select: 'select',
})

export const FILTER_FIELDS_KEY_MAP = Object.freeze({
  active: 'active',
  integratorId: 'integratorId',
  firstName: 'person.firstName',
  lastName: 'person.lastName',
  businessName: 'business.name',
  value: 'value',
  status: 'status',
  sort: 'sort',
  alphabetical: 'alphabetical',
  amount: 'amount',
  recent: 'recent',
  startDate: 'startDate',
  endDate: 'endDate',
})

export const DATE_FILTER_KEYS = Object.freeze({
  [FILTER_TYPES.daterange]: FILTER_TYPES.daterange,
  [FILTER_FIELDS_KEY_MAP.startDate]: FILTER_FIELDS_KEY_MAP.startDate,
  [FILTER_FIELDS_KEY_MAP.endDate]: FILTER_FIELDS_KEY_MAP.endDate,
})

export const STANDARD_DATE_DISPLAY_FORMAT = 'yyyy-MM-dd'

function constructDateFilter({ key, value }) {
  if (key === 'startDate') {
    return `from:createdDate>${value}`
  }

  return `to:createDate<${value}`
}
//  Example:
//  https://develop.api.caliza.co/core-api/v1/admin/beneficiaries?filterOr=person.firstName:a,person.lastName:a,business.name:a&page=0&size=10
export function convertFilterObjectToString({ filters = {} }) {
  return Object.keys(filters).reduce((filterStr, filterKey, index) => {
    const isDateFilter = !!DATE_FILTER_KEYS[filterKey]
    const filterVal = filters[filterKey]
    let filter = ''

    if (isDateFilter) {
      filter = constructDateFilter({ key: filterKey, value: filterVal })
    } else {
      const stringifiedFilterValue = isArray(filterVal)
        ? filterVal.join(',')
        : filterVal
      filter = `${filterKey}:${stringifiedFilterValue}`
    }

    filterStr += index === 0 ? `${filter}` : `,${filter}`

    return filterStr
  }, '')
}
