import React, { useState } from 'react';

import { 
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl
} from '@mui/material';

import Variable from './variable'


const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, onCreation, ledgerStatus }) => {

    const [values, setValues] = useState(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        acc[column.editSelectOptions ?? ''] = '';
        return acc;
      }, {}),
    );
  
    const handleSubmit = () => {
      values.id=uuidv4()
      values.type = values.type == "" ? null : values.type

      const row = {...values}

      if(row.debit && row.credit) {
        if(Array.isArray(row.debit)) {

          row.credit = "";
          row.debit = row.debit.join("");
        } else {
          row.credit = row.credit.join("");
          row.debit = "";
        }
      }

      onCreation(row);

      onClose();
    };
  
    const updateDebitsOrCredits = (info, column) => {
      
      if(!values.debit && !values.credit) {
        setValues({...values});
        return;
      }

      if(column.accessorKey == "debit") {
        values.debit = info;
        setValues({...values});
      } else {
        values.credit = info;
        setValues({...values});
      }
    }
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Account</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {
                columns.map((column) => {
  
                  if(column.editVariant == 'select') {
                    
                    const label = column.header
                    return (
                      <FormControl> 
                        <InputLabel id="simple-select-label">{label}</InputLabel>
                        <Select
                          name={column.accessorKey}
                          onChange={(e) => {
                            setValues({ ...values, [e.target.name]: e.target.value })
                          }}
                        >
                          {
                            column.editSelectOptions.map(i => {
                              return (<MenuItem value={i.value}>{i.text}</MenuItem>)
                            })
                          } 
                        </Select>
                        </FormControl>
                      )
                  } else if(column.editVariant == 'chip') {
                    
                    const label = column.header
                    if(!Array.isArray(column.debit)) {
                      column.debit = [column.debit]
                    }
  
                    if(!Array.isArray(column.credit)) {
                      column.credit = [column.credit]
                    }
  
                    let fundVariable = ""
                    if(column.accessorKey == "debit") {
                      fundVariable = column.debit;
                    } else {
                      fundVariable = column.credit;
                    }
  
                    return (
                      <FormControl> 
                        <InputLabel id="simple-select-label" htmlFor={column.accessorKey}>{label}</InputLabel>
                        <Variable 
                          providers={ledgerStatus.providers}
                          currencies={ledgerStatus.currencies}
                          fees={ledgerStatus.types}
                          value = {fundVariable || []} 
                          onChange={(info) => updateDebitsOrCredits(info, column)}
                        />
                      </FormControl>
                      )
                  } else {
                    return (
                      <TextField
                        key={column.accessorKey}
                        label={column.header}
                        name={column.accessorKey}
                        onChange={(e) =>
                          setValues({ ...values, [e.target.name]: e.target.value })
                        }
                      />)
                  }
                })
              }
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={handleSubmit} variant="contained">
            Create new Ledger Movement
          </Button>
        </DialogActions>
      </Dialog>
    );
};

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16),
    )
}
  

export default CreateNewAccountModal;