import { useContext } from 'react'

// Material UI
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

// Components
import { FormContext } from '../providers/FormProvider'

// Styling
import { getCustomShadows } from '../../theme/shadows'

// Styled Components
const StyledPrimaryButton = styled(LoadingButton)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.primary
  const boxShadow = getCustomShadows({
    componentName: 'filterButtons',
  })

  return {
    ...defaultButton,
    height: 40,
    boxShadow,
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '&:active': pressed,
  }
})

export default function FormSubmitButton({ submitButtonText }) {
  const { loading, handleSubmit } = useContext(FormContext)

  return (
    <StyledPrimaryButton
      key='submit_button'
      type='submit'
      variant='contained'
      loading={loading}
      onClick={handleSubmit}>
      {submitButtonText || 'Save Changes'}
    </StyledPrimaryButton>
  )
}
