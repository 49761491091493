import axios from '../config/axiosConfig';
import { getRoles } from './authenticationService'

export const getBalanceForIntegrator = async (integratorId) => {

    let url = ``
    if(getRoles().indexOf("ROLE_ADMIN") > -1) {
        url = `/v1/admin/balances${url}?integratorId=${integratorId}`;
    } else {
        url = `/v1/balances${url}`;
    }

    const response = await axios.get(url)
    return response.data
}

export const getBalanceForIntegratorAndBeneficiary = async (integratorId, beneficiaryId) => {

    let url = `?beneficiaryId=${beneficiaryId}`
    if(getRoles().indexOf("ROLE_ADMIN") > -1) {
        url = `/v1/admin/balances${url}&integratorId=${integratorId}`;
    } else {
        url = `/v1/balances${url}`;
    }

    const response = await axios.get(url)
    return response.data
}