import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getCustomShadows } from '../../theme/shadows'
import palette from '../../theme/palette'

const StyledSelectMenu = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  boxShadow: getCustomShadows({ componentName: 'inputField' }),
  width: '100%',
  '&:focused': {},
  '&.Mui-focused': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))

SelectMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      displayValue: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
}

export default function SelectMenu({
  defaultValue = null,
  menuItems,
  onChange = () => {},
  ...restProps
}) {
  const menu = useRef(null)
  const [selectedValue, setSelectedValue] = useState(defaultValue)
  const handleChange = (value, optionalData = {}) => {
    setSelectedValue(value)
    onChange(value, optionalData)
  }

  return (
    <StyledSelectMenu
      value={selectedValue}
      onClose={() => {
        // On menu close, the Mui-focused is still on
        // the select field. In order to revert styling of display
        // when dropdown menu is open, we need to remove this class
        // manually unfortuately.
        // const { current } = menu
        // current.blur()
        // current.classList.remove('Mui-focused')
      }}
      ref={menu}
      MenuProps={{
        sx: {
          boxShadow: getCustomShadows({ componentName: 'inputField' }),
          '.MuiMenu-paper': {
            backgroundColor: palette.primary.light,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '.MuiMenu-list': {
            padding: 0,
          },
          '.MuiMenuItem-root:hover': {
            backgroundColor: palette.grey[200],
          },
          '.MuiMenuItem-root.Mui-selected': {
            backgroundColor: palette.grey[200],
          },
        },
        className: 'select_menu',
      }}
      {...restProps}>
      {menuItems.map(
        ({ displayValue, value, key, ...restMenuItemProps }) => {
          return (
            <MenuItem
              key={key || value}
              value={value}
              onClick={() => handleChange(value, restMenuItemProps)}>
              {displayValue}
            </MenuItem>
          )
        },
      )}
    </StyledSelectMenu>
  )
}
