import { useState, useEffect } from 'react';

import List from "../../components/crud/list"
import { useNavigate } from 'react-router-dom';
import { errorAlert, successAlert } from '../../services/alertService'

import { listWallets } from '../../services/walletService'
import { getIntegratorById, listIntegrators } from '../../services/integratorService'
import { getBeneficiaryById, listBeneficiaries } from '../../services/beneficiaryService'
import { getCurrencyById, listCurrencies } from '../../services/currencyService'

// ----------------------------------------------------------------------

export default function TrasferList({theme}) {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sort, setSort] = useState({});
  const [filters, setFilters] = useState({
    integratorId: null,
    beneficiaryId: null,
    currencyId: null
  });

  const [dataWrapper, setDataWrapper] = useState({
      totalPages: 0,
      totalElements: 0,
      content: []
  });

  const getIntegratorFilter = () => {

    if(!filters.integratorId) {
      return null
    }

    return filters.integratorId
  }

  const getBeneficiaryFilter = () => {

    if(!filters.beneficiaryId) {
      return null
    }

    return filters.beneficiaryId
  }

  const getCyrrencyFilter = () => {

    if(!filters.currencyId) {
      return null
    }

    return filters.currencyId
  }

  const fetchData = () => {

    const filterIntegrator = (getIntegratorFilter()) ? `integratorId:${getIntegratorFilter()}` : ''
    const filterBeneficiary = (getBeneficiaryFilter()) ? `beneficiaryId:${getBeneficiaryFilter()}` : ''
    const filterCurrency = (getCyrrencyFilter()) ? `currencyId:${getCyrrencyFilter()}` : ''

    const filterEq = `${filterCurrency}${filterBeneficiary}${filterIntegrator}`.length > 0 ? `filterEq=${filterBeneficiary},${filterIntegrator},${filterCurrency}&` : ''
    const filterOr = `filterOr=id:${search}`
    listWallets(`${filterEq}${filterOr}`, page, rowsPerPage, sort)
      .then(({ totalPages, totalElements, content }) => {
        setDataWrapper({
              totalPages,
              totalElements,
              content,
          })
        setLoading(false)
      })
      .catch((e) => errorAlert(e.message))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [search, page, rowsPerPage, sort, filters]);

  return (
    <List
      loading={loading}
      label="Wallets"
      subTitle="Here's your wallets details data"
      hideOptions={true}
      headers={[
        { id: 'id', label: 'Wallet ID', alignRight: false },
        { id: 'integratorId', label: 'Integrator', alignRight: false, onRender: async (integratorId) => {
          const integrator = await getIntegratorById(integratorId);
          return integrator.name;
        }},
        { id: 'beneficiaryId', label: 'Beneficiary', alignRight: false, onRender: async (integratorId) => {
          const beneficiary = await getBeneficiaryById(integratorId);
          const name = beneficiary.person ? (beneficiary.person.firstName + beneficiary.person.lastName) : beneficiary.business.name;
          return name;
        } },
        { id: 'provider', label: 'Provider', alignRight: false },
        { id: 'currencyId', label: 'Currency', alignRight: false, onRender: async (currencyId) => {
          const integrator = await getCurrencyById(currencyId);
          return integrator.name;
        }},
        { id: 'value', label: 'Value', alignRight: false}
      ]}
      hideAddButton={true}
      dataWrapper={dataWrapper}
      page={page}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={perPage => {
        setRowsPerPage(perPage)
      }}
      onPageChange={page => {
        setPage(page)
      }}
      onRequestSort={selectedSort => {
        sort[selectedSort.filter] = selectedSort;
        setSort({
          ...sort,
          [selectedSort.filter]: selectedSort
        })
      }}
      onSearch={(search) => {
        setSearch(search)
      }}
      filters={[
       
        {
          name: "Integrator",
          key: "integratorId",
          selected: filters.integratorId,
          onSelected: (selected) => {

            if(!selected) {
              setFilters({
                ...filters,
                integratorId: null
              })
              return 
            }
            setFilters({
              ...filters,
              integratorId: selected
            })
          },
          onSearch: async (s) => {
            const r = await listIntegrators("filterEq=" + s, 0, 10)
            return r.content;
          }
        },
        {
          name: "Beneficiary",
          key: "beneficiaryId",
          selected: filters.beneficiaryId,
          onSelected: (selected) => {

            if(!selected) {
              setFilters({
                ...filters,
                beneficiaryId: null
              })
              return 
            }
            setFilters({
              ...filters,
              beneficiaryId: selected
            })
          },
          onSearch: async (s) => {
            const r = await listBeneficiaries("filterEq=" + s, 0, 10)
            return r.content.map(c => {

              const name = ((c.person && c.person.firstName + " " + c.person.lastName) || 
                  (c.business.name))
              const id= c.id;
              return {
                id, 
                name
              }
            });
          }
        },
        {
          name: "Currency",
          key: "currencyId",
          selected: filters.currencyId,
          onSelected: (selected) => {

            if(!selected) {
              setFilters({
                ...filters,
                currencyId: null
              })
              return 
            }
            setFilters({
              ...filters,
              currencyId: selected
            })
          },
          onSearch: async (s) => {
            const r = await listCurrencies("filterEq=" + s, 0, 10)
            return r.content
          }
        }
      ]}
    />
  );
}

