import { useState, useEffect, useCallback } from 'react'
import List from '../../components/crud/list'
import { useNavigate } from 'react-router-dom'
import { errorAlert, successAlert } from '../../services/alertService'
import {
    getTransactionsThatAffectAnAccount
} from '../../services/transactionService'
import Page from '../../components/Page'
import { useParams } from 'react-router-dom'

// ----------------------------------------------------------------------

export default function TransactionList({ theme }) {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const { id } = useParams()
  console.log(id)

  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchData = useCallback(() => {
    getTransactionsThatAffectAnAccount(id, page, rowsPerPage)
      .then(({ totalPages, totalElements, content }) => {
    
        console.log(content)
        content = content.map(c => {

            return c
        })
        console.log(content)
        setDataWrapper({
          totalPages,
          totalElements,
          content,
        })
        setLoading(false)
      })
      .catch(e => errorAlert(e.message))
      .finally(() => setLoading(false))
  }, [search, page, rowsPerPage, sort])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Page className='main_content' title={'Transactions | Overview'}>
      <List
        loading={loading}
        label='Transactions'
        hideSearchFilter={true}
        headers={[
          { id: 'id', label: 'Id', alignRight: false },
          { id: 'currency', label: 'Currency', alignRight: false },
          { id: 'createdAt', label: 'CreatedAt', alignRight: false },
          { id: 'amount', label: 'Amount', alignRight: false },
          { id: 'Account type', label: 'Account type', alignRight: false, onRender: (owner)=> {

              if(!owner) {
                return "No owner"
              }
              if(owner.integratorId && owner.beneficiaryId) {
                return "Beneficiary owner"
              } else {
                return "Integrator owner"
              }
          } },
          { id: 'owner', label: 'Owner Integrator Id', alignRight: false, onRender: (owner)=> {

            if(!owner) {
              return ""
            }
            return owner.integratorId
        } },
        { id: 'owner', label: 'Owner Beneficiary Id', alignRight: false, onRender: (owner)=> {

          if(!owner) {
            return ""
          }
          return owner.beneficiaryId
      } },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
      />
    </Page>
  )
}
