import {
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import usePrevious from '../../hooks/usePrevious'

// Material UI
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'

// Components
import Page from '../../components/Page'
import { Form } from '../../components/form/Form'
import FormSubmitButton from '../../components/form/FormSubmitButton'
import Stepper from '../../components/Stepper'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import PageHeading from '../../components/ui/PageHeading'
import {
  FormProvider,
  FormContext,
} from '../../components/providers/FormProvider'

// Utils
import {
  getRoles,
  getIntegratorId,
} from '../../services/authenticationService'
import { addBeneficiary } from '../../services/beneficiaryService'
import { listIntegrators } from '../../services/integratorService'
import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import {
  createInitialDataForIndividualBeneficiary,
  createInitialDataForBusinessBeneficiary,
  getCreateBeneficiarySteps,
  mapIndividualRequest,
  mapBusinessRequest,
  getBeneficiaryDataFields,
  BENEFICIARY_USER_TYPES,
  BUSINESS_BENEFICIARY_CONTACT_FIELDS,
  BUSINESS_CONTACT_INITIAL_DATA,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// Styling
import { getCustomShadows } from '../../theme/shadows'

// ----------------------------------------------------------------------
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Styled Components
const StyledPage = styled(Page)(({ theme, sx }) => ({
  maxWidth: 600,
  margin: 0,
  ...sx,
  '.fieldset': {
    marginBottom: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.up('lg')]: {
    padding: 0,
  },
}))
const Header = styled('header')(({ theme }) => ({
  marginBottom: theme.spacing(6),
}))
const StyledForm = styled(Form)(({ theme }) => ({
  '.form_field': {
    marginBottom: theme.spacing(1.5),
  },
}))
const StyledPrimaryButton = styled(LoadingButton)(({ theme }) => {
  const {
    default: { icon, ...defaultButton },
    hover,
    pressed,
  } = theme.palette.buttons.primary
  const boxShadow = getCustomShadows({
    componentName: 'filterButtons',
  })

  return {
    ...defaultButton,
    height: 40,
    boxShadow,
    '&:hover': {
      ...hover,
      boxShadow,
    },
    '&:active': pressed,
  }
})
const FormFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

// Local Utils
function getCurrentStepFormFields({
  currentStepFields,
  formFieldsSchemas,
}) {
  return currentStepFields.reduce((fields, fieldKey) => {
    return {
      ...fields,
      [fieldKey]: formFieldsSchemas[fieldKey],
    }
  }, {})
}

function getFormFieldsSchemas({
  isAdmin,
  onBeneficiaryTypeSelect,
  profileType,
}) {
  let fields = getBeneficiaryDataFields(profileType)

  if (isAdmin) {
    fields.integratorId = {
      type: 'string',
      fieldType: 'select',
      readOnly: false,
      label: 'Integrator',
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      value: null,
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterEq=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content
        })
      },
    }
  }

  fields.type.onSelected = onBeneficiaryTypeSelect

  return fields
}

// Main
function StepButton({ onClick, children, ...otherProps }) {
  return (
    <StyledPrimaryButton
      color='primary'
      type='button'
      variant='contained'
      onClick={onClick}
      {...otherProps}>
      {children}
    </StyledPrimaryButton>
  )
}

function StepButtonGroup({
  steps,
  currentStepIndex,
  changeStepHandler,
}) {
  const nextStepHandler = () =>
    changeStepHandler(currentStepIndex + 1, true)
  const prevStepHandler = () =>
    changeStepHandler(currentStepIndex - 1, true)
  const buttons = []

  if (currentStepIndex > 0) {
    buttons.push(
      <StepButton
        key='previous_step'
        sx={{ marginRight: 'auto' }}
        onClick={prevStepHandler}>
        Prev Step
      </StepButton>,
    )
  }

  if (currentStepIndex < steps.length - 1) {
    buttons.push(
      <StepButton key='next_step' onClick={nextStepHandler}>
        Next Step
      </StepButton>,
    )
  }

  return buttons
}

function AddAnotherContactButton({ addBusinessContactHandler }) {
  const { formData, updateFormData } = useContext(FormContext)
  const addContact = () => {
    const { contacts, ...restOfFormData } = { ...formData }
    updateFormData({
      ...restOfFormData,
      contacts: [...contacts, BUSINESS_CONTACT_INITIAL_DATA],
    })

    addBusinessContactHandler()
  }

  return (
    <Button buttonType='primary' onClick={addContact}>
      Add Another Contact
    </Button>
  )
}

function MainPage({
  isIndividual,
  isAdmin,
  profileType,
  updateBeneficiaryTypeHandler,
}) {
  const prevProfileType = usePrevious(profileType)
  const { updateFormData, updateFieldsSchemas } =
    useContext(FormContext)
  const navigate = useNavigate()
  const goToListPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const [currentStep, setCurrentStep] = useState(0)
  const defaultData = {
    type: profileType,
    integratorId: isAdmin ? '' : getIntegratorId(),
  }
  const initialData = isIndividual
    ? createInitialDataForIndividualBeneficiary(defaultData)
    : createInitialDataForBusinessBeneficiary(defaultData)
  const onBeneficiaryTypeSelect = useCallback(
    selectedValue => updateBeneficiaryTypeHandler(selectedValue),
    [updateBeneficiaryTypeHandler],
  )
  const formFieldsSchemas = useMemo(
    () =>
      getFormFieldsSchemas({
        isAdmin,
        onBeneficiaryTypeSelect,
        profileType,
      }),
    [isAdmin, onBeneficiaryTypeSelect, profileType],
  )
  const [businessContacts, setBusinessContacts] = useState({
    contacts: { valueSchema: [] },
  })
  const steps = getCreateBeneficiarySteps({ profileType, isAdmin })
  const isLastStep = steps.length - 1 === currentStep
  const changeStep = (
    toStep,
    force = false,
    sequentialStep = false,
  ) => {
    if (sequentialStep && !force && toStep > currentStep) {
      return
    }

    if (toStep + 1 > steps.length) {
      return
    }

    setCurrentStep(toStep)
  }
  const addBusinessContact = () =>
    setBusinessContacts(({ contacts: { valueSchema, ...rest } }) => ({
      contacts: {
        ...rest,
        valueSchema: [
          ...valueSchema,
          BUSINESS_BENEFICIARY_CONTACT_FIELDS,
        ],
      },
    }))
  const stepFormFields = getCurrentStepFormFields({
    currentStepFields: steps[currentStep].fields,
    formFieldsSchemas,
  })

  useEffect(() => {
    updateFieldsSchemas(formFieldsSchemas)
  }, [formFieldsSchemas, updateFieldsSchemas])

  useEffect(() => {
    if (!isIndividual) {
      const {
        contacts: { valueSchema, ...restOfContactSchema },
      } = formFieldsSchemas
      setBusinessContacts({
        contacts: {
          ...restOfContactSchema,
          valueSchema: [valueSchema],
        },
      })
    }
  }, [formFieldsSchemas, isIndividual])

  useEffect(() => {
    if (prevProfileType !== profileType) {
      updateFormData(initialData)
    }
  }, [prevProfileType, profileType, initialData, updateFormData])

  return (
    <StyledPage
      steps={steps}
      className='main_content'
      title={'Create Beneficiary'}>
      <Header>
        <PageHeading gutterBottom>Create Beneficiary</PageHeading>

        <BackButton onClick={goToListPage} />
      </Header>
      {steps && (
        <Stepper
          sx={{ marginBottom: '32px' }}
          steps={steps}
          activeStep={currentStep}
          changeStep={step => changeStep(step)}
        />
      )}
      <StyledForm
        stepFormFields={
          isLastStep && !isIndividual
            ? businessContacts
            : stepFormFields
        }>
        <FormFooter>
          <Button buttonType='secondary' onClick={goToListPage}>
            Cancel
          </Button>
          <StepButtonGroup
            steps={steps}
            currentStepIndex={currentStep}
            changeStepHandler={changeStep}
          />
          {isLastStep && !isIndividual ? (
            <AddAnotherContactButton
              addBusinessContactHandler={addBusinessContact}
            />
          ) : null}

          {isLastStep ? (
            <FormSubmitButton submitButtonText='Create Beneficiary' />
          ) : null}
        </FormFooter>
      </StyledForm>
    </StyledPage>
  )
}

export default function BeneficiarySave() {
  const navigate = useNavigate()
  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1
  const [profileType, setProfileType] = useState(
    BENEFICIARY_USER_TYPES.INDIVIDUAL.value,
  )
  const updateBeneficiaryType = newType => setProfileType(newType)
  const isIndividual =
    profileType === BENEFICIARY_USER_TYPES.INDIVIDUAL.value
  const defaultData = {
    type: profileType,
    integratorId: isAdmin ? '' : getIntegratorId(),
  }
  const initialData = isIndividual
    ? createInitialDataForIndividualBeneficiary(defaultData)
    : createInitialDataForBusinessBeneficiary(defaultData)
  const onSuccess = beneficiaryCreated => {
    navigate(
      `${BENEFICIARIES_BASE_PATH}/${beneficiaryCreated.id}/documents?createdMessage=true`,
    )
    successAlert('Beneficiary created with success')
  }

  const saveData = data => {
    const request= isIndividual
      ? mapIndividualRequest(data)
      : mapBusinessRequest(data)

    return addBeneficiary(request)
  }

  return (
    <FormProvider
      onSuccess={onSuccess}
      initialFormData={initialData}
      submitAction={saveData}>
      <MainPage
        isAdmin={isAdmin}
        profileType={profileType}
        isIndividual={isIndividual}
        updateBeneficiaryTypeHandler={updateBeneficiaryType}
      />
    </FormProvider>
  )
}
