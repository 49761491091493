import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Page from '../../components/Page'
import Upsert from '../../components/crud/upsert'

// Services
import { addTeamMember } from '../../services/teamService'
import { getMe } from '../../services/authenticationService'
import { errorAlert, successAlert } from '../../services/alertService'

// Constants
import { SECTION_BASE_PATHS } from '../../utils/general'
import { TEAM_MEMBER_TYPES } from '../../utils/constants'

// ----------------------------------------------------------------------
const TEAM_BASE_PATH = SECTION_BASE_PATHS.team
const { INTEGRATOR, INTEGRATOR_USER } = TEAM_MEMBER_TYPES

const DEFAULT_FORM_STATE = {
  email: '',
  firstName: '',
  lastName: '',
  type: '',
}

export default function TeamMemberSave() {
  const navigate = useNavigate()
  const [data, setData] = useState(DEFAULT_FORM_STATE)
  const [loading, setLoading] = useState(false)
  const [integratorId, setIntegratorId] = useState(null)
  const goToListPage = () => navigate(`${TEAM_BASE_PATH}/list`)

  const saveData = data => {
    setLoading(true)

    return addTeamMember({ ...data, integratorId })
      .then(newTeamMember => {
        setLoading(false)
        navigate(`${TEAM_BASE_PATH}/list?createdMessage=true`)
        successAlert('New team member was created successfully!')
      })
      .catch(e => {
        setLoading(false)
        errorAlert(e.message)
      })
  }

  useEffect(() => {
    if (!integratorId) {
      async function getIntegratorId() {
        const { integratorId } = await getMe()

        setIntegratorId(integratorId)
      }

      getIntegratorId()
    }
  }, [integratorId])
  const FORM_FIELDS = {
    email: {
      type: 'string',
      fieldType: 'text',
      label: 'Email',
      constraints: [
        {
          type: 'min',
          min: 2,
          message: 'Too Short!',
        },
        {
          type: 'max',
          max: 200,
          message: 'Email is to big',
        },
        {
          type: 'required',
          message: 'Email is required',
        },
        {
          type: 'email',
          message: 'Invalid email',
        },
      ],
    },
    firstName: {
      type: 'string',
      fieldType: 'text',
      label: 'First Name',
      constraints: [
        {
          type: 'required',
          message: 'First Name is required',
        },
      ],
    },
    lastName: {
      type: 'string',
      fieldType: 'text',
      label: 'Last Name',
      constraints: [
        {
          type: 'required',
          message: 'Last Name is required',
        },
      ],
    },
    type: {
      type: 'string',
      fieldType: 'select',
      label: 'Role',
      constraints: [
        {
          type: 'required',
          message: 'Role is required',
        },
      ],
      onSelected: role => {
        setData({
          ...data,
          type: role,
        })
      },
      options: [
        { id: INTEGRATOR_USER.key, name: INTEGRATOR_USER.displayValue },
        { id: INTEGRATOR.key, name: INTEGRATOR.displayValue },
      ],
    },
  }
  return (
    <Page className='main_content' title={'Team | Add Member'}>
      <Upsert
        label='Add Team Member'
        loading={loading}
        isLoading={loaded => setLoading(loaded)}
        fields={FORM_FIELDS}
        initialValue={DEFAULT_FORM_STATE}
        onSave={data => saveData(data)}
        backAction={goToListPage}
        goToListPage={goToListPage}
      />
    </Page>
  )
}
