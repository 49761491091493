import { createContext, useState } from 'react'

// Utils
import { checkValue, checkIndividualValue } from '../../utils/form'
import {errorAlert} from "../../services/alertService";

// Main
export const FormContext = createContext()

export function FormProvider({
  children,
  initialFormData = {},
  formFields = {},
  submitAction,
  onSuccess = () => {},
}) {
  const [formData, setFormData] = useState(initialFormData)
  const [messages, setMessages] = useState({})
  const [loading, setLoading] = useState(false)
  const [fieldsSchemas, setFieldsSchemas] = useState(formFields)
  const updateFormData = newFormData => setFormData(newFormData)
  const updateFieldsSchemas = newFieldsSchemas =>
    setFieldsSchemas(newFieldsSchemas)
  const formFieldErrorHandling = ({ hasErrors, errors, key }) => {
    const messagesState = {
      ...messages,
      [key]: null,
    }
    if (hasErrors) {
      messagesState[key] = errors[0]
    }

    setMessages(messagesState)
  }
  const handleFormChange = ({ key, value }) => {
    checkIndividualValue({
      key,
      value,
      callback: formFieldErrorHandling,
    })

    setFormData(data => ({
      ...data,
      [key]: value,
    }))
  }
  const resetForm = () => setFormData({})
  const fetchHandler = data => {
    setLoading(true)

    return submitAction(data)
      .then(response => {
        setLoading(false)
        onSuccess(response)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        errorAlert(error.message)
      })
  }
  const handleSubmit = async e => {
    e.preventDefault()

    const request = {
      ...formData,
    }
    const validation = await checkValue(fieldsSchemas, request)

    if (!validation.ok) {
      const messagesUpdate = { ...messages }
      validation.errors.forEach(err => {
        messagesUpdate[err.field] = err.message
        errorAlert(`Invalid value for \n field: ${err.field}. Error: ${err.message}`)
      })
      setMessages(messagesUpdate)
    } else {
      await fetchHandler(request)
      setMessages({})
    }
  }

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        updateFieldsSchemas,
        handleFormChange,
        resetForm,
        handleSubmit,
        errorMessages: messages,
        loading,
        fieldsSchemas,
      }}>
      {children}
    </FormContext.Provider>
  )
}
