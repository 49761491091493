import { useMemo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { getRoles } from '../../services/authenticationService'

import { SECTION_BASE_PATHS } from '../../utils/general'
import { fCurrency } from '../../utils/formatNumber'

import { styled } from '@mui/material/styles'
import PickDate from '../../components/PickDate'


// Services
import { getStatements } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'

// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Loading from '../../components/Loading'
import List from '../../components/crud/list'
import { getCustomShadows } from '../../theme/shadows'

// COnstants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Utils - maybe move into utils/general.js
const formatDate = (d) => {
  const date = new Date(d)

  return format(date, 'd MMM, yyyy')
}
// Styling
BeneficiaryDetailPage.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
}

function getFirstDayOfMonth() {
  var date = new Date();
  date.setHours(0, 0, 0); 
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getLastDayOfMonth() {
  var date = new Date();
  date.setHours(23, 59, 59); 
  return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

export default function BeneficiaryDetailPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [from, setFrom] = useState(getFirstDayOfMonth())
  const [to, setTo] = useState(getLastDayOfMonth())
  const [sort, setSort] = useState({})
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const Label = styled((props) => <label {...props} />)(({ theme }) => {
    return {
      ...theme.typography.body1,
      color: theme.palette.blue.primary,
    }
  })

  const FormFieldWrapper = styled('div')(({ theme }) => {
    return {
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'space-between',
      'backgroundColor': theme.palette.primary.light,
      'borderRadius': 8,
      'padding': theme.spacing(1, 1.5),
      'boxShadow': getCustomShadows({ componentName: 'formField' }),
      // Specific for the Autocomplete/Select component
      '& .MuiAutocomplete-root': {
        flexGrow: 1,
        maxWidth: 300,
      },
      '& .MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-root': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: 'none',
      },
      '.MuiAutocomplete-root[aria-expanded="true"] .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.formFields.borderColor}`,
      },
    }
  })

  const PickDateFormField = ({ fieldKey, label, data, handleChange }) => (
    <FormFieldWrapper className='form_field'>
      <Label>{label}</Label>
      <PickDate key={fieldKey} data={data} onChange={(value) => handleChange(fieldKey, value)} />
    </FormFieldWrapper>
  )


  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () => {
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  }
  const goToStatementViewPage = (statement) => {
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements/${statement.id}/view`)
  }
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)

  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getStatements(id, page, rowsPerPage, from, to)
      .then(balance => {
        setDataWrapper(balance)
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  }, [setDataWrapper, setLoading, id, from, to, page, rowsPerPage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isEmpty(dataWrapper)) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'Statements',
        value: 'statements',
        onClick: goToStatements,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'statements',
  }

  const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1

  if(isAdmin) {
    tabsData.tabs.push({
      label: 'AML Check',
      value: 'amlcheck',
      onClick: goToAmlCheck,
    })
  }

  return (
    <Page className='main_content' title={'Beneficiary | View'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary | Basic Info`}
          tabsData={tabsData}
        />

        <section>

          <PickDateFormField
            data ={from}
            fieldKey = {"from"} 
            label= {"from"} 
            handleChange = {(field, d) => {
              setFrom(d)
            }}
          />

          <PickDateFormField
            data ={to}
            fieldKey = {"to"} 
            label= {"to"} 
            handleChange = {(field, d) => {
              setTo(d)
            }}
          />
          
          <List
            loading={loading}
            goToAddPage={() =>
              navigate(`${BENEFICIARIES_BASE_PATH}/save`)
            }
            goToEditPage={id => () =>
              navigate(`${BENEFICIARIES_BASE_PATH}/${id}/edit`)}
            headers={[
              { id: 'id', label: 'Database ID', alignRight: false },
              {
                id: 'amount',
                label: 'Amount',
                alignRight: false,
                onRender: (amount, row) => {
                  return fCurrency(amount.value)
                },
              },
              { id: 'correlationId', label: 'Correlation ID', alignRight: false },
              { id: 'type', label: 'Statement Type', alignRight: false },
              {
                id: 'operation', 
                label: 'Operation Type', 
                alignRight: false,
                onRender: (amount, row) => {
                  return row.data.type
                },

              },
              { id: 'status', label: 'Statement Status', alignRight: false },
              { id: 'createdDate', label: 'Date', alignRight: false },
            ]}
            hideAddButton={true}
            hideSearchFilter={true}
            dataWrapper={dataWrapper}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={perPage => {
              setRowsPerPage(perPage)
            }}
            onPageChange={page => {
              console.log(page)
              setPage(page)
            }}
            onRequestSort={selectedSort => {
              sort[selectedSort.filter] = selectedSort
              setSort({
                ...sort,
                [selectedSort.filter]: selectedSort,
              })
            }}
            onTableRowClick={goToStatementViewPage}
          />
        </section>
      </Loading>
    </Page>
  )
}
