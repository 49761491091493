import axios from '../config/axiosConfig';

export const getAccountsAffectedByTransaction = async (transactionId) => {

    let url = `/v1/admin/transactions/${transactionId}/accounts`;
    const response = await axios.get(url)
    return response.data
}

export const getTransactionsThatAffectAnAccount = async (accountId, page, limit) => {

    let url = `/v1/admin/accounts/${accountId}/transactions`;
    const response = await axios.get(url)
    return response.data
}