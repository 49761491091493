import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

// Material
import { styled } from '@mui/material/styles'

// Components
import { ReactComponent as CalizaLogo } from '../images/caliza-white-logo.svg'

// ----------------------------------------------------------------------

const StyledLogo = styled(CalizaLogo)(({ theme }) => ({
  width: 122,
}))

Logo.propTypes = {
  sx: PropTypes.object,
  asLink: PropTypes.bool,
}

Logo.defaultProps = {
  asLink: true,
}

export default function Logo({ asLink, sx, ...otherProps }) {
  if (asLink) {
    return (
      <NavLink to='/' className='logo_link'>
        <StyledLogo className='caliza_logo logo' {...otherProps} />
      </NavLink>
    )
  }

  return (
    <StyledLogo className='caliza_logo logo' sx={sx} {...otherProps} />
  )
}
