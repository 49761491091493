
import axios from '../config/axiosConfig';

import { createSortUrl, createFilters } from './urlBuilder'

export const listIntegrators = async (search, page, limit, sort = {}) => {
    const url = `${process.env.REACT_APP_CORE_API_URL}/v1/integrators?${search}&page=${page}&size=${limit}${createSortUrl(sort)}`;
    const response = await axios.get(url)

    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getIntegratorById = async (id) => {
    const response = await axios.get(`/v1/integrators/${id}`);
    return response.data;
}

export const getIntegratorByToken = async () => {
    const response = await axios.get(`/v1/integrators/me`);
    return response.data;
}

export const addIntegrator = async (integrator) => {
    const response = await axios.post(`/v1/integrators`, integrator);
    return response.data;
}

export const signup = async (integrator) => {
    const response = await axios.post(`/v1/integrators/signup`, integrator);
    return response.data;
}

export const updateIntegrator = async (integrator) => {
    const response = await axios.put(`/v1/integrators/${integrator.id}`, integrator);
    return response.data;
}

export const updateMe = async (integrator) => {
    const response = await axios.put(`/v1/integrators/me`, integrator);
    return response.data;
}

export const deleteIntegrator = async(id) => {
    const response = await axios.delete(`/v1/integrators/${id}`);
    return response.data;
}