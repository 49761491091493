import { Icon } from '@iconify/react'
import ActionButton from './ActionButton'

export default function BackButton({ onClick, ...rest }) {
  return (
    <ActionButton
      variant='contained'
      onClick={onClick}
      startIcon={<Icon icon='ep:back' className='back_icon icon' />}
      {...rest}>
      Back
    </ActionButton>
  )
}
