import axios from '../config/axiosConfig'

const listKyx = async (
  beneficiaryId
) => {
  const url = `/v1/kyx/${beneficiaryId}`

  const response = await axios.get(url)

  return response.data
}

const updateStatus = async(kyxId, workflowId, status, reason) => {
  const url = `/v1/admin/kyx/${kyxId}/steps`

  await axios.put(url, {
    workflowId, status, reason
  })
}

export { listKyx, updateStatus}