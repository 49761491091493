import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listRiskScoreVersions,
  // deleteRiskScoreVersion,
} from '../../services/riskScoreService'

// Components
import ActiveLabel from '../../components/ActiveLabel'
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'

// Constants
const RISK_SCORE_VERSIONS_BASE_PATH = SECTION_BASE_PATHS.riskScoreVersions

let RISK_SCORE_VERSIONS_FILTER_SET = [

]

// Main Component
export default function RiskScoreVersionList() {
  const navigate = useNavigate()
  const [appliedFiltersFromQueryParams, setSearchParams] =
      useQueryParam(FILTER_QUERY_PARAM_KEY)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const goToViewPage = ({ id }) => navigate(`${RISK_SCORE_VERSIONS_BASE_PATH}/${id}/view`);

  const fetchFilteredData = useCallback(
      async () => {
        try {
          setLoading(true);
          const data = await listRiskScoreVersions(page, rowsPerPage,
              sort?.field ?? 'version');
          setDataWrapper({
            totalPages: data.totalPages,
            totalElements: data.totalElements,
            content: data.content,
          });
        } catch (error) {
          errorAlert('Failed to fetch risk score versions');
        } finally {
          setLoading(false);
        }
      },
      [page, rowsPerPage, sort]
  );

  useEffect(() => {
    fetchFilteredData();
  }, [fetchFilteredData]);

  const filterSets = useMemo(() => RISK_SCORE_VERSIONS_FILTER_SET, [])

  return (
      <Page className='main_content' title={'Risk Score Versions | Overview'}>
        <List
            loading={loading}
            label='Risk Score Versions'
            goToAddPage={() =>
                navigate(`${RISK_SCORE_VERSIONS_BASE_PATH}/save`)
            }
            onTableRowClick={goToViewPage}
            goToEditPage={null}
            onDelete={null}
            headers={[
              { id: 'id', label: 'ID', alignRight: false },
              {
                id: 'version',
                label: 'Version',
                alignRight: false,
              },
              {
                id: 'active',
                label: 'Active Status',
                alignRight: false,
                onRender: value => <ActiveLabel isActive={value} />,
              },
              {
                id: 'lowRiskThreshold',
                label: 'Low Risk Threshold',
                alignRight: false,
              },
              {
                id: 'mediumRiskThreshold',
                label: 'Medium Risk Threshold',
                alignRight: false,
              },
              {
                id: 'createdBy',
                label: 'Created By',
                alignRight: false,
              },
              {
                id: 'createdDate',
                label: 'Created Date',
                alignRight: false,
              },
              {
                id: 'updatedBy',
                label: 'Updated By',
                alignRight: false,
              },
              {
                id: 'updatedDate',
                label: 'Update Date',
                alignRight: false,
              },
            ]}
            dataWrapper={dataWrapper}
            page={page}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={perPage => {
              setRowsPerPage(perPage)
            }}
            onPageChange={newPage => {
              setPage(newPage)
            }}
            onRequestSort={selectedSort => {
              const isAsc = sort.field === selectedSort && sort.direction === 'asc';
              setSort({
                field: selectedSort,
                direction: isAsc ? 'desc' : 'asc',
              });
            }}
            onSearch={setSearch}
            filters={{
              filterSets: filterSets,
            }}
        />
      </Page>
  )
}
