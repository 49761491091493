import clsx from 'clsx'
import PropTypes from 'prop-types'
import Button from './Button'

ActionButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
}

export default function ActionButton({ children, className, ...rest }) {
  return (
    <Button
      className={clsx('action_button', className)}
      buttonType='secondary'
      {...rest}>
      {children}
    </Button>
  )
}
