import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Icon } from '@iconify/react'
import palette from '../theme/palette'
import typography from '../theme/typography'

ActiveLabel.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export default function ActiveLabel({ isActive }) {
  return (
    <Typography
      color={palette.blue.primary}
      variant='body'
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: typography.fontWeightRegular,
      }}
      noWrap>
      <Icon
        icon='ci:dot-05-xl'
        width={24}
        className='icon'
        color={isActive ? palette.success.active : palette.error.inactive}
      />
      {isActive ? 'Active' : 'Inactive'}
    </Typography>
  )
}
