// ----------------------------------------------------------------------

export default function Backdrop(theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(11, 34, 51, 0.45)',
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
          },
        },
      },
    },
  }
}
