import axios from '../config/axiosConfig';

export const getAccountsTree = async (nodeId, integratorId, beneficiaryId) => {

    let url = `/v1/admin/accounts/tree?`;

    if(nodeId) {
        url = `${url}parentAccountId=${nodeId}`
    }
    if(integratorId !== "" && integratorId != null) {
        url =  `${url}&integratorId=${integratorId}`
    }
    if(beneficiaryId !== "" && integratorId != null) {
        url =  `${url}&beneficiaryId=${beneficiaryId}`
    }

    const response = await axios.get(url)
    return response.data
}

export const calculateNodeBalance = async (nodeId, integratorId, beneficiaryId) => {

    let url = `/v1/admin/accounts/tree/${nodeId}/totals?`;

    if(integratorId !== "" && integratorId != null) {
        url =  `${url}&integratorId=${integratorId}`
    }
    if(beneficiaryId !== "" && integratorId != null) {
        url =  `${url}&beneficiaryId=${beneficiaryId}`
    }

    const response = await axios.get(url)
    return response.data
}

export const getAccountTView = async (integratorId, beneficiaryId) => {

    let url = `/v1/admin/accounts/t-account?`;

    if(integratorId !== "" && integratorId != null) {
        url =  `${url}&integratorId=${integratorId}`
    }
    if(beneficiaryId !== "" && beneficiaryId != null) {
        url =  `${url}&beneficiaryId=${beneficiaryId}`
    }
    console.log(url)

    const response = await axios.get(url)
    return response.data
}

export const listAccounts = async (integratorId, beneficiaryId, page, rowsPerPage) => {

    let url = `/v1/admin/accounts`;

    if(integratorId !== "" && integratorId != null) {
        url =  `${url}&integratorId=${integratorId}`
    }
    if(beneficiaryId !== "" && beneficiaryId != null) {
        url =  `${url}&beneficiaryId=${beneficiaryId}`
    }
    url += "?page=" + page + "&size=" + rowsPerPage

    const response = await axios.get(url)
    return response.data
}