import { useParams } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

// material
import Edit from '../../components/crud/upsert'
import { getTransferById, getTransferStatus } from '../../services/transferService'
import { listCurrencies } from '../../services/currencyService'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'
import { errorAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import { SECTION_BASE_PATHS } from '../../utils/general'

// ----------------------------------------------------------------------
const TRANSFERS_BASE_PATH = SECTION_BASE_PATHS.transfers

export default function TransferView() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { id } = useParams()
  const [data, setData] = useState({})
  const goToListPage = () => navigate(`${TRANSFERS_BASE_PATH}/list`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getTransferById(id)
      .then(data => {

        return {
          ...data,
          amount: data.to.value,
          currencyId: data.to.currencyId,
          errorMessage: "None"
        }
        
      })
      .then(async data => {

        if(data.status == "FAILED") {
          const status = await getTransferStatus(data.id)
          data.errorMessage = status.message
          setData(data)
          setLoading(false)
        }

        setData(data)
        setLoading(false)
      })
      .catch(e => {
        errorAlert(e.message)
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fields = {
    id: {
      type: 'string',
      fieldType: 'text',
      label: 'Transfer Id',
      readOnly: true,
    },
    beneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = 'filterEq=' + search

        if (!isMongoId(search)) {
          query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`
        }
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    integratorId: {
      type: 'string',
      fieldType: 'select',
      label: 'Integrator',
      readOnly: true,
      constraints: [],
      onSearch: async search => {
        let query = 'filterEq=' + search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search}`
        }
        return listIntegrators(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name: v.name,
            }
          })
        })
      },
    },
    type: {
      type: 'string',
      fieldType: 'text',
      label: 'Transfer Type',
      readOnly: true,
      constraints: [],
    },
    amount: {
      type: 'string',
      fieldType: 'number',
      label: 'Total amount',
      readOnly: true,
      constraints: [],
    },
    currencyId: {
      type: 'string',
      fieldType: 'select',
      label: 'Currency',
      constraints: [
        {
          type: 'required',
          message: 'Currency is required',
        },
      ],
      readOnly: true,
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}&filterEq=type:FIAT`
        }
        return listCurrencies(query, 0, 5, '').then(data => {
          return data.content
        })
      },
    },
    errorMessage: {
      type: 'string',
      fieldType: 'text',
      label: 'Error Message',
      readOnly: true,
      constraints: [],
    },
  }

  return (
    <>
      {!loading && (
        <Edit
          label='View Transfers'
          initialValue={data}
          loading={loading}
          isLoading={loaded => setLoading(loaded)}
          fields={fields}
          hideSubmitButton={true}
          onSave={data => {}}
          goToListPage={goToListPage}
          backAction={goToListPage}
        />
      )}
    </>
  )
}
