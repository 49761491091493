import { useState } from 'react'
import PropTypes from 'prop-types'

// Material
import { styled } from '@mui/material/styles'
import { Box, Tab, Tabs } from '@mui/material'

// Components
import BackButton from './BackButton'
import PageHeading from '../components/ui/PageHeading'

const StyledTabbar = styled(Tabs)(({ theme }) => ({
  '.MuiButtonBase-root': {
    ...theme.typography.body1,
    color: theme.palette.grey.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    justifyContet: 'flex-end',
    lineHeight: 1,
    padding: theme.spacing(1.5),
  },
  '.MuiButtonBase-root.Mui-selected': {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.blue.secondary,
    padding: theme.spacing(0, 0, 1.5, 0),
    lineHeight: 1.5,
    minWidth: 0,
    '&:not(:last-child)': {
      marginRight: theme.spacing(1.5),
    },
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1.5),
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.blue.secondary,
  },
}))

const StyledBackButton = styled(BackButton)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

function Tabbar({ tabs, ariaLabel, selectedTab }) {
  const [value, setValue] = useState(selectedTab)
  const handleChange = (evt, val) => setValue(val)

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <StyledTabbar
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel}>
        {tabs.map(tab => (
          <Tab key={tab.value} {...tab} />
        ))}
      </StyledTabbar>
    </Box>
  )
}

PageHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  tabsData: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ).isRequired,
    selectedTab: PropTypes.any.isRequired,
  }),
  children: PropTypes.any,
  backButtonAction: PropTypes.func,
}

export default function PageHeader({
  heading,
  tabsData = null,
  children,
  backButtonAction = null,
  ...rest
}) {
  return (
    <header {...rest}>
      <PageHeading gutterBottom>{heading}</PageHeading>

      {backButtonAction ? (
        <StyledBackButton onClick={backButtonAction} />
      ) : null}

      {tabsData ? <Tabbar {...tabsData} /> : null}

      {children}
    </header>
  )
}
