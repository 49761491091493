import axios from '../config/axiosConfig';

export const listPendingDeposits = async (search, page, limit) => {
    const url = `${process.env.REACT_APP_CORE_API_URL}/v1/admin/deposits?query=${search}&page=${page}&size=${limit}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}

export const getPendingDeposit = async (id) => {
    const url = `${process.env.REACT_APP_CORE_API_URL}/v1/admin/deposits/${id}`;
    const response = await axios.get(url)
    return response.data
}

export const depositRefunded = async (id) => {
    const url = `${process.env.REACT_APP_CORE_API_URL}/v1/admin/deposits/${id}/refunded`;
    const response = await axios.put(url)
    return response.data
}