import ReactJson from 'react-json-view';
import { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { SECTION_BASE_PATHS } from '../../utils/general';
import { errorAlert } from '../../services/alertService';
import { getRoles } from '../../services/authenticationService';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import View from '../../components/crud/view';
import { getRiskScoreSnapshotById } from "../../services/riskScoreService";

const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries;

const formatDate = (d) => {
    const date = new Date(d);
    return format(date, 'd MMM, yyyy');
};

const ManageAccountButton = styled(Button)(({ theme }) => {
    return {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightRegular,
        padding: theme.spacing(2),
        height: 'auto',
    };
});

RiskDetailsPage.propTypes = {
    data: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
    }),
};

export default function RiskDetailsPage(props) {
    const navigate = useNavigate();
    const { id, riskScoreSnapshotId } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`);
    const goToEditPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/edit`);
    const goToViewPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`);
    const goToTransfersPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`);
    const goToPaymentsPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`);
    const goToDocumentsPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`);
    const goToBalance = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`);
    const goToStatements = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`);
    const goToAmlCheck = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`);
    const goToKyx = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`);
    const goToRiskDDetails = () => navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details/${riskScoreSnapshotId}`);

    const fetchData = useCallback(() => {
        setLoading(true);
        getRiskScoreSnapshotById(riskScoreSnapshotId)
            .then(riskScoreSnapshot => {
                console.log(riskScoreSnapshot)
                setData(riskScoreSnapshot);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                errorAlert('Error has occurred while fetching beneficiary risk score info');
                setLoading(false);
            });
    }, [setData, setLoading, riskScoreSnapshotId]);

    const getRiskScoreColor = () => {
        if (data.riskRating === 'LOW') return 'green';
        if (data.riskRating === 'MEDIUM') return '#eecc1a';
        return 'red';
    };

    const fields = useMemo(() => {
        const riskScoreColor = getRiskScoreColor(data.riskScore);

        return [
            {
                label: {
                    displayValue: 'Risk Score',
                },
                value: {
                    displayValue: data?.score,
                    style: { color: riskScoreColor },
                },
            },
            {
                label: {
                    displayValue: 'Risk Rating',
                },
                value: {
                    displayValue: data?.riskRating || '',
                    style: { color: riskScoreColor },
                },
            },
            {
                label: {
                    displayValue: 'Risk Score Version',
                },
                value: {
                    displayValue: data?.scoreVersion || '',
                },
            },
            {
                label: {
                    displayValue: 'Calculated At',
                },
                value: {
                    displayValue: data?.createdDate || '',
                },
            },
        ];
    }, [data]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (isEmpty(data)) {
        return null;
    }

    const tabsData = {
        tabs: [
            {
                label: 'Basic Info',
                value: 'view',
                onClick: goToViewPage
            },
            {
                label: 'Transfers',
                value: 'transfers',
                onClick: goToTransfersPage,
            },
            {
                label: 'Payments',
                value: 'payments',
                onClick: goToPaymentsPage,
            },
            {
                label: 'Documents',
                value: 'documents',
                onClick: goToDocumentsPage,
            },
            {
                label: 'Balance',
                value: 'balance',
                onClick: goToBalance,
            },
            {
                label: 'KYX',
                value: 'kyx',
                onClick: goToKyx,
            },
            {
                label: 'Risk Details',
                value: 'risk',
                onClick: goToRiskDDetails,
            }
        ],
        selectedTab: 'risk',
    };

    const isAdmin = getRoles().indexOf('ROLE_ADMIN') > -1;

    if (isAdmin) {
        tabsData.tabs.push({
            label: 'AML Check',
            value: 'amlcheck',
            onClick: goToAmlCheck,
        });
    }

    return (
        <Page className='main_content' title={'Beneficiary | Risk Details'}>
            <Loading loading={loading}>
                <PageHeader
                    style={{ marginBottom: 32 }}
                    backButtonAction={goToListPage}
                    heading={`Beneficiary - Risk Details`}
                    tabsData={tabsData}
                />

                <section>
                    <View fields={fields} />
                    <section>
                        <text>Risk Score Calculation Details:</text>
                        <ReactJson src={data.snapshot} theme="harmonic" collapsed={false} />
                    </section>
                </section>
            </Loading>
        </Page>
    );
}
