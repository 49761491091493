import { useMemo, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import clsx from 'clsx'

// Utils
import { SECTION_BASE_PATHS } from '../../utils/general'
import { convertFilterObjectToString } from '../../utils/filters'
import { useQueryParam } from '../../hooks/useQueryParam'

// Services
import { errorAlert, successAlert } from '../../services/alertService'
import {
  listBeneficiaries,
  deleteBeneficiary,
} from '../../services/beneficiaryService'
// import { listIntegrators } from '../../services/integratorService'
// import { getRoles } from '../../services/authenticationService'

// Components
import StatusPill from '../../components/ui/StatusPill'
import ActiveLabel from '../../components/ActiveLabel'
import Page from '../../components/Page'
import List from '../../components/crud/list'
import { FILTER_QUERY_PARAM_KEY } from '../../components/ui/Filter/Filter'
import { Icon } from '@iconify/react'

// Constants
import { KYC_STATUS_MAP } from '../../utils/beneficiaries'
const USER_TYPE = {
  INDIVIDUAL: 'Individual',
  BUSINESS: 'Business',
}
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

let BENEFICIARIES_FILTER_SET = [
  {
    title: 'Filters',
    filterSetKey: 'filter',
    iconType: 'filter',
    toggleButtonLabel: 'Filter',
    acceptsMultipleFilters: true,
    filters: [
      {
        label: 'Active',
        key: 'active',
        filterType: 'checkbox',
        acceptsMultipleValues: true,
        options: [
          { key: 'active', value: 'active', displayValue: 'Active' },
          {
            key: 'inactive',
            value: 'inactive',
            displayValue: 'Inactive',
          },
        ],
      },
      {
        label: 'Value',
        key: 'value',
        filterType: 'checkbox',
        acceptsMultipleValues: true,
        options: [
          { key: '0-100', value: '0-100', displayValue: '$0-100' },
          {
            key: '101-500',
            value: '101-500',
            displayValue: '$101-500',
          },
          { key: '500+', value: '500+', displayValue: '$500+' },
        ],
      },
      {
        label: 'Status',
        key: 'status',
        filterType: 'checkbox',
        acceptsMultipleValues: true,
        options: [
          {
            key: 'complete',
            value: 'complete',
            displayValue: 'Complete',
          },
          {
            key: 'processing',
            value: 'processing',
            displayValue: 'Processing',
          },
          { key: 'failed', value: 'failed', displayValue: 'Failed' },
        ],
      },
    ],
  },
  {
    title: 'Sort by:',
    filterSetKey: 'sort',
    iconType: 'sort',
    toggleButtonLabel: 'Sort',
    acceptsMultipleFilters: false,
    filters: [
      {
        label: 'Alphabetical',
        key: 'alphabetical',
        filterType: 'radio',
        acceptsMultipleValues: false,
        options: [
          { key: 'asc', value: 'asc', displayValue: 'A-Z' },
          { key: 'desc', value: 'desc', displayValue: 'Z-A' },
        ],
      },
      {
        label: 'Amount',
        key: 'amount',
        filterType: 'radio',
        acceptsMultipleValues: false,
        options: [
          {
            key: 'desc',
            value: 'desc',
            displayValue: 'High - Low',
          },
          {
            key: 'asc',
            value: 'asc',
            displayValue: 'Low - High',
          },
        ],
      },
      {
        label: 'Recent',
        key: 'recent',
        filterType: 'radio',
        acceptsMultipleValues: false,
        options: [
          {
            key: 'most',
            value: 'most',
            displayValue: 'Most',
          },
          {
            key: 'least',
            value: 'least',
            displayValue: 'Least',
          },
        ],
      },
    ],
  },
  {
    title: 'Date:',
    filterSetKey: 'daterange',
    iconType: 'date',
    toggleButtonLabel: 'Date',
    acceptsMultipleFilters: true,
    filters: [
      {
        label: 'Start Date',
        key: 'startDate',
        filterType: 'daterange',
        acceptsMultipleValues: false,
        options: [
          {
            key: 'startDate',
            value: null,
            displayValue: 'Start Date',
          },
          { key: 'endDate', value: null, displayValue: 'End Date' },
        ],
      },
      {
        label: 'End Date',
        key: 'endDate',
        filterType: 'daterange',
        acceptsMultipleValues: false,
        options: [
          { key: 'endDate', value: null, displayValue: 'End Date' },
        ],
      },
    ],
  },
]

// Main
export default function BeneficiaryList() {
  const navigate = useNavigate()
  const [appliedFiltersFromQueryParams, setSearchParams] =
    useQueryParam(FILTER_QUERY_PARAM_KEY)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const updateSearchFilter = searchVal => {
    setSearch(searchVal)
    setSearchParams(
      {
        'person.firstName': searchVal,
        'person.lastName': searchVal,
        'business.name': searchVal,
      },
      { replace: true },
    )
  }
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sort, setSort] = useState({})
  // const isAdmin = useMemo(() => getRoles().indexOf('ROLE_ADMIN') > -1, [])
  const goToViewPage = ({ id }) =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const [dataWrapper, setDataWrapper] = useState({
    totalPages: 0,
    totalElements: 0,
    content: [],
  })

  const fetchFilteredData = useCallback(
    appliedFilters => {
      const filterStr = !isEmpty(appliedFilters)
        ? `filterEq=${convertFilterObjectToString({
            filters: appliedFilters,
          })}&`
        : ''
      const filter = `${filterStr}filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`

      listBeneficiaries(
        filter,
        page,
        rowsPerPage,
        sort,
        appliedFilters,
      )
        .then(({ totalPages, totalElements, content }) => {
          setDataWrapper({
            totalPages,
            totalElements,
            content,
          })
          setLoading(false)
        })
        .catch(e => errorAlert(e.message))
        .finally(() => setLoading(false))
    },
    [page, rowsPerPage, sort, search],
  )

  // This is for the initial page load and search
  useEffect(() => {
    fetchFilteredData(appliedFiltersFromQueryParams)
  }, [
    appliedFiltersFromQueryParams,
    fetchFilteredData,
    search,
    page,
    rowsPerPage,
    sort,
  ])

  // if (isAdmin) {
  //   BENEFICIARIES_FILTER_SET = [
  //     ...BENEFICIARIES_FILTER_SET,
  //     {
  //       title: 'Integrator',
  //       filterSetKey: 'integratorId',
  //       iconType: 'filter',
  //       toggleButtonLabel: 'Integrator',
  //       filters: [
  //         {
  //           label: 'Alphabetical',
  //           key: 'alphabetical',
  //           filterType: 'autocomplete',
  //           defaultSelected: null,
  //           onSearch: async s => {
  //             const r = await listIntegrators('filterEq=' + s, 0, 10)
  //             return r.content
  //           },
  //         },
  //       ],
  //     },
  //   ]
  // }

  const filterSets = useMemo(() => BENEFICIARIES_FILTER_SET, [])

  return (
    <Page className='main_content' title={'Beneficiaries | Overview'}>
      <List
        loading={loading}
        label='Beneficiaries'
        goToAddPage={() =>
          navigate(`${BENEFICIARIES_BASE_PATH}/save`)
        }
        goToEditPage={id => () =>
          navigate(`${BENEFICIARIES_BASE_PATH}/${id}/edit`)}
        onDelete={id => {
          setLoading(true)
          deleteBeneficiary(id)
            .then(() => {
              fetchFilteredData()
              successAlert('Beneficiary deleted with success')
            })
            .catch(() =>
              errorAlert(
                'Error has occurred while fetching beneficiaries',
              ),
            )
            .finally(() => setLoading(false))
        }}
        headers={[
          { id: 'id', label: 'Database ID', alignRight: false },
          {
            id: 'type',
            label: 'Name',
            alignRight: false,
            onRender: (value, row) => {
              if (value === 'INDIVIDUAL') {
                return (
                  row.person.firstName + ' ' + row.person.lastName
                )
              } else {
                return row.business.name
              }
            },
          },
          {
            id: 'type',
            label: 'Type',
            alignRight: false,
            onRender: (value, row) => USER_TYPE[value],
          },
          {
            id: 'active',
            label: 'Account Status',
            alignRight: false,
            onRender: value => <ActiveLabel isActive={value} />,
          },
          {
            id: 'status',
            label: 'Kyc Status',
            alignRight: false,
            onRender: status => {
              const { className, iconifyIcon, label } =
                KYC_STATUS_MAP[status]

              return (
                <StatusPill
                  className={clsx('document_status', className)}>
                  <Icon icon={iconifyIcon} className='icon' />
                  {label}
                </StatusPill>
              )
            },
          },
        ]}
        dataWrapper={dataWrapper}
        page={page}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={perPage => {
          setRowsPerPage(perPage)
        }}
        onPageChange={page => {
          setPage(page)
        }}
        onRequestSort={selectedSort => {
          sort[selectedSort.filter] = selectedSort
          setSort({
            ...sort,
            [selectedSort.filter]: selectedSort,
          })
        }}
        onSearch={updateSearchFilter}
        filters={{
          filterSets: filterSets,
        }}
        onTableRowClick={goToViewPage}
      />
    </Page>
  )
}
