import { useState, useEffect } from 'react'
import clsx from 'clsx'

// Material
import { styled } from '@mui/material/styles'

// Components
const ProgressBarWrapper = styled('div')(({ theme }) => ({
  width: '100%',
}))
const ProgressBarMain = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  display: 'block',
  height: '12px',
}))
const ProgressBarFilled = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.blue.secondary,
  borderRadius: '8px',
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  overflow: 'hidden',
  display: 'block',
  transitionProperty: 'transform',
  transitionDuration: '400ms',
}))

// Main
export default function ProgressBar({ autoReset = false, className }) {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (autoReset && oldProgress === 100) {
          return 0
        }

        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [autoReset])

  return (
    <ProgressBarWrapper className={clsx('progress_bar', className)}>
      <ProgressBarMain className='progress_bar_main'>
        <ProgressBarFilled
          className='progress_bar_current'
          style={{ transform: `translateX(${progress - 100}%)` }}
        />
      </ProgressBarMain>
    </ProgressBarWrapper>
  )
}
