import {React, useState} from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

export default function Tags(props) {

    let value = [];
    if (!Array.isArray(props.value)) {
      value = props.value.split(/(\+)|(\-)/)
    }

    console.log(props)
    const filtered = value.filter(it => it);
    const [data, setData] = useState([...filtered]);
    const variablesCombinated = combinateVariables(variableNames, props.providers, props.currencies, props.fees);

  return (
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        multiple
        value={data}
        isOptionEqualToValue= { () => false}
        onChange={(d, b) => {
            setData(b)
            props.onChange(b)
        }}
        options={variablesCombinated.map((option) => option.value)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            let props = getTagProps({ index })
            delete(props.onDelete)
            return <Chip label={option} {...props}/>
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
          />
        )}
      />
      
    </Stack>
  );
}

const combinateVariables = (variableNames, providers, currencies, fees) => {

    let providerArray = []
    console.log(providers)
    for(let i=0; i < providers.length; i++) {

        var provider = providers[i]
        for(let j=0; j < currencies.length; j++) {
            let currency = currencies[j]
            const variableName =  "PROVIDERS>TOTAL_FEES>" + provider.value + ">" + currency.value
            providerArray.push({name: variableName, value: variableName})
        }

        for(let j=0; j < fees.length; j++) {
            var fee = fees[j];
            for(let k=0; k < currencies.length; k++) {
                let currency = currencies[k]
                const variableName = "PROVIDERS>" + fee.value + ">" + provider.value + ">" + currency.value
                providerArray.push({name: variableName, value: variableName})
            }
        }
    }

    for(let n = 0; n < currencies.length; n++) {

        let currency = currencies[n]

        const variableName = "CURRENCIES>TOTAL_FEES>" + currency.value
        providerArray.push({name: variableName, value: variableName})
    }

    return [...variableNames, ...providerArray]
}

const variableNames = [
  { name: 'ORIGINAL_AMOUNT', value: "ORIGINAL_AMOUNT" },
  { name: 'NET_AMOUNT', value: "NET_AMOUNT" },
  { name: 'AMOUNT_FOR_EXCHANGE', value: "AMOUNT_FOR_EXCHANGE" },
  { name: 'GROSS_CONVERTED_AMOUNT', value: "GROSS_CONVERTED_AMOUNT" },
  { name: '+', value: "+" },
  { name: '-', value: "-" },

];