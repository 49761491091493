import { useState } from 'react';
import FileUpload from 'react-material-file-upload';
import { Typography } from '@mui/material';
import Table from './Table'
import { uploadFile, deleteFile, downloadFile } from '../services/uploadService'
import download from 'js-file-download';
import { errorAlert } from '../services/alertService'

export default function ({data, handleChange, customUploadFile=null, hideFiles=false})  {

  const [files, setFiles] = useState([]);

  const handleUpload = async (files) => {
    setFiles(files);

    const promises = files.map(async f => {

      if(customUploadFile) {
        return await customUploadFile(f)
      } else {
        return await uploadFile(f)
      }
    });

    try {
      const uploadedFiles = await Promise.all(promises);
      handleChange([...data, ...uploadedFiles])
      setFiles([])
    } catch(e) {
      errorAlert("Error has occurred while uploading files")
    }
   
  }

  const handleDeleteFile = async (id) => {
    await deleteFile(data[id])
    
    const indexOf = data.indexOf(id)
    data.splice(indexOf, 1)
    handleChange([...data])
  }

  const handleDownload = async (id) => {
    let blob = await downloadFile(data[id])
    download(blob, data[id]);
  }

  return <>
    <FileUpload loading={true} value={files} onChange={(files) => handleUpload(files)} />
       
          { !hideFiles && 
          <>
           <Typography variant="4" gutterBottom>
           Uploaded files
          </Typography>
          <Table
          label={"Uploaded files"}
          hideHeader={true}
          data={{content: data.map((f, id) => ({id, name: f})),  totalElements: data.length}}
          disablePagination={true}
          headers={[ { id: 'id', label: 'Id', alignRight: false }, { id: 'name', label: 'Name', alignRight: false }]}
          filters={[]}
          onDownload={(id) => {
            handleDownload(id)
          }}
          onRemove={(id) => {
            handleDeleteFile(id)
          }}
        /></>}
    </>
  
}