import { useMemo, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'

import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import {
  mapFromIndividualRequest,
  mapFromBusinessRequest,
} from '../../utils/beneficiaries'
import { SECTION_BASE_PATHS } from '../../utils/general'

// Services
import { getBeneficiaryById } from '../../services/beneficiaryService'
import { errorAlert, successAlert } from '../../services/alertService'
import { checkAmlLock, deleteAmlLock, addAmlLock } from '../../services/amlLockService'
import TextareaAutosize from '@mui/material/TextareaAutosize';



// Components
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import View from '../../components/crud/view'

// COnstants
const BENEFICIARIES_BASE_PATH = SECTION_BASE_PATHS.beneficiaries

// Utils - maybe move into utils/general.js
const formatDate = (d) => {
  const date = new Date(d)

  return format(date, 'd MMM, yyyy')
}

export default function BeneficiaryDetailPage(props) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [hasLock, setHasLock] = useState(false)
  const [amlLockId, setAmlLockId] = useState(null)
  const [reason, setReason] = useState(null)


  // Button click handlers
  const goToListPage = () => navigate(`${BENEFICIARIES_BASE_PATH}/list`)
  const goToViewPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/view`)
  const goToTransfersPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/transfers`)
  const goToPaymentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/payments`)
  const goToDocumentsPage = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/documents`)
  const goToBalance = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/balance`)
  const goToStatements = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/statements`)
  const goToAmlCheck = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/amlcheck`)
  const goToKyx = () =>
    navigate(`${BENEFICIARIES_BASE_PATH}/${id}/kyx`)
  const goToRiskDDetails = () =>
      navigate(`${BENEFICIARIES_BASE_PATH}/${id}/risk-details`)

  const fetchData = useCallback(() => {
    setLoading(true)
    getBeneficiaryById(id)
      .then(beneficiary => {
        const data =
          beneficiary.type === 'INDIVIDUAL'
            ? mapFromIndividualRequest(beneficiary)
            : mapFromBusinessRequest(beneficiary)
        setData(data)
        return checkAmlLock(data.integratorId, id)
      })
      .then(amllock => {
        if(amllock == null) {
          setHasLock(false)
        } else {
          console.log(amllock)
          setAmlLockId(amllock.id)
          setHasLock(true)
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setHasLock(false)
        errorAlert('Error has occurred while fetching integrator info')
        setLoading(false)
      })
  }, [setLoading, setHasLock, setAmlLockId, id])

  const fields = useMemo(
    () => [
      {
        label: {
          displayValue: 'Account Status',
        },
        value: {
          displayValue: hasLock ? "Locked" : "Unlocked",
        },
      },
    ],
    [hasLock],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isEmpty(data)) {
    return null
  }

  const tabsData = {
    tabs: [
      { label: 'Basic Info', value: 'view', onClick: goToViewPage },
      {
        label: 'Transfers',
        value: 'transfers',
        onClick: goToTransfersPage,
      },
      {
        label: 'Payments',
        value: 'payments',
        onClick: goToPaymentsPage,
      },
      {
        label: 'Documents',
        value: 'documents',
        onClick: goToDocumentsPage,
      },
      {
        label: 'Balance',
        value: 'balance',
        onClick: goToBalance,
      },
      {
        label: 'AML Check',
        value: 'amlcheck',
        onClick: goToAmlCheck,
      },
      {
        label: 'KYX',
        value: 'kyx',
        onClick: goToKyx,
      },
      {
        label: 'Risk Details',
        value: 'risk-details',
        onClick: goToRiskDDetails,
      }
    ],
    selectedTab: 'amlcheck',
  }

  const onClick = async () => {

    if(hasLock) {
      deleteAmlLock(amlLockId)
        .then(() => {
          successAlert('Account unlocked with success')
          setHasLock(false)
        })
        .catch(() => errorAlert("somethingg went wrong"))
      
    } else {
      await addAmlLock({
        integratorId: data.integratorId,
        beneficiaryId: id,
        reason
      }).then(() => {
        successAlert('Account locked with success')
        setHasLock(true)
        fetchData()
      })
      .catch(() => errorAlert("somethingg went wrong"))
    }
  }

  return (
    <Page className='main_content' title={'Beneficiary | View'}>
      <Loading loading={loading}>
        <PageHeader
          style={{ marginBottom: 32 }}
          backButtonAction={goToListPage}
          heading={`Beneficiary | ${data.firstName || data.name} ${
            data.lastName || ''
          } - Basic Info`}
          tabsData={tabsData}
        />

        <section>
          <View fields={fields} />
          {!hasLock && <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Reason"
            minRows={5}
            style={{ width: '100%' }}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />}
          <Button
            onClick={onClick}
            key='submit_button'>
              {!hasLock ? "Lock Account" : "Unlock Account"}
            
          </Button>
        </section>
      </Loading>
    </Page>
  )
}
