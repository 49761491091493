
import axios from '../config/axiosConfig';

export const notifySwiftStarted = async (request) => {
    const response = await axios.post('v1/batch-operations/settlement-in', request);
    return response.data;
}

export const notifySwiftCompleted = async (request) => {
    const response = await axios.post('v1/batch-operations/settlement-out', request);
    return response.data;
}

export const listBatchOperations = async (type) => {
    const response = await axios.get('v1/batch-operations?currencyExchangeFlows=' + type + '&size=30');
    return response.data;
}


export const getBatchOperationById = async (id) => {
    const response = await axios.get('v1/batch-operations/' + id);
    return response.data;
}