import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import Edit from "../../components/crud/upsert"
import { getAmlLockById, deleteAmlLock } from '../../services/amlLockService'
import { listUsers } from '../../services/userService'
import { errorAlert, successAlert } from '../../services/alertService'
import { isMongoId } from '../../services/stringValidation'
import { listIntegrators } from '../../services/integratorService'
import { listBeneficiaries } from '../../services/beneficiaryService'

// ----------------------------------------------------------------------

export default function AmlLockEdit() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true)
    getAmlLockById(id)
      .then((data) => {

        setData(data)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const updateData = (data) => {
    setLoading(true)
    return deleteAmlLock(id)
        .then(() => {
          setLoading(false)
          setData(data)
          successAlert("Aml lock updated with success")
          setLoading(false)
        })
        .catch((e) => {
          errorAlert(e.message)
        })
  }

  const fields = {
    id: {
      type: "string",
      fieldType: "text",
      label: "Id",
      readOnly: true
    },
    integratorId: {
      type: "string",
      fieldType: "select",
      label: "Integrator",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Integrator id is required'
      }],
      onSearch: async (search) => {

        let query = search;
        if(!isMongoId(search)) {
          query = `filterOr=name:${search},code:${search}`;
        }
        return listIntegrators(query, 0, 5, "")
          .then(data => {
            return data.content
          })
      },
    },
    beneficiaryId: {
      type: 'string',
      fieldType: 'select',
      label: 'Beneficiary',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'Beneficiary is required',
        },
      ],
      onSelected: beneficiaryId => {
        setData({
          ...data,
          beneficiaryId,
        })
      },
      onSearch: async search => {
        let query = search
        if (!isMongoId(search)) {
          query = `filterOr=person.firstName:${search},person.lastName:${search},business.name:${search}`
        }
        return listBeneficiaries(query, 0, 5, '').then(data => {
          return data.content.map(v => {
            return {
              id: v.id,
              name:
                (v.person &&
                  v.person.firstName + ' ' + v.person.lastName) ||
                (v.business && v.business.name),
            }
          })
        })
      },
    },
    reason: {
      type: "string",
      fieldType: "text_area",
      label: "Reason",
      placeholder: "Money laundry",
      readOnly: true,
      constraints: [{
        type: "required",
        message: 'Reason is required'
      }]
    },
    lockOrigin: {
      type: 'string',
      fieldType: 'select',
      label: 'Lock origin',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      options: [{ id: 'CALIZA', name: 'CALIZA' }],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          type: type,
        })
      },
    },
    active: {
      type: 'string',
      fieldType: 'text',
      label: 'Lock status',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      onRender: (v) => {

        return v ? "Enabled" : "Disabled"
      }
    },
    createdBy: {
      type: 'string',
      fieldType: 'select',
      label: 'Created by',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      onSearch: async search => {
        let query = "filterEq=" + search;
        return listUsers(query, 0, 5, '').then(data => {

          console.log(data)
          return data.content.map( v => {
            return {
              id: v.keycloakId,
              name: v.firstName + " " + v.lastName,
            }
          })
      })
      },
    },
    createdDate: {
      type: 'string',
      fieldType: 'text',
      label: 'Created at',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          type: type,
        })
      },
    },
    lastModifiedBy: {
      type: 'string',
      fieldType: 'select',
      label: 'Last modified by',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      onSearch: async search => {
        let query = "filterEq=" + search;
        return listUsers(query, 0, 5, '').then(data => {

          console.log(data)
          return data.content.map( v => {
            return {
              id: v.keycloakId,
              name: v.firstName + " " + v.lastName,
            }
          })
      })
      },
    },
    lastModifiedDate: {
      type: 'string',
      fieldType: 'text',
      label: 'Last modified at',
      readOnly: true,
      constraints: [
        {
          type: 'required',
          message: 'is required',
        },
      ],
      onSelected: (type, allValues) => {
        setData({
          ...allValues,
          type: type,
        })
      },
    },
  };

  return (
    <>
    {
      data && <Edit
      label="Update Country"
      initialValue={data}
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      onSave={(data) => updateData(data)}
      backAction={() => navigate("/dashboard/amllock/list")}
      saveButtonText={"Disable lock"}
    />
    }
    </>
    
  );
}
