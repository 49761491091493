import { useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMongoId } from '../../services/stringValidation'

// material
import Edit from "../../components/crud/upsert"
import { getOperationById, markExchangedFundsAvailable } from '../../services/operationService'
import { errorAlert, successAlert } from '../../services/alertService'
import { removeNumberCommaAndConvertToNumber } from '../../services/stringValidation'
import { SECTION_BASE_PATHS, getCurrency, numberWithCommas } from '../../utils/general'
import { listCurrencies } from '../../services/currencyService'

// ----------------------------------------------------------------------

const EXCHANGED_FUNDS_AVAILABLE_BASE_PATH = SECTION_BASE_PATHS.exchanged_funds_available

export default function ExchangedFundsAvailableEdit() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [operation, setOperation] = useState({});

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = () => {
    setLoading(true)
    getOperationById(id)
      .then((operation) => {

        setOperation(operation)
        setLoading(false)
      })
      .catch((e) => {
        errorAlert(e.message)
        setLoading(false)
      })
  }

  const renderAmount = (amount) => {

    console.log(amount)
    if(!amount)
      return 0

    const num = parseFloat(amount.value)
    return `${getCurrency(
      amount.currencyCode,
    )}${numberWithCommas(num.toFixed(2))}`
  }

  const updateData = (data) => {
    
    const request = {
      referenceId: data.id,
      to: {
        value: removeNumberCommaAndConvertToNumber(data.toAmount),
        currencyCode: data.toAmountCurrency
      },
      taxes: removeNumberCommaAndConvertToNumber(data.taxes)
    }
    setLoading(true)
    return markExchangedFundsAvailable(request)
        .then(() => {
          setLoading(false)
          setOperation(data)
          successAlert("Operation updated with success")
          setLoading(false)
          navigate(`${EXCHANGED_FUNDS_AVAILABLE_BASE_PATH}/list`)
        })
        .catch((e) => {
          errorAlert(e.message)
        })
  }

  const fields = {
    id: {
      type: "string",
      fieldType: "text",
      label: "Id",
      readOnly: true
    },
    startAmount: {
      type: "string",
      fieldType: "text",
      label: "Start Amount",
      onRender: renderAmount,
      readOnly: true
    },
    provider: {
      type: "string",
      fieldType: "text",
      label: "Provider",
      readOnly: true
    },
    externalId: {
      type: "string",
      fieldType: "text",
      label: "External Id",
      readOnly: true
    },
    toAmount: {
      type: "string",
      fieldType: 'number',
      label: "To amount",
      onRender: renderAmount,
      constraints: [{
        type: "min",
        min: 2,
        message: 'Too Short!'
      }, {
        type: "max",
        max: 50,
        message: 'To is required'
      }, {
        type: "required",
        message: 'To is required'
      }]
    },
    toAmountCurrency: {
      type: "string",
      fieldType: "select",
      label: "Currency",
      constraints: [{
        type: "required",
        message: 'Currency is required'
      }],
      onSelected: (a) => console.log("za"),
        options: [
          { id: "USD", name: "Dollar" },
          { id: "BRL", name:  "Real" },
        ],
    },
    taxes: {
      type: "string",
      fieldType: 'number',
      label: "Taxes",
      constraints: [{
        type: "required",
        message: 'Taxes is required'
      }]
    },
  };

  return (
    <>
    {
      operation && <Edit
      label="Update Operation"
      initialValue={operation}
      loading={loading}
      isLoading={(loaded) => setLoading(loaded)}
      fields={fields}
      onSave={(data) => updateData(data)}
      goToListPage={() => navigate(`${EXCHANGED_FUNDS_AVAILABLE_BASE_PATH}/list`)}
    />
    }
    </>
    
  );
}
