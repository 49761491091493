
import axios from '../config/axiosConfig';

export const listPendingWireIn = async (from, to, page, limit) => {
    const url = `/v1/admin/pending-wire?from=${from.toISOString()}&to=${to.toISOString()}&page=${page}&size=${limit}`;
    const response = await axios.get(url)
    response.data.content = response.data.content.map(content => {
        return content;
    })
    return response.data
}